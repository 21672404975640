import { formatDate, formatDateAndTime, mString, snakeToTitleCase } from 'lib/helpers/utility';

import { AllocateAction } from '../components/AllocateAction';

export const getColDefs = (timezone) => [
  {
    field: 'appointment.starts_at',
    headerName: 'Appointment Date',
    valueFormatter: ({ value }) => formatDate(value, timezone),
    filter: 'agDateColumnFilter'
  },
  {
    field: 'created_at',
    headerName: 'Transaction Date',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone),
    filter: 'agDateColumnFilter',
    sort: 'desc'
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: ({ value }) => (value?.length > 0 ? snakeToTitleCase(value.toLowerCase()) : '')
  },
  {
    field: 'amount_paid_self',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'actions',
    cellRenderer: AllocateAction
    // maxWidth: 70,
  }
];

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true, flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
