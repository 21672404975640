import React, { useRef } from 'react';
import NewPackage from './NewPackage';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';

const PackageModal = ({ currentPackage, isOpen, handleClose, filters }) => {
  const formPackageRef = useRef(null);

  return (
    <Modal
      customStyling={{ width: '620px' }}
      bodyClassName="!p-4 !pr-1 !max-h-[unset] !h-full overflow-hidden"
      handleOpen={isOpen}
      handleClose={handleClose}
      title={`${currentPackage ? 'Edit' : 'Create new'} package`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={handleClose}
            id="cancelPackageModalBtn"
            data-qa="cancel-btn"
          />
          <Button
            onClick={() => formPackageRef.current.submitForm()}
            text={currentPackage ? 'Update' : 'Create package'}
            id="savePackageModalBtn"
            data-qa="create-update-package-btn"
          />
        </div>
      }>
      <NewPackage
        ref={formPackageRef}
        onClose={handleClose}
        pack={currentPackage}
        filters={filters}
      />
    </Modal>
  );
};

export default PackageModal;
