import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import adminState from 'components/admin/lib/adminState';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { submitSupervision, updateSupervision } from '/api/Supervision';
import { io, optionify } from '/lib/helpers/utility';
import { useServices } from '/lib/hooks/queries/services/useServices';
import { useMember } from '/lib/hooks/queries/useMember';
import { useSupervisor } from '/lib/hooks/queries/useSupervisor';
import { initialValues } from '../lib/initials';
import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import { extractNewObjectIds, getChanges, processData } from '../lib/helpers';
import { showAlert } from 'components/shared/Alert/Alert';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Select from 'components/shared/Select/Select';
import Icon from 'components/shared/Icon/Icon';

const SupervisorSettings = ({ member, hideModal, showModal }) => {
  const admin = useRecoilValue(adminState.current_admin);
  const [mode, setMode] = useState({ update: false });
  const navigate = useNavigate();

  const onSuccess = (response) => {
    if (!io(response)) return response;

    setMode({ update: true });
    formik.setValues(initialValues(response));
  };
  const sendPracticeIdIfCurrentUserAdmin = !!admin?.user_id &&
    admin?.logged_in &&
    !!admin?.email && {
      params: {
        practiceId: member?.practice_id
      }
    };

  const { data: payers } = usePayers({
    ...sendPracticeIdIfCurrentUserAdmin,
    options: { select: (response) => optionify(response.payers, 'name', 'id') }
  });
  const useSupervisorParams = {
    supervisorIds: [member.user_id],
    withRelations: { user: true, supervisor: true, procedure: true, payer: true }
  };

  const { data } = useSupervisor({
    params: useSupervisorParams,
    options: {
      onSuccess: (response) => onSuccess(response),
      select: ({ data }) => processData(data),
      refetchOnMount: true
    },
    dependencies: [member]
  });

  const { data: providers } = useMember({
    ...sendPracticeIdIfCurrentUserAdmin,
    options: {
      select: (response) => optionify(response.members, (p) => `${p.f_name} ${p.l_name}`, 'user_id')
    },
    dependencies: [member]
  });

  const { data: procedures } = useServices({
    params: {
      ...sendPracticeIdIfCurrentUserAdmin.params,
      offset: 0
    },
    options: {
      select: (response) => optionify(response.services, 'name', 'id')
    },
    dependencies: [member]
  });

  const queryClient = useQueryClient();
  const updateSupervisionMutation = useMutation((data) => updateSupervision(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['supervisorData']);
      showAlert({
        title: 'Success!',
        message: `Supervisor settings updated successfully!`,
        color: 'success'
      });
    }
  });
  const createSupervisionMutation = useMutation((data) => submitSupervision(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['supervisorData']);
      showAlert({
        title: 'Success!',
        message: `Supervisor settings created successfully!`,
        color: 'success'
      });
    }
  });

  const formik = useFormik({
    initialValues: initialValues({ ...data, supervisorId: member?.user_id }),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      if (mode.update) {
        const changes = getChanges(formik, values);

        updateSupervisionMutation.mutate({
          ...changes,
          supervisorId: member.user_id,
          practiceId: member?.practice_id
        });
      } else {
        const supervisorId = member?.user_id;
        const finalizedData = extractNewObjectIds({ ...values, supervisorId });

        createSupervisionMutation.mutate({ ...finalizedData, practiceId: member?.practice_id });
      }

      hideModal();
      setSubmitting(false);
    }
  });

  const Label = ({ label, tippyMessage }) => (
    <div className="flex gap-2">
      <div>{label}</div>
      <Tippy content={tippyMessage} className="tippy-dark">
        <div>
          <Icon icon="new-info" color="neutral" />
        </div>
      </Tippy>
    </div>
  );

  return (
    showModal && (
      <Modal
        handleOpen={showModal}
        handleClose={hideModal}
        title={member?.f_name ? `Supervisor: ${member.f_name}` : ''}
        className="w-[720px] sm:w-full"
        slideFromRight
        footer={
          <div className="flex w-full justify-between">
            <Button outlined text="Cancel" color="neutral" onClick={hideModal} />

            {mode?.update ? (
              <Button
                text="Update"
                loading={updateSupervisionMutation?.isLoading}
                disabled={updateSupervisionMutation?.isLoading}
                onClick={formik.submitForm}
              />
            ) : (
              <Button
                text="Create"
                loading={createSupervisionMutation?.isLoading}
                disabled={createSupervisionMutation?.isLoading}
                onClick={formik.submitForm}
              />
            )}
          </div>
        }>
        <div className="grid gap-y-4">
          <Select
            isMulti
            inputId="providers-select"
            options={providers || []}
            label={Label({
              label: 'Providers',
              tippyMessage: `Please note that any provider entered below will require supervisory signature by ${member.f_name} ${member.l_name}`
            })}
            placeholder="Type and select Providers"
            value={formik?.values?.users || []}
            onChange={(option) => formik.setFieldValue('users', option)}
          />

          <Select
            isMulti
            inputId="payers-select"
            options={payers || []}
            label={Label({
              label: 'Payers',
              tippyMessage: `Supervisory signature is needed by ${member.f_name} ${member.l_name} only if the patient uses one of the insurance companies listed below`
            })}
            placeholder="Type and select Payers"
            value={formik?.values?.payers || []}
            onChange={(option) => formik.setFieldValue('payers', option)}
          />

          <Select
            isMulti
            inputId="procedures-select"
            options={procedures || []}
            label={Label({
              label: 'Services',
              tippyMessage: `Supervisory signature is needed by ${member.f_name} ${member.l_name} only when one of the procedures listed below is rendered AND the patient uses one of the payers selected (if applicable)`
            })}
            placeholder="Type and select Services"
            value={formik?.values?.procedures || []}
            onChange={(option) => formik.setFieldValue('procedures', option)}
          />
        </div>
      </Modal>
    )
  );
};

export default SupervisorSettings;
