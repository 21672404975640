import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from '../../../../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import { showAlert } from '../../../../../shared/Alert/Alert';
import Button from '../../../../../shared/Buttons/Button';
import InfoBox from '../../../../../shared/InfoBox/InfoBox';
import TPModal from '../TPModal';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

export const filterAvailableDates = (item) => {
  if (item?.treatment_frequency_date?.endDate) {
    const endDateMoment = moment(item.treatment_frequency_date.endDate, 'MM/DD/YYYY');
    const currentDate = moment();
    return endDateMoment.isSameOrAfter(currentDate, 'day');
  }
  return false;
};

const noteTypes = ['progress', 'soap'];

const TPAlert = () => {
  const { id, type } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const availableTP = iaRa(treatmentPlan).filter((item) => filterAvailableDates(item));
  const { setAlert, setLoading, setLoadedTP, saveNoteRef } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const [showTPModal, setShowTPModal] = useState(false);

  useEffect(() => {
    if (!clinicalNote?.treatment_plan_id && ia(availableTP) && noteTypes.includes(type)) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [availableTP, clinicalNote?.treatment_plan_id]);

  if (clinicalNote?.treatment_plan_id || !ia(availableTP) || !noteTypes.includes(type)) return null;

  const getDataFromTP = () => {
    if (availableTP.length === 1) {
      setLoading(true);

      const tp = {
        ...availableTP[0],
        icd_10_codes: availableTP[0].icd10
      };

      delete tp.id;
      delete tp.icd10;
      setClinicalNote({
        ...clinicalNote,
        ...tp,
        present_illness: tp?.patient_needs,
        other_complaints: tp?.problem_manifestation_definition,
        user_id: tp.patient_id,
        treatment_plan_id: availableTP[0].id
      });
      showAlert({ title: 'Loaded data successfully!' });
      setLoadedTP(tp);

      setTimeout(() => {
        saveNoteRef.current?.onSaveNote();
        setLoading(false);
      }, 300);
    }
    if (availableTP.length > 1) {
      setShowTPModal(true);
    }
  };

  return (
    <>
      <InfoBox color="primary" text="You have an active Treatment plan">
        <Button
          text="Load data"
          outlined
          transparent
          className="ml-8"
          size="small"
          onClick={getDataFromTP}
        />
      </InfoBox>
      {showTPModal && (
        <TPModal
          isOpen={showTPModal}
          handleClose={() => setShowTPModal(false)}
          loadView="list"
          loadEPNToCN
        />
      )}
    </>
  );
};

export default TPAlert;
