import React from 'react';

import ServiceModal from './components/ServiceModal';
import { defaultColumns, defaultFilters } from './lib/helpers';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import 'react-confirm-alert/src/react-confirm-alert.css';

const Services = () => {
  const columnDefs = defaultColumns();
  const initialFilters = defaultFilters();

  return (
    <>
      <AgTablePage
        ModalComponent={ServiceModal}
        defaultColumns={columnDefs}
        defaultFilters={initialFilters}
        endpoint="/api/practice/services/get"
        name="services"
        queryParams={{ forPractice: true, getPayers: true }}
      />
    </>
  );
};

export default Services;
