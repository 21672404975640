import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import { getStates } from '../../../api/State';
import { genders, marital_status, races } from '../../../constants';
import Account from './index';
const FormValidators = require('../../practice/validation/validate_patient_update');
const validateUpdate = FormValidators.validateUpdate;
const { getFilestackClient } = require('lib/clients/filestack/filestackClient');

const DEFAULT_ADDRESS_OBJECT = {
  address_ln_1: '',
  address_ln_2: '',
  city: '',
  state: '',
  zip: ''
};
const DEFAULT_EMERGENCY_CONTACT_OBJECT = {
  name: '',
  phone: '',
  relationship: ''
};

const AccountContainer = async () => {
  const [patient, setPatient] = useState({
    f_name: '',
    m_name: '',
    l_name: '',
    email: '',
    dob: '',
    gender: '',
    personal_id: '',
    phone: '',
    marital_status: '',
    title: '',
    primary_doctor: '',
    race: '',
    emergency_contact: { ...DEFAULT_EMERGENCY_CONTACT_OBJECT },
    profile_photo: null
  });
  const [address, setAddress] = useState(DEFAULT_ADDRESS_OBJECT);
  const [states, setStates] = useState([]);
  const [imageHandle, setImageHandle] = useState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    f_name: {
      success: false,
      message: ''
    },
    l_name: {
      success: false,
      message: ''
    },
    email: {
      success: false,
      message: ''
    },
    dob: {
      success: false,
      message: ''
    },
    race: {
      success: false,
      message: ''
    },
    gender: {
      success: false,
      message: ''
    },
    phone: {
      success: false,
      message: ''
    },
    address: {
      address_ln_1: {
        success: false,
        message: ''
      },
      address_ln_2: {
        success: false,
        message: ''
      },
      city: {
        success: false,
        message: ''
      },
      state: {
        success: false,
        message: ''
      },
      zip: {
        success: false,
        message: ''
      }
    }
  });

  const filestackClient = await getFilestackClient(navigate);

  useEffect(() => {
    getPatient();
    getStatesMethod();
  }, []);

  const getPatient = async () => {
    try {
      let res = await interimApi('/api/patient/get/one', {}, navigate);
      const { patient: loadedPatient } = res.data;
      if (res.data) {
        let newPatientObject = { ...loadedPatient };
        if (!loadedPatient.emergency_contact) {
          newPatientObject['emergency_contact'] = DEFAULT_EMERGENCY_CONTACT_OBJECT;
        }
        setPatient(newPatientObject);
        setAddress({ ...DEFAULT_ADDRESS_OBJECT, ...loadedPatient.userAddress });
      } else {
        toast.error(`There's been an error loading user. Please try again later.`);
      }
    } catch (error) {
      toast.error(`An unexpected error has occurred. Please try again later.`);
      console.error(error);
    }
  };

  const getStatesMethod = async () => {
    try {
      let res = await getStates(navigate, { method: 'get' });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleChange = (event) => {
    const field = event.target?.name;
    const value = event.target?.value;
    setPatient({
      ...patient,
      [field]: value
    });
  };

  const handleAddressChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setAddress({
      ...address,
      [field]: value
    });
  };

  const handleEmergencyContactChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    let emergency_contact = {};
    Object.assign(emergency_contact, patient.emergency_contact);
    emergency_contact[field] = value;
    setPatient({ ...patient, emergency_contact });
  };

  const submitUpdate = async (patient, address) => {
    try {
      let res = await interimApi(
        '/api/patient/update',
        {
          patient,
          address
        },
        navigate
      );
      if (res.status === 200) {
        toast.success('Account updated successfully');
      } else {
        toast.error('There was an error updating your account');
      }
    } catch (err) {
      Honeybadger.notify(`Updating data submit error. ${err}`);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let payload = validateUpdate(patient, address);
    if (payload.success) {
      setErrors({
        f_name: {
          success: false,
          message: ''
        },
        l_name: {
          success: false,
          message: ''
        },
        email: {
          success: false,
          message: ''
        },
        dob: {
          success: false,
          message: ''
        },
        race: {
          success: false,
          message: ''
        },
        gender: {
          success: false,
          message: ''
        },
        phone: {
          success: false,
          message: ''
        },
        address: {
          address_ln_1: {
            success: false,
            message: ''
          },
          address_ln_2: {
            success: false,
            message: ''
          },
          city: {
            success: false,
            message: ''
          },
          state: {
            success: false,
            message: ''
          },
          zip: {
            success: false,
            message: ''
          }
        }
      });
      submitUpdate(patient, address);
    } else {
      const errors = payload.errors;
      setErrors({
        f_name: {
          success: false,
          message: errors.f_name
        },
        l_name: {
          success: false,
          message: errors.l_name
        },
        email: {
          success: false,
          message: errors.email
        },
        dob: {
          success: false,
          message: errors.dob
        },
        race: {
          success: false,
          message: errors.race
        },
        gender: {
          success: false,
          message: errors.gender
        },
        phone: {
          success: false,
          message: errors.phone
        },
        address: {
          address_ln_1: {
            success: false,
            message: errors.address_ln_1
          },
          address_ln_2: {
            success: false,
            message: errors.address_ln_2
          },
          city: {
            success: false,
            message: errors.city
          },
          state: {
            success: false,
            message: errors.state
          },
          zip: {
            success: false,
            message: errors.zip
          }
        }
      });
    }
  };

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    filestackClient.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    const handle = {
      jpg: data.filesUploaded[0].handle
    };
    let res = await interimApi(
      '/api/filestack/profile_photo',
      {
        handle,
        userId: patient.id
      },
      navigate
    );
    if (res.data.profilePhoto > 0) {
      setPatient({ ...patient, profile_photo: JSON.stringify(handle) });
    } else {
      console.error('There was an error uploading this image. Please try again.');
      toast.error('Uploading image failed. Please try again');
    }
  };

  return (
    <div>
      {states && (
        <Account
          onSubmit={validateForm}
          onChange={handleChange}
          onAddressChange={handleAddressChange}
          errors={errors}
          patient={patient}
          address={address}
          genderList={genders}
          raceList={races}
          stateList={states}
          maritalStatusList={marital_status}
          onHandleEmergencyContactChange={handleEmergencyContactChange}
          openFilestackPicker={openFilestackPicker}
        />
      )}
    </div>
  );
};

export default AccountContainer;
