import * as filestack from 'filestack-js';
import { getSecurity } from 'api/Filestack';

let filestackClient = null;
let filestackSecurity = null;
let lastFetchTime = null;

const THIRTY_MINUTES = 30 * 60 * 1000;

export const getFilestackClient = async (navigate) => {
  const now = new Date().getTime();

  if (!filestackClient || !filestackSecurity || now - lastFetchTime > THIRTY_MINUTES) {
    try {
      const response = await getSecurity(navigate);
      filestackSecurity = response?.security || {};
      lastFetchTime = now;

      filestackClient = filestack.init(process.env.REACT_APP_FILESTACK, {
        security: { ...filestackSecurity }
      });
    } catch (error) {
      console.error('Failed to fetch Filestack security data:', error);
      return null;
    }
  }

  return filestackClient;
};
