const { ia, stripHtmlTags, isEmpty } = require('lib/helpers/utility');

exports.createAllergiesNarrativeHelper = (data = {}) => {
  if (data?.noDetails || !ia(data?.allergies)) {
    return 'No Known Allergies';
  }

  return data?.allergies
    .filter((allergy) => !!allergy?.allergy?.value)
    .map((allergy) => {
      let narrative = `The patient reports an allergy to ${allergy?.allergy?.label}.`;

      if (allergy?.typicalReaction?.label !== 'None') {
        narrative += ` The typical reaction is ${allergy?.typicalReaction?.label ? allergy.typicalReaction.label?.toLowerCase() : 'not specified'}.`;
      }

      if (allergy?.note) {
        narrative += `\n${allergy.note}`;
      }

      return narrative;
    })
    .join('\n\n');
};

exports.createAllergiesNarrativeHelperV2 = (data = []) => {
  if (!ia(data)) {
    return '<p>No Known Allergies</p>';
  }

  return data
    .filter((allergy) => !!allergy?.allergy?.value)
    .map((allergy) => {
      let narrative = '<p>';

      narrative += `Patient reports an allergy to ${allergy?.allergy?.label}.`;

      if (allergy?.typicalReaction?.label !== 'None') {
        narrative += ` The typical reaction is ${allergy.typicalReaction?.label ? allergy.typicalReaction.label.toLowerCase() : 'not specified'}.`;
      }

      narrative += '</p>'; // Close the main allergy paragraph

      if (!isEmpty(stripHtmlTags(allergy?.note))) {
        narrative += `<p>Note: ${allergy.note}</p>`;
      }

      return narrative;
    })
    .join('<br>');
};
