import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';

import TableView from 'components/shared/Tags/components/TableView';

import { handleUpdateFaxTags } from '../lib/updateFaxTags';

const TagsCell = ({ value, data }) => {
  const { limit, page, sort, filters, name } = useTableContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCreateModal, setIsCreateModal] = useState(false);

  const getQueryKeys = (name) => {
    if (name === 'outbound_faxes') {
      return ['outboundFaxes', limit, page, sort, mapValues(filters)];
    } else if (name === 'inbound_faxes') {
      return ['inboundFaxes', limit, page, sort, mapValues(filters)];
    } else if (name === 'archived_faxes') {
      return ['archivedFaxes', limit, page, sort, mapValues(filters)];
    } else if (name === 'drafts_faxes') {
      return ['draftFaxes', limit, page, sort, mapValues(filters)];
    }
    return [];
  };

  const debouncedUpdateTags = debounce(async (faxId, tags, mode) => {
    let queryKeys = getQueryKeys(name);

    await handleUpdateFaxTags({ navigate, queryClient, faxId, tags, mode, queryKeys });
  }, 1000);

  const handleAddTags = (fax, tag) => {
    const previousTagIds = value?.map((v) => v?.id) || [];
    const newTagIds = tag?.map((t) => t?.id);
    const allTags = [...previousTagIds, ...newTagIds];

    debouncedUpdateTags(fax?.id, allTags, 'add');
  };

  const handleRemoveTag = async (fax, id) => {
    let queryKeys = getQueryKeys(name);

    const filterRemovedTag = value?.filter((v) => v?.id !== id);
    const tagIds = filterRemovedTag?.map((f) => f?.id);
    await handleUpdateFaxTags({
      navigate,
      queryClient,
      faxId: fax?.id,
      tags: tagIds,
      mode: 'remove',
      queryKeys
    });
  };

  const tableActions = [
    {
      label: 'Create Tag',
      icon: 'new-tag-new',
      onClick: () => setIsCreateModal(true)
    },
    {
      label: 'Manage Tags',
      icon: 'new-tag-settings',
      onClick: () => navigate('/portal/settings/tags'),
      disabled: false
    }
  ];

  return (
    <TableView
      currTags={value || []}
      tagsKind="fax"
      handleAdd={(tag) => handleAddTags(data, tag)}
      handleRemove={(id) => handleRemoveTag(data, id)}
      removeConfirmationTitle="Are you sure you want to remove this tag from the fax?"
      isCreateModal={isCreateModal}
      setIsCreateModal={setIsCreateModal}
      tableActions={tableActions}
      usePendingState
      fromTable
      fromFaxes
    />
  );
};

export default TagsCell;
