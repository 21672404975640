import { currentPractice } from 'components/practice/practiceState';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import UpsertProviderMedication from 'components/shared/Forms/Custom/ProviderMedications/components/UpsertMedication';
import {
  getRowId,
  onRowClicked
} from 'components/shared/Forms/Custom/ProviderMedications/lib/medicationTableSelectHelpers';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import { permissions } from 'components/state';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import MedicationsTable from './components/MedicationsTable';
import { medicationFooterText } from './lib/medication';
import medicationsCols from './lib/medicationsCols';
import MedicationWidgetFooter from './lib/MedicationWidgetFooter';

export default function Medications(props) {
  const userPermissions = useRecoilValue(permissions);
  const practice = useRecoilValue(currentPractice);
  const [itemModal, setItemModal] = useState({ open: false, data: null });

  return (
    <TableContextProvider
      cols={medicationsCols({
        setShowMedicationModal: setItemModal,
        userPermissions,
        practice,
        showCheckboxes: true
      })}
      name="Medications"
      defaultFilters={[]}>
      <Table
        props={props}
        itemModal={itemModal}
        setItemModal={setItemModal}
        userPermissions={userPermissions}
        practice={practice}
      />
    </TableContextProvider>
  );
}

const Table = ({ props, itemModal, setItemModal, userPermissions, practice }) => {
  const [show, setShow] = useState(false);
  const { selectedRows, gridApi, setColDefs } = useTableContext();
  const formattedData = iaRa(selectedRows)?.map((row) => {
    const { data } = row;
    return data;
  });
  useEffect(() => {
    if (!gridApi) return;

    setColDefs(
      medicationsCols({
        setShowMedicationModal: setItemModal,
        userPermissions,
        practice,
        showCheckboxes: true,
        gridApi
      })
    );
  }, [gridApi]);

  const onCellClicked = async (e) => {
    if (['0', 'actions'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }

    await onRowClicked({ e, gridApi });
  };

  const { patientId } = props;

  const { data } = useMedication({
    params: { patientId },
    dependencies: [String(patientId)],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications)
    }
  });
  const openItemModal = (item = null) => {
    setItemModal({ data: item, open: true });
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto" data-dd-privacy="allow">
      <WidgetHeader
        onPlusClick={() => openItemModal()}
        icon="medication"
        addPermissions="patients_medical_history.update"
        {...props}></WidgetHeader>
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <ClinicalNoteContextProvider>
          <TableContextProvider
            cols={medicationsCols({
              setShowMedicationModal: setItemModal,
              userPermissions,
              practice,
              showCheckboxes: false
            })}
            name="medications"
            defaultFilters={[]}>
            <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
              <FooterWrapper onClick={() => setShow(true)} text={medicationFooterText(data)}>
                {data ? (
                  <MedicationsTable data={data} getRowId={getRowId} />
                ) : (
                  <div className="p-[20px] py-3 text-center">
                    <p className="font-medium text-neutral-500">No Medications</p>
                  </div>
                )}
              </FooterWrapper>
            </div>
          </TableContextProvider>

          <Modal
            handleOpen={show}
            className="w-[91%] bg-white sm:w-[96%] md:w-[96%] lg:w-[96%] xxl:max-w-[1920px]"
            bodyClassName="p-0"
            title="Medications"
            slideFromRight
            footer={
              <MedicationWidgetFooter
                gridApi={gridApi}
                formattedData={formattedData}
                setShow={setShow}
              />
            }
            handleClose={() => setShow(false)}>
            <MedicationsTable data={data} onCellClicked={onCellClicked} getRowId={getRowId} />
          </Modal>

          {itemModal?.open && (
            <UpsertProviderMedication showModal={itemModal} hideModal={() => setItemModal(null)} />
          )}
        </ClinicalNoteContextProvider>
      </Allowed>
    </div>
  );
};
