import { queryClient } from 'App';
import { iaRa } from 'lib/helpers/utility';

export default async (data) => {
  const { patient_id, insuranceProfile } = data;

  queryClient.setQueryData(['insurance-profiles', patient_id], (oldData) => {
    if (!oldData) return;
    const newInsExists = iaRa(oldData.insuranceProfile).find((it) => it.id === insuranceProfile.id);

    return {
      ...oldData,
      insuranceProfile: newInsExists
        ? oldData.insuranceProfile
        : [...oldData.insuranceProfile, insuranceProfile]
    };
  });
};
