import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import state from '../../../state';
import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { showAlert } from 'components/shared/Alert/Alert';

const Upload = ({ patientId, setDocuments, fromPatient }) => {
  const [user_state, setUserState] = useRecoilState(state.userState);
  const navigate = useNavigate();

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*', '.pdf'],
      displayMode: 'inline',
      container: 'FileStackInline',
      fromSources: ['local_file_system'],
      maxFiles: 15,
      maxSize: 50 * 1024 * 1024,
      uploadInBackground: false
    };

    pickerOptions.onUploadDone = async (documents) => {
      try {
        let params = {
          documents: documents.filesUploaded,
          patientId
        };

        const { f_name, l_name } = user_state || 0;

        let { data } = await interimApi('/api/practice/charts/documents/create', params, navigate);

        if (!data.documents) {
          return showAlert({ title: 'Failed to upload files!', color: 'danger' });
        }

        data = data?.documents?.map((file) => ({
          ...file,
          category: 'clinical',
          uploader_name: `${f_name || ''} ${l_name || ''}`
        }));

        setDocuments((prevDocuments) => [...data, ...prevDocuments]);
      } catch (error) {
        console.error(error);
      }
    };

    filestackClient.picker(pickerOptions).open();
  };

  useEffect(() => {
    openFilestackPicker();
  }, []);

  return (
    <div
      id="FileStackInline"
      className={`${
        fromPatient && 'w-full'
      } FilestackInline overflow-hidden !rounded-lg border !border-dashed !border-[#dcdcdc]`}
      data-qa="select-files-to-upload"></div>
  );
};

export default Upload;
