import { useQuery } from '@tanstack/react-query';
import { getDashboardWidget } from 'api/Widgets';
import { useNavigate } from 'react-router-dom';

export const useDashboard = (params = {}, options = {}) => {
  const navigate = useNavigate();
  const { startAt, endAt, appointmentViewType } = params;

  return useQuery(
    ['dashboard', startAt, endAt, appointmentViewType],
    () => getDashboardWidget(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5,
      ...options
    }
  );
};
