import { Honeybadger } from '@honeybadger-io/react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { currentPractice } from '../../practiceState';
import ServiceEntryComponent from './ServiceEntryComponent';

const NewService = forwardRef(({ formik }, ref) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({ loading: false });
  const [practitioners, setPractitioners] = useState([]);
  const practice = useRecoilValue(currentPractice);
  const [ndcCode, setNdcCode] = useState(false);

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));
  const updatePaySchedule = (e) => {
    const paySchedule = formik.values.paySchedule;
    let updatedPaySchedule = Object.assign([], paySchedule);
    let payScheduleIndex = paySchedule.findIndex(
      (schedule) => schedule.service_id === e.service_id && schedule.payer_id === e.payer_id
    );
    if (payScheduleIndex === -1) {
      updatedPaySchedule.push(e);
    } else {
      updatedPaySchedule[payScheduleIndex] = { ...updatedPaySchedule[payScheduleIndex], ...e };
    }
    formik.setFieldValue('paySchedule', updatedPaySchedule);
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  const selectCPT = (e) => {
    const parAmount = e.par_amount.toFixed(2);

    formik.setFieldValue('procedure_code', e.value);
    formik.setFieldValue('description', e.description || formik.values.description);
    formik.setFieldValue('total_cost_cents', parAmount);
    formik.setFieldValue(
      'standard_charge',
      parseFloat(
        parAmount *
          (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage / 100 || 1)
      ).toFixed()
    );
  };

  const getPractitioners = async () => {
    try {
      const {
        data: { members }
      } = await interimApi('/api/practice/member/get', {}, navigate);
      if (members) {
        setPractitioners(
          members.map((practitioner) => ({
            value: practitioner.user_id,
            label: `${practitioner.f_name} ${practitioner.l_name}`
          }))
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /services/new_service, method: getPractitioners - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  return (
    <div>
      <ServiceEntryComponent
        selectCPT={selectCPT}
        ndcCode={ndcCode}
        setNdcCode={setNdcCode}
        practitioners={practitioners}
        formik={formik}
        updatePaySchedule={updatePaySchedule}
      />
      {show.loading && <div>Loading...</div>}
    </div>
  );
});

export default NewService;
