import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import React, { useCallback, useMemo, useState } from 'react';
import AGTable from '../AGTable/AGTable';
import TableHeader from './components/TableHeader';
import { CustomStatusBarCount } from '../AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from '../AGTable/CustomStatusBarPagination';
import CustomStatusBarAggregationComponent from '../AGTable/CustomStatusBarAggregationComponent';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS } from './lib/constants';
import { useFetchData } from 'lib/hooks/queries/useFetchData';
import { mapValues } from 'lib/helpers/utility';

const AgTablePage = ({
  name,
  endpoint,
  queryParams = {},
  defaultColumns,
  defaultFilters,
  ModalComponent
}) => {
  return (
    <TableContextProvider
      defaultFilters={defaultFilters}
      cols={defaultColumns}
      name={name}
      pagination>
      <AgTablePageContent
        endpoint={endpoint}
        queryParams={queryParams}
        ModalComponent={ModalComponent}
      />
    </TableContextProvider>
  );
};

const AgTablePageContent = ({ endpoint, queryParams = {}, ModalComponent }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { name, limit, page, sort, setPage, filters } = useTableContext();

  const queryFilters = useMemo(() => {
    return mapValues(filters);
  }, [filters]);

  const { data, isLoading } = useFetchData({
    queryKey: name,
    url: endpoint,
    params: {
      limit,
      page,
      sort,
      filters: queryFilters,
      withCount: true,
      ...queryParams
    },
    dependencies: [limit, page, sort, queryFilters]
  });

  const items = useMemo(() => data?.[name] || [], [data, name]);
  const count = data?.count;
  const statusPanelData = data?.statusPanelData;

  const onCellClicked = (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    setSelectedItem(event?.data);
    setShowModal(true);
  };

  const onModalToggle = () => {
    setShowModal((prev) => !prev);
    setSelectedItem(null);
  };

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: items,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: items,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        ...(statusPanelData
          ? [
              {
                statusPanel: CustomStatusBarAggregationComponent,
                statusPanelParams: {
                  statusPanelData
                },
                align: 'center'
              }
            ]
          : [])
      ]
    };
  }, [items, count, page, limit, onPageChange, statusPanelData]);

  return (
    <>
      <TableHeader category={name} onModalToggle={onModalToggle} />
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz h-full">
          <AGTable
            data={items}
            gridOptions={GRID_OPTIONS}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            onCellClicked={onCellClicked}
            loading={isLoading}
            statusBar={statusBar}
            reactiveCustomComponents={onCellClicked}
          />
        </div>
      </div>
      {showModal && ModalComponent && (
        <ModalComponent
          item={selectedItem}
          isOpen={showModal}
          handleClose={onModalToggle}
          filters={filters}
        />
      )}
    </>
  );
};

export default AgTablePage;
