import { DEFAULT_FORMATS } from './constants';

export const generateDescription = (input) => {
  const types = input.split(',').map((type) => type.trim().toUpperCase());

  const formattedTypes =
    types.length > 1 ? types.slice(0, -1).join(', ') + ' and ' + types.slice(-1) : types[0];

  return `${formattedTypes} formats, up to 50MB.`;
};

export const getAcceptAttribute = (formats = DEFAULT_FORMATS) => {
  return formats
    .split(',')
    .map((format) => `.${format.trim()}`)
    .join(', ');
};

export const isFileAllowed = (file, formats) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();
  return formats.includes(fileExtension);
};
