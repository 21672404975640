import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { defaultFilters } from './lib';
import ResourcesTable from './components/ResourcesTable';
import { defaultColumns } from './lib/resourceTableCols';

export default function Superbills() {
  const columnDefs = defaultColumns();

  return (
    <TableContextProvider
      cols={columnDefs}
      pagination
      name="resources"
      defaultFilters={defaultFilters}>
      <ResourcesTable />
    </TableContextProvider>
  );
}
