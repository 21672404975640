import { queryClient } from 'App';
import { iaRa } from 'lib/helpers/utility';

export default async (data) => {
  const { note_id, patient_id } = data;

  queryClient.setQueryData(['sticky-notes', patient_id], (oldData) => {
    if (oldData) {
      return {
        ...oldData,
        notes: iaRa(oldData.notes).filter((note) => note.id !== note_id)
      };
    }
  });
};
