import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import { creationActionAppointment, remindAppointment } from 'api/Appointment';
import { ia, pm } from 'lib/helpers/utility';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { useFormikContext } from 'formik';
import NoShowConfirmation from './Sections/Timeline/components/content/components/NoShowConfirmation';

const FooterAction = ({ handleClose }) => {
  const [appointmentConfirmation, setAppointmentConfirmation] = useState(false);
  const [appointmentReminder, setAppointmentReminder] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [restoreConfirmationModal, setRestoreConfirmationModal] = useState(false);
  const [loading, setLoading] = useState({});
  const permissions = useRecoilValue(state.permissions);
  const { values, initialValues, setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (values.status === 'cancelled' && initialValues.status !== 'cancelled') {
      setShowConfirmationModal(true);
    }
    if (
      !['cancelled', 'no_show'].includes(values.status) &&
      ['cancelled', 'no_show'].includes(initialValues.status)
    ) {
      setRestoreConfirmationModal(true);
    }
  }, [values.status]);

  const handleRemind = async (type = '') => {
    const params = { appointment_id: values.id, type };
    await remindAppointment(navigate, params);
    showAlert({ message: 'Appointment reminder sent successfully', type: 'success' });
    setAppointmentReminder(false);
  };

  const handleCreationAction = async (type = '') => {
    const params = {
      appointment_id: values.id,
      type
    };
    await creationActionAppointment(navigate, params);
    showAlert({ message: 'Appointment creation sent successfully', type: 'success' });
    setAppointmentConfirmation(false);
  };

  const cancelAppointment = async () => {
    setLoading({ ...loading, cancel: true });

    const onSuccess = () => {
      setShowConfirmationModal(false);
      showAlert({ message: 'Appointment was canceled', color: 'success' });

      queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.id === values.id ? { ...values, status: 'cancelled' } : apt
        )
      }));

      queryClient.setQueryData(['appointmentv2', values?.id], (oldData) => {
        return {
          ...oldData,
          appointment: { ...values, status: 'cancelled', appointment_disabled: true }
        };
      });
      handleClose();
    };

    await requestApi({
      url: '/api/appointment/cancel',
      navigate,
      params: { id: values?.id },
      onSuccess
    });
    setLoading({ ...loading, cancel: false });
  };

  const restoreAppointment = async () => {
    setLoading({ ...loading, restore: true });

    const onSuccess = (data) => {
      const updatedAppointment = data?.updatedAppointment;
      setRestoreConfirmationModal(false);
      showAlert({ message: 'Appointment was restored', color: 'success' });
      queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.id === values.id ? { ...values, ...updatedAppointment } : apt
        )
      }));
      queryClient.setQueryData(['appointmentv2', values?.id], (oldData) => {
        return {
          ...oldData,
          appointment: { ...values, ...updatedAppointment }
        };
      });
    };

    const onError = (error) => {
      setRestoreConfirmationModal(false);
      showAlert({ message: error, color: 'danger' });
    };

    const params = {
      id: values?.id,
      ...(!['cancelled', 'restore', 'no_show'].includes(values.status) && {
        status: values.status
      })
    };

    await requestApi({
      url: '/api/appointment/restore',
      navigate,
      params,
      onSuccess,
      onError
    });
    setLoading({ ...loading, restore: false });
  };

  const cancelAppointmentMessage =
    'Are you sure you want to cancel this appointment?' +
    (values?.invoice?.amount_paid > 0
      ? " We do not recommend cancelling appointments that have finished payments. Once done the payment will be reallocated to the patient's balance"
      : '');

  let restoreAppointmentMessage = `Are you sure you want to restore this appointment?`;

  if (ia(values?.invoice?.products)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment will cause negative inventory levels if the stock is empty. \n Proceeding with the restoration will set these products to negative inventory. Do you wish to continue?';
  }

  if (values?.invoice?.procedures?.some?.((p) => p?.invoiceId)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment involves a package that has already been fully utilized. As a result, the balance for this package will not be adjusted, and no additional services or products will be available for this appointment. \n Proceeding with the restoration will not add any new balances to the package. Do you wish to continue?';
  }

  const isAptDisabled = values.appointment_disabled;

  const handleCloseCancelConfirmation = () => {
    setFieldValue('status', initialValues.status);
    setShowConfirmationModal(false);
  };
  const handleCloseRestoreConfirmation = () => {
    setFieldValue('status', initialValues.status);
    setRestoreConfirmationModal(false);
  };

  return (
    <div>
      {!['cancelled', 'no_show'].includes(initialValues.status) && (
        <>
          <div className="flex items-center gap-3" data-dd-privacy="allow">
            <Popover className="relative">
              {({ close, open }) => (
                <div data-dd-privacy="allow">
                  <Popover.Button
                    disabled={!pm(permissions, 'appointments.update') || isAptDisabled}
                    className="flex h-[30px]  items-center justify-center  bg-white shadow-small">
                    <div
                      data-dd-privacy="allow"
                      data-qa="cancel-appointment"
                      className={`flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md  ${open ? 'bg-primary-700' : 'bg-white'}`}>
                      <Icon icon="three-dots" />
                    </div>
                  </Popover.Button>

                  <Popover.Panel
                    className={cs(
                      'absolute -top-[130px] right-1 z-[99999] min-w-[270px] cursor-pointer   rounded-lg  bg-white !py-[6px] shadow-lg'
                    )}>
                    <div
                      data-dd-privacy="allow"
                      className="relative z-50 flex items-center justify-between gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onMouseEnter={() => setAppointmentConfirmation(true)}
                      onMouseLeave={() => setAppointmentConfirmation(false)}>
                      <div className="flex items-center gap-1">
                        <Icon icon="calendar-tick" />
                        <div className="text-sm text-primary-900">Resend Appointment Creation</div>
                      </div>
                      <Icon icon="chevron-right" color="primary" />
                      {appointmentConfirmation && (
                        <div
                          className="absolute -right-[125px] -top-1  z-30 rounded-md bg-white !py-[6px] shadow-sm"
                          data-dd-privacy="allow">
                          <div
                            data-dd-privacy="allow"
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleCreationAction('email')}>
                            <Icon icon="new-email-blue" size="15px" />
                            <div className="text-sm text-primary-900">Email</div>
                          </div>
                          <div
                            data-dd-privacy="allow"
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleCreationAction('text_message')}>
                            <Icon icon="text-message" size="15px" />
                            <div className="text-sm text-primary-900">Text Message</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      data-dd-privacy="allow"
                      className="flex items-center justify-between gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        close();
                      }}
                      onMouseEnter={() => setAppointmentReminder(true)}
                      onMouseLeave={() => setAppointmentReminder(false)}>
                      <div className="flex items-center gap-1" data-dd-privacy="allow">
                        <Icon icon="calendar-reminder" />
                        <div className="text-sm text-primary-900">
                          Resend Appointment Reminder/Forms{' '}
                        </div>
                      </div>
                      <Icon icon="chevron-right" color="primary" className="ml-3" />
                      {appointmentReminder && (
                        <div
                          className="absolute -right-[125px] top-10  z-30 rounded-md bg-white !py-[6px] shadow-sm"
                          data-dd-privacy="allow">
                          <div
                            data-dd-privacy="allow"
                            className="relative flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleRemind('email')}>
                            <Icon icon="new-email-blue" size="15px" />
                            <div className="text-sm text-primary-900">Email</div>
                          </div>
                          <div
                            data-dd-privacy="allow"
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleRemind('text_message')}>
                            <Icon icon="text-message" size="15px" />
                            <div className="text-sm text-primary-900">Text Message</div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        setShowConfirmationModal(true);
                        close();
                      }}>
                      <Icon icon="calendar-remove" color="primary" size="18px" />
                      <div className="text-sm text-primary-900">Cancel Appointment </div>
                    </div>
                  </Popover.Panel>
                </div>
              )}
            </Popover>
          </div>
        </>
      )}
      {isAptDisabled && (
        <>
          <div className="flex items-center gap-3">
            <Button
              data-qa="restore-appointment"
              color="success"
              loading={loading.restore}
              onClick={() => {
                setRestoreConfirmationModal(true);
              }}
              text="Restore Appointment"
              size="small"
            />
          </div>
        </>
      )}
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={handleCloseCancelConfirmation}
        handleContinue={cancelAppointment}
        title="Cancel appointment"
        message={cancelAppointmentMessage}
        primaryBtnTxt="Yes, cancel the appointment"
        secondaryBtnTxt="Cancel"
        icon="new-calendar-red"
        variant="danger"
        loading={loading?.cancel}
        // {...(values.status === 'cancelled' && {
        //   slideFromRight: false,
        //   overlayClassName: 'bg-transparent top-[calc(100%+4px)] absolute right-0 w-max h-max',
        //   parentSelector: () => document.getElementById('appointment-statuses'),
        //   customClassName: '!transition-none'
        // })}
      />
      <Confirm
        variant="success"
        secondaryBtnTxt="Cancel"
        iconColor="success"
        icon="restore-appointment"
        title="Restore appointment"
        loading={loading?.restore}
        message={restoreAppointmentMessage}
        handleContinue={restoreAppointment}
        primaryBtnTxt="Yes, restore the appointment"
        handleOpen={restoreConfirmationModal}
        handleClose={handleCloseRestoreConfirmation}
      />
      <NoShowConfirmation handleClose={handleClose} />
    </div>
  );
};

export default FooterAction;
