import { camelCaseToReadableFormat, formatDate, ia, iaRa } from 'lib/helpers/utility';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';

const formatArrayValue = (arr) => {
  return arr
    .map((item) => {
      if (typeof item === 'object' && item !== null) {
        return Object.entries(item)
          .filter(([_, val]) => val !== null)
          .map(([key, val]) => val)
          .join(', ');
      }
      return item;
    })
    .filter(Boolean)
    .join(', ');
};
export const formatDisplayValue = (subKey, subValue, parentKey) => {
  let displayValue;

  if (parentKey === 'children') {
    if (subKey === 'yes' && subValue === true) {
      return 'Yes';
    }
    if (subKey === 'numberOfChildren') {
      return `Number of children: ${subValue}`;
    }
    if (subKey === 'ages' && ia(subValue)) {
      const elements = iaRa(subValue).filter(
        (item) => typeof item === 'string' && item.trim() !== ''
      );
      if (!ia(elements)) return null;
      return `Ages: ${elements.join(', ')}`;
    }
  }

  // General cases
  if (subKey === 'yes' && subValue === true) {
    displayValue = camelCaseToReadableFormat(subKey);
  } else if (subKey === 'contracteption' && subValue === true) {
    displayValue = 'Contraception: Yes';
  } else if (ia(subValue)) {
    displayValue = `${camelCaseToReadableFormat(subKey)}: ${formatArrayValue(subValue)}`;
  } else if (typeof subValue === 'string' && moment(subValue, moment.ISO_8601).isValid()) {
    displayValue = `${camelCaseToReadableFormat(subKey)}: ${formatDate(subValue)}`;
  } else if (subKey !== 'yes' && subValue === true) {
    displayValue = camelCaseToReadableFormat(subKey);
  } else {
    displayValue = `${camelCaseToReadableFormat(subKey)}: ${subValue}`;
  }

  return displayValue;
};
export const emptyChildrenFormat = (currentChildrenFormat) => {
  const emptyAges = { no: false, yes: false, numberOfChildren: null, ages: [] };
  return isEqual(emptyAges, currentChildrenFormat);
};
