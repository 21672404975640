import Button from 'components/shared/Buttons/Button';
import { clinicalNote as clinicalNoteState } from 'components/state';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import UpsertVitals from './components/UpsertVitals';
import VitalNoteHeader from './components/VitalNoteHeader';
import VitalsMenu from './components/VitalsMenu';
const MainVitals = () => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [showVitalsUpsertModal, setShowVitalsUpsertModal] = useState(false);
  const [vital, setVital] = useState({});
  return (
    <>
      <VitalNoteHeader title="Vitals">
        <Button
          text="Add Vitals"
          icon="new-add-square"
          disabled={clinicalNote?.locked}
          iconColor="white"
          size="small"
          onClick={() => {
            setShowVitalsUpsertModal(true);
            setVital({});
          }}
        />
      </VitalNoteHeader>
      <VitalsMenu setShowVitalsUpsertModal={setShowVitalsUpsertModal} setVital={setVital} />
      {showVitalsUpsertModal && (
        <UpsertVitals
          vital={vital}
          disabled={clinicalNote?.locked}
          showModal={showVitalsUpsertModal}
          hideModal={() => setShowVitalsUpsertModal(false)}
        />
      )}
    </>
  );
};
export default MainVitals;
