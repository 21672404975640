import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { useRecoilValue } from 'recoil';
import NewAppointmentModal from 'components/practice/Dashboard/components/NewAppointmentModal';
import {
  appointmentStatuses as aptStatuses,
  currentPractice
} from 'components/practice/practiceState';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, ia, io } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';
import { userState } from 'components/state';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import NotFound from '../../../components/NotFound';
import { getAptStatsBasedOnType, getColorForStatus, getStatusLabel } from './lib';

const Appointments = (props) => {
  const { dashboardData, item, layout, isFetching } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const user = useRecoilValue(userState);
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const { saveDisplaySettings } = useDisplaySettings(userState);

  const updateAppointmentSettings = (viewType) => {
    saveDisplaySettings({
      ...user.display_settings,
      appointment: {
        ...user.display_settings.appointment,
        dashboard_view_type: viewType
      }
    });
  };
  const viewType = user?.display_settings?.appointment?.dashboard_view_type || 'status';

  const { height, width, proportion } = useChartSize(container);

  const practice = useRecoilValue(currentPractice);
  let totalAppointments = 0;
  const [showModal, setShowModal] = useState(false);

  dashboardData?.count?.appointments?.forEach((appointment) => {
    totalAppointments += parseInt(appointment.count);
  });

  const formattedData = getAptStatsBasedOnType(dashboardData, viewType, practice.timezone);

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },

    series:
      dashboardData?.count?.appointments?.map?.((item) => ({
        type: 'line',
        xKey: 'timestamp',
        yKey: item.status,
        yName: `${getStatusLabel(item.status, viewType, appointmentStatuses)} (${item.count})`,
        stroke: getColorForStatus(item.status),
        marker: {
          size: 6,
          fill: getColorForStatus(item.status),
          stroke: getColorForStatus(item.status)
        }
      })) || [],
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };
  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  const viewTypes = [
    {
      value: 'status',
      label: 'Status',
      onClick: () => updateAppointmentSettings('status')
    },
    {
      value: 'stage',
      label: 'Stage',
      onClick: () => updateAppointmentSettings('stage')
    },
    {
      value: 'confirmation',
      label: 'Confirmation',
      onClick: () => updateAppointmentSettings('confirmation')
    }
  ];

  if (
    (!io(dashboardData?.appointmentGraphData) || !ia(dashboardData?.count.appointments)) &&
    !isFetching
  )
    return (
      <>
        <NotFound
          icon="new-illustration-3"
          iconClassName="!pb-5"
          title="No appointments report yet!"
          description="You can start scheduling new appointments right now."
          createText="Schedule new appointment"
          onCreate={() => setShowModal(true)}
        />
        <NewAppointmentModal showModal={showModal} setShowModal={setShowModal} />
      </>
    );

  return (
    <div>
      {isFetching ? (
        <Skeleton count={6} />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <span className="pb-3 text-lg font-500 leading-6 text-primary-900">
              {`${totalAppointments} Appointments`}
            </span>
            <Popover
              id="appointment-statuses"
              className="rounded-md bg-white"
              buttonClassName="max-w-[180px]"
              textClassName="text-ellipsis overflow-hidden whitespace-nowrap"
              panelClassName="max-h-[300px] xl:max-h-[500px] overflow-y-auto"
              icon={false}
              text={capitalize(viewType)}
              buttonTheme="primary"
              isFixed
              isDropdown
              position="left"
              options={viewTypes}
            />
          </div>
          <AGChart options={options} key={chartKey} />
        </>
      )}
    </div>
  );
};

export default Appointments;
