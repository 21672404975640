import React, { useEffect } from 'react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import TPEdit from './views/TPEdit';
import TPPreview from './views/TPPreview';
import { io } from '../../../../../lib/helpers/utility';

const TreatmentPlan = ({ view, origin, patientSideTreatmentPlan, patientSide = false }) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  useEffect(() => {
    if (io(patientSideTreatmentPlan)) setSelectedTreatmentPlan(patientSideTreatmentPlan);
  }, [patientSideTreatmentPlan]);

  if (['edit', 'create'].includes(view)) return <TPEdit view={view} origin={origin} />;
  return <TPPreview id={selectedTreatmentPlan?.id} patientSide={patientSide} />;
};

export default TreatmentPlan;
