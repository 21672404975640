import React from 'react';
import { findStateName } from 'lib/helpers/utility';
import './Billing.scss';

export default function Row({ row, onClick, states }) {
  return (
    <div className="Wrapper" onClick={onClick}>
      <div className="ColumnWrapper">
        <div className="RowWrapper">
          <label>Name:</label>
          <h5>{row.bill_name}</h5>
        </div>
        <div className="RowWrapper">
          <label>NPI:</label>
          <h5>{row.bill_npi}</h5>
        </div>
        <div className="RowWrapper">
          <label>Tax ID:</label>
          <h5>{row.bill_taxid}</h5>
        </div>
        <div className="RowWrapper">
          <label>Tax ID Type:</label>
          <h5>{row.bill_taxid_type}</h5>
        </div>
        <div className="RowWrapper">
          <label>Taxonomy:</label>
          <h5>{row.bill_taxonomy}</h5>
        </div>
        <div className="RowWrapper">
          <label>Atypical ID:</label>
          <h5>{row.bill_id}</h5>
        </div>
      </div>
      <div>
        <div className="RowWrapper">
          <label>Address 1:</label>
          <h5>{row.bill_addr_1}</h5>
        </div>
        <div className="RowWrapper">
          <label>Address 2:</label>
          <h5>{row.bill_addr_2}</h5>
        </div>
        <div className="RowWrapper">
          <label>City:</label>
          <h5>{row.bill_city}</h5>
        </div>
        <div className="RowWrapper">
          <label>State:</label>
          <h5>{findStateName({ states, stateId: row.bill_state })}</h5>
        </div>
        <div className="RowWrapper">
          <label>Zip:</label>
          <h5>{row.bill_zip?.replace(/^(\d{5})(\d{4})$/, '$1-$2')}</h5>
        </div>
        <div className="RowWrapper">
          <label>Phone:</label>
          <h5>{row.bill_phone}</h5>
        </div>
      </div>
      {/* <div>
        <div className="RowWrapper">
          <label>Members:</label>
          {row.practitioner_ids.map((id) => {
            const p = members.find((p) => p.id === id);
            return <h5 key={id}>{`${p?.f_name} ${p?.l_name}`}</h5>;
          })}
        </div>
      </div> */}
    </div>
  );
}
