import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../../api/InterimApi';
import Button from '../../../shared/Buttons/Button';
import Input from '../../../shared/Input/Input';
import Loading from '../../../shared/Loading/Loading';
import { currentPractice } from '../../practiceState';
import { useNavigate } from 'react-router-dom';
import Select from 'components/shared/Select/Select';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';

const DisplaySettingsBillingValidationSchema = Yup.object().shape({
  medicarePercentage: Yup.number().typeError('Please enter a number, no special characters')
});

const setPOSValue = (default_pos, posOptions) => {
  const { code, name } =
    (posOptions && posOptions.filter((option) => option?.code === default_pos)[0]) || {};

  if (!default_pos) return { label: 'Select' };

  return {
    label: `${code} - ${name}`,
    value: code
  };
};

const BillingSettings = () => {
  const [loading, setLoading] = useState(false);
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [posOptions, setPosOptions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    getPOS();
  }, []);

  const formik = useFormik({
    initialValues: {
      medicarePercentage: practice?.display_settings?.billing?.feeSchedule?.medicarePercentage,
      pos: setPOSValue(practice?.display_settings?.billing?.superbill?.default_pos, posOptions),
      updateServices: false
    },
    validationSchema: DisplaySettingsBillingValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await updateBillingSettings();
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (formik)
      formik.setFieldValue(
        'pos',
        setPOSValue(practice?.display_settings?.billing?.superbill?.default_pos, posOptions)
      );
    formik.setFieldValue(
      'medicarePercentage',
      practice?.display_settings?.billing?.feeSchedule?.medicarePercentage
    );
  }, [posOptions, practice?.display_settings?.billing]);

  const getPOS = async () => {
    setLoading(true);

    try {
      let { data } = await interimApi('/api/codes/place_of_service/get', {}, navigate);

      if (data) setPosOptions(data?.place_of_service);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const updateBillingSettings = async () => {
    try {
      const display_settings = {
        ...practice?.display_settings,
        billing: {
          ...practice?.display_settings?.billing,
          superbill: {
            ...practice?.display_settings?.billing?.superbill,
            default_pos: formik.values.pos.value
          },
          feeSchedule: {
            ...practice?.display_settings?.billing?.feeSchedule,
            medicarePercentage: formik.values.medicarePercentage
          }
        }
      };

      const updateServices = formik.values.updateServices;

      const { data } = await interimApi(
        '/api/practice/update',
        { fields: { display_settings }, updateServices },
        navigate
      );

      if (data?.updatedItems > 0) {
        showAlert({
          color: 'success',
          message: updateServices
            ? 'Billing settings and services updated successfully.'
            : 'Billing settings updated successfully.'
        });

        setPractice({ ...practice, display_settings });

        if (updateServices) {
          queryClient.invalidateQueries({ queryKey: ['services'], refetchType: 'all' });
        }
      }

      formik.setFieldValue('updateServices', false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveHandler = async (state) => {
    if (state === 'all') {
      formik.setFieldValue('updateServices', true);
    }

    formik.handleSubmit();
  };

  return (
    <div className="p-4">
      {loading ? (
        <Loading />
      ) : (
        practice && (
          <div className="grid w-1/2 gap-4 sm:w-full">
            <div className="flex flex-col gap-4">
              <p className="text-lg font-500">Superbill</p>
              <div>
                <Select
                  placeholder="Type and select"
                  isClearable={!!formik?.values?.pos?.value}
                  value={formik?.values?.pos}
                  onChange={(e) => {
                    if (!e) {
                      formik.setFieldValue('pos', { label: 'Select', value: '' });
                      return;
                    }
                    formik.setFieldValue('pos', { label: `${e.label}`, value: e.value });
                  }}
                  options={posOptions?.map((item) => {
                    return { label: `${item.code} - ${item.name}`, value: item.code };
                  })}
                />
                <p className="pt-1 text-xs tracking-wide text-neutral-500">
                  Select the default place of service
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <p className="text-lg font-500">Schedule of fees</p>
                <Input
                  label="Please enter your desired multiple of the Medicare schedule of fees as your practice’s standard billed amount. (If blank this will default to 100%) "
                  value={formik?.values?.medicarePercentage}
                  tooltip="For example, if 125% is entered, your claims will reflect 125% of the Medicare schedule for that code "
                  rightText="%"
                  name="medicarePercentage"
                  onChange={(e) => formik.setFieldValue('medicarePercentage', e.target.value)}
                  error={formik.errors.medicarePercentage}
                />
              </div>
            </div>
            <div className="flex w-full gap-4">
              <Button
                text="Save"
                onClick={onSaveHandler}
                className="w-full"
                loading={formik.isSubmitting && !formik.values.updateServices}
              />
              <Button
                text="Save & Apply to all services"
                onClick={() => setShowConfirmation(true)}
                className="w-full"
                loading={formik.isSubmitting && formik.values.updateServices}
              />
            </div>
          </div>
        )
      )}

      {showConfirmation && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Update"
          title="Are you sure you want to update the standard charge for all services?"
          message="This action will update the standard charge of all services which contain a CPT Code and have a default medicare allowable amount."
          handleContinue={() => {
            setShowConfirmation(false);
            onSaveHandler('all');
          }}
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default BillingSettings;
