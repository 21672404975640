import { queryClient } from 'App';

export default async (data) => {
  const { patient } = data;

  queryClient.setQueryData(['patient', patient?.id], (oldData) => {
    if (oldData) {
      return {
        ...oldData,
        patient: { ...(oldData?.patient || {}), ...patient }
      };
    }
  });

  //we need to update the patient in all the appointments
  const allSingleAppointmentQueries = queryClient.getQueryCache().findAll(['appointmentv2']);
  for (const query of allSingleAppointmentQueries) {
    queryClient.setQueryData(query.queryKey, (oldData) => {
      if (oldData && oldData.appointment?.patient.id === patient.id) {
        return {
          ...oldData,
          appointment: {
            ...oldData?.appointment,
            patient: { ...oldData?.appointment?.patient, ...patient }
          }
        };
      }
      return oldData;
    });
  }

  const allAppointmentQueries = queryClient.getQueryCache().findAll(['appointment-events']);
  for (const query of allAppointmentQueries) {
    queryClient.setQueryData(query.queryKey, (oldData) => {
      return {
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.patient.id === patient.id ? { ...apt, patient: { ...apt.patient, ...patient } } : apt
        )
      };
    });
  }
};
