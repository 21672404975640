import { queryClient } from 'App';
import { iaRa } from 'lib/helpers/utility';

export default async (data) => {
  const { patient_id, insuranceProfileId } = data;

  queryClient.setQueryData(['insurance-profiles', patient_id], (oldData) => {
    if (!oldData) return;

    return {
      ...oldData,
      insuranceProfile: iaRa(oldData.insuranceProfile).filter((it) => it.id !== insuranceProfileId)
    };
  });
};
