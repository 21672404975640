import moment from 'moment-timezone';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { iaRa } from '../../../../../../lib/helpers/utility';
import { currentPractice } from '../../../../practiceState';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import TPTable from '../../TreatmentPlan/views/TPTable';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { getTPTableCols } from './lib/getTPTableCols';

const TPList = ({ data = [] }) => {
  const practice = useRecoilValue(currentPractice);
  const clinicalNote = useRecoilValue(clinicalNoteState);

  iaRa(data)?.sort((a, b) => moment(a?.created_at) - moment(b?.created_at));

  return (
    <TableContextProvider
      name="sidebar_treatment_plans"
      cols={getTPTableCols({
        treatment_plan_id: clinicalNote?.treatment_plan_id,
        timezone: practice.timezone
      })}>
      <TPTable data={data} />
    </TableContextProvider>
  );
};

export default TPList;
