import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { useFormik } from 'formik';
import { requestApi } from '../../../../../api/Api';
import { getFinalProductObjShape } from '../lib/helpers';
import { initialValues } from '../lib/initials';
import validationSchema from '../lib/validationSchema';
import ProductForm from './ProductForm';
import { formatDate, keyChanges } from 'lib/helpers/utility';
import { useProductsCategories } from 'lib/hooks/queries/products/useProductCategories';

const NewProduct = forwardRef(({ onClose, product }, ref) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const queryClient = useQueryClient();

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const formik = useFormik({
    initialValues: initialValues(product),
    validationSchema,
    // enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const productToBeCreated = getFinalProductObjShape(values);

      setSubmitting(true);
      product
        ? await updateProduct(productToBeCreated, resetForm)
        : await createProduct(productToBeCreated, resetForm);
      setSubmitting(false);
    }
  });

  const { data: categories } = useProductsCategories();

  const createProduct = async (newProduct, resetForm) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);

      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create products!",
          color: 'danger'
        });
    };

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['products'], refetchType: 'all' });

      resetForm();
      onClose();
      showAlert({
        title: 'Product creation',
        message: 'Product created successfully',
        color: 'success'
      });
    };

    requestApi({
      url: 'api/product/create',
      navigate,
      onError,
      onSuccess,
      params: {
        product: {
          ...newProduct,
          category_id: newProduct.category_id ? newProduct.category_id : null
        }
      }
    });
  };

  const updateProduct = async (productToBeUpdated, resetForm) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);
      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to update products!",
          color: 'danger'
        });
    };

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['products'], refetchType: 'all' });

      showAlert({
        title: 'Product update',
        message: 'Product updated successfully',
        color: 'success'
      });

      resetForm();
      onClose();
    };

    const updatedProduct = keyChanges(product, {
      ...productToBeUpdated,
      ...(productToBeUpdated.expiration_date && {
        expiration_date: formatDate(productToBeUpdated.expiration_date)
      })
    });

    requestApi({
      url: `/api/product/update`,
      navigate,
      onError,
      onSuccess,
      params: { product: { ...updatedProduct, code: formik.values.code, id: product.id } }
    });
  };

  return (
    <div>
      <ProductForm
        formik={formik}
        categories={categories?.categories}
        formRef={formRef}
        updating={!!product}
      />
    </div>
  );
});

export default NewProduct;
