import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import numOrDefault from 'lib/helpers/numOrDefault';
import { formatLargeNumber, io } from 'lib/helpers/utility';
import React from 'react';

const EPCS = ({ data }) => {
  const prices = JSON.parse(process.env.REACT_APP_PRICES);
  let epcsVeradigmCount = {
    new: data?.thisPeriodAndTotalCount?.periodVeradigmEpcsCount,
    total: data?.thisPeriodAndTotalCount?.totalVeradigmEpcsCount
  };
  const formattedData =
    data?.veradigmEpcsGraphData?.map((dataPoint) => {
      const date = new Date(dataPoint.date);
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - date.getDay() + 1);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      const startDateString = startOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const endDateString = endOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const weekLabel = `${startDateString}-${endDateString}`;
      return {
        ...dataPoint,
        date: weekLabel
      };
    }) ?? [];

  const options = {
    data: formattedData,
    height: 270,
    series: [
      {
        type: 'bar',
        xKey: 'date',
        yKey: 'veradigm_e_prescription_epcs',
        fill: '#13B9FF',
        stroke: '#004F6B',
        cornerRadius: 15
      }
    ],
    axes: [
      {
        type: 'cateasdgory',
        nice: true,
        position: 'bottom'
      },
      {
        position: 'left'
      }
    ],
    theme: 'ag-material'
  };
  return (
    <div>
      {io(data?.veradigmEpcsGraphData) ? (
        <>
          <div className="flex items-center justify-between !px-5 pt-[14px]">
            <div className="flex items-center justify-center">
              <p className="text-md text-neutral-500">Total: </p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {formatLargeNumber(epcsVeradigmCount?.total)}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-md pl-3 text-neutral-500">Total Cost: </p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {`$${numOrDefault(epcsVeradigmCount?.total * prices.veradigm_epcs).toFixed(2) || '0'}`}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between !px-5 pt-[14px]">
            <div className="flex items-center justify-center">
              <p className="text-md text-neutral-500">This Period: </p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {formatLargeNumber(epcsVeradigmCount?.new)}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-md pl-3 text-neutral-500">Cost</p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {`$${numOrDefault(epcsVeradigmCount?.new * prices.veradigm_epcs).toFixed(2) || '0'}`}
              </div>
            </div>
          </div>
          <AGChart options={options} />
        </>
      ) : (
        <>
          <NotFound icon="new-illustration-6" title="You don't have veradigm yet" />
        </>
      )}
    </div>
  );
};

export default EPCS;
