import React from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../practiceState';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';

const CreatePopover = ({ showNewAppointmentModal, setSellModalVisible, setShowPaymentModal }) => {
  const practice = useRecoilValue(currentPractice);
  const { device } = useUIContext();
  const displaySettings = practice?.display_settings ?? {};
  const { packages, products } = displaySettings;

  const handleAppointmentClick = (closePopover) => () => {
    showNewAppointmentModal();
    closePopover();
  };

  const handleSellClick = (closePopover) => () => {
    setSellModalVisible(true);
    closePopover();
  };

  const handlePaymentClick = (closePopover) => () => {
    setShowPaymentModal(true);
    closePopover();
  };

  return (
    <Popover
      position="left"
      text={device === 'desktop' && 'Create'}
      icon="new-add-square"
      iconSize={device !== 'desktop' ? 20 : 16}
      iconRight={device === 'desktop' && 'new-chevron-down'}
      buttonClassName="!rounded-full gap-1"
      textClassName="text-primary-500"
      panelClassName="w-max"
      buttonTheme="primary">
      {({ closePopover }) => (
        <div className="grid w-max rounded-lg bg-white">
          <Allowed requiredPermissions="appointments.create">
            <div
              data-qa="patient-overview-new-appointment"
              className="group flex cursor-pointer items-center rounded-lg px-4 py-3 hover:bg-primary-50"
              onClick={handleAppointmentClick(closePopover)}>
              <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                <Icon icon="new-calendar" />
              </div>
              <div className="grid">
                <span className="text-sm font-500 text-primary-900">Appointment</span>
                <span className="text-xs text-neutral-600">New appointment</span>
              </div>
            </div>
            <div className="px-2">
              <div className="h-[1px] w-full bg-neutral-100"></div>
            </div>
          </Allowed>
          <Allowed requiredPermissions={['products.read', 'packages.read']} isAny>
            {packages || products ? (
              <div
                data-qa="patient-overview-sell-package-product"
                className="group flex cursor-pointer items-center rounded-lg px-4 py-3 hover:bg-primary-50"
                onClick={handleSellClick(closePopover)}>
                <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                  <Icon icon="new-receipt-textv2" size={24} />
                </div>
                <div className="grid">
                  <span className="text-sm font-500 text-primary-900">Transaction</span>
                  <span className="text-xs text-neutral-600">Sell Products & Packages</span>
                </div>
              </div>
            ) : null}
          </Allowed>

          <Allowed requiredPermissions="payments.create">
            <div
              data-qa="patient-overview-quote"
              className="group flex cursor-pointer items-center rounded-lg px-4 py-3 hover:bg-primary-50"
              onClick={handlePaymentClick(closePopover)}>
              <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                <Icon icon="money-send" size={24} />
              </div>
              <div className="grid">
                <span className="text-sm font-500 text-primary-900">Payment</span>
                <span className="text-xs text-neutral-600">Pay balance</span>
              </div>
            </div>
          </Allowed>
        </div>
      )}
    </Popover>
  );
};

export default CreatePopover;
