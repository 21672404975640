import { capitalize } from 'lodash';
import ResourceTableActions from '../components/ResourceTableActions';

export const defaultColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    flex: 1,
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'description',
    headerName: 'Description',
    cellClass: 'no-border',
    flex: 1,
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'user.full_name',
    headerName: 'Created by',
    cellClass: 'no-border',
    flex: 1,
    sortable: false,
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: ResourceTableActions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
