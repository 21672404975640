import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Placeholder = () => {
  return (
    <div
      className={classNames(
        'flex h-fit w-full flex-col items-start gap-4 rounded-lg border border-solid bg-white p-4',
        'border-neutral-100'
      )}>
      <div className="flex h-fit w-full flex-row items-start gap-3">
        <Skeleton circle={true} height={24} width={24} />
        <div className="flex w-full flex-col items-start">
          <Skeleton width={200} height={22} />
          <div className="flex cursor-default items-center gap-1 text-xs font-normal leading-5 text-neutral-700 ">
            <Skeleton width={100} height={16} />
            <div className="flex items-center gap-1">
              <Skeleton circle={true} height={16} width={16} />
              <Skeleton width={50} height={16} />
            </div>
          </div>
        </div>
        <Skeleton circle={true} height={16} width={16} />
      </div>
    </div>
  );
};

export default Placeholder;
