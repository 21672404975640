import cs from 'classnames';
import Allowed from 'components/shared/Permissions/Allowed';
import { clinicalNote as clinicalNoteState } from 'components/state';
import React from 'react';
import { useRecoilValue } from 'recoil';
import Icon from '../../../../shared/Icon/Icon';

const SimpleAccordion = ({
  forwardedRef,
  open,
  title,
  titleHighlight = true,
  children,
  onToggle,
  edit,
  className,
  addPermissions = '',
  showExpandIcon = false,
  // copied,
  ...rest
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <div
      className={cs(
        'relative select-none rounded-md border border-solid border-neutral-200 bg-white !p-3 !py-2 shadow-small duration-200 ease-linear',
        className
      )}
      ref={forwardedRef}
      {...rest}>
      <div className="flex items-center justify-between">
        <span
          className={cs(
            "relative text-base font-500 text-primary-900 first-letter:capitalize before:absolute before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-500 before:duration-200 before:ease-linear before:content-['']",
            open && titleHighlight && 'before:!w-6'
          )}>
          {title}
        </span>

        <div className="flex items-center gap-x-[2px]">
          {showExpandIcon && (
            <Icon
              icon={open ? 'new-minimize-flipped' : 'new-maximize-flipped'}
              onClick={onToggle}
            />
          )}
          <Allowed requiredPermissions={addPermissions}>{!clinicalNote?.locked && edit}</Allowed>
        </div>
      </div>
      {children && (
        <div
          className={cs(
            'grid gap-y-1 duration-200',
            open ? 'max-h-max !pt-2' : 'invisible h-0 max-h-0 overflow-auto opacity-0'
          )}>
          {children}
        </div>
      )}
      {/* {copied && (
        <div
          className="absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center !rounded-md"
          style={{
            backgroundColor: 'rgb(19 185 255 / 13%)',
            backdropFilter: 'blur(1px)'
          }}>
          <span className="font-500 text-primary-500">Copied!</span>
        </div>
      )} */}
    </div>
  );
};

export default SimpleAccordion;
