import React from 'react';
import classNames from 'classnames';

import { addNewItems } from '../lib';
import { mString, pm } from 'lib/helpers/utility';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { NewItemCounter } from 'components/practice/NewAppointment/components/Sections/Pricing/components/ProceduresProduct/components';

const ListItem = ({ item, index, formik, newItems, isChecked, setNewItems, type, permissions }) => {
  const isServices = type === 'procedures';

  const outOfStock = !isServices && item?.quantity === 0;

  return (
    <div
      className={classNames(
        'flex !min-h-[34px] items-center justify-between rounded-md !px-2 py-[3px]',
        isChecked && 'bg-primary-50'
      )}
      key={item?.id}>
      <Checkbox
        id={index}
        disabled={outOfStock}
        onBlur={formik.handleBlur}
        label={item?.name + (outOfStock ? ' (Out of stock)' : '')}
        labelTextColor={classNames('leading-4 ', outOfStock && '!text-danger-600')}
        handleClick={() => addNewItems({ newItem: item, newItems, setNewItems })}
        isChecked={isChecked}
      />

      <div className="flex items-center !gap-2">
        <NewItemCounter newItem={item} newItems={newItems} setNewItems={setNewItems} />

        {type === 'products' && pm(permissions, ['payments.read']) && (
          <p className="w-14 text-xs text-neutral-500">{mString(item?.amount_cents)}</p>
        )}
      </div>
    </div>
  );
};

export default ListItem;
