import * as Yup from 'yup';

export const NewEOBValidationSchema = Yup.object().shape({
  payerid: Yup.string().required('Insurance Payer required'),
  check_number: Yup.string().required('Payer Trace number required'),
  payment_method: Yup.string().required('Payment method required'),
  total_paid: Yup.number()
    .required('Amount paid required')
    .min(0, 'Amount paid cannot be less than 0'),
  paid_date: Yup.string().required('Check date required'),
  handle: Yup.string()
});
