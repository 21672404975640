import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import HeaderTable from './components/HeaderTable';
import { defaultColumns } from './lib/helpers';
import { useMember } from 'lib/hooks/queries/useMember';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';
import StaffMember from './components/StaffMember';

const Staff = () => {
  const columnDefs = defaultColumns();

  return (
    <TableContextProvider cols={columnDefs} pagination name="resources" defaultFilters={[]}>
      <StaffTable />
    </TableContextProvider>
  );
};

const StaffTable = () => {
  const { id } = useParams();
  const [selectedStaff, setSelectedStaff] = useState(null);

  const { limit, page, sort, setPage, filters } = useTableContext();

  const { data, isLoading } = useMember({
    params: {
      practiceId: id,
      limit,
      page,
      sort,
      filters: mapValues(filters),
      withCount: true
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const staff = data?.members || [];
  const count = data?.count || 0;

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100
    };
  }, []);

  const onCellClicked = async (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    setSelectedStaff(event?.data);
  };

  const handleCloseModal = () => {
    setSelectedStaff(null);
  };

  return (
    <>
      <HeaderTable />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="flex h-full flex-col overflow-hidden">
          <div className="ag-theme-quartz !mb-0 h-full">
            <AGTable
              data={staff}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading}
            />
          </div>
          <div className="flex items-center justify-between px-3">
            <TableCounter page={page} limit={limit} count={count && count} />
            <Pagination
              onPageChange={({ selected }) => setPage(selected + 1)}
              totalItems={count ? count : null}
              page={page}
              perPage={limit}
            />
          </div>
        </div>
      )}

      {selectedStaff && (
        <StaffMember
          practiceId={id}
          member={selectedStaff}
          handleOpen={Boolean(selectedStaff)}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Staff;
