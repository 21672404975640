import React, { useState } from 'react';
import numOrDefault from 'lib/helpers/numOrDefault';

import Button from 'components/shared/Buttons/Button';
import CashPayment from 'components/Payments/cash/cash';
import { showAlert } from 'components/shared/Alert/Alert';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';

const EOBPayment = ({ formik, onProceed, handleClose, showInfoInRecordings }) => {
  const [retry, setRetry] = useState(false);

  const values = formik.values;

  const handleOnFail = () => {
    let newErrorMessage = 'EOB was not created, please try again.';
    setRetry(true);
    showAlert({
      title: 'Transaction failed.',
      color: 'danger',
      message: newErrorMessage
    });
  };

  const onSuccessPay = () => {
    formik.submitForm();
    formik.setValues({});
    handleClose();
  };

  const handleOnSuccess = () => {
    let newErrorMessage = 'Your payment has been processed successfully. Thank you!';
    onSuccessPay();
    showAlert({
      title: 'Transaction Finished.',
      color: 'success',
      message: newErrorMessage
    });
  };

  return (
    <div data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
      {process.env.ENV === 'local' ? (
        <CashPayment
          amount={numOrDefault(values?.total_paid)}
          payerId={values?.payerid}
          onSuccess={onSuccessPay}
          onFail={handleOnFail}
        />
      ) : (
        <LightboxWrapper
          amount={numOrDefault(values?.total_paid)}
          payerId={values?.payerid}
          onApproval={handleOnSuccess}
          onCancel={handleOnFail}
        />
      )}
      {retry && (
        <div className="mt-5 flex justify-center">
          <Button
            text="Retry Payment"
            onClick={() => {
              setRetry(false);
              onProceed();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EOBPayment;
