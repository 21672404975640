import React from 'react';
import cs from 'classnames';

const extractInfo = (item, prefix) => ({
  name: item?.name.replace(prefix, ''),
  color: item?.color
});

const PatientStatus = ({
  appointment = {},
  label = '',
  bulletPoints = false,
  wrapperClassName = '',
  listClassName = ''
}) => {
  const clinicalNote = extractInfo(
    appointment?.timeline?.clinical?.['clinical-note-started'],
    'Clinical Note '
  );
  const superbill = extractInfo(appointment?.timeline?.billing?.superbill, 'Superbill ');
  const claim = extractInfo(appointment?.timeline?.billing?.claim, 'Claim ');

  return (
    <div className={wrapperClassName} data-dd-privacy="allow">
      {label && <p className="text-sm font-500">{label}</p>}
      <div className={cs('flex flex-col gap-2', listClassName)} data-dd-privacy="allow">
        <div className="flex items-center justify-between gap-1" data-dd-privacy="allow">
          <div className="flex items-baseline gap-3" data-dd-privacy="allow">
            {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
            <span className="text-xs font-500 leading-5 text-neutral-600">Clinical Note</span>
          </div>
          <span
            className={`!rounded-lg text-${clinicalNote.color}-600 bg-${clinicalNote.color}-100 !px-[6px] text-xs font-600 capitalize leading-[22px]`}>
            {clinicalNote.name}
          </span>
        </div>
        <div className="flex items-center justify-between gap-1" data-dd-privacy="allow">
          <div className="flex items-baseline gap-3" data-dd-privacy="allow">
            {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
            <span className="text-xs font-500 leading-5 text-neutral-600">Superbill</span>
          </div>
          <span
            className={`!rounded-lg text-${superbill.color}-600 bg-${superbill.color}-100 !px-[6px] text-xs font-600 capitalize leading-[22px]`}>
            {superbill.name}
          </span>
        </div>
        <div className="flex items-center justify-between gap-1" data-dd-privacy="allow">
          <div className="flex items-baseline gap-3" data-dd-privacy="allow">
            {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
            <span className="text-xs font-500 leading-5 text-neutral-600">Claim</span>
          </div>
          <span
            className={`!rounded-lg text-${claim.color}-600 bg-${claim.color}-100 !px-[6px] text-xs font-600 capitalize leading-[22px]`}>
            {claim.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PatientStatus;
