export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true,
  suppressAggFuncInHeader: true,
  enableRangeSelection: true,
  groupSelectsChildren: true,
  suppressRowClickSelection: true,
  customClassName: 'ag-grid-interactive',
  rowSelection: 'multiple'
};

export const DEFAULT_COLUMN_DEFS = { resizable: true, enableRowGroup: true, minWidth: 100 };
