import React, { useEffect } from 'react';
import TPList from './TPList';
import TPPreview from './TPPreview';
import { useTreatmentPlan } from '../../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import { useParams } from 'react-router-dom';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import { io } from '../../../../../../lib/helpers/utility';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const TreatmentPlan = () => {
  const { id } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {}, isLoading, isFetched } = useTreatmentPlan(params);
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  useEffect(() => {
    if (isFetched && treatmentPlan?.length === 1) {
      setSelectedTreatmentPlan(treatmentPlan[0]);
    }
  }, [isFetched, treatmentPlan]);

  if (isLoading) return <Skeleton count={3} height="52px" />;

  if (!io(selectedTreatmentPlan)) {
    return <TPList data={treatmentPlan} />;
  } else {
    return <TPPreview data={treatmentPlan} />;
  }
};

export default TreatmentPlan;
