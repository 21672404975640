import React from 'react';

/**
 * @param {import('./lib/types').ProgressProps} props
 */

const ProgressBar = ({ progress }) => {
  return (
    <div className="relative h-[6px] w-full rounded-[6px] bg-neutral-200">
      <div
        className="absolute left-0 top-0 h-[6px] rounded-[6px] bg-primary-500"
        style={{ width: `${progress}%` }}>
        <div className="h-[6px] w-[8px] rounded-[6px] bg-primary-500"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
