import React from 'react';

import cs from 'classnames';

import { howLongAgo } from '../../../../../constants';
import Input from '../../../Input/Input';
import Select from '../../../Select/Select';
import Container from '../components/Container';
import Row from '../components/Row';
import SelectableButton from '../components/SelectableButton';

const RSPatientBlock = ({ title, noBlock, row, blockIdx, onChange, disabled, locked = false }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <Container newDesign>
        <p className="mb-[1rem] px-2 text-center text-lg text-primary-900">
          Are you experiencing any of the following {title} symptoms?
        </p>

        <div className={cs(' mb-4 !grid w-full !grid-cols-2 gap-2')}>
          <SelectableButton
            data-qa="yes"
            label="Yes"
            isChecked={noBlock === false}
            onChange={() => onChange('noBlock', false, blockIdx, false, true)}
          />
          <SelectableButton
            data-qa="no"
            label="No"
            isChecked={noBlock === true}
            onChange={() => onChange('noBlock', true, blockIdx, false, true)}
          />
        </div>

        <div className="flex flex-col gap-4">
          {!noBlock &&
            row?.map((row, rowIdx) => (
              <Row title={row.name} className="" key={rowIdx}>
                <div className="grid grid-cols-2 gap-2">
                  <SelectableButton
                    id={`rspatient-checkbox-${rowIdx}-yes`}
                    label="Yes"
                    isChecked={row.checked === true}
                    onChange={() => onChange('checked', true, blockIdx, rowIdx)}
                    disabled={disabled || locked}
                  />

                  <SelectableButton
                    id={`rspatient-checkbox-${rowIdx}-no`}
                    label="No"
                    isChecked={row.checked === false}
                    onChange={() => onChange('checked', false, blockIdx, rowIdx)}
                    disabled={disabled || locked}
                  />
                </div>
                {row.checked && (
                  <div className="mb-[6px] mt-3 grid gap-1">
                    <Select
                      inputId={`rspatient-select-${rowIdx}`}
                      className="w-full"
                      placeholder="How long ago?"
                      value={row.when}
                      onChange={(value) => onChange('when', value, blockIdx, rowIdx)}
                      options={howLongAgo}
                      disabled={disabled || locked}
                    />
                    {Object.prototype.hasOwnProperty.call(row, 'where') && (
                      <Input
                        data-qa={`rspatient-input-${rowIdx}`}
                        placeholder="Where"
                        id={`rspatient-input-${rowIdx}`}
                        value={row.where}
                        onChange={(event) =>
                          onChange('where', event.target.value, blockIdx, rowIdx)
                        }
                        disabled={disabled || locked}
                      />
                    )}
                  </div>
                )}
              </Row>
            ))}
        </div>
      </Container>
    </div>
  );
};

export default RSPatientBlock;
