import { ia } from 'lib/helpers/utility';
import { useMemo, useState } from 'react';

const useBreadcrumb = (s) => {
  const [steps, setSteps] = useState(ia(s) ? s : [s]);
  const [step, setStep] = useState(0);

  const currentStep = useMemo(() => steps[step]?.name, [step]);
  const current = useMemo(() => steps[step], [step]);

  const goBack = () => {
    if (step === 0) return;
    let trimmedSteps = steps.slice(0, step);
    setSteps(trimmedSteps);
    setStep(step - 1);
  };

  const goToStep = (stepName) => {
    const index = steps.findIndex((step) => step.name === stepName);
    if (index !== -1) setStep(index);
  };

  const push = (newStep) => {
    const existingIndex = steps.findIndex((s) => s.name === newStep.name);
    if (existingIndex !== -1) {
      // Step already exists: jump to that step
      const trimmedSteps = steps.slice(0, existingIndex + 1);
      setSteps([...trimmedSteps]);
      setStep(existingIndex);
    } else {
      // Add the new step and trim forward steps
      const trimmedSteps = steps.slice(0, step + 1);
      setSteps([...trimmedSteps, newStep]);
      setStep(trimmedSteps.length);
    }
  };

  return { steps, step, setStep, goBack, push, currentStep, setSteps, goToStep, current };
};

export default useBreadcrumb;
