import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TagContextProvider } from 'lib/context/TagContext/TagContextProvider';
import { mapValues } from 'lib/helpers/utility';

import ReorderWrapper from 'components/shared/Tags/components/Reorder/ReorderWrapper';
import TableView from 'components/shared/Tags/components/TableView';

import { handleUpdatePatientTags } from '../../lib/updatePatientTags';

const TagsCell = ({ value, data }) => {
  const { limit, page, sort, filters } = useTableContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [openReorderModal, setOpenReorderModal] = useState(false);

  const debouncedUpdateTags = debounce(async (patientId, tags, mode) => {
    let queryKeys = ['patients', mapValues(filters), page, sort, limit];
    await handleUpdatePatientTags({ navigate, queryClient, patientId, tags, mode, queryKeys });
  }, 1000);

  const handleAddTags = (patient, tag) => {
    const filteredNewTags = tag?.filter(
      (newTag) => !patient?.tags?.some((existingTag) => existingTag?.id === newTag?.id)
    );
    debouncedUpdateTags(patient?.id, filteredNewTags, 'add');
  };

  const handleRemoveTag = async (patient, id) => {
    let queryKeys = ['patients', mapValues(filters), page, sort, limit];

    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId: patient?.id,
      tags: [id],
      mode: 'remove',
      queryKeys
    });
  };

  const tableActions = [
    {
      label: 'Create Tag',
      icon: 'new-tag-new',
      onClick: () => setIsCreateModal(true)
    },
    {
      label: 'Re-order Tags',
      icon: 'new-sort-reorder',
      onClick: () => setOpenReorderModal(true),
      disabled: value?.length < 2,
      tippyText:
        value?.length < 2 ? 'Select tags to add to this patient’s chart' : 'Re-order selected tags'
    },
    {
      label: 'Manage Tags',
      icon: 'new-tag-settings',
      onClick: () => navigate('/portal/settings/tags'),
      disabled: false
    }
  ];
  return (
    <TagContextProvider>
      <TableView
        currTags={value || []}
        tagsKind="chart"
        handleAdd={(tag) => handleAddTags(data, tag)}
        handleRemove={(id) => handleRemoveTag(data, id)}
        tableActions={tableActions}
        isCreateModal={isCreateModal}
        setIsCreateModal={setIsCreateModal}
        usePendingState
        fromTable
      />
      {openReorderModal && (
        <ReorderWrapper
          openReorderModal={openReorderModal}
          handleClose={() => setOpenReorderModal(false)}
          patientId={data?.id}
          queryKeys={['patients', mapValues(filters), page, sort, limit]}
          fromTable
        />
      )}
    </TagContextProvider>
  );
};

export default TagsCell;
