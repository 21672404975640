import { ia, iaRa } from 'lib/helpers/utility';
import { setPatientAggregateNarrativeState } from '../../lib/patientAggregateNarrativeStates';

export const handleNoKnown = async ({
  mutateNoKnown,
  queryClient,
  patientId,
  title,
  noKnownState,
  setNoKnownModal,
  setOverviewData
}) => {
  await mutateNoKnown(
    {
      upsertData: {
        user_type: 'provider',
        patient_id: patientId,
        form_type: title,
        narrative: null,
        synced: true,
        no_known: !noKnownState
      }
    },
    {
      onSuccess: async ({ data = {} } = {}) => {
        await setPatientAggregateNarrativeState({
          queryClient,
          keys: [patientId, title],
          payload: {
            id: data?.narrative?.id,
            no_known: data?.narrative?.no_known,
            narrative: null
          }
        });

        if (title === 'medications') {
          await queryClient.invalidateQueries(['medication']);
          if (ia(data?.narrative?.unsynced_whls_ids)) {
            setOverviewData((prev) => ({
              ...prev,
              weightLossAdministration: prev?.weightLossAdministration?.map((weightLoss) => {
                if (data?.narrative?.unsynced_whls_ids.includes(weightLoss?.id)) {
                  return {
                    ...weightLoss,
                    response: {
                      ...weightLoss.response,
                      row: iaRa(weightLoss.response.row).map(({ medication_id, ...rest }) => rest)
                    }
                  };
                }
                return weightLoss;
              })
            }));
          }
        } else {
          setOverviewData((prevState) => {
            if (prevState?.[title]) {
              delete prevState[title];
            }

            return { ...prevState };
          });
        }
      }
    }
  );
  setNoKnownModal(false);
};
