exports.createPastMedicalHistoryNarrative = (data) => {
  if (data?.noDetails) return 'No Known Medical History';

  let narrative = '';

  const chronic = data?.chronic;
  if (chronic?.illness === 'yes') {
    narrative += 'Patient has been diagnosed with chronic illness.\n';

    if (chronic?.note) {
      narrative += `${chronic.note}\n`;
    }
  } else if (chronic?.illness === 'no') {
    narrative += 'Patient has not been diagnosed with any chronic illness.\n';
  }

  if (narrative) narrative += '\n';

  const surgeries = data?.surgeries;
  if (surgeries?.surgery === 'yes') {
    narrative += 'Patient has had major surgeries.\n';

    if (surgeries?.note) {
      narrative += `${surgeries.note}\n`;
    }
  } else if (surgeries?.surgery === 'no') {
    narrative += 'Patient has not had any major surgeries.\n';
  }

  if (narrative) narrative += '\n';

  const allergies = data?.allergies;
  if (allergies?.allergy === 'yes') {
    narrative += 'Patient has known allergies.\n';

    if (allergies?.note) {
      narrative += `${allergies.note}\n`;
    }
  } else if (allergies?.allergy === 'no') {
    narrative += 'Patient does not have any known allergies.\n';
  }

  if (narrative) narrative += '\n';

  const hospitalization = data?.hospitalization;
  if (hospitalization?.illnessOrInjury === 'yes') {
    narrative += 'Patient has been hospitalized for illness or injury.\n';

    if (hospitalization?.note) {
      narrative += `${hospitalization.note}\n`;
    }
  } else if (hospitalization?.illnessOrInjury === 'no') {
    narrative += 'Patient has not been hospitalized for illness or injury.\n';
  }

  return narrative.trim() || 'No specific past medical history details provided.';
};
