import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteVital } from 'api/Vitals';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateVitalsStatesAfterDelete } from '../lib/helper';
const VitalsModaFooter = ({ formik, hideModal = () => {}, disabled, loading, fromOverview }) => {
  const { setCurrentHpOverviewData, setOverviewData } = useClinicalNoteContext() || {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutatateDeleteVital = useMutation({
    mutationFn: () =>
      deleteVital(navigate, {
        vitalId: formik?.values?.id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Delete Vital',
          message: error,
          color: 'warning'
        });
        return;
      }
      updateVitalsStatesAfterDelete({
        vitalId: formik?.values?.id,
        setCurrentHpOverviewData,
        setOverviewData
      });
      if (fromOverview) await queryClient.invalidateQueries(['vitals']);
    }
  });

  const onHandleDelete = async () => {
    if (formik?.values?.id) {
      await mutatateDeleteVital.mutateAsync();
    }
    hideModal();
    setConfirmOpen(false);
  };

  return (
    <ModalFooter
      className="z-50"
      leftButtons={<Button outlined text="Cancel" color="neutral" onClick={hideModal} />}
      rightButtons={
        <div className="flex gap-2">
          {formik?.values?.id && (
            <>
              <Button
                icon="trash"
                outlined={true}
                iconColor="black"
                color="neutral"
                className="h-[40px] w-[40px]"
                disabled={disabled || mutatateDeleteVital?.isLoading}
                onClick={() => {
                  setItemToDelete(formik?.values?.id);
                  setConfirmOpen(true);
                }}
              />
              {itemToDelete && (
                <Confirm
                  icon="new-info"
                  iconColor="danger"
                  iconSize={42}
                  variant="danger"
                  primaryBtnTxt="Delete"
                  title="Delete Vital"
                  message="Are you sure you want to delete this vital?"
                  handleContinue={onHandleDelete}
                  handleOpen={confirmOpen}
                  handleClose={() => setConfirmOpen(false)}
                  disabled={mutatateDeleteVital?.isLoading || mutatateDeleteVital?.isLoading}
                  loading={mutatateDeleteVital?.isLoading || mutatateDeleteVital?.isLoading}
                />
              )}
            </>
          )}
          <Button
            text={formik?.values?.id ? 'Save' : 'Create'}
            icon="save-changes"
            iconColor="white"
            data-qa="upsert-btn"
            onClick={() => formik?.submitForm()}
            loadingIcon={loading}
            disabled={disabled || loading}
          />
        </div>
      }
    />
  );
};
export default VitalsModaFooter;
