import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { appointment_lengths, appointmentLocations } from '../../../../../constants';
import { useNewAppointmentContext } from '../../../../../lib/context/Appointment/NewAppointmentContext';
import { filePath, ia, iaRa, io } from '../../../../../lib/helpers/utility';
import Select from '../../../../shared/Select/Select';
import Textarea from '../../../../shared/Textarea/Textarea';
import { userState } from '../../../../state';
import NewPatient from '../../../charts/PatientCharts/components/NewPatient';
import { currentPractice } from '../../../practiceState';
import { useQueryClient } from '@tanstack/react-query';
import Allowed from 'components/shared/Permissions/Allowed';
import NewTagSelect from 'components/shared/Tags/components/Select/NewSelect';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import FormPacketAppointment from '../../components/FormPacketAppointment/FormPacketAppointment';
import ProceduresProducts from '../../components/ProceduresProduct/ProceduresProducts';
import AppointmentDateAndTime from '.././components/DateAndTime';
import AppointmentLocation from '.././components/Location';
import AppointmentPatient from '.././components/Patient';
import Footer from '../../components/Footer';
import SendAppNotification from './SendAppNotification';

export const customSelectStyle = {
  control: () => ({
    minHeight: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 16,
    backgroundColor: '#f8f8f8',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};

export const customSelectStyle__FullWidthOptions = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 14,
    backgroundColor: '#f8f8f8',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  container: (provided) => {
    return {
      ...provided,
      position: 'initial'
    };
  }
};

const NewAppointment = ({
  practitioners,
  resources,
  selectedDateTime,
  hideNewAppointment,
  setSelectedTab,
  setFooter,
  selectedResourceId,
  selectedPatient: loadedSelectedPatient,
  selectedPractitioner: loadedSelectedPractitioner
}) => {
  const {
    formik,
    newPatientModal,
    setNewPatientModal,
    selectedService,
    setSelectedService,
    selectedPractitioner,
    setSelectedPractitioner,
    selectedResource,
    setSelectedResource,
    selectedTimeLength,
    setSelectedTimeLength,
    setSelectedPatient,
    additionalTimes,
    setAdditionalTimes
  } = useNewAppointmentContext();
  const queryClient = useQueryClient();
  const currentUser = useRecoilValue(userState);
  const currPractice = useRecoilValue(currentPractice);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState([]);

  const [dateAndTime, setDateAndTime] = useState({
    dateTime: moment.tz(new Date(), currPractice?.timezone).format(),
    locationAvailableSlots: [],
    virtualAvailableSlots: [],
    showAll: false
  });
  const [formPackets, setFormPackets] = useState([]);
  const [packages, setPackages] = useState([]);
  const [formPacketLoading, setFormPacketLoading] = useState(false);
  const navigate = useNavigate();
  const [formIds, setFormIds] = useState([]);

  let location = {};
  const defaultLocation = currPractice?.display_settings?.appointment?.default_location || null;
  if (defaultLocation) location = appointmentLocations.find((l) => l?.value === defaultLocation);

  useEffect(() => {
    setSelectedTab('appointment');
    getHours();
    getFormPackets();

    const currentPractitioner = practitioners?.find((p) => p?.value === currentUser?.user_id) || '';
    setSelectedPractitioner(currentPractitioner);

    formik?.setFieldValue('appointmentLocation', location?.value);
    formik?.setFieldValue('practitionerId', currentPractitioner?.value || '');

    if (selectedDateTime?.start) formik?.setFieldValue('startsAt', selectedDateTime?.start);
  }, []);

  useEffect(() => {
    if (loadedSelectedPatient) {
      const { id, f_name, l_name, profile_photo } = loadedSelectedPatient || {};

      setSelectedPatient({
        f_name,
        l_name,
        value: id,
        label: `${f_name} ${l_name}`,
        profilePhoto: profile_photo
      });

      formik.setFieldValue('patientId', id);

      getPackages(id);
    }
  }, [loadedSelectedPatient]);

  useEffect(() => {
    if (loadedSelectedPractitioner) {
      setSelectedPractitioner({
        label: `${loadedSelectedPractitioner?.f_name} ${loadedSelectedPractitioner?.l_name}`,
        value: loadedSelectedPractitioner.id
      });
      formik.setFieldValue('practitionerId', loadedSelectedPractitioner.id);
    }
  }, [loadedSelectedPractitioner]);

  useEffect(() => {
    if (selectedResourceId) {
      const resource = resources.find((r) => r.value === selectedResourceId);
      setSelectedResource(resource);
      formik.setFieldValue('resourceId', resource?.value);
    }
  }, [selectedResourceId, resources]);

  useEffect(() => {
    handleDateTimeSelection();
  }, [selectedDateTime]);

  useEffect(() => {
    if (!selectedService) {
      setDateAndTime((prev) => ({
        ...prev,
        locationAvailableSlots: [],
        virtualAvailableSlots: []
      }));
    }
    if (dateAndTime && selectedPractitioner && formik?.values?.procedures?.length > 0) {
      getPractitionerAvailability(dateAndTime.dateTime, true);
    }
  }, [
    formik?.values?.procedures?.length,
    selectedPractitioner,
    selectedService,
    selectedTimeLength,
    moment(dateAndTime.dateTime).format('dddd/MM/YYYY')
  ]);

  useEffect(() => {
    setFooter(
      <Footer
        hideModal={hideNewAppointment}
        loading={loading}
        submitForm={() => submitAppointment(true)}
        title="Create appointment"
      />
    );
  }, [formik?.values, loading, additionalTimes, formIds]);

  useEffect(() => {
    const ids = extractFormIds(formPackets);
    setFormIds(ids);
  }, [formPackets]);

  // NEED TO CHECK
  useEffect(() => {
    if (selectedPractitioner) getFormPacketsSelected();
  }, [selectedPractitioner, formik?.values?.procedures?.length, formik?.values?.tags?.length]);
  useEffect(() => {
    const patientVirtualLink =
      loadedSelectedPatient?.practicePatientLink?.virtual_link ||
      formik?.values?.patient?.virtual_link;
    if (selectedPractitioner && !patientVirtualLink) {
      const practice_virtual_link = currPractice?.display_settings?.appointment?.virtual_link;
      formik.setFieldValue(
        'virtual_link',
        selectedPractitioner?.virtual_link || practice_virtual_link
      );
    } else {
      formik.setFieldValue('virtual_link', patientVirtualLink);
    }
  }, [selectedPractitioner, formik?.values?.patient]);

  const handleInvalidateQuery = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['patientBalance'] }),
      queryClient.refetchQueries({ queryKey: ['patientInvoices'] }),
      queryClient.invalidateQueries({ queryKey: ['appointment-events'] }),
      queryClient.invalidateQueries({ queryKey: ['overview-appointments'] }),
      queryClient.invalidateQueries({ queryKey: ['clinicalNotes'] })
    ]);
  };

  const getFormPacketsSelected = async (incomingPackets) => {
    try {
      const result = await requestApi({
        url: '/api/practice/settings/forms/form_packet/selected',
        params: {
          provider_id: selectedPractitioner?.value,
          service_id: ia(formik?.values?.procedures)
            ? formik?.values?.procedures?.map((p) => p?.id)
            : [],
          tag_id: ia(formik?.values.tags) ? formik?.values?.tags?.map((t) => t) : []
        },
        navigate
      });
      let packets = result?.packets;

      // if (!ia(packets)) return;

      let allFormIds = [];
      iaRa(packets).map((packet) => {
        allFormIds.push(...packet?.form_ids);
      });

      allFormIds = [...new Set(allFormIds)];

      return setFormPackets(
        (incomingPackets || formPackets).map((formPacket) => {
          return {
            ...formPacket,
            form_ids: formPacket.form_ids.map((form) => {
              const doesSameIdExistHere = allFormIds.includes(form.value);
              return doesSameIdExistHere ? { ...form, checked: true } : { ...form, checked: false };
            })
          };
        })
      );
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getFormPackets = async () => {
    try {
      setFormPacketLoading(true);
      const result = await requestApi({
        url: '/api/practice/settings/forms/form_packet/get',
        params: {
          withGraphFetched: { allForms: true, activeOnly: true }
        },
        navigate
      });
      let packets = result?.packets;

      if (ia(packets)) {
        setFormPackets(packets);
        getFormPacketsSelected(packets);
      } else setFormPackets([]);
      setFormPacketLoading(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const handleDateTimeSelection = () => {
    const { start, end } = selectedDateTime || {};
    const momentStart = moment.tz(start, currPractice?.timezone);
    if (start) {
      setDateAndTime({ ...dateAndTime, dateTime: momentStart });
      formik.setFieldValue('startsAt', start);

      if (end) {
        let duration = moment(start).diff(moment(end), 'm') * -1;

        if (duration >= 1440) duration = 30;

        selectTimeLength({ value: duration, label: `${duration} min` });
      }
    }
  };

  const clearTimes = () => {
    setDateAndTime({
      dateTime: moment.tz(new Date(), currPractice?.timezone),
      locationAvailableSlots: [],
      virtualAvailableSlots: [],
      showAll: false
    });
    setAdditionalTimes([]);
  };

  const extractFormIds = (packets) => {
    let formIds = [];
    packets.forEach((formPacket) => {
      formPacket.form_ids.forEach((form) => {
        if (form.checked && !formIds.includes(form.value)) {
          formIds.push(form.value);
        }
      });
    });
    return formIds;
  };

  const submitAppointment = async (checkForExistingAppt = true) => {
    setLoading(true);

    try {
      const appointment = formik?.values;
      const tags = formik?.values.tags;

      const hasErrors = await formik.validateForm().then((errors) => ia(Object.values(errors)));
      if (hasErrors) {
        formik.submitForm();
        setLoading(false);
        return;
      }

      const appointmentEndingTime = moment
        .tz(appointment.startsAt, currPractice?.timezone)
        .add(appointment.appointmentLength, 'm')
        .format();

      const appointmentToBeCreated = {
        user_id: appointment.patientId,
        practitioner_id: appointment.practitionerId,
        resource_id: appointment.resourceId,
        practice_note: appointment.practiceNote,
        type: appointment.appointmentType,
        starts_at: appointment.startsAt,
        ends_at: appointmentEndingTime,
        location: appointment.appointmentLocation,
        form_ids: formIds,
        procedures: ia(appointment.procedures) ? appointment.procedures : null,
        products: ia(appointment.products) ? appointment.products : null,
        virtual_link: appointment.virtual_link,
        enable_notifications: appointment.enable_notifications,
        tag_ids: formik?.values.tags
      };

      const data = await requestApi({
        url: '/api/appointment/create',
        params: {
          appointment: appointmentToBeCreated,
          additionalTimes,
          appointmentLength: appointment.appointmentLength,
          checkForExistingAppt
        },
        navigate
      });

      const { code, error, appointment_id } = data;

      switch (code) {
        case -1:
          // push redirect
          break;

        case 0:
          clearTimes();
          handleInvalidateQuery();
          hideNewAppointment();

          formik.resetForm();
          setSelectedPractitioner(null);
          setSelectedResource(null);
          setSelectedAppointmentType(null);
          setSelectedService(null);
          setSelectedPatient(null);
          setFormPackets([]);

          showAlert({
            message: 'Appointment created successfully',
            type: 'success',
            buttons: [
              {
                text: 'View',
                onClick: () => navigate(`/portal/appointments/${appointment_id}`)
              }
            ]
          });
          break;

        case 6:
          showAlert({
            message: 'An appointment already exists at this time',
            color: 'warning',
            buttons: [
              {
                text: 'Create anyway',
                onClick: () => submitAppointment(false),
                color: 'warning'
              }
            ]
          });
          break;
        case 4:
          error.map((errorMessage) => showAlert({ message: errorMessage, color: 'danger' }));
          break;

        default:
          showAlert({ message: error, color: 'danger' });
          Honeybadger.notify(`${filePath(import.meta.url)}, submitAppointment(), error: ${error}`);
          break;
      }
    } catch (err) {
      showAlert({ message: 'Appointment creation failed', color: 'danger' });
      Honeybadger.notify(`${filePath(import.meta.url)}, submitAppointment(), err: ${err}`);
    }

    setLoading(false);
  };

  const selectPractitioner = (practitioner, type) => {
    if (!practitioner) {
      setSelectedPractitioner('');
      formik.setFieldValue('practitionerId', '');

      return;
    }

    if (type === 'new_patient') {
      practitioner = practitioners.find((p) => {
        return p.value === Number(practitioner);
      });
    }

    setSelectedPractitioner(practitioner);
    formik.setFieldValue('practitionerId', practitioner.value);
  };

  const selectResource = (resource) => {
    if (!resource) {
      setSelectedResource('');
      formik.setFieldValue('resourceId', '');

      return;
    }

    setSelectedResource(resource);
    formik.setFieldValue('resourceId', resource.value);
  };

  const selectAppointmentLocation = (appointmentLocation) => {
    setSelectedLocation(appointmentLocation);
    formik.setFieldValue('appointmentLocation', appointmentLocation?.value);
  };

  const selectTimeLength = (time) => {
    if (!time) {
      setSelectedTimeLength('');
      formik.setFieldValue('appointmentLength', '');

      return;
    }

    const { value } = time;

    setSelectedTimeLength({ value, label: `${value} min` });

    formik.setFieldValue('appointmentLength', time?.value);
  };

  const hideNewPatientModal = () => setNewPatientModal(false);

  const getNewPatient = (newPatient) => {
    const { id, practitionerId, f_name, l_name, profile_photo } = newPatient;

    setSelectedPatient({
      f_name,
      l_name,
      value: id,
      label: `${f_name} ${l_name}`,
      profilePhoto: profile_photo
    });
    formik.setFieldValue('patientId', id);
    selectPractitioner(practitionerId, 'new_patient');
    setNewPatientModal(false);
  };

  const addAdditionalTime = async () => {
    try {
      const timeCopy = [...additionalTimes];
      const newTime = moment
        .tz(
          timeCopy[timeCopy.length - 1]?.dateTime || formik.values.startsAt,
          currPractice?.timezone
        )
        .add(1, 'w')
        .format();
      const data = await getPractitionerAvailability(newTime);

      const additionalTimeObj = {
        dateTime: newTime,
        locationAvailableSlots: data?.locationAvailableSlots,
        virtualAvailableSlots: data?.virtualAvailableSlots,
        showAll: false
      };
      timeCopy.push(additionalTimeObj);
      setAdditionalTimes(timeCopy);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDateTimeChange = async ({ newTime, index = null, type, itemSelected }) => {
    let dateTimeObject =
      typeof index === 'number' ? additionalTimes[index].dateTime : dateAndTime.dateTime;
    if (dateTimeObject && type === 'date') {
      newTime = moment.tz(newTime, currPractice?.timezone).add(12, 'h');
      const date = moment.tz(newTime, currPractice?.timezone).format('L');
      const time = moment.tz(dateTimeObject, currPractice?.timezone).format('h:mm A') || '';
      newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', currPractice?.timezone);
    } else if (dateTimeObject && type === 'time') {
      const date =
        moment.tz(dateTimeObject, currPractice?.timezone).format('L') ||
        moment().add(12, 'h').format('L');
      const time = newTime;
      newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', currPractice?.timezone);
    }
    if (!dateTimeObject && type === 'time') {
      newTime = moment.tz(
        moment().add(12, 'h').format('L') + ' ' + newTime,
        'M/D/YYYY h:mm A z',
        currPractice?.timezone
      );
    }
    const updatedTime = moment.tz(newTime, currPractice?.timezone).format();
    if (typeof index === 'number') {
      const updatedSlots = await getPractitionerAvailability(updatedTime);
      const timeCopy = [...additionalTimes];
      timeCopy[index] = {
        ...timeCopy[index],
        dateTime: updatedTime,
        selected: itemSelected,
        availableSlots: updatedSlots
      };
      setAdditionalTimes(timeCopy);
    } else {
      setDateAndTime({
        ...dateAndTime,
        dateTime: updatedTime,
        selected: itemSelected
      });
      formik.setFieldValue('startsAt', updatedTime);
    }
  };

  const deleteTime = (index) => {
    const timeCopy = [...additionalTimes];
    timeCopy.splice(index, 1);
    setAdditionalTimes(timeCopy);
  };

  const getHours = () => {
    const locale = 'en';
    const newHours = [];

    moment.locale(locale);

    const startHr = currPractice?.working_hours?.startsAt?.hour || 0;
    const endHr = currPractice?.working_hours?.endsAt?.hour || 24;

    for (let hour = startHr; hour < endHr; hour++) {
      newHours.push(moment({ hour }).format('h:mm A'));
      for (let minutes = 1; minutes < 12; minutes++) {
        newHours.push(
          moment({
            hour,
            minute: minutes * 5
          }).format('h:mm A')
        );
      }
    }
    setHours(newHours);
  };

  const getPractitionerAvailability = async (dateTime, singleDate) => {
    try {
      const params = {
        practitioner_id: selectedPractitioner.value,
        practice_id: currPractice?.id,
        service_id: selectedService.value || selectedService.id || null,
        time_length: selectedTimeLength.value,
        dateTime
      };

      const data = await requestApi({ url: '/api/calendar/availability', params, navigate });
      const availableSlots = {
        locationAvailableSlots: data.slotsAvailable.location,
        virtualAvailableSlots: data.slotsAvailable.virtual
      };
      if (singleDate) {
        setDateAndTime({
          ...dateAndTime,
          ...availableSlots,
          showAll: false,
          selected: null
        });
      }
      return availableSlots;
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    //! return availableSlots; shoudl check for this return
  };

  const handleLoadMore = ({ index }) => {
    if (typeof index === 'number') {
      const copyAdditionalTimes = [...additionalTimes];
      copyAdditionalTimes[index] = { ...copyAdditionalTimes[index], showAll: true };
      setAdditionalTimes(copyAdditionalTimes);
    } else {
      setDateAndTime({ ...dateAndTime, showAll: true });
    }
  };

  const onClickAvailableDate = ({ date }) => {
    handleDateTimeChange({ newTime: date, type: 'date' });
  };

  const onClickAdditionalDate = ({ date, index }) => {
    handleDateTimeChange({ newTime: date, index, type: 'date' });
  };

  const getPackages = async (patientId) => {
    const onSuccess = (data) => {
      const { invoicePackages } = data;

      if (ia(invoicePackages, -1)) setPackages(invoicePackages);
    };

    patientId &&
      requestApi({
        url: '/api/package/get_by_user',
        params: { patientId },
        onSuccess,
        navigate
      });
  };

  const handleSelectCreatedPatient = (patient) => {
    formik.setFieldValue('patientId', patient.id);
    formik.setFieldValue('patient', {
      ...patient,
      value: patient.id,
      label: patient.fullName,
      type: 'patient'
    });
    setSelectedPatient({
      ...patient,
      value: patient.id,
      label: patient.fullName,
      type: 'patient'
    });
  };

  return (
    <Allowed
      requiredPermissions={['appointments.create', 'member.read', 'patients.read', 'services.read']}
      showMessage={true}
      showIllustration={true}>
      <>
        <form className="grid !gap-5">
          <div className="grid grid-cols-1 !gap-4">
            <AppointmentPatient getPackages={getPackages} />
          </div>
          <div className="grid grid-cols-2 !gap-4">
            <Select
              isImage
              required
              label="Practitioner"
              options={practitioners}
              value={selectedPractitioner}
              inputId="practitionerSelect"
              data-qa="appointment-practitioner-select"
              className="border-neutral-100"
              placeholder="Type and select practitioner"
              onChange={(value) => selectPractitioner(value)}
              error={formik?.touched?.practitionerId && formik?.errors?.practitionerId}
            />
            <Allowed requiredPermissions="resources.read">
              <Select
                isImage
                label="Resource"
                options={resources}
                value={selectedResource}
                inputId="resourceSelected"
                data-qa="appointment-resource-select"
                className="border-neutral-100"
                placeholder="Type and select resource"
                onChange={(value) => selectResource(value)}
                error={formik?.touched?.resourceId && formik?.errors?.resourceId}
              />
            </Allowed>
          </div>

          <AppointmentDateAndTime
            practice={currPractice}
            dateAndTime={dateAndTime}
            handleDateTimeChange={handleDateTimeChange}
            onClickAvailableDate={onClickAvailableDate}
            addAdditionalTime={addAdditionalTime}
            additionalTimes={additionalTimes}
            hours={hours}
            deleteTime={deleteTime}
            onClickAdditionalDate={onClickAdditionalDate}
            handleLoadMore={handleLoadMore}
            practiceTimezone={currPractice?.timezone}
            selectAppointmentLocation={selectAppointmentLocation}
          />
          <Allowed requiredPermissions={['services.read', 'products.read']} isAny={true}>
            <ProceduresProducts packages={packages} />
          </Allowed>
          <Select
            placeholder="Duration"
            inputId="select-appointment-duration"
            data-qa="appointment-duration"
            value={io(selectedTimeLength) && selectedTimeLength}
            label="Appointment duration"
            options={appointment_lengths}
            onChange={(value) => selectTimeLength(value)}
            error={formik.touched.appointmentLength && formik.errors.appointmentLength}
          />
          <div className="flex flex-col gap-y-1">
            <p className="text-sm font-500">Appointment tags</p>
            <NewTagSelect
              kind="appointment"
              menuPortalTarget={document.body}
              setTagIds={(ids) => formik.setFieldValue('tags', ids)}
              emptyStateText="Select tags"
              outlined
            />
          </div>
          <AppointmentLocation
            location={location}
            selectedLocation={selectedLocation}
            appointmentLocations={appointmentLocations}
            selectAppointmentLocation={selectAppointmentLocation}
            virtual_link={formik.values?.virtual_link}
            updateVirtualLink={(value) => formik.setFieldValue('virtual_link', value)}
          />

          <Textarea
            id="note"
            data-qa="new-appointment-note"
            label="Note"
            value={formik.values.practiceNote}
            inputClassName="!overflow-y-scroll"
            placeholder="Add any notes, problems or symptoms"
            onChange={(e) => formik.setFieldValue('practiceNote', e.target.value)}
          />

          <FormPacketAppointment
            formPackets={formPackets}
            loading={formPacketLoading}
            setFormPackets={setFormPackets}
          />
          <SendAppNotification />
        </form>

        <NewPatient
          getNewPatient={getNewPatient}
          newPatientModal={newPatientModal}
          hideNewPatientModal={hideNewPatientModal}
          handleReturnCreatedPatient={handleSelectCreatedPatient}
        />
      </>
    </Allowed>
  );
};

export default NewAppointment;
