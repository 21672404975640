import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ClinicalSocialHistory from 'components/shared/Forms/Custom/SocialHistory/ClinicalSocialHistory';
import { syncHpOverviewTemplates } from 'components/shared/Forms/Custom/lib/customFormsHelper';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { userState } from 'components/state';
import Axios from 'configuredAxios';
import { io } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const SocialHistoryModal = ({
  defaultValue,
  setCustomResponses,
  setCurrentHpOverviewData,
  formId
}) => {
  const [currentFormData, setCurrentFormData] = useState(defaultValue);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, appointmentId } = useParams();
  const user = useRecoilValue(userState);

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId,
        patientId: id,
        appointmentId,
        response: currentFormData,
        type: 'socialHistory'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ message: 'Successfully saved!', color: 'success' });
        syncHpOverviewTemplates({
          formId,
          type: 'socialHistory',
          setOverviewData: setCustomResponses,
          setCurrentHpOverviewData,
          customFormResponse: newResponse,
          user,
          patientId: id
        });
      }
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {io(defaultValue) ? (
        <Icon
          icon="new-edit-gray"
          onClick={() => setShowModal(true)}
          data-qa="new-edit-gray-social-history"
        />
      ) : (
        <Icon
          icon="new-plus"
          onClick={() => setShowModal(true)}
          data-qa="new-plus-social-history"
        />
      )}

      <Modal
        slideFromRight
        handleOpen={showModal}
        title="Social history"
        className="w-[924px]"
        handleClose={() => {
          setShowModal(false);
        }}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setShowModal(false);
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }>
        {loading ? (
          <div>
            <Skeleton count={1} height="74px" />
            <Skeleton count={1} height="300px" />
            <Skeleton count={1} height="300px" />
          </div>
        ) : (
          <div>
            <div className="!pt-6">
              <ClinicalSocialHistory
                setItemModal={setShowModal}
                fromClinicalNote={true}
                formId={formId}
                defaultValue={defaultValue}
                setCurrentFormData={setCurrentFormData}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default SocialHistoryModal;
