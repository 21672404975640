import React, { useState } from 'react';
import { useEvent } from 'lib/hooks/queries/event/useEvent';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { reShapePractitioners, reShapeProcedures } from 'lib/helpers/utility';
import PreviewAppointment from 'components/practice/appointment/PreviewAppointment/PreviewAppointment';
import EventPreview from 'components/practice/appointment/EventForm/EvenPreview/EventPreview';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
// import Appointment from 'components/practice/NewAppointment/Appointment';

const FullPreview = ({ id, type, show }) => {
  const { data: event } = useEvent({ params: { id }, options: { enabled: type === 'event' } });
  const { data: practitionersData } = usePractitioners(null);
  const { data: servicesData } = useServices({ options: { refetchOnMount: false } });
  const [, setSelectedValue] = useState(null);
  const { currentModal, setCurrentModal } = useDashboardContext();

  const handleHideModal = () => setCurrentModal({ ...currentModal, show: false });

  if (type === 'event' && event)
    return (
      <EventPreview
        event={event}
        isOpen={show}
        hideEventModal={handleHideModal}
        practitioners={reShapePractitioners(practitionersData?.practitioners)}
        setEvent={setSelectedValue}
      />
    );

  if (type === 'appointment')
    return (
      <PreviewAppointment
        appointment={{ id }}
        showPreviewAppointment={show}
        hidePreviewAppointment={handleHideModal}
        setAppointment={setSelectedValue}
        practitioners={reShapePractitioners(practitionersData?.practitioners)}
        services={reShapeProcedures(servicesData?.services)}
      />
    );
};

export default FullPreview;
