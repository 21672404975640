import cs from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { imagePath } from 'lib/helpers/utility';
import { useNavigate } from 'react-router-dom';
import overlay from '../../practice/charts/InsuranceProfile/icons/overlay.svg';
import ImagePickerActions from './components/ImagePickerActions';
import { captureHighResScreenshot, resizeFile } from './lib/helpers';

const ImagePicker = ({
  handle,
  onCapture = () => {},
  name,
  kiosk,
  noCrop,
  processing,
  handleContinue,
  resetHandle,
  coords = {},
  removeOverlay,
  defaultFacingMode = 'environment',
  dimensions = [1280, 720]
}) => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cameraReady, setCameraReady] = useState(false); // State to track camera readiness
  const [facingMode, setFacingMode] = useState(defaultFacingMode);
  const [url, setUrl] = useState(handle ? imagePath(handle) : null);
  const [crop, setCrop] = useState(null);

  const { device } = useUIContext();

  const imageRef = useRef(null);

  const handleCameraLoad = () => {
    setCameraReady(true);
  };

  useEffect(() => {
    setUrl(handle ? imagePath(handle) : null);
  }, [handle]);

  const videoConstraints = {
    facingMode: { ideal: facingMode },
    width: { ideal: dimensions[0] || 1280 },
    height: { ideal: dimensions[1] || 720 },
    aspectRatio: dimensions[0] / dimensions[1]
  };

  const capture = async () => {
    const filestackClient = await getFilestackClient(navigate);
    setLoading(true);

    // Capture the full image from the webcam
    const imageSrc = captureHighResScreenshot(webcamRef);

    // Create an image element to load the captured screenshot
    const image = new Image();
    image.src = imageSrc;

    // Wait for the image to load
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    // Create a canvas to crop the image
    const canvas = document.createElement('canvas');
    const targetWidth = dimensions[0];
    const targetHeight = dimensions[1];

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext('2d');

    // Calculate the scale factors and crop offsets
    const aspectRatio = targetWidth / targetHeight;
    const imageAspectRatio = image.width / image.height;

    let cropWidth, cropHeight, cropX, cropY;

    if (imageAspectRatio > aspectRatio) {
      // Image is wider than the target aspect ratio
      cropHeight = image.height;
      cropWidth = cropHeight * aspectRatio;
      cropX = (image.width - cropWidth) / 2;
      cropY = 0;
    } else {
      // Image is taller than the target aspect ratio
      cropWidth = image.width;
      cropHeight = cropWidth / aspectRatio;
      cropX = 0;
      cropY = (image.height - cropHeight) / 2;
    }

    // Draw the cropped portion onto the canvas
    ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, targetWidth, targetHeight);

    // Convert the cropped canvas to a Blob
    const croppedImageSrc = canvas.toDataURL('image/jpeg');
    const blob = await fetch(croppedImageSrc).then((res) => res.blob());

    // Resize the image if necessary and upload
    const resizedImageBlob = await resizeFile(blob);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await filestackClient.upload(resizedImageBlob);
    onCapture(response?.handle, name);

    setLoading(false);
  };

  const onFileChange = async (event) => {
    const filestackClient = await getFilestackClient(navigate);
    setLoading(true);
    const file = event.target.files[0];
    const resizedImageBlob = await resizeFile(file);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await filestackClient.upload(resizedImageBlob);
    onCapture(response?.handle, name);
    setLoading(false);
  };

  useEffect(() => {
    const img = imageRef.current;
    const handleImageLoad = () => {
      if (coords && img) {
        const scaleX = img.width / img.naturalWidth;
        const scaleY = (img.height / img.naturalHeight) * (img.naturalHeight / img.naturalWidth);

        setCrop({
          unit: 'px',
          width: Math.abs(coords?.width) * scaleX,
          height: Math.abs(coords?.height) * scaleY,
          x: coords?.x * scaleX,
          y: coords?.y * scaleY
        });
      }
    };

    if (img?.complete) {
      handleImageLoad();
    } else {
      img?.addEventListener('load', handleImageLoad);
    }

    return () => {
      img?.removeEventListener('load', handleImageLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords?.x, coords?.y, coords?.width, coords?.height]);

  useEffect(() => {
    if (imageRef.current?.complete) {
      setCrop(null);
    }
  }, [imageRef.current?.complete]);

  return (
    <div className="flex w-full flex-col items-center px-6">
      <div
        style={{
          maxWidth: '30rem',
          maxHeight: '60vh',
          aspectRatio: dimensions[0] / dimensions[1]
        }}
        className={url ? 'relative mt-4' : `relative mt-4 overflow-hidden rounded-xl`}>
        {url ? (
          <>
            {!processing ? (
              <ReactCrop crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
                <img ref={imageRef} crossOrigin="use-credentials" src={url} />
              </ReactCrop>
            ) : (
              <img ref={imageRef} className="w-full" crossOrigin="use-credentials" src={url} />
            )}

            {loading && !kiosk && !removeOverlay && (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl ">
                <img className="!w-full" src={overlay}></img>
              </div>
            )}
          </>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              className="h-full w-full rounded-xl object-cover"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover' // Ensures the feed behaves like object-fit
              }}
              onUserMedia={handleCameraLoad} // Event to mark camera as ready
            ></Webcam>
            {!kiosk && (
              <div
                className={cs(
                  'absolute  left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl',
                  device === 'tablet'
                    ? 'bottom-0'
                    : device === 'mobile' || device === 'tablet-sm'
                      ? 'bottom-[4.38px]'
                      : 'bottom-[5.5px]'
                )}>
                {!removeOverlay && <img className="!w-full" src={overlay}></img>}
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-5 flex items-center justify-center gap-4 text-white">
        <ImagePickerActions
          handle={handle}
          name={name}
          processing={processing}
          resetHandle={resetHandle}
          coords={coords}
          crop={crop}
          loading={loading}
          imageRef={imageRef}
          noCrop={noCrop}
          handleContinue={handleContinue}
          onFileChange={onFileChange}
          capture={capture}
          cameraReady={cameraReady}
          setUrl={setUrl}
          setFacingMode={setFacingMode}
        />
      </div>
    </div>
  );
};

export default ImagePicker;
