import React, { useState } from 'react';
import { ia } from 'lib/helpers/utility';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';
import Loading from 'components/shared/Loading/Loading';
import Button from 'components/shared/Buttons/Button';
import CardBox from 'components/Payments/components/CardBox';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PatientCheckinPaymentOnline = ({
  cards,
  cardsLoading,
  setShow: parentSetShow,
  show: parentShow,
  setSelectedCard,
  setCards,
  amount,
  appointmentIdx,
  saveCard,
  SuccessScreen,
  onApproval,
  invoices,
  billingAddress,
  onFail,
  onCancel,
  onDeclined,
  updateAmount,
  surcharge = 0
}) => {
  const [show, setShow] = useState({ manualEntry: false });

  if (show.manualEntry) {
    return (
      <LightboxWrapper
        amount={amount}
        appointmentIdx={appointmentIdx}
        saveCard={saveCard}
        SuccessScreen={SuccessScreen}
        onApproval={onApproval}
        invoices={invoices}
        billingAddress={billingAddress}
        onFail={onFail}
        onCancel={onCancel}
        onDeclined={onDeclined}
        updateAmount={updateAmount}
        surcharge={surcharge}
      />
    );
  }

  if (cardsLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div>Please select from the cards below</div>
        <Loading />
        <div>or</div>
        <Button
          text="Enter Card Information Manually"
          onClick={() => setShow((ps) => ({ ...ps, manualEntry: true }))}
        />
      </div>
    );
  }

  if (ia(cards)) {
    return (
      <div className="flex flex-col items-center justify-center">
        {ia(cards) && (
          <div className="!mb-5 !mt-5 flex w-full flex-col items-center">
            <p className="text-sm font-400 text-neutral-500">Saved Cards</p>

            <div className="!mt-3 flex w-full flex-wrap justify-center !gap-4  xl:!px-16">
              {cards.map((c, i) => {
                return (
                  <CardBox
                    setShow={parentSetShow}
                    show={parentShow}
                    key={i}
                    card={c}
                    setSelectedCard={setSelectedCard}
                    cards={cards}
                    setCards={setCards}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div>or</div>
        <Button
          text="Enter Card Information Manually"
          onClick={() => setShow((ps) => ({ ...ps, manualEntry: true }))}
        />
      </div>
    );
  }

  return (
    <LightboxWrapper
      amount={amount}
      appointmentIdx={appointmentIdx}
      saveCard={saveCard}
      SuccessScreen={SuccessScreen}
      onApproval={onApproval}
      invoices={invoices}
      billingAddress={billingAddress}
      onFail={onFail}
      onCancel={onCancel}
      onDeclined={onDeclined}
      updateAmount={updateAmount}
      surcharge={surcharge}
    />
  );
};

export default withErrorBoundary(PatientCheckinPaymentOnline);
