/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getAppointmentv2 } from 'api/Appointment';

export const useAppointmentv2 = ({ params, options }) => {
  const navigate = useNavigate();
  const { id } = params;

  return useQuery(
    ['appointmentv2', parseInt(id)],
    ({ signal }) => getAppointmentv2(navigate, params, signal),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
