import { useInfiniteFetchData } from 'lib/hooks/useInfiniteFetchData';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from 'lodash';
import cs from 'classnames';
import Input from '../Input/Input';
import { camelCaseToReadableFormat, ia } from 'lib/helpers/utility';
import Loading from '../Loading/Loading';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import useDebounce from 'lib/hooks/useDebounce';
import Icon from '../Icon/Icon';

const LIMIT_DEFAULT = 25;
const INITIAL_PAGE = 1;

const InfiniteScroll = (
  {
    queryKey,
    endpoint,
    queryProps = {},
    children, // children is expected to be a function
    EmptyComponent = NotFound,
    className = '',
    withSearch = false,
    formatData,
    setData,
    data,
    BeforeComponent = () => null
  },
  ref
) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debounceSearch = useDebounce(searchTerm, 300);

  const inputRef = useRef(null);
  const scrollRef = useRef(null);

  const name = camelCaseToReadableFormat(queryKey);

  const {
    data: newData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage
  } = useInfiniteFetchData({
    queryKey,
    url: endpoint,
    params: {
      limit: LIMIT_DEFAULT,
      page: INITIAL_PAGE,
      withCount: true,
      ...queryProps,
      filters: { searchTerm: debounceSearch }
    },
    dependencies: ['infinite', debounceSearch],
    formatData,
    onSuccess: (data) => {
      if (data?.pages?.length && setData) {
        const flattenedData = data?.pages?.flatMap((page) => page?.[queryKey]) || [];
        setData(flattenedData || []);
      }
    }
  });

  const handleScroll = debounce(() => {
    const div = scrollRef.current;
    const parentDiv = div?.parentElement;

    const isNearBottom = parentDiv.scrollTop + parentDiv.clientHeight >= parentDiv.scrollHeight - 1;

    if (isNearBottom && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 200);

  useImperativeHandle(ref, () => ({
    ...ref.current,
    handleScroll
  }));

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <div ref={scrollRef} className="flex h-auto flex-col gap-2">
      <BeforeComponent />
      {withSearch && (
        <div className="sticky top-0 z-10 bg-white pt-2 ">
          <Input
            forwardedRef={inputRef}
            rounded="full"
            name="searchTerm"
            value={searchTerm}
            id={`search-${queryKey}`}
            data-qa={`search-${queryKey}`}
            placeholder={`Search ${name}`}
            rightIcon={searchTerm ? 'new-close' : 'search'}
            rightIconClick={() => setSearchTerm('')}
            onChange={handleInputChange}
          />
          <p className="text-xs font-500 leading-5 text-neutral-500">{name}</p>
        </div>
      )}
      {ia(newData) ? (
        <div className={cs('flex h-full flex-col gap-2', className)}>
          {newData?.map((item, index) => (
            <React.Fragment key={index}>{children({ item, index })}</React.Fragment>
          ))}
        </div>
      ) : (
        <EmptyComponent />
      )}
      {isFetchingNextPage && (
        <div className="align-center flex justify-center pb-2">
          <Icon icon="loader" className="animate-spin" />
        </div>
      )}
    </div>
  );
};
export default forwardRef(InfiniteScroll);
