import onUserInit from './listeners/userInit/onUserInit';
import onAuthReq from './listeners/authReq/onAuthReq';
import onUserAuth from './listeners/userAuth/onUserAuth';
import onDataUpdate from './listeners/dataUpdate/onDataUpdate';

export default (socket, sessionToken = null, sessionClientId = null) => {
  try {
    socket.emit('user-init', { ok: true });

    socket.on('user-init', (response) =>
      onUserInit(socket, response, sessionToken, sessionClientId)
    );
    socket.on('auth-req', () => onAuthReq(socket));

    socket.on('user-auth', () => onUserAuth(socket));

    socket.on('data-update', onDataUpdate);

    return () => {
      socket.off('user-auth', { sessionToken, sessionClientId });
    };
  } catch (error) {
    console.error(error);
  }
};
