import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

const PatientNeeds = ({ isTPlan, sectionRef = null }) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const [patientNeeds, setPatientNeeds] = useState('');
  const patientNeedsRef = useRef();

  useEffect(() => {
    setPatientNeeds(isTPlan ? selectedTreatmentPlan?.patient_needs : clinicalNote?.present_illness);
  }, [selectedTreatmentPlan, clinicalNote?.present_illness]);

  useImperativeHandle(sectionRef, () => ({
    formData: { present_illness: patientNeeds }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setPatientNeeds(value ?? e);

    if (isTPlan) {
      setSelectedTreatmentPlan({ ...selectedTreatmentPlan, patient_needs: value ?? e });
    } else {
      setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
    }
  };
  return (
    <Textarea
      isEditor
      label="Patient needs / presenting issues"
      placeholder="Write patient needs here"
      id="present_illness"
      name="present_illness"
      className="min-h-[200px]"
      value={patientNeeds}
      onChange={(e) => handleChange(e, 'present_illness')}
      transcribing
      forwardedRef={patientNeedsRef}
      disabled={!isTPlan && clinicalNote?.locked}
      data-qa="present-illness"
    />
  );
};

export default PatientNeeds;
