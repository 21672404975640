const { io, ia, iaRa, spaceToKebabCase } = require('../../../../../../lib/helpers/utility');

const TARGET_KEYS = [
  'allergies',
  'vaccines',
  'familyHistory',
  'surgicalHistory',
  'medications',
  'weightLossAdministration'
];

exports.prepareSelection = ({
  savedSelection = {},
  initialSelection = {},
  componentsOrder = null,
  selectedCustomFormTypes = null
}) => {
  if (io(savedSelection) && io(initialSelection)) {
    const result = selectMatchingKeys({ savedSelection, initialSelection });

    const completeSelection = verifyAndCompleteObject({
      selection: result,
      componentsOrder,
      selectedCustomFormTypes
    });

    return completeSelection;
  }

  if (io(initialSelection)) {
    const completeSelection = verifyAndCompleteObject({
      selection: initialSelection,
      componentsOrder,
      selectedCustomFormTypes
    });

    return completeSelection;
  }

  return;
};

const selectMatchingKeys = ({ savedSelection = {}, initialSelection = {} }) => {
  const newObj = {};

  Object.keys(savedSelection).forEach((key) => {
    if (key in initialSelection) {
      newObj[key] = savedSelection[key];
    }
  });

  return newObj;
};

const verifyAndCompleteObject = ({
  selection,
  componentsOrder = null,
  selectedCustomFormTypes = null
}) => {
  let newObject = {};

  Object.keys(selection).forEach((key) => {
    const currentValue = selection[key];

    if (ia(currentValue.formType) && !checkIfIsCustomForm(key)) delete selection[key].formType;

    const isChart3D = key === 'chart3d';

    if (isChart3D) {
      newObject[key] = face3DChartFormTypes({ currentValue });
    } else if (checkIfIsCustomForm(key)) {
      // Commented out for now
      // let formTypes = mergeData({
      //   currentData: currentValue?.formType,
      //   newData: createFormTypes(selectedCustomFormTypes?.[key] || componentsOrder?.[key]?.formType)
      // });

      newObject[key] = {
        ...currentValue,
        position: componentsOrder ? componentsOrder?.[key]?.position : currentValue?.position,
        formType: componentsOrder?.[key]?.formType
          ? componentsOrder?.[key]?.formType
          : createFormTypes(selectedCustomFormTypes?.[key] || componentsOrder?.[key]?.formType)
      };
    } else {
      newObject[key] = currentValue;
    }

    newObject = temporaryTurnOffSections(newObject, componentsOrder);

    if (!currentValue.id) {
      newObject[key] = {
        ...newObject[key],
        id: self.crypto.randomUUID()
      };
    }
  });

  const finalCheck = this.checkIfAllFormTypesFalse({
    selection: newObject,
    processType: 'CHILDREN',
    checkAll: true
  });

  const finalizeNewObject = sortByPosition(finalCheck, componentsOrder);

  return finalizeNewObject;
};

const temporaryTurnOffSections = (forms, componentsOrder = null) => {
  const result = structuredClone(forms);

  TARGET_KEYS.forEach((key) => {
    if (forms[key]) {
      if (componentsOrder) {
        if (componentsOrder?.[key]) {
          // Keep the key if it exists in componentsOrder
          result[key] = forms[key];
        } else {
          // Remove the key if it's not in componentsOrder
          delete result[key];
        }
      } else {
        // If componentsOrder is null, apply the original logic
        result[key] = {
          ...forms[key],
          formType: forms[key].formType?.map((type) => ({ ...type, checked: false })) || []
        };
      }
    }
  });

  return result;
};

// const mergeData = ({ currentData = [], newData = [] }) => {
//   let mergedData = structuredClone(iaRa(newData));

//   iaRa(currentData).forEach((currentItem) => {
//     const index = mergedData.findIndex((item) => item?.title === currentItem?.title);
//     if (index !== -1) {
//       mergedData[index] = currentItem;
//     } else {
//       mergedData.push(currentItem);
//     }
//   });

//   return updateCheckedStatus({ formTypes: mergedData });
// };

const face3DChartFormTypes = ({ currentValue = {} }) => {
  const defaultFormTypes = [
    { title: 'Front View', checked: true },
    { title: 'Right View', checked: true },
    { title: 'Left View', checked: true },
    { title: 'Items', checked: true },
    { title: 'Narrative', checked: true }
  ];

  const existingFormTypes = iaRa(currentValue?.formType);

  const processedFormTypes = defaultFormTypes.map((defaultType) => {
    const existingType = existingFormTypes.find((type) => type?.title === defaultType.title);

    if (!existingType) {
      return {
        ...defaultType,
        id: self.crypto.randomUUID()
      };
    }

    return existingType;
  });

  return {
    ...currentValue,
    formType: processedFormTypes
  };
};

const checkIfIsCustomForm = (formName) => {
  const SIMPLE_FORMS = [
    'providerSignature',
    'vitals',
    'exam',
    'test',
    'assessment',
    'plan',
    'cptCodes',
    'notes',
    'diagnosisCodes',
    'objective',
    'subjective',
    'complaints',
    'patientNeeds',
    'problemManifestationDefinition',
    'sessionQuotesAndContents',
    'timeFrames',
    'riskAndCoMorbidities',
    'referralOrders',
    'nonERXPrescriptionOrders',
    'labOrImagingOrders',
    'myscribe'
  ];

  return !SIMPLE_FORMS.includes(formName);
};

const createFormTypes = (selectedCustomFormTypes = []) => {
  let formTypes = [];

  if (ia(selectedCustomFormTypes)) {
    formTypes = selectedCustomFormTypes.map((row) => {
      return {
        id: self.crypto.randomUUID(),
        title: row?.label,
        checked: false
      };
    });
  } else {
    formTypes = [
      {
        id: self.crypto.randomUUID(),
        title: 'Template',
        checked: false
      },
      {
        id: self.crypto.randomUUID(),
        title: 'Narrative',
        checked: true
      }
    ];
  }

  return updateCheckedStatus({ formTypes });
};

const updateCheckedStatus = ({ formTypes = [] }) => {
  const findNarrativeType = iaRa(formTypes).findIndex((type) => type?.title === 'Narrative');

  if (findNarrativeType !== -1) {
    formTypes[findNarrativeType].checked = true;
  } else {
    formTypes[0].checked = true;
  }

  return formTypes;
};

exports.checkIfAllFormTypesFalse = ({ selection, processType, key = null, checkAll = false }) => {
  let copySelection = {};

  Object.keys(selection).forEach((currrentKey) => {
    let currentObject = selection[currrentKey];
    const shouldUpdate = currrentKey === key || checkAll;

    if (shouldUpdate && processType === 'PARENT') {
      return (copySelection[currrentKey] = {
        ...currentObject,
        formType: isSectionChecked(currentObject)
      });
    }

    if (shouldUpdate && processType === 'CHILDREN') {
      return (copySelection[currrentKey] = isOneOfFormTypesChecked(currentObject));
    }

    return (copySelection[currrentKey] = currentObject);
  });

  return copySelection;
};

const isSectionChecked = (currentObject) => {
  if (!ia(currentObject.formType)) return currentObject;

  const isSectionChecked = currentObject?.checked;

  if (isSectionChecked) {
    return currentObject?.formType?.map((f) =>
      f.title !== 'Template' ? { ...f, checked: true } : { ...f, checked: false }
    );
  } else {
    return currentObject?.formType?.map((f) => ({ ...f, checked: false }));
  }
};

const isOneOfFormTypesChecked = (currentObject) => {
  if (!ia(currentObject.formType)) return currentObject;

  const isOneOfFormTypesChecked = currentObject?.formType?.some(({ checked }) => checked);

  if (isOneOfFormTypesChecked) return { ...currentObject, checked: true };
  else return { ...currentObject, checked: false };
};

exports.titleToPosition = (reference) => {
  if (!reference) return null;

  return Object.keys(reference || {})
    .filter((key) => !!reference[key]?.checked)
    .reduce((acc, key) => {
      const refItem = reference?.[key];
      if (refItem) {
        acc[key] = refItem;
      }
      return acc;
    }, {});
};

const sortByPosition = (selection, reference = null) => {
  return (
    Object.keys(selection)
      // include if key is in TARGET_KEYS or if checked is true this it temporary
      // .filter((key) => TARGET_KEYS.includes(key) || selection[key].checked)
      // .filter((key) => selection[key].checked)
      .sort((a, b) => {
        const getPosition = (item) => {
          if (!reference) return item?.position;

          const newWay = findByTitle(reference, item?.title);
          if (newWay) return newWay?.position;

          const refItem = reference[item?.title];
          return refItem;
        };

        function findByTitle(reference, searchTitle) {
          return Object.values(reference || {}).find((refItem) => refItem?.title === searchTitle);
        }

        const posA = getPosition(selection[a]);
        const posB = getPosition(selection[b]);

        if (typeof posA === 'number' && typeof posB === 'number') {
          return posA - posB;
        }
        if (typeof posA === 'number') {
          return -1;
        }
        if (typeof posB === 'number') {
          return 1;
        }
        return 0;
      })
      .reduce((acc, key) => {
        acc[key] = selection[key];
        return acc;
      }, {})
  );
};

exports.processAdvancedHPForms = (advancedHP, cnDisplaySettings) => {
  const advancedHPForms = advancedHP?.map((item) => {
    if (
      cnDisplaySettings?.hp?.enabled &&
      cnDisplaySettings?.sections?.hp[item.form_id || item.id]?.enabled === false
    ) {
      return null;
    }
    return item;
  });

  const filteredAdvancedHPForms = advancedHPForms?.filter((value) => io(value) && value.form);

  const getAdvancedHP = ia(filteredAdvancedHPForms)
    ? Object.fromEntries(
        filteredAdvancedHPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'hp',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedHP;
};

exports.processAdvancedSOAPForms = (advancedSOAP, cnDisplaySettings) => {
  const advancedSOAPForms = advancedSOAP?.map((item) => {
    if (cnDisplaySettings?.sections?.soap[item.form_id || item.id]?.enabled === false) {
      return null;
    }
    return item;
  });

  const filteredAdvancedSOAPForms = advancedSOAPForms?.filter((value) => io(value) && value.form);

  const getAdvancedSOAP = ia(filteredAdvancedSOAPForms)
    ? Object.fromEntries(
        filteredAdvancedSOAPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'soap',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedSOAP;
};

exports.processAdvancedCustomForms = (advancedSOAP) => {
  const filteredAdvancedSOAPForms = advancedSOAP?.filter((value) => io(value) && value.form);

  const getAdvancedSOAP = ia(filteredAdvancedSOAPForms)
    ? Object.fromEntries(
        filteredAdvancedSOAPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'customForm',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedSOAP;
};

const advancedFormTitle = (item) => {
  return `${item?.form?.name || item?.name} #${item?.form_id || item?.id}`;
};
