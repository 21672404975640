const { default: toast } = require('react-hot-toast');
const _ = require('lodash');
const { Honeybadger } = require('@honeybadger-io/react');
const { io } = require('../../../../../lib/helpers/utility');
const { requestApi } = require('../../../../../api/Api');

exports.submitForm = async ({
  id,
  appointmentId,
  currentFormData,
  defaultState,
  type,
  setOverviewData,
  setCurrentHpOverviewData,
  formId = null,
  navigate,
  setSaveButtonText,
  updateSaveButton = () => null,
  user
}) => {
  try {
    if (_.isEqual(currentFormData, defaultState)) return;
    setSaveButtonText('Saving');
    const result = await requestApi({
      url: '/api/custom_form_response/create',
      params: {
        formId,
        patientId: id,
        appointmentId,
        response: currentFormData,
        type
      },
      navigate
    });

    const { code, redirect, error, newResponse } = result;
    switch (code) {
      case -1:
        navigate(redirect);
        break;
      case 0:
        this.syncHpOverviewTemplates({
          formId,
          type,
          setOverviewData,
          setCurrentHpOverviewData,
          customFormResponse: newResponse,
          user,
          patientId: id
        });
        break;
      case 2:
        error && toast.error(error);
        break;
      case 3:
        error && toast.error(error);
        break;
      default:
        Honeybadger.notify(
          `file: customFormHelper, method: submitForm - catch, error: ${
            error ? error : 'unknown error'
          }`
        );
        break;
    }
  } catch (error) {
    Honeybadger.notify(`file: customFormHelper, method: submitForm - catch, error: ${error}`);
    console.error(error);
  }
  await updateSaveButton();
};

exports.syncHpOverviewTemplates = ({
  formId,
  type,
  setOverviewData,
  setCurrentHpOverviewData,
  customFormResponse,
  user,
  patientId
}) => {
  if (!io(customFormResponse) || !setOverviewData) return;

  const customFormRows = customFormResponse?.response;

  const { f_name, l_name, id } = user;

  const createdBy = {
    id,
    fullName: `${f_name} ${l_name}`.trim(),
    isPatient: Number(patientId) === id
  };
  const customFormResponseWithCreatedInfo = { ...customFormResponse, createdBy };

  switch (type) {
    case 'medicationHistory':
      setCurrentHpOverviewData((prevState) => {
        const previousData = prevState || {};

        return {
          ...previousData,
          [type]: customFormResponse
        };
      });

      setOverviewData((prevState) => {
        const previousTypeData = prevState[type] || [];
        let findFormId = previousTypeData?.findIndex((cForm) => cForm.id === formId);

        let allCustomForms = [...previousTypeData];
        if (findFormId >= 0) {
          allCustomForms[findFormId] = {
            ...allCustomForms[findFormId],
            createdBy,
            response: customFormRows
          };
        }

        return {
          ...(prevState || {}),
          [type]:
            findFormId >= 0
              ? allCustomForms
              : [customFormResponseWithCreatedInfo, ...(prevState.medications || [])]
        };
      });
      break;
    default:
      setCurrentHpOverviewData((prevState) => {
        const previousData = prevState || {};
        return {
          ...previousData,
          [type]: customFormResponse
        };
      });

      setOverviewData((prevState) => {
        const previousTypeData = prevState[type] || [];
        let findFormId = previousTypeData?.findIndex((cForm) => cForm.id === formId);

        let allCustomForms = [...previousTypeData];

        if (findFormId >= 0) {
          allCustomForms[findFormId] = {
            ...allCustomForms[findFormId],
            createdBy,
            response: customFormRows
          };
        }

        return {
          ...(prevState || {}),
          [type]:
            findFormId >= 0
              ? allCustomForms
              : [customFormResponseWithCreatedInfo, ...previousTypeData]
        };
      });
      break;
  }
};

exports.scrollIntoNarrativeView = ({ ref = {}, className = '!bg-primary-50' }) => {
  setTimeout(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    ref?.current?.classList.add(className);

    setTimeout(() => {
      ref?.current?.classList.remove(className);
    }, 3000);
  }, 500);
};

exports.convertToNormalText = (key) => {
  if (!key) return key;

  const convertedKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

  if (!convertedKey) return key;

  return convertedKey.charAt(0).toUpperCase() + convertedKey.slice(1).replace(/_/g, ' ');
};
