import React from 'react';
import { useEnhanceNoteContextData } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';
import { filterCheckedAndSelectedComponents } from '../../lib/helpers';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { getSelectedForms, transformData } from '../../../shared/lib/helpers';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

export function EnhanceNoteViewFooter() {
  const { filledForms = {} } = useEnhanceNoteContextData();
  const checkedAndSelectedFilledForms = filterCheckedAndSelectedComponents(filledForms);

  const {
    currentHpOverviewData,
    advancedHP,
    advancedSOAP,
    setEnhanceNarrativeParams,
    customFormTypes,
    overviewData
  } = useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);

  const customAdvancedForms = Object.values(customFormTypes || [])?.flat();
  const advancedForms = [...advancedHP, ...advancedSOAP, ...customAdvancedForms];
  const clinicalNoteForms = { ...clinicalNote, ...currentHpOverviewData, ...overviewData };

  const navigate = useNavigate();

  const formsList = transformData(Object.values(checkedAndSelectedFilledForms));

  const onGenerate = () => {
    // setEnhanceLoading(true);
    const selectedForms = getSelectedForms(
      checkedAndSelectedFilledForms,
      clinicalNoteForms,
      advancedForms
    );

    const finalPrompt = {
      clinical_note_id: clinicalNote?.id,
      selectedForms: Object.keys(selectedForms)
    };
    console.log({ finalPrompt });
    setEnhanceNarrativeParams({
      kind: 'myscribe-ai',
      messageKind: 'narrative',
      body: selectedForms
    });
  };

  const onGoBack = () => {
    navigate(-1);
  };
  return (
    <AIChatInput
      rightButtons={[
        <SignAndLock
          key={1}
          buttonProps={{
            className: '!py-2 !pl-4 !pr-4 mr-2 border-[1.5px] border-solid',
            outlined: true,
            textClassName: 'text-primary-300',
            iconColor: 'black',
            disabled: formsList?.length === 0
          }}
        />,
        <Button
          key={2}
          text="Enhance"
          icon="stars-ai"
          iconSize={18}
          iconColor="white"
          size="small"
          className={cs('!py-2 !pl-4 !pr-4')}
          onClick={onGenerate}
          disabled={!formsList?.length}
        />
      ]}
      className="footer-blurred mb-10 items-center pb-2"
      onGoBack={onGoBack}
      isInput={false}
    />
  );
}
