import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Button from '../../../../../shared/Buttons/Button';
import PatientNeeds from '../../CurrentNote/TypeNotes/IntakeNote/components/PatientNeeds';
import ProblemManifestationDefinition from '../../CurrentNote/TypeNotes/IntakeNote/components/ProblemManifestationDefinition';
import Goals from '../../CurrentNote/TypeNotes/ProgressNote/components/Goals';
import ICDCodes from '../../CurrentNote/TypeNotes/SOAPNote/components/ICDCodes';
import Box from '../../shared/Box';
import AdditionalComments from '../components/edit/AdditionalComments';
import TreatmentFrequency from '../components/edit/TreatmentFrequency';
import TreatmentTeam from '../components/edit/TreatmentTeam/TreatmentTeam';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';
import { ia } from 'lib/helpers/utility';
import Tippy from '@tippyjs/react';

const noteTypes = ['intake', 'progress', 'soap'];

const TPEdit = ({ view, origin }) => {
  const formRef = useRef();
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();
  let clinicalNote = useRecoilValue(clinicalNoteState);
  const { type } = useParams();

  useEffect(() => {
    if (noteTypes.includes(origin)) loadDataFromCN();
  }, []);

  const loadDataFromCN = () => {
    setSelectedTreatmentPlan((prevSelectedTreatmentPlan) => ({
      ...prevSelectedTreatmentPlan,
      icd10: clinicalNote?.icd_10_codes,
      patient_needs: clinicalNote?.present_illness,
      problem_manifestation_definition: clinicalNote?.other_complaints,
      goals: clinicalNote?.goals
    }));
  };

  const hasData =
    ia(clinicalNote?.icd_10_codes) ||
    clinicalNote?.present_illness ||
    clinicalNote?.other_complaints ||
    ia(clinicalNote?.goals);

  return (
    <div>
      {view === 'create' && (
        <div className="flex justify-end">
          <Tippy className="tippy-dark" disabled={hasData} content="No available data to load">
            <span>
              <Button
                type="secondary"
                color="neutral"
                onClick={loadDataFromCN}
                disabled={!hasData}
                text="Load data from clinical note"
                data-qa="load-data-from-clinical-note-btn"
              />
            </span>
          </Tippy>
        </div>
      )}
      <div className="flex flex-col gap-4 !pb-5 !pt-3" ref={formRef}>
        <ICDCodes box={false} isTPlan showOldUI={true} />
        <PatientNeeds isTPlan />
        <ProblemManifestationDefinition isTPlan />
        <Box className="bg-white !pr-2">
          <Goals isNarrative={false} title="Goals" isTPlan section={type} isAdvancedForm />
        </Box>
        <TreatmentTeam />
        <AdditionalComments />
        <TreatmentFrequency />
      </div>
    </div>
  );
};

export default TPEdit;
