export default (status) => {
  switch (status) {
    case 'unconfirmed':
      return '#8191E8';
    case 'confirmed':
      return '#13B9FF';
    case 'cancelled':
      return '#8FA0A7';
    case 'not_checked_in':
      return '#EF4444';
    case 'partially_checked_in':
      return '#F59E0B';
    case 'checked_in':
      return '#22C55E';
    case 'balance_not_paid':
      return '#F59E0B';
    case 'balance_partially_paid':
      return '#1F35AC';
    case 'balance_paid':
      return '#15803D';
    case 'no_cc_on_file':
      return '#0085B5';
    case 'cc_on_file':
      return '#17B169';
    case 'cc_not_needed':
      return '#CADCFC';
    case 'not_arrived':
      return '#F96167';
    case 'arrived_waiting':
      return '#97BC62';
    case 'appointment_ended':
      return '#408EC6';
    case 'clinical_note_not_started':
      return '#A7BEAE';
    case 'clinical_note_started':
      return '#20948B';
    case 'clinical_note_locked':
      return '#32de84';
    case 'superbill_not_created':
      return '#B85042';
    case 'superbill_not_scrubbed':
      return '#A7BEAE';
    case 'superbill_scrubbed':
      return '#6AB187';
    case 'claim_not_created':
      return '#d9138a';
    case 'claim_ready':
      return '#77c593';
    case 'claim_acknowledged':
      return '#f47a60';
    case 'claim_rejected':
      return '#b20238';
    case 'claim_pending':
      return '#d2601a';
    case 'claim_denied':
      return '#d71b3b';
    case 'claim_paid':
      return '#1b6535';
    case 'completed':
      return '#3a6b35';
    case 'rescheduled':
      return '#f47a60';
    case 'no_show':
      return '#EF0107';

    case 'checkin':
      return '#13B9FF';
    case 'financial':
      return '#22C55E';
    case 'clinical':
      return '#8191E8';
    case 'billing':
      return '#F59E0B';
    case 'final':
      return '#14532D';

    default:
      //random color
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
};
