import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getProductCategories } from '../../../../api/Products';

export const useProductsCategories = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();

  return useQuery(
    ['productCategories', ...dependencies],
    () => getProductCategories(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
