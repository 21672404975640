const Honeybadger = require('@honeybadger-io/js');
const { interimApi } = require('api/InterimApi');

export const handleCPTSearch = async (searchTerm, navigate, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await interimApi('/api/codes/cpt/get', params, navigate);
      if (res?.data?.cpt) {
        return res.data.cpt.map((item) => {
          return {
            value: item.code,
            label: `${item.code} - ${item.name}`,
            description: item.name,
            par_amount: item.par_amount ? item.par_amount : 0
          };
        });
      } else {
        Honeybadger.notify(
          `file: /services/new_service, method: handleCPTSearch - else, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /services/new_service, method: handleCPTSearch - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};
