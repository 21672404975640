import updateClinicalNote from './lib/updateClinicalNote';

export default (data) => {
  switch (data?.vertical) {
    case 'clinical_narrative':
      updateClinicalNote(data);
      break;
    default:
      updateClinicalNote(data);
      break;
  }
};
