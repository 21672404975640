import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { DEFAULT_COLUMN_DEFS, getColDefs, GRID_OPTIONS } from './configs';
import Button from 'components/shared/Buttons/Button';
import { useParams } from 'react-router-dom';
import { useTreatmentPlan } from 'lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import TPModal from 'components/practice/charts/ClinicalNote/TreatmentPlan/TPModal';
import ModalFooter from '../../../../../../../../shared/Modal/ModalFooter/ModalFooter';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import TPEdit from 'components/practice/charts/ClinicalNote/TreatmentPlan/views/TPEdit';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import EmptyTreatmentPlan from './components/EmptyTreatmentPlan';
import { cloneDeep } from 'lodash';
import Icon from 'components/shared/Icon/Icon';

export function Table({ breadcrumb }) {
  const { steps, setSteps, step, setStep, push } = breadcrumb;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { id } = useParams();
  const params = { patientId: id };

  const { gridApi, selectedRows, setSelectedRows } = useTableContext();
  const { onAddComponent, setSelectedTableRows, onRemoveComponent, setComponentToAdd } =
    useChatContextApi();
  const { selectedTableRows } = useChatContextData();
  const { setSelectedTreatmentPlan, selectedTreatmentPlan } = useClinicalNoteContext();
  const selectedTableNodes = selectedTableRows?.['treatment_plan']?.selectedNodes;

  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedRowsData = selectedNodes.map((node) => node.data);

  const { data } = useTreatmentPlan(params);
  const plans = data?.data;
  const rowSelection = useMemo(() => {
    return { mode: 'singleRow', checkboxes: false, enableClickSelection: true };
  }, []);

  useEffect(() => {
    setSteps([
      {
        title: 'Treatment Plan',
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      setSelectedNodes(selectedTableNodes);
      selectedTableNodes.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  const previewTPModal = (date) => {
    setSteps((prev) => [
      ...prev.slice(0, steps.length - 1),
      {
        title: date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          timeZoneName: 'short'
        }),
        name: 'preview'
      }
    ]);
  };

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  if (['create', 'preview'].includes(steps[step]?.name)) {
    const onGoBack = () => {
      setSteps((prev) => prev.slice(0, step));
      setStep((prev) => prev - 1);
    };
    const onEditTP = () => {
      setSteps((prev) => [
        ...prev.slice(0, step),
        {
          title: 'Edit Treatment Plan',
          name: 'edit'
        }
      ]);
    };

    const onAddTreatmentPlan = () => {
      setSelectedTableRows((prev) => ({
        ...prev,
        treatment_plan: {
          selectedNodes: selectedRows,
          data: selectedTreatmentPlan
        }
      }));
      onAddComponent();
    };

    return (
      <TPModal
        loadView={steps[step]?.name}
        isModal={false}
        isOpen={true}
        handlePreview={previewTPModal}
        footer={({ submit, isSubmitting, disabled }) =>
          steps[step]?.name === 'create' ? (
            <>
              <ModalFooter
                rightButtons={
                  <Button
                    disabled={disabled}
                    loading={isSubmitting}
                    key={1}
                    text="Create Treatment Plan"
                    // icon="stars-ai"
                    iconSize={18}
                    iconColor="white"
                    size="small"
                    className="!py-2 !pl-4 !pr-4"
                    onClick={() => submit({ nextView: steps[step]?.name })}
                  />
                }
                leftButtons={
                  <Button
                    key={1}
                    text="Back"
                    outlined
                    transparent
                    size="small"
                    className="!py-2 !pl-4 !pr-4"
                    onClick={onGoBack}
                  />
                }
              />
            </>
          ) : (
            <ModalFooter
              rightButtons={[
                <Button
                  key={1}
                  text="Edit"
                  icon="save-changes"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="!mr-2 !py-2 !pl-4 !pr-4"
                  onClick={onEditTP}
                  outlined
                />,
                <Button
                  key={2}
                  text={` Add Treatment Plan`}
                  // icon="stars-ai"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onAddTreatmentPlan}
                />
              ]}
              leftButtons={
                <Button
                  key={1}
                  text="Back"
                  outlined
                  transparent
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onGoBack}
                />
              }
            />
          )
        }
      />
    );
  }

  if (steps[step]?.name === 'edit') {
    return (
      <div className="z-50">
        <div className="mb-[112px] gap-[24px] px-[230px]">
          <TPEdit view="edit" />
        </div>
        <div className="flex justify-center px-[230px]">
          <ModalFooter
            rightButtons={
              <Button
                // loading={isSubmitting}
                // disabled={isSubmitting}
                key={1}
                text="Save"
                icon="save-changes"
                iconSize={18}
                iconColor="white"
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={() => previewTPModal(new Date())}
              />
            }
            leftButtons={
              <Button
                key={1}
                text="Back"
                outlined
                transparent
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={onGoBack}
              />
            }
          />
        </div>
      </div>
    );
  }

  const onTPPreview = (date) => {
    push({
      title: date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZoneName: 'short'
      }),
      name: 'preview'
    });
  };

  const onRowClicked = (row) => {
    const node = gridApi?.getRowNode(row.node.id);
    if (node) {
      node.setSelected(true);
    }
    setSelectedRows([node]);
    setSelectedTreatmentPlan(row.data);
    onTPPreview(row.data.updated_at);
  };

  const onDeleteTreatmentPlanTag = () => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['macros'];
    setSelectedTableRows(newSelectedTableRows);
    onRemoveComponent();
    setComponentToAdd(null);
  };

  return (
    <div className="ag-theme-quartz forms_table !mb-0 h-full">
      <AGTable
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDef={getColDefs(practiceTimezone)}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={plans}
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        reactiveCustomComponents
        onRowClicked={onRowClicked}
        rowSelection={rowSelection}
        noRowsOverlayComponent={EmptyTreatmentPlan}
        noRowsOverlayComponentParams={{ breadcrumb }}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px]">
            <Skeleton count={24} />
          </div>
        )}
      />
      {selectedTableNodes?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon
                key={1}
                icon="trash"
                className="ml-4 cursor-pointer"
                onClick={onDeleteTreatmentPlanTag}
              />
            )
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}
