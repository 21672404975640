import { useQueryClient } from '@tanstack/react-query';
import { upsertCustomFormResponse } from 'api/CustomForms';
import ResizableContainer from 'components/practice/charts/ClinicalNote/shared/VerticallyResizableDiv/ResizableContainer';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { clinicalNote as clinicalNoteState, permissions, userState } from 'components/state';
import { useFormik } from 'formik';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import AllergiesRow from '../../Allergies/AllergiesRow';
import { Allergies as initialValue } from '../../CustomFormInitialStates';
import { transformData } from '../../lib/aggregatedDataHelper';
import AddToTodaysNote from '../components/AddToTodaysNote';
import CustomFormConfirm from '../components/CustomFormConfirm';
import FormsTable from '../components/FormsTable';
import NarrativeForm from '../components/NarrativeForm';
import NoteHeader from '../components/NoteHeader';
import UpsertModal from '../components/UpsertModal';
import { setPatientAggregateNarrativeState } from '../lib/patientAggregateNarrativeStates';
import { FINAL_NOTE_OPTIONS } from './lib/constants';
import providerAllergiesTableCols from './lib/providerAllergiesTableCols';

const ProviderAllergies = ({ customFormsParams, options, isResizable = true }) => {
  const { setSelectedCustomFormTypes, overviewData, setOverviewData } = useClinicalNoteContext();
  const userPermissions = useRecoilValue(permissions);
  const user = useRecoilValue(userState);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { id: patientId, appointmentId } = useParams();

  const allergies = useMemo(() => {
    if (overviewData?.allergies) {
      return transformData({
        data: overviewData?.allergies,
        formType: 'allergies',
        validation: 'allergy'
      });
    }
    return [];
  }, [overviewData?.allergies]);

  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [resize, setResize] = useState(250);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: initialValue?.allergies[0],
    enableReinitialize: true,
    validationSchema: Yup.object({
      allergy: Yup.object().required('Allergy is required')
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!values?.allergy?.label) {
        return showAlert({ title: 'Please select an allergy', color: 'danger' });
      }

      setLoading(true);
      const id = values?.cfr_id;
      delete values?.cfr_id;
      delete values?.createdBy;

      const result = await upsertCustomFormResponse(navigate, {
        formId: id,
        patientId: customFormsParams?.id || patientId,
        appointmentId: customFormsParams?.appointmentId || appointmentId,
        response: { allergies: [values] },
        type: 'allergies'
      });

      result.newResponse.createdBy = {
        id: user.user_id,
        fullName: `${user?.f_name ?? ''} ${user?.l_name ?? ''}`.trim(),
        isPatient: Number(customFormsParams?.id || patientId) === user.user_id
      };

      if (id) {
        setOverviewData((prev) => ({
          ...prev,
          allergies: prev?.allergies?.map((allergy) => {
            if (allergy?.id === id) {
              return result.newResponse;
            }
            return allergy;
          })
        }));
      } else {
        setOverviewData((prev) => ({
          ...prev,
          allergies: [result.newResponse, ...iaRa(prev?.allergies)]
        }));

        const noKnown = await queryClient.getQueryData([
          'patientAggregateNarrative',
          patientId,
          'allergies'
        ]).data?.narrative?.no_known;

        if (patientId && noKnown) {
          await setPatientAggregateNarrativeState({
            queryClient,
            keys: [patientId, 'allergies'],
            payload: {
              synced: true,
              no_known: false
            }
          });
        }
      }
      setShowFormModal(false);
      setLoading(false);
      resetForm();
    }
  });

  const handleOnChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const hideModal = () => {
    formik.resetForm();
    setShowFormModal(false);
  };

  const onDeleteItem = async (customFormId) => {
    setOverviewData((prev) => {
      return {
        ...prev,
        allergies: prev?.allergies?.filter((allergy) => allergy?.id !== customFormId)
      };
    });
  };

  return (
    <TableContextProvider
      key={`${clinicalNote?.locked}-provider-allergies`}
      cols={() =>
        providerAllergiesTableCols({
          setItemToDelete,
          setShowFormModal,
          formik,
          userPermissions,
          noteLocked: clinicalNote?.locked
        })
      }
      name="Allergies"
      defaultFilters={[]}>
      <div className="h-full">
        <AddToTodaysNote
          type="allergies"
          finalNoteOptions={FINAL_NOTE_OPTIONS}
          onChange={({ type, options }) =>
            setSelectedCustomFormTypes((prevState) => ({
              ...prevState,
              [type]: options
            }))
          }
        />

        <NoteHeader title="Allergies">
          <DisplayButton />
          <Button
            text="Add Allergy"
            icon="new-add-square"
            iconColor="white"
            disabled={clinicalNote?.locked}
            size="small"
            onClick={() => {
              setShowFormModal(true);
            }}
          />
        </NoteHeader>

        {showFormModal && (
          <UpsertModal
            showModal={showFormModal}
            title="Allergy"
            loading={loading}
            formik={formik}
            hideModal={hideModal}
            onDeleteItem={onDeleteItem}>
            <AllergiesRow formik={formik} onChange={handleOnChange} {...formik.values} />
          </UpsertModal>
        )}

        <ResizableContainer
          isResizable={isResizable}
          onResize={isResizable ? (height) => setResize(height) : () => {}}
          topContent={<FormsTable items={allergies} getGridApi={(api) => setGridApi(api)} />}
          bottomContent={
            <NarrativeForm
              key={`allergies-narrative-${customFormsParams?.id || patientId}`}
              type="allergies"
              loading={loading}
              gridApi={gridApi}
              resize={resize}
              items={allergies}
              options={options}
            />
          }
          resizableDivClassNames={{
            contentClassName: '!mt-0 !pt-0'
          }}
        />
      </div>

      <CustomFormConfirm
        type="Allergy"
        itemToDelete={itemToDelete}
        onDeleteItem={onDeleteItem}
        setItemToDelete={setItemToDelete}
      />
    </TableContextProvider>
  );
};

export default ProviderAllergies;
