import React from 'react';
import { useNavigate } from 'react-router-dom';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Button from 'components/shared/Buttons/Button';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';
import { filterCheckedForms } from '../lib/helpers';
import { getSelectedForms } from '../../shared/lib/helpers';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

export function ChatFooter({ formsList }) {
  const navigate = useNavigate();
  const { chatForms = {} } = useMyTemplateContext();
  const {
    currentHpOverviewData,
    advancedHP,
    advancedSOAP,
    setEnhanceNarrativeParams,
    customFormTypes,
    overviewData
  } = useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const chatSelectedForms = filterCheckedForms(chatForms);
  const customAdvancedForms = Object.values(customFormTypes || [])?.flat();
  const advancedForms = [...advancedHP, ...advancedSOAP, ...customAdvancedForms];

  const clinicalNoteForms = {
    ...clinicalNote,
    ...currentHpOverviewData,
    ...overviewData,
    ...(clinicalNote?.epn || {})
  };

  const onGenerate = () => {
    const selectedForms = getSelectedForms(chatSelectedForms, clinicalNoteForms, advancedForms);
    const finalPrompt = {
      clinical_note_id: clinicalNote?.id,
      selectedForms: Object.keys(selectedForms)
    };
    console.log({ finalPrompt });
    // setEnhanceNarrativeParams({
    //   kind: 'myscribe-ai',
    //   messageKind: 'manual_map',
    //   body: {}
    // });
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <AIChatInput
      className="footer-blurred items-center pb-3"
      rightButtons={[
        <SignAndLock
          key={1}
          buttonProps={{
            className: '!py-2 !pl-4 !pr-4 mr-2 border-[1.5px] border-solid',
            outlined: true,
            textClassName: 'text-primary-300',
            iconColor: 'black',
            disabled: formsList?.length === 0
          }}
        />,
        <Button
          key={2}
          text="Enhance"
          icon="stars-ai"
          iconSize={18}
          iconColor="white"
          size="small"
          className="!py-2 !pl-4 !pr-4"
          disabled={formsList?.length === 0}
          onClick={onGenerate}
        />
      ]}
      onSubmit={onGenerate}
      onGoBack={onGoBack}
      isInput={false}
    />
  );
}
