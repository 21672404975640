import React, { useEffect, useState } from 'react';
import Box from '../../../../shared/Box';
import Button from '../../../../../../../shared/Buttons/Button';
import TreatmentRow from './TreatmentRow';
import ErrorMessage from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ErrorBoundary } from 'react-error-boundary';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from '../../../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../../../../state';

const TreatmentTeam = () => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const user = useRecoilValue(userState);
  const initialState = [
    {
      provider: null,
      method: null
    }
  ];
  const [treatmentTeam, setTreatmentTeam] = useState(initialState);

  useEffect(() => {
    if (!ia(selectedTreatmentPlan?.treatment_team) && user?.id) {
      addToTreatmentTeamProvider();
    } else if (ia(selectedTreatmentPlan?.treatment_team)) {
      setTreatmentTeam(selectedTreatmentPlan?.treatment_team);
    }
  }, [user?.id, selectedTreatmentPlan?.treatment_team]);

  const addToTreatmentTeamProvider = () => {
    const defaultProvider = [
      {
        provider: { value: user?.id, label: `${user?.f_name} ${user?.l_name}` },
        method: null
      }
    ];
    setTreatmentTeam(defaultProvider);
    setSelectedTreatmentPlan({ ...selectedTreatmentPlan, treatment_team: defaultProvider });
  };

  const handleChange = (event, key, index) => {
    const updatedTT = treatmentTeam.map((item, idx) => {
      if (idx === index) {
        return { ...item, [key]: event };
      } else {
        return item;
      }
    });
    setTreatmentTeam(updatedTT);
    setSelectedTreatmentPlan({ ...selectedTreatmentPlan, treatment_team: updatedTT });
  };

  const addProvider = () => {
    setTreatmentTeam([...initialState, ...treatmentTeam]);
    setSelectedTreatmentPlan({
      ...selectedTreatmentPlan,
      treatment_team: [...initialState, ...treatmentTeam]
    });
  };

  const handleDelete = (index) => {
    const filteredTT = treatmentTeam.filter((_, idx) => idx !== index);
    setTreatmentTeam(filteredTT);
    setSelectedTreatmentPlan({ ...selectedTreatmentPlan, treatment_team: filteredTT });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Box className="bg-white">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <span className="!m-0 text-lg font-500 leading-7 text-primary-900">Treatment team</span>
          <Button
            onClick={addProvider}
            text="Add another provider"
            transparent
            iconRight="add-circle"
            data-qa="add-another-provider"
          />
        </div>
        {ia(treatmentTeam) &&
          treatmentTeam.map((item, index) => (
            <TreatmentRow
              key={index}
              index={index}
              onChange={handleChange}
              onDelete={handleDelete}
              length={treatmentTeam?.length}
              {...item}
            />
          ))}
      </Box>
    </ErrorBoundary>
  );
};

export default TreatmentTeam;
