import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const VitalPreviewItem = ({ vital, feet, inches, pounds, ounces }) => {
  const vitalsConfig = [
    {
      icon: 'new-heart-blue',
      label: 'Heart rate',
      value: vital?.heart_rate,
      unit: 'bpm'
    },
    {
      icon: 'new-lungs',
      label: 'Respiratory rate',
      value: vital?.respirations,
      unit: 'bpm'
    },
    {
      icon: 'new-blood-pressure-systolic',
      label: 'Blood pressure (systolic)',
      value: vital?.blood_pressure_systolic,
      unit: 'mmHg',
      customStyling: '!break-word'
    },
    {
      icon: 'new-blood-pressure-diastolic',
      label: 'Blood pressure (diastolic)',
      value: vital?.blood_pressure_diastolic,
      unit: 'mmHg',
      customStyling: '!break-word'
    },
    {
      icon: 'new-temperature',
      label: 'Temperature',
      value: vital?.temperature,
      unit: '°F'
    },
    {
      icon: 'new-weight',
      label: 'Weight',
      value: vital?.weight,
      unit: 'lbs',
      customValue:
        !pounds && !ounces
          ? '-'
          : `${pounds ? `${pounds} lbs` : ''} ${ounces ? `${ounces} oz` : ''}`
    },
    {
      icon: 'new-height',
      label: 'Height',
      value: null,
      customValue:
        !feet && !inches ? '-' : `${feet ? `${feet} ft` : ''} ${inches ? `${inches} in` : ''}`
    },
    {
      icon: 'new-oxygen-level',
      label: 'Oxygen level',
      value: vital?.oxygen_level,
      unit: '%'
    },
    {
      icon: 'new-body',
      label: 'BMI',
      value: vital?.bmi,
      unit: 'kgm²'
    },
    {
      icon: 'new-ruler',
      label: 'Head circumference',
      value: vital?.head_circumference,
      unit: 'inch'
    }
  ];

  const VitalItem = ({ icon, label, value, unit, customValue, customStyling }) => (
    <div className="flex items-center gap-2">
      <div className="flex h-[35px] w-[35px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
        <Icon icon={icon} className="h-[18px] w-[18px]" />
      </div>
      <div>
        <p
          className={cs('text-sm font-500 text-neutral-800', {
            'whitespace-nowrap': !customStyling,
            'break-words': customStyling === '!break-word'
          })}>
          {label}:
        </p>
        <p className="text-sm font-400 leading-4 text-neutral-700">
          {customValue || (value ? `${value} ${unit}` : '-')}
        </p>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-3 gap-x-20 gap-y-6 bg-white p-4 pr-12">
      {vitalsConfig.map((vitalItem, index) => (
        <VitalItem key={index} {...vitalItem} />
      ))}
    </div>
  );
};
export default VitalPreviewItem;
