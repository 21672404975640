import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noKnownPatientAggregateNarrative } from 'api/PatientAggregateNarrative';
import cs from 'classnames';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PreviousFormDropdown from './PreviousFormDropdown';
import { handleNoKnown } from './lib/noteHeaderHelpers';

const NoteHeader = ({ title, children, showNoKnown = true, showPreviousFrom = true }) => {
  const { setOverviewData } = useClinicalNoteContext();
  const [noKnownModal, setNoKnownModal] = useState(false);
  const { id: patientId } = useParams();
  const navigate = useNavigate();
  const clinicalNote = useRecoilValue(clinicalNoteState);

  const queryClient = useQueryClient();
  const noKnownState = queryClient.getQueryData([
    'patientAggregateNarrative',
    patientId,
    camelCase(title)
  ])?.data?.narrative?.no_known;

  const { mutateAsync: mutateNoKnown, isLoading: noKnownLoading } = useMutation({
    mutationFn: (data) => noKnownPatientAggregateNarrative(navigate, data)
  });

  return (
    <div className="flex items-center border border-l-0 border-r-0 border-t-0 border-solid border-neutral-100">
      <div className={cs('bg-white py-3.5 shadow-[4px_0px_16px_0px_#004F6B0A]')}>
        <p className="px-5 text-base font-500 text-neutral-800 lg:whitespace-pre xl:whitespace-pre">
          {title}
        </p>
      </div>
      <div className="flex w-full items-center justify-between !px-4">
        {showPreviousFrom && (
          <div>
            <PreviousFormDropdown />
          </div>
        )}
        <div className="flex items-center gap-2">
          {showNoKnown && (
            <Checkbox
              isChecked={noKnownState}
              disabled={noKnownLoading || clinicalNote?.locked}
              label={`No Known ${title}`}
              handleClick={() => setNoKnownModal(true)}
            />
          )}
          {children}
        </div>
      </div>

      <Confirm
        handleOpen={!!noKnownModal}
        handleClose={() => setNoKnownModal(false)}
        handleContinue={() =>
          handleNoKnown({
            mutateNoKnown,
            queryClient,
            patientId,
            title: camelCase(title),
            noKnownState,
            setNoKnownModal,
            setOverviewData
          })
        }
        title={`No Known ${title}`}
        icon={noKnownState ? 'new-document' : 'new-document-remove-red'}
        loading={noKnownLoading}
        disabled={noKnownLoading}
        message={
          noKnownState ? (
            <div>
              Are you sure you want to unselect "No Known"? This will remove the "No Known" status
              from the patient's chart.
            </div>
          ) : (
            <div>
              Are you sure you want to mark as 'No Known'?{' '}
              <em className="text-danger-500">
                {' '}
                This will remove any existing {title?.toLowerCase()} from the patient's chart.
              </em>
            </div>
          )
        }
        variant={noKnownState ? 'primary' : 'danger'}
      />
    </div>
  );
};

export default NoteHeader;
