import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../../api/InterimApi';
import { getStates } from '../../../../api/State';
import { ia } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Button/Button';
import Input from '../../../shared/Input/Input';
import { appointmentData } from '../schedulingState';
import './css/ContactInformation.scss';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
const validator = require('validator');

const customSelectStyle = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    minWidth: '350px !important',
    fontSize: 14,
    backgroundColor: '#fff',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};

const NewPatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().required('Phone number required'),
  password: Yup.string().required('Password is required'),
  phone: Yup.string().required('Phone is required'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  dob: Yup.string().required('Date of birth required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;
    if (value === undefined || value === null || value === '')
      return createError({ message: 'Phone number required.' });
    if (!validator.isMobilePhone(value))
      return createError({ message: 'Please provide a correct phone number.' });
    return true;
  }),
  address_ln_1: Yup.string().required('Address required'),
  city: Yup.string().required('City required'),
  state: Yup.string().required('State required'),
  zip: Yup.string().required('Zip code required')
});

export default function ContactInformation() {
  const [appData, setAppData] = useRecoilState(appointmentData);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const formRef = useRef();

  useEffect(() => {
    getStatesMethod();
  }, []);

  useEffect(() => {
    if (!appData.userId) return;
    appData?.practice_require_card
      ? navigate('/book/card-on-file')
      : navigate('/book/confirmation');
  }, [appData.userId]);

  const formik = useFormik({
    initialValues: {
      f_name: '',
      l_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      dob: '',
      phone: '',
      address_ln_1: '',
      city: '',
      state: '',
      zip: '',
      invitationEmail: true
    },
    validationSchema: NewPatientValidationSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      const patientToBeCreated = {
        patient: {
          f_name: values.f_name,
          l_name: values.l_name,
          email: values.email,
          dob: values.dob,
          phone: values.phone,
          password: values.password
        },
        address: {
          address_ln_1: values.address_ln_1,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        practitioner_id: appData.practitioner_userId,
        invitationEmail: values.invitationEmail
      };

      setSubmitting(true);
      await submitPatient(patientToBeCreated);
      setSubmitting(false);
    }
  });

  const redirectBack = () => {
    localStorage.setItem('redirectTo', pathname);
    navigate(`/`);
  };

  const submitPatient = async (data) => {
    const modifiedPatient = {
      ...data.patient
    };

    const modifiedData = {
      ...data,
      patient: modifiedPatient,
      type: 'self_scheduling_register'
    };

    try {
      const res = await interimApi('/api/practice/patient/add_patient', modifiedData, navigate);
      if (res.data) {
        if (res.data.patientId) {
          if (res?.data?.code === 0 && res?.data?.message) {
            toast.success(res.data.message, { duration: 6000 });
          } else {
            toast.success('Account created successfully.');
          }
          submitUserLogin(modifiedData.patient.email, modifiedData.patient.password);
        } else if (res.data.code === 2) {
          toast.error('This patient already exists in your directory', { duration: 6000 });
        } else {
          console.error(res.data.message);
          Honeybadger.notify(
            `file: self_scheduling/steps/ContactInformation, method: submitPatient - else, error: ${
              res.data.message ?? 'Theres been an error'
            }`
          );
          if (res.data.message) toast.error(res.data.message);
        }
      }
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: submitPatient - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getStatus = async ({ user_id, fullName }) => {
    try {
      const { status } = await interimApi(
        '/api/patient/appointment/self_schedule',
        {
          user_full_name: fullName,
          user_id
        },
        navigate
      );
      if (status == 200) {
        navigate('/book/card-on-file');
      }
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: getStatus - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const submitUserLogin = async (email, password) => {
    try {
      const res = await interimApi(
        '/api/user/login',
        {
          email,
          password
        },
        navigate
      );
      const { code, user, error } = res.data;
      switch (code) {
        case 0:
          appData?.practice_require_card
            ? await getStatus({ fullName: `${user.f_name} ${user.l_name}`, user_id: user.user_id })
            : navigate('/book/confirmation');
        case 1:
          if (error) toast.error(error);
          else toast.error(`There's been an error logging in. Please check your credentials.`);
          break;
        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: self_scheduling/steps/ContactInformation, method: submitUserLogin - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (err) {
      const res = err.response;
      if (res) {
        const { code, error, redirect } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 1:
            if (error) toast.error(error);
            else toast.error(`There's been an error logging in. Please check your credentials.`);
            break;
          default:
            if (error) toast.error(error);
            Honeybadger.notify(
              `file: self_scheduling/steps/ContactInformation, method: submitUserLogin - catch, error: ${
                error ?? 'Theres been an error'
              }`
            );
            break;
        }
      }
    }
  };

  const handleSubmitNewPatient = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  const getStatesMethod = async () => {
    try {
      let res = await getStates(navigate, { method: 'get' });
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: getStates - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const findStateName = () => {
    const stateName = states?.find((state) => {
      if (state?.id == formik?.values?.state) {
        return state;
      }
    });

    return stateName && stateName?.name;
  };

  const handleSelectState = (state) => {
    setSelectedState(state);
    formik.setFieldValue('state', state?.value);
  };

  return (
    <div className="ml-10 w-full">
      <div className="mb-20">
        <h3 className="ContactTitle">Register</h3>
      </div>
      <form ref={formRef}>
        <div className="GroupContainer mb-10">
          <Input
            label="First name"
            placeholder="First name"
            value={formik.values.f_name}
            onChange={formik.handleChange}
            name="f_name"
            id="firstName"
            error={formik.errors.f_name}
          />
          <Input
            label="Last name"
            placeholder="Last Name"
            value={formik.values.l_name}
            onChange={formik.handleChange}
            name="l_name"
            id="last_name"
            error={formik.errors.l_name}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Input
            label="Phone Number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            placeholder="Phone number"
            name="phone"
            id="phone"
            error={formik.errors.phone}
          />
          <Input
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="email"
            name="email"
            id="email"
            error={formik.errors.email}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Input
            label="Address"
            placeholder="Address"
            value={formik.values.address_ln_1}
            onChange={formik.handleChange}
            name="address_ln_1"
            id="address_ln_1"
            error={formik.errors.address_ln_1}
          />
          <Input
            label="City"
            placeholder="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            name="city"
            id="city"
            error={formik.errors.city}
          />
          <Input
            label="Zip code"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            id="zipCode"
            error={formik.errors.zip}
          />
        </div>
        <div className="GroupContainer mb-10">
          <div>
            <label className="m-0 block pb-[6px] text-sm font-500 text-neutral-800" htmlFor="state">
              State
            </label>
            <Select
              placeholder="Type and select state"
              value={selectedState}
              onChange={(value) => handleSelectState(value)}
              options={
                ia(states)
                  ? states.map((state) => ({
                      value: state.id,
                      label: state.name
                    }))
                  : []
              }
              inputId="state"
            />
            {formik.errors.state && (
              <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.state}</span>
            )}
          </div>
          <div>
            <DatePopover
              label="Date of birth"
              labelClassName="w-max"
              placeholder="Date of birth"
              name="dob"
              value={formik.values.dob}
              onChange={(event) => formik.setFieldValue('dob', event)}
            />
            {formik.errors.dob && (
              <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.dob}</span>
            )}
          </div>
        </div>
        <div className="GroupContainer mb-20">
          <Input
            label="Password"
            type="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Password"
            name="password"
            id="password"
            error={formik.errors.password}
          />
          <Input
            label="Confirm Password"
            type="Password"
            placeholder="Password"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            name="password_confirmation"
            id="password_confirmation"
            error={formik.errors.password_confirmation}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Button black onClick={handleSubmitNewPatient}>
            Create Account
          </Button>
        </div>
        <div className="GroupContainer">
          Already have an account?
          <div className="TextBlueColor" onClick={() => redirectBack()}>
            Log in
          </div>
        </div>
      </form>
    </div>
  );
}
