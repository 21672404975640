import cs from 'classnames';
import React from 'react';
const VitalNoteHeader = ({ title, children }) => {
  return (
    <div className="!sticky top-0 border border-l-0 border-r-0 border-t-0 border-solid border-neutral-100">
      <div className="flex-between flex w-full items-center bg-white">
        <div
          className={cs('sticky top-0 bg-white !px-4 py-3.5 shadow-[4px_0px_16px_0px_#004F6B0A]')}>
          <p className="px-5 text-base font-500 text-neutral-800 lg:whitespace-pre xl:whitespace-pre">
            {title}
          </p>
        </div>
        <div className="ml-auto !px-4">{children}</div>
      </div>
    </div>
  );
};
export default VitalNoteHeader;
