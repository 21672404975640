import Allowed from 'components/shared/Permissions/Allowed';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  cmToInch,
  ia,
  iaRa,
  isEmpty,
  separateFeetAndInches,
  separatePoundsAndOunces,
  stripHtmlTags
} from 'lib/helpers/utility';
import React from 'react';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import CreationInfo from './CreationInfo';

const VitalsOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  isGrowthChartEnabled = false
}) => {
  const { overviewData } = useClinicalNoteContext();
  const vitals = overviewData?.vitals;
  const vitalsToMap = iaRa(vitals)?.flatMap((appointment) =>
    appointment?.vitals.map((vital) => ({
      ...vital,
      created_at: appointment.created_at
    }))
  );
  if (!cnSettings?.overview.vitals) return null;

  return (
    <Allowed requiredPermissions="vitals.read">
      <SimpleAccordion
        title="Vitals"
        open={isOpen.vitals}
        showExpandIcon={ia(vitalsToMap)}
        // onCopy={() => handleCopy(note.id)}
        // copied={copiedIndex === note.id ? true : false}
        onToggle={() => toggleOne('vitals')}>
        {vitalsToMap.map((vital, index) => {
          const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);
          const { feet, inches } = separateFeetAndInches(cmToInch(vital?.height));
          return (
            <React.Fragment key={index}>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
              {(!isEmpty(vital?.blood_pressure_systolic) ||
                !isEmpty(vital?.blood_pressure_diastolic)) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Blood pressure:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.blood_pressure_systolic || 0} / ${
                      vital.blood_pressure_diastolic || 0
                    } mmHg`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.heart_rate) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Heart rate:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.heart_rate} bpm`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.respirations) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Respirations:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.respirations} bpm`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.height) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Height:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${feet} ft ${inches} in`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.weight) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Weight:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${pounds} lb ${ounces} oz`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.temperature) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Temperature:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.temperature}  °F`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.bmi) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Bmi:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.bmi} kgm²`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.oxygen_level) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Oxygen:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.oxygen_level} %`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.head_circumference) && isGrowthChartEnabled && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Head circumference:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.head_circumference} ${vital?.head_circumference_unit}`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.notes) && (
                <div className="flex flex-wrap justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Note:
                  </span>
                  <span className="break-all text-sm font-400 text-primary-900">
                    {stripHtmlTags(vital?.notes)}
                  </span>
                </div>
              )}
              <CreationInfo createdAt={vital?.timestamp} createdBy={vital?.createdBy?.fullName} />
            </React.Fragment>
          );
        })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default VitalsOverview;
