import { requestApi } from './Api';

export const getProviders = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/member/get_practitioners',
    params,
    onError: () => {}
  });

export const getMembers = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/member/get',
    params,
    onError: () => {}
  });

export const deleteMember = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/member/remove',
    params
  });
};
