import React from 'react';
import Header from 'components/shared/Header/Header';
import usePageTitle from 'lib/hooks/usePageTitle';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import { DEFAULT_FILTERS, defaultColumns, updateMultiple } from './lib/helper';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import { useNotifications } from 'lib/hooks/queries/notifications/useNotifications';
import { ia, mapValues } from 'lib/helpers/utility';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const Notifications = () => {
  const practice = useRecoilValue(currentPractice);

  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns(practice?.timezone)}
      name="notifications_queue"
      pagination>
      <Table />
    </TableContextProvider>
  );
};
function Table() {
  usePageTitle('Notifications');
  const category = 'notifications';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { limit, page, setPage, sort, filters, setFilters, selectedRows, setSelectedRows } =
    useTableContext();
  const practice = useRecoilValue(currentPractice);

  const { data, isLoading, isFetching } = useNotifications({
    queryKey: 'notifications',
    params: {
      withal: { notifications: true },
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [
      limit,
      page,
      sort,
      moment(filters.created_at?.values?.endDate).format('YYYY-MM-DD'),
      moment(filters.created_at?.values?.startDate).format('YYYY-MM-DD'),
      filters.title?.values,
      filters.type?.values
    ]
  });

  const notifications = data?.data?.notifications || [];
  const count = data?.data?.count || 0;

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'checkbox') return;
    if (e?.column?.getColId() === 'actions') return;
    if (['actions', '0'].includes(e?.column?.colId)) return;
    navigate(e?.data?.link);
  };

  const options = [
    {
      label: 'Mark as unread',
      icon: 'new-eye-slash',
      onClick: () =>
        updateMultiple({
          notifications: selectedRows?.map((n) => n?.data?.id),
          data: { dataToBeUpdated: { is_read: false } },
          setSelectedRows,
          navigate,
          queryClient
        })
    },
    {
      label: 'Mark as read',
      icon: 'new-eye',
      onClick: () =>
        updateMultiple({
          notifications: selectedRows?.map((n) => n?.data?.id),
          data: { dataToBeUpdated: { is_read: true } },
          setSelectedRows,
          navigate,
          queryClient
        })
    }
  ];

  return (
    <>
      <Header title="Notifications">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <Popover
              className="flex flex-1 justify-center self-center"
              buttonClassName="!bg-primary-700"
              position="left"
              isFixed
              options={options}
            />
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={notifications}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              columnDef={defaultColumns(practice?.timezone)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(Notifications);
