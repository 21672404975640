import { showAlert } from 'components/shared/Alert/Alert';
import { imagePath } from 'lib/helpers/utility';

export const addItem = (formik, type) => {
  const newItem = {};

  const updatedItems = [...formik.values[type], newItem];

  formik.setFieldValue(type, updatedItems);
};

export const addItems = (setFieldValue, currentValues, newValues, type) => {
  const updatedItems = [...currentValues, ...newValues];

  setFieldValue(type, updatedItems);
};

export const removeItem = (setFieldValue, items, type, i) => {
  const updatedItems = items;

  updatedItems.splice(i, 1);

  setFieldValue(type, updatedItems);
};

export const handleChangeQuantity = (setFieldValue, currentValues, val, id, productsList = []) => {
  const sales_count = Number(val);

  const product = productsList?.find(({ id: pId }) => pId === id); //current product

  if (product?.status !== 'active') {
    showAlert({ message: 'This product is not anymore active.', color: 'danger' });
    return;
  }

  if (product?.quantity < sales_count || sales_count < 0) {
    sales_count > 0 &&
      showAlert({
        color: 'danger',
        message: `You currently have ${product?.quantity} products available in stock.`
      });
    return;
  }

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id) {
        return { ...s, sales_count, total_amount_cents: s.amount_cents * sales_count };
      }
      return s;
    })
  );
};

export const handleChangePackageQuantity = (type, setFieldValue, currentValues, val, id) => {
  const sales_count = Number(val);
  setFieldValue(
    type,
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, sales_count, total_amount_cents: s.amount_cents * sales_count };
      return s;
    })
  );
};

export const handleChangePrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, total_amount_cents: price, amount_cents: price / s?.quantity };
      return s;
    })
  );
};

export const handleChangePriceProcedures = (setFieldValue, currentValues, val, i, key) => {
  const price = Number(val);

  const updatedValues = currentValues.map((procedure, index) => {
    if (index !== i) return procedure;

    const { pt_balance, total_cost_cents } = procedure;
    const modifiedProcedure = { ...procedure };

    if (key === 'charge') {
      modifiedProcedure.charge = price; // Charge
    } else if (key === 'ins_payment') {
      modifiedProcedure.ins_payment = price; // Allowed
      modifiedProcedure.allowed = price;
    } else {
      if ((pt_balance && price !== pt_balance) || (!pt_balance && price !== total_cost_cents)) {
        modifiedProcedure.pt_balance = price; // Est. Pt. Portion
        modifiedProcedure.modified = true; //?

        if (price === total_cost_cents) {
          delete modifiedProcedure.pt_balance;
          delete modifiedProcedure.modified;
        }
      } else if (pt_balance === total_cost_cents) {
        delete modifiedProcedure.pt_balance;
        delete modifiedProcedure.modified;
      }
    }
    return modifiedProcedure;
  });

  setFieldValue('procedures', updatedValues);
};

export const handleChangeUnitPrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, amount_cents: price, total_amount_cents: price * s?.quantity };
      return s;
    })
  );
};

export const reShapeProducts = (data) => {
  return (
    data?.products?.map((p) => ({
      id: p?.id,
      quantity: 1,
      label: p.name,
      amount_cents: p.amount_cents,
      total_amount_cents: p.amount_cents,
      ...(p?.image && {
        image: imagePath(JSON.parse(p?.image || `{}`)?.jpg)
      })
    })) || []
  );
};

export const handleProcedureType = (setFieldValue, currentValues, index, typeItem, list) => {
  const updatedValues = currentValues.map((s, i) => {
    if (i === index) {
      if (typeItem === 'self_pay') {
        // TODO: this list needs to have the service we are looking for, otherwise it will default to $0
        // TODO:  or handle in the backend
        const findService = list?.find((service) => service.id === s.id);

        delete s.pt_balance;
        delete s.ins_payment;
        delete s.allowed;
        delete s.modified;

        return {
          ...s,
          type: typeItem,
          total_cost_cents: findService?.total_cost_cents || findService?.charge
        };
      } else if (typeItem === 'primary') {
        delete s.pt_balance;
        delete s.modified;
        delete s.total_cost_cents;
        return { ...s, type: typeItem };
      } else {
        return { ...s, type: typeItem };
      }
    }
    return s;
  });

  setFieldValue('procedures', updatedValues);
};

export const timeFilterOption = (candidate, input) => {
  const normalizedLabel = candidate.label.toLowerCase();
  const normalizedInput = input.toLowerCase().trim();

  const regex = /^(\d{1,2})(\s?(a|p)m?)?$/;
  const match = normalizedInput.match(regex);

  if (match) {
    const [_, inputHour, , inputMeridiem] = match;

    const formattedInputHour = inputHour.padStart(2, '');

    if (!inputMeridiem) {
      return normalizedLabel.startsWith(`${formattedInputHour}:`);
    }

    const fullInputMeridiem =
      inputMeridiem.trim() === 'p'
        ? 'pm'
        : inputMeridiem.trim() === 'a'
          ? 'am'
          : inputMeridiem.trim();

    return (
      normalizedLabel.startsWith(`${formattedInputHour}:`) &&
      normalizedLabel.endsWith(fullInputMeridiem)
    );
  }

  return normalizedLabel.startsWith(normalizedInput);
};
