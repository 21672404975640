import { clinicalNoteFilters } from 'components/practice/charts/ClinicalNote/Vitals/lib/helpers';
import { currentPractice } from 'components/practice/practiceState';
import Select from 'components/shared/Select/Select';
import { formatDateAndTimeZ, iaRa } from 'lib/helpers/utility';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const AssignToClinicalNote = ({ patientId, vital, setVital = () => {} }) => {
  const { patient } = useOutletContext();
  const practice = useRecoilValue(currentPractice);
  const filters = clinicalNoteFilters(patient);
  const handleChangeCn = (appointmentId) => {
    setVital((prevVital) => ({
      ...prevVital,
      appointment_id: appointmentId
    }));
  };
  const { data: clinicalNoteOptions } = useClinicalNote({
    params: { ...filters, patientId },
    dependencies: [patientId, filters],
    options: {
      enabled: !!patientId,
      select: (data) => {
        return [
          {
            label: 'Unassigned',
            value: null
          },
          ...iaRa(data?.clinicalNote).map((note) => ({
            label: formatDateAndTimeZ(note?.appointment?.starts_at, practice?.timezone),
            value: note?.appointment?.id
          }))
        ];
      }
    }
  });

  return (
    <div className="m-0 flex items-center justify-center  p-0">
      <p className="whitespace-nowrap text-primary-900">DOS:</p>
      <Select
        inputId="appointment_id"
        icon="new-notev2"
        isClearable={false}
        placeholder="Unassigned"
        parentClassName="w-[256px] mr-4 !border-none"
        className="!left-1"
        menuPortalTarget={document.body}
        options={clinicalNoteOptions}
        styles={{
          control: {
            borderColor: 'transparent !important'
          }
        }}
        value={
          vital?.appointment_id
            ? iaRa(clinicalNoteOptions).find((note) => note?.value === vital?.appointment_id)
            : null
        }
        onChange={(option) => {
          handleChangeCn(option ? option?.value : null);
        }}
      />
    </div>
  );
};
export default AssignToClinicalNote;
