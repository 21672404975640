const { iaRa, isEmpty, stripHtmlTags, ia } = require('lib/helpers/utility');

exports.createVaccineNarrative = (vaccines) => {
  const { noDetails = false, row, generalNote } = vaccines || {};
  let narrative = '';

  if (noDetails || !iaRa(row)) {
    return 'No Known Vaccines';
  }

  iaRa(row)
    .filter((v) => v?.vaccine)
    .forEach((v) => {
      const doseCount =
        v?.doses?.value === 'Not sure'
          ? 'an unspecified number of'
          : v.doses?.value || 'an unknown number of';

      narrative += `The patient has received ${doseCount} dose${doseCount !== 'One' ? 's' : ''} of ${v.vaccine?.value}`;

      if (!v?.notSure && v?.lastDose) {
        narrative += `. The most recent dose was administered on ${v.lastDose}.`;
      }

      if (v?.notSure) {
        narrative += '. The patient is unsure about the date of the last dose.';
      }

      if (v?.note) narrative += `\n${v.note}`;

      narrative += '\n\n';
    });

  if (generalNote) {
    narrative += `General note on vaccinations: ${generalNote}`;
  }

  return narrative?.trim();
};

exports.createVaccineNarrativeV2 = (vaccines) => {
  if (!ia(vaccines)) {
    return '<p>No Known Vaccines</p>';
  }

  let narrative = '';

  iaRa(vaccines)
    .filter((v) => v?.vaccine)
    .forEach((v) => {
      let doseCount =
        v?.doses?.value === 'Not sure'
          ? 'an unspecified number of'
          : v.doses?.value || 'an unknown number of';

      let entry = `<p>The patient has received ${doseCount} dose${doseCount !== 'One' ? 's' : ''} of ${v.vaccine?.value}.`;

      if (!v?.notSure && v?.lastDose) {
        entry += ` The most recent dose was administered on ${v.lastDose}.`;
      }

      if (v?.notSure) {
        entry += ' The patient is unsure about the date of the last dose.';
      }

      entry += '</p>';

      if (!isEmpty(stripHtmlTags(v?.note))) {
        entry += `<p>${v.note}</p>`;
      }

      entry += '<br>';
      narrative += entry;
    });

  return narrative.trim();
};
