import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { Capitalize, formatCurrencyValue, ia } from 'lib/helpers/utility';
import { useInsuranceProfiles } from 'lib/hooks/queries/insuranceProfiles/useInsuranceProfiles';
import { PatientStatus } from 'components/practice/appointment/PreviewAppointment/components';

const Body = ({ appointment, event, type }) => {
  const params = {
    userId: appointment?.patient?.id
  };
  const { data } = useInsuranceProfiles({ params, options: { enabled: type === 'appointment' } });

  return (
    <div
      className={cs(
        'flex flex-col gap-2',
        (type === 'appointment' || event?.description) && '!px-3 !py-2'
      )}>
      {(appointment?.practice_note || event?.description) && (
        <div className="flex items-start justify-between gap-2 rounded-md border !border-warning-200 bg-warning-50 px-[10px] py-[6px]">
          <p className="text-sm font-500 leading-4 text-warning-700">
            {appointment?.practice_note || event?.description}
          </p>
          <Icon icon="new-note" color="warning" size={16} />
        </div>
      )}

      {type === 'appointment' && (
        <>
          {ia(appointment?.allergies) && appointment?.allergies[0] && (
            <div className="flex items-start justify-between gap-2 rounded-md border !border-danger-200 bg-danger-50 !py-2 px-[10px]">
              <p className="text-sm font-500 leading-4 text-danger-500">
                {appointment?.allergies.filter(Boolean).join(', ')}
              </p>
              <Icon icon="new-warning-v2" color="danger" size={16} />
            </div>
          )}
          {ia(appointment?.invoice?.procedures) && (
            <div className="flex flex-col gap-[6px]">
              <div className="flex items-center gap-[6px]">
                <Icon icon="new-star" size={18} />
                <span className="text-xs font-500 uppercase leading-5 text-neutral-600">
                  Service
                </span>
              </div>
              <ul className="!ml-[6px]">
                {appointment?.invoice?.procedures?.slice(0, 3).map((procedure, index) => (
                  <li
                    key={index}
                    className="mt-[6px] list-inside list-disc text-sm font-400 leading-[22px] text-primary-900 marker:text-neutral-300 first-of-type:mt-0">
                    {procedure?.name}
                  </li>
                ))}
                {appointment?.invoice?.procedures?.length > 3 && (
                  <span className="!ml-2 text-sm font-500 leading-5 text-neutral-600 underline">
                    +{appointment?.invoice?.procedures?.length - 3}
                  </span>
                )}
              </ul>
            </div>
          )}
          {ia(data?.insuranceProfile) && (
            <div className="flex flex-col gap-[6px]">
              <div className="flex items-center gap-[6px]">
                <Icon icon="new-shield-tick" size={18} />
                <span className="text-xs font-500 uppercase leading-5 text-neutral-600">
                  Insurance
                </span>
              </div>
              <ul className="!ml-[6px]">
                {data?.insuranceProfile?.slice(0, 3).map((insurance, index) => (
                  <li
                    className="mt-[6px] list-inside list-disc text-sm font-400 leading-[22px] text-primary-900 marker:text-neutral-300 first-of-type:mt-0"
                    key={index}>
                    {insurance?.payer_name}
                    <span className="!ml-1 text-xs font-500 leading-5 text-primary-600">
                      ({Capitalize(insurance?.type)})
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="!mt-2 grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-[6px] rounded-md bg-primary-25 p-[6px]">
              <div className="flex items-center gap-[6px]">
                <Icon icon="new-notification-status" size={16} />
                <span className="text-xs font-500 uppercase leading-5 text-neutral-600">
                  Status
                </span>
              </div>
              <div className="!ml-1 flex flex-col gap-2">
                <PatientStatus appointment={appointment} label={false} bulletPoints />
              </div>
            </div>
            <div className="flex flex-col gap-[6px] rounded-md bg-primary-25 p-[6px]">
              <div className="flex items-center gap-[6px]">
                <Icon icon="new-dollar-square" size={18} />
                <span className="text-xs font-500 uppercase leading-5 text-neutral-600">
                  Financial
                </span>
              </div>
              <div className="!ml-1 flex flex-col gap-2">
                {!isNaN(appointment?.invoice?.total_amount) && (
                  <div className="flex items-center justify-between gap-1">
                    <div className="flex items-baseline gap-3">
                      <span className="text-[15px] text-neutral-300">•</span>
                      <span className="text-xs font-500 leading-5 text-neutral-600">
                        Appointment Cost
                      </span>
                    </div>
                    <span className="!rounded-[3px] bg-primary-500 !px-[6px] text-xs font-600 leading-[22px] text-white">
                      {`$${formatCurrencyValue(appointment?.invoice?.total_amount)}`}
                    </span>
                  </div>
                )}
                {!isNaN(appointment?.invoice?.balance) && (
                  <div className="flex items-center justify-between gap-1">
                    <div className="flex items-baseline gap-3">
                      <span className="text-[15px] text-neutral-300">•</span>
                      <span className="text-xs font-500 leading-5 text-neutral-600">
                        Appointment Balance
                      </span>
                    </div>
                    <span className="!rounded-[3px] bg-primary-500 !px-[6px] text-xs font-600 leading-[22px] text-white">
                      {`$${formatCurrencyValue(appointment?.invoice?.balance)}`}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Body;
