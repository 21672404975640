import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';
import { pm } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import NewResourceModal from './NewResourceModal';
import { deleteResource } from 'api/Resource';

const ResourceTableActions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userPermissions = useRecoilValue(permissions);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let options = [
    ...(pm(userPermissions, 'resources.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'resources.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];

  const handleDelete = async () => {
    const { code } = await deleteResource(navigate, { id: data.id });

    if (code == 0) {
      showAlert({
        title: 'Success',
        message: 'Resource deleted successfully',
        color: 'success'
      });

      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'resources';
        },
        refetchType: 'all'
      });
    }
  };

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <NewResourceModal resource={data} isOpen={showEditModal} handleClose={onEditModalToggle} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete resource"
          title="Delete Resource"
          icon="new-calendar-red"
          message="Are you sure you want to delete this resource?"
          handleOpen={showDeleteModal}
          handleContinue={handleDelete}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default ResourceTableActions;
