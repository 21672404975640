import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useInvoices } from 'lib/hooks/queries/billing/useInvoices';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getColDefs, DEFAULT_COLUMN_DEFS, GRID_OPTIONS } from '../configs/balanceConfigs';
import AllocateForm from './AllocateForm';
import { CustomMasterDetailCheckbox } from './CustomMasterDetailCheckbox';
import WriteOffForm from './WriteOffForm';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';

const NewBalanceTable = memo(({ mode }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const finalColDefs = useMemo(() => {
    if (mode?.writeoff || mode?.allocate) {
      return [
        {
          field: 'checkbox',
          headerName: '',
          maxWidth: 50,
          suppressSizeToFit: true,
          cellRenderer: CustomMasterDetailCheckbox
        },
        ...getColDefs(practiceTimezone)
      ];
    }
    return getColDefs(practiceTimezone);
  }, [mode?.allocate, mode?.writeoff, practiceTimezone]);

  return <Table finalColDefs={finalColDefs} mode={mode} />;
});

function Table({ finalColDefs, mode }) {
  const detailCellRenderer = useMemo(() => {
    if (mode?.writeoff) return WriteOffForm;
    if (mode?.allocate) return AllocateForm;
    return null;
  }, [mode]);

  const { limit, page, sort, setPage, setSelectedRows } = useTableContext();
  const { id: patientId } = useParams();

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useInvoices({
    params: {
      page,
      sort,
      limit: 500, // TODO: Implement pagination
      user_id: patientId,
      unpaid: false,
      withBalance: true
    },
    dependencies: [limit, page, sort, patientId]
  });

  const invoices = useMemo(() => data?.invoices || [], [data?.invoices]);
  const count = data?.count || 0;
  const statusPanelData = data?.statusPanelData;

  useEffect(() => {
    if (!mode?.writeoff && !mode?.allocate) {
      setSelectedRows([]);
    }
  }, [mode?.writeoff, mode?.allocate, setSelectedRows]);

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [invoices, count, page, limit, onPageChange, statusPanelData]);

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {isGetInvoicesLoading || isFetching ? (
        <Skeleton count={limit} />
      ) : (
        <div className="ag-theme-quartz !mb-0 mt-3 h-[calc(100vh-185px)]">
          <AGTable
            getRowId={getRowId}
            detailCellRenderer={detailCellRenderer}
            masterDetail={mode?.writeoff || mode?.allocate}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            columnDefs={finalColDefs}
            gridOptions={GRID_OPTIONS}
            animateRows={true}
            data={invoices}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            isRowSelectable={() => true}
            customClassName="ag-grid-interactive"
            detailRowHeight={120}
            reactiveCustomComponents
            statusBar={statusBar}
            enableRangeSelection
          />
        </div>
      )}
    </ErrorBoundary>
  );
}

export default NewBalanceTable;
