import Tippy from '@tippyjs/react';
import { ia } from 'lib/helpers/utility';
import React from 'react';
import {
  useEnhanceNoteContextData,
  useEnhanceNoteContextApi
} from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { filterNotHiddenComponents, transformData } from '../../../shared/lib/helpers';
import { filterCheckedAndSelectedComponents } from '../../lib/helpers';

const List = ({ type }) => {
  const { setFilledForms, setScrollTo } = useEnhanceNoteContextApi();
  const { filledForms = {}, loading } = useEnhanceNoteContextData();
  const visibleComponents = filterNotHiddenComponents(filledForms?.[type]?.components);

  const checkedAndSelectedFilledForms = filterCheckedAndSelectedComponents(filledForms);

  const formsList = transformData(Object.values(checkedAndSelectedFilledForms));

  const currentComponents = Object.entries(visibleComponents)?.filter(
    ([, value]) => !!value?.selected
  );

  const onSelect = (e, itemKey) => {
    e.stopPropagation();
    const { checked = false } = e.target;

    setFilledForms((prevFilledForms = {}) => {
      return {
        ...prevFilledForms,
        [type]: {
          ...prevFilledForms?.[type],
          components: {
            ...prevFilledForms?.[type]?.components,
            [itemKey]: {
              ...prevFilledForms?.[type]?.components?.[itemKey],
              checked
            }
          }
        }
      };
    });
  };

  const scrollToComponent = (item) => {
    if (!item?.checked) return;

    const idx = formsList.findIndex((form) => form.path === item.path);
    setScrollTo(idx);
  };

  const handleDivClick = (e, item) => {
    const isCheckboxOrLabel =
      e.target.matches('input[type="checkbox"]') || e.target.closest('label');

    if (!isCheckboxOrLabel) {
      scrollToComponent(item);
    }
  };

  return (
    <div className=" py-[6px] !pr-2 pl-[10px]">
      {
        ia(currentComponents) ? (
          currentComponents?.map(([itemKey, item]) => {
            return (
              <Tippy content={item?.title} placement="right" className="tippy-dark" key={itemKey}>
                <div
                  className="flex cursor-pointer items-center rounded-md p-2 shadow-md"
                  onClick={(e) => handleDivClick(e, item)}>
                  <Checkbox
                    onChange={(e) => onSelect(e, itemKey)}
                    color="blue"
                    label={item?.title}
                    value={item.title}
                    checked={item.checked}
                    data-qa={`left-sidebar-checkbox-${item.title}`}
                  />
                </div>
              </Tippy>
            );
          })
        ) : loading ? (
          <Skeleton count={25} height="25px" />
        ) : null
        // <NotFound {...propsForNotFoundNote} />
      }
    </div>
  );
};

export default List;
