import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upsertPatientAggregateNarrative } from 'api/PatientAggregateNarrative';
import ClinicalNoteCurrentNoteTypeNotesMyScribeWarning from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeWarning';
import NarrativeFormComponent from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import { usePopoverContext } from 'components/shared/Popovers/Popover/PopoverContext';
import { PopoverContextProvider } from 'components/shared/Popovers/Popover/PopoverContextProvider';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import state, { intelligenceAiState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { camelToSpaceCase, ia, iaRa, isEmpty, pm, stripHtmlTags } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { compareObjectsOnFields } from '../../lib/compareObjectsOnFields';
import { getNarrativeGenerateFN } from '../lib/getNarrativeGenerateFN';

const NarrativeFormA = ({ loading, gridApi, resize, items, type, options }) => {
  const permissions = useRecoilValue(state.permissions);
  const { aggregatedDataRef, patientHistoryLoading } = useClinicalNoteContext();
  const { setContext } = usePopoverContext();
  const [oldData, setOldData] = useState({ narrative: null, synced: false });
  const intelligenceState = useRecoilValue(intelligenceAiState);
  const { id: patientId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const keyPatient = ['patientAggregateNarrative', patientId, type];
  const createNarrative = getNarrativeGenerateFN(type);
  const capitalizedType = camelToSpaceCase(type);

  const {
    data: patientNarrative,
    isLoading: patientNarrativeLoading,
    isFetching: patientNarrativeFetching
  } = usePatientAggregateNarrative({
    params: { user_type: 'provider', form_type: type, patientId },
    dependencies: [patientId, type],
    options: {
      select: (response) => response?.data?.narrative,
      enabled: !patientHistoryLoading,
      onSuccess: (narrativeObj) => {
        if (narrativeObj?.id && !narrativeObj?.synced) {
          setOldData({
            narrative: narrativeObj?.narrative,
            synced: narrativeObj?.synced
          });
          return;
        }

        queryClient.setQueryData(keyPatient, (prev) => ({
          ...prev,
          data: {
            ...prev?.data,
            narrative: {
              ...prev?.data?.narrative,
              narrative: createNarrative(items),
              synced: true
            }
          }
        }));
      }
    }
  });

  const updateSyncState = (prev, sync) => {
    let props = { synced: typeof sync === 'boolean' ? sync : !prev.synced };

    if (props?.synced) {
      props = {
        ...props,
        narrative: createNarrative(items)
      };
    }

    return props;
  };

  const handleNarrative = ({ event, sync }) => {
    setContext(event);
    queryClient.setQueryData(keyPatient, (prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        narrative: {
          ...prev?.data?.narrative,
          ...updateSyncState(prev?.data?.narrative, sync),
          narrative: event
        }
      }
    }));
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells({ force: true });
    }

    if (patientNarrative?.synced && !isPatientAggregateNarrativeLoading) {
      const narrative = createNarrative(iaRa(items));
      handleNarrative({
        event: narrative,
        sync: true
      });
    }
  }, [items]);

  useEffect(() => {
    if (patientNarrative) {
      setContext(patientNarrative?.narrative);
    }
  }, [patientNarrative]);

  useEffect(() => {
    console.count('intelligenceState', intelligenceState?.path);
    if (
      intelligenceState?.path?.includes?.(type) &&
      intelligenceState?.response !== patientNarrative?.narrative
    ) {
      console.log('updating query client');
      queryClient.setQueryData(keyPatient, (prev) => ({
        ...prev,
        data: {
          ...prev?.data,
          narrative: {
            ...prev?.data?.narrative,
            narrative: intelligenceState?.response
          }
        }
      }));
      if (!intelligenceAiState?.loading) {
        console.log('updating context');
        setContext(intelligenceState?.response);
      }
    }
  }, [intelligenceState?.response]);

  const { mutateAsync: mutateUpsertNarrative, isLoading: upsertNarrativeLoading } = useMutation({
    mutationFn: (data) => upsertPatientAggregateNarrative(navigate, data)
  });

  const handleUpsertNarrative = async () => {
    try {
      if (compareObjectsOnFields(oldData, patientNarrative, 'narrative', 'synced')) {
        return;
      }

      await mutateUpsertNarrative(
        {
          upsertData: {
            user_type: 'provider',
            patient_id: patientId,
            form_type: type,
            narrative: patientNarrative?.narrative,
            synced: patientNarrative?.synced
          }
        },
        {
          onSuccess: ({ data }) => {
            let narrative = null;
            if (data?.narrative?.synced) {
              narrative = createNarrative(items);
            }

            queryClient.setQueryData(keyPatient, (prev) => ({
              ...prev,
              data: {
                ...prev.data,
                narrative: {
                  ...prev.data?.narrative,
                  ...(!isEmpty(narrative) && { narrative }),
                  id: data?.narrative?.id
                }
              }
            }));

            setOldData({
              narrative: data?.narrative?.narrative,
              synced: data?.narrative?.synced
            });
          }
        }
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const setSyncNarrative = (valueOrToggle) => {
    queryClient.setQueryData(keyPatient, (prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        narrative: {
          ...prev?.data?.narrative,
          ...updateSyncState(prev?.data?.narrative, valueOrToggle)
        }
      }
    }));
  };

  const updateNarrative = () => {
    if (!ia(items)) {
      showAlert({
        color: 'warning',
        title: 'Narrative',
        message: `Please add ${type} to the list before updating the narrative`
      });

      return;
    }

    handleNarrative({ event: createNarrative(items), sync: true });
  };

  const isPatientAggregateNarrativeLoading = patientNarrativeLoading || patientNarrativeFetching;
  if (isPatientAggregateNarrativeLoading) {
    return <Skeleton count={3} />;
  }

  return (
    <>
      <button onClick={handleUpsertNarrative} ref={aggregatedDataRef} className="hidden" />
      <NarrativeFormComponent
        formType={type}
        textAreaClassName="!min-h-0 [&>.ql-container]:h-0 pb-0"
        syncNarrative={patientNarrative?.synced}
        setSyncNarrative={setSyncNarrative}
        isLoading={
          upsertNarrativeLoading ||
          (patientNarrative?.synced && loading) ||
          intelligenceState?.loading
        }
        onChange={(event) => handleNarrative({ event, sync: false })}
        setCurrentForm={() =>
          queryClient.setQueryData(keyPatient, (prev) => {
            return {
              ...prev,
              data: {
                ...prev?.data,
                narrative: {
                  ...prev?.data?.narrative,
                  narrative: null
                }
              }
            };
          })
        }
        restData={{
          customStyle: {
            height: `${resize}px`,
            maxHeight: `calc(${resize - 10}px - ${pm(permissions, ['myscribe.narrative_update'], 'or') ? '10' : '5'}0px)`
          },
          label: `${capitalizedType} Narrative`,
          placeholder: 'Add narrative here',
          id: `Narrative-${type}`,
          'data-qa': `narrative-${type}`,
          name: `Narrative-${type}`,
          value: patientNarrative?.narrative,
          formName: type
        }}
        narrativeOptions={[
          {
            title: (
              <div className="flex gap-1.5 transition-all hover:bg-primary-50">
                <Icon
                  icon="new-delete-macro"
                  color="primary"
                  size={19}
                  className="cursor-pointer"
                />
                <p>Update Narrative</p>
              </div>
            ),
            onClick: updateNarrative
          },
          ...iaRa(options)
        ]}
      />
      <ClinicalNoteCurrentNoteTypeNotesMyScribeWarning />
    </>
  );
};

const NarrativeForm = (props) => {
  return (
    <PopoverContextProvider>
      <NarrativeFormA {...props}>{props?.children}</NarrativeFormA>
    </PopoverContextProvider>
  );
};

export default NarrativeForm;
