import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';
import Status from 'components/shared/Status/Status';
import { mString } from 'lib/helpers/utility';

const Appointment = ({ index, appointment, callback, ctaCopy, exportRef, checkIn }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const cancelAppointment = async () => {
    let params = {
      appointmentId: appointment.id,
      userId: appointment.user_id,
      cancel: true,
      reason: exportRef?.current[index]?.current?.value
    };

    try {
      let res = await interimApi('/api/patient/appointment/update', params, navigate);

      if (res.data.appointment) {
        toast.success('Appointment was canceled successfully');
        setShowConfirmationModal(false);
        callback();
      } else {
        toast.error('Appointment cancellation failed');
        Honeybadger.notify(`Appointment cancellation failed`);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: appointments/appointment, method: cancelAppointment - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const copyToClickboard = async () => {
    try {
      let address = '';
      if (appointment.practice.practiceAddress.address_ln_1) {
        address += appointment.practice.practiceAddress.address_ln_1;
      }
      if (appointment.practice.practiceAddress.address_ln_2) {
        address += ', ';
        address += appointment.practice.practiceAddress.address_ln_2;
      }
      if (appointment.practice.practiceAddress.city) {
        address += ', ';
        address += appointment.practice.practiceAddress.city;
      }
      if (
        appointment.practice.practiceAddress.state &&
        isNaN(parseInt(appointment.practice.practiceAddress.state))
      ) {
        address += ', ';
        address += appointment.practice.practiceAddress.state;
      }
      if (appointment.practice.practiceAddress.zip) {
        address += ', ';
        address += appointment.practice.practiceAddress.zip;
      }
      const res = await navigator.clipboard.writeText(address);
      toast.success('Copied to Clipboard');
    } catch (error) {
      console.error(error);
    }
  };

  const checkCancellationTime = (cancellationHours, starts_at, timezone) => {
    const nowWithTimezone = moment.tz(moment(), timezone).format();

    const subtractedTime = moment(starts_at).subtract(cancellationHours, 'h').format();

    const cancellationTime = moment.tz(subtractedTime, timezone).format();

    if (nowWithTimezone <= cancellationTime) return true;
  };

  return (
    <Box className="flex h-full flex-col gap-4">
      <div className="text-2xl font-500">{appointment.practice.name}</div>
      {appointment.status === 'cancelled' && <Status status="canceled" className="w-max" />}
      <div className="flex flex-col">
        <div className="flex gap-1">
          <span>Time:</span>
          <div className="font-500">
            {moment
              .tz(appointment.starts_at, appointment.practice.timezone)
              .format('MMMM Do, hh:mm A z')}
          </div>
        </div>
        <div className="flex flex-wrap">
          {appointment.practice.email && (
            <div className="flex gap-1">
              <span>Email:</span>
              <span className="font-500">{appointment.practice.email}</span>
            </div>
          )}
          {appointment.practice.phone && (
            <div className="flex gap-1">
              <span>Phone:</span>
              <span className="font-500">{appointment.practice.phone}</span>
            </div>
          )}
        </div>
        {appointment.invoice && (
          <div className="flex gap-1">
            <span>Appointment Balance:</span>
            <div className="font-500">{mString(appointment?.invoice?.balance || 0)}</div>
          </div>
        )}
      </div>

      {appointment.status !== 'cancelled' &&
        appointment.status !== 'rescheduled' &&
        appointment.upcoming && (
          <div className="flex items-center justify-between gap-4">
            {checkCancellationTime(
              appointment?.practice?.cancellation,
              appointment?.starts_at,
              appointment?.practice?.timezone
            ) && (
              <Button
                text="Cancel"
                onClick={() => setShowConfirmationModal(true)}
                outlined
                color="neutral"
              />
            )}
            <Button text={ctaCopy} onClick={() => checkIn(appointment.id)} />
          </div>
        )}
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={cancelAppointment}
        title="Cancel appointment"
        variant="danger"
        icon="new-calendar-red"
        primaryBtnTxt="Cancel appointment"
        secondaryBtnTxt="Keep">
        <Input
          placeholder="Write a reason here"
          name="reason"
          forwardedRef={exportRef?.current[index]}
        />
      </Confirm>
    </Box>
  );
};

export default Appointment;
