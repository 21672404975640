import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import { Capitalize, ia } from 'lib/helpers/utility';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Axios from '../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import InfoBox from '../../../../shared/InfoBox/InfoBox';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import {
  clinicalNote as clinicalNoteState,
  permissions as userPermissions,
  userState
} from '../../../../state';
import TPAlert from '../TreatmentPlan/components/TPAlert';
import { processCustomNoteTypes } from '../lib/processCustomNoteTypes';
import Footer from './Footer/Footer';
import PatientOverview from './PatientOverview/PatientOverview';
import Sidebar from './Sidebar/Sidebar';
import Note3D from './TypeNotes/3DNote/3DNote';
import HPNote from './TypeNotes/HPNote/HPNote';
import IntakeNote from './TypeNotes/IntakeNote/IntakeNote';
import MyScribeAI from './TypeNotes/MyScribeAI/MyScribeAI';
import ProgressNote from './TypeNotes/ProgressNote/ProgressNote';
import SOAPNote from './TypeNotes/SOAPNote/SOAPNote';

const CurrentNote = () => {
  const { patient, hpNoteOverviewRefs, open, navbarRef, enabledNotes } = useOutletContext();
  const {
    loading,
    cnError,
    customFormTypes,
    appointment,
    setAppointment = () => null,
    components
  } = useClinicalNoteContext();
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { appointmentId, type, formName } = useParams();
  const wrapperRef = useRef();
  const navigate = useNavigate();
  const { user_id } = useRecoilValue(userState);
  const permissions = useRecoilValue(userPermissions);
  const [practitionerId, setPractitionerId] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(
    permissions?.clinical_note?.create ||
      permissions?.clinical_note?.update ||
      user_id === practitionerId
  );

  const typeNotes = {
    hp: HPNote,
    soap: SOAPNote,
    intake: IntakeNote,
    progress: ProgressNote,
    '3d': Note3D,
    myscribe: MyScribeAI,
    ...processCustomNoteTypes({ customFormTypes }),
    notFoundNote: NotFound
  };

  const firstActiveComponent = Object.values(components || {})?.[0]?.path;
  const CurrentNoteComponent = typeNotes?.[type] ?? typeNotes.notFoundNote;

  const propsForNotFoundNote = {
    icon: '404-not-found',
    title: 'Note not found',
    titleClassName: '!mt-3',
    description: (
      <div>
        The note of type <em className="text-md font-600 text-primary-900">"{Capitalize(type)}"</em>{' '}
        you are looking for does not exist.
      </div>
    ),
    createText: 'Please go back',
    onCreate: () => onNavigateBack()
  };

  const onNavigateBack = () => {
    if (!ia(enabledNotes)) {
      navigate(`/portal/charts/${patient?.id}/clinical-notes`);
      return;
    }

    navigate(
      `/portal/charts/${patient?.id}/clinical-notes/${appointmentId}/${enabledNotes?.[0].path}`
    );
  };
  useEffect(() => {
    getAppointment();
  }, []);

  useEffect(() => {
    setHasPermissions(
      permissions?.clinical_note?.create ||
        permissions?.clinical_note?.update ||
        user_id === practitionerId
    );
  }, [practitionerId]);

  // TODO: convert this with ReactQuery
  const getAppointment = async () => {
    try {
      let params = { id: appointmentId, createInvoiceIfNotExists: false };

      const { data } = await Axios.post('/api/appointment/get_one', params);

      if (data?.appointment) {
        setAppointment(data?.appointment);
        setPractitionerId(data?.appointment?.practitioner_id);
      }
    } catch (err) {
      Honeybadger.notify(`Appointment get one in current note - ${err}`);
    }
  };

  const getFormClassName = () => {
    const aggregatedForms = [
      'medicationHistory',
      'allergies',
      'vaccines',
      'surgicalHistory',
      'familyHistory',
      'weightLossAdministration',
      'vitals'
    ];

    const otherForms = ['diagnosis-codes'];

    const activeForm = formName ?? firstActiveComponent;

    if (aggregatedForms.includes(activeForm)) {
      return 'h-[calc(100vh-170px)] !p-0';
    }
    if (otherForms.includes(activeForm)) {
      return 'h-[calc(100vh-170px)] !p-5';
    }

    return '!p-5';
  };

  return (
    <div className="current-note grid h-full w-full" ref={wrapperRef}>
      <Sidebar navbarRef={navbarRef} appointment={appointment} />
      <div
        className={cs(
          'grow duration-200',
          open ? 'pl-[264px]' : 'pl-[128px]',
          (type === '3d' || formName === 'diagnosis-codes') && 'overflow-hidden'
        )}>
        <PatientOverview />
        <Footer open={open} forwardedRef={wrapperRef} />
        <div
          className={cs(
            'flex flex-col gap-4',
            getFormClassName()
            // (type === '3d' || formName === 'diagnosis-codes' || formName === 'medicationHistory') &&
            // 'h-[calc(100vh-170px)]'
          )}>
          {(cnError || !hasPermissions) && (
            <div className="flex flex-col gap-2">
              {cnError && <InfoBox color="danger" text={cnError} />}
              {!hasPermissions && !permissions && (
                <InfoBox text="The note will not be saved because you don't have the necessary permissions!" />
              )}
            </div>
          )}
          {!clinicalNote?.locked && <TPAlert />}
          {clinicalNote?.locked && (
            <InfoBox
              icon="new-lockv2"
              color="primary"
              text="This note is Locked. Must Unlock to make modifications."
            />
          )}
          {type && !loading ? (
            <CurrentNoteComponent
              {...propsForNotFoundNote}
              context={{
                appointment,
                patient,
                hpNoteOverviewRefs
              }}
            />
          ) : (
            <Skeleton height="calc(100vh - 214px)" />
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentNote;
