import React from 'react';
import Icon from '../../../../../../shared/Icon/Icon';
import GrowthChartModal from '../../../../../../shared/Modal/GrowthChartModal/GrowthChartModal';
import GrowthChart from '../../../GrowthChart/GrowthChart';
import SimpleAccordion from '../../../shared/SimpleAccordion';

const GrowthChartOverview = ({
  isGrowthChartEnabled = false,
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  growthChartModal = false,
  setGrowthChartModal = () => {},
  patient = {}
}) => {
  if (!isGrowthChartEnabled) return null;
  return (
    <SimpleAccordion
      title="Growth Chart"
      open={isOpen.growthChart}
      showExpandIcon={currentHpOverviewData?.vitals && patient}
      edit={
        <>
          <Icon icon="new-expand" className="!ml-2" onClick={() => setGrowthChartModal(true)} />
          <GrowthChartModal
            patient={patient}
            vitals={currentHpOverviewData?.vitals}
            showGrowthChartModal={growthChartModal}
            hideGrowthChartModal={() => setGrowthChartModal(false)}
          />
        </>
      }
      onToggle={() => toggleOne('growthChart')}>
      <GrowthChart patient={patient} vitals={currentHpOverviewData?.vitals} />
    </SimpleAccordion>
  );
};

export default GrowthChartOverview;
