import React, { useState } from 'react';
import Icon from 'components/shared/Icon/Icon';

import Confirm from 'components/shared/Modal/Confirm/Confirm';
import PreviewFile from './PreviewFile';
import ProgressBar from 'components/shared/ProgressBar/ProgressBar';
import cs from 'classnames';
import { DOCUMENT_TYPES, ICON_TYPES, STATUSES, TRY_AGAIN_LABEL } from '../lib/constants';

/**
 * @param {import('../lib/types').FileProps} props
 */

const File = ({ file, onDelete, onRetry, status = 'uploading' }) => {
  const [preview, setPreview] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);

  if (!file) return null;

  const fileType = file?.type;

  const isFailed = status === 'failed';
  const isUploading = status === 'uploading';
  const icon = ICON_TYPES[status];

  const togglePreview = () => setPreview((prev) => !prev);

  return (
    <>
      <div
        className={cs(
          'flex h-fit w-full flex-col items-start gap-4 rounded-lg border border-solid bg-white p-4',
          isFailed ? 'border-danger-500' : 'border-gray-300'
        )}>
        <div className="flex h-fit w-full flex-row items-start gap-3">
          <Icon icon={DOCUMENT_TYPES[fileType]} />
          <div className="flex w-full flex-1 flex-col items-start">
            <span
              className={cs(
                'flex cursor-default items-center text-sm font-medium leading-[22px] text-neutral-900',
                !isFailed && 'cursor-pointer hover:opacity-70',
                'overflow-hidden whitespace-normal'
              )}
              title={file.name} // Tooltip for full filename
              onClick={togglePreview}>
              {file.name}
            </span>
            <div className="flex cursor-default items-center gap-1 text-xs font-normal leading-5 text-neutral-700 ">
              <span className="flex items-center ">
                {`${Math.round(file.uploadedSize / 1000)} KB of ${Math.round(file.size / 1000)} KB`}
              </span>
              <div className="h-0.5 w-0.5 rounded-full bg-neutral-500" />
              <div className="flex items-center gap-1">
                <Icon
                  icon={icon}
                  className={cs(isUploading ? 'animate-spin' : '')}
                  {...(isFailed && { color: 'danger', shade: 500 })}
                />
                <span>{STATUSES[status]}</span>
              </div>
            </div>
            {isFailed && (
              <span
                onClick={() => onRetry(file.fileId)}
                className={cs(
                  'flex items-center text-sm leading-[22px] text-red-500 underline',
                  'cursor-pointer hover:opacity-70 '
                )}>
                {TRY_AGAIN_LABEL}
              </span>
            )}
          </div>
          {!isUploading && (
            <Icon
              size={16}
              icon="trash"
              color={isFailed ? 'danger' : 'neutral'}
              shade={isFailed ? 500 : 700}
              onClick={() => setDeleteWarning(true)}
            />
          )}
        </div>
        {isUploading && <ProgressBar progress={file.progress} />}
      </div>
      {preview && (
        <PreviewFile
          isOpen={preview}
          type={fileType}
          handle={file.handle}
          name={file.name}
          onClose={togglePreview}
        />
      )}
      <Confirm
        handleOpen={deleteWarning}
        handleClose={() => setDeleteWarning(false)}
        handleContinue={() => {
          onDelete(file.handle);
          setDeleteWarning(false);
        }}
        title="Delete file"
        message="Are you sure you want to delete this file?"
        variant="danger"
        icon="file-unknown"
      />
    </>
  );
};
export default File;
