import React, { useRef, useCallback, useState, memo, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const VirtualizedList = ({ data, renderItem, scrollToIndex }) => {
  const listRef = useRef(null);
  const rowHeights = useRef(new Map());
  const [inputValues, setInputValues] = useState({});

  // Set and update row height
  const setRowHeight = useCallback((index, size) => {
    const currentHeight = rowHeights.current.get(index);
    if (currentHeight !== size) {
      rowHeights.current.set(index, size);
      if (listRef.current) {
        listRef.current.resetAfterIndex(index, true); // Recompute heights from this index
      }
    }
  }, []);

  // Get row height dynamically
  const getItemSize = useCallback((index) => {
    const height = rowHeights.current.get(index);
    return height || 0; // Use a minimal fallback height to avoid errors
  }, []);

  useEffect(() => {
    if (scrollToIndex !== null && scrollToIndex !== undefined && listRef.current) {
      listRef.current.scrollToItem(scrollToIndex, 'start'); // Scroll item to the start of the viewport
    }
  }, [scrollToIndex]);

  // Handle input value changes
  const handleInputChange = useCallback((index, value) => {
    setInputValues((prev) => ({
      ...prev,
      [index]: value
    }));
  }, []);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={listRef}
          height={height}
          width={width}
          itemCount={data.length}
          itemSize={getItemSize}
          estimatedItemSize={700}>
          {({ index, style }) => (
            <div style={style}>
              <MeasuredRow index={index} setRowHeight={setRowHeight}>
                {renderItem(data[index], index, handleInputChange, inputValues)}
              </MeasuredRow>
            </div>
          )}
        </List>
      )}
    </AutoSizer>
  );
};

const MeasuredRow = memo(({ index, setRowHeight, children }) => {
  const rowRef = useRef(null);

  useEffect(() => {
    if (rowRef.current) {
      const height = rowRef.current.getBoundingClientRect().height; // Measure row height
      setRowHeight(index, height); // Update height in parent
    }
  }, [index, children, setRowHeight]);

  return <div ref={rowRef}>{children}</div>;
});

export default memo(VirtualizedList);
