module.exports = {
  ReviewSystems: [
    {
      title: 'General',
      noBlock: null,
      row: [
        {
          name: 'Fatigue',
          checked: null,
          when: null
        },
        {
          name: 'Fever',
          checked: null,
          when: null
        },
        {
          name: 'Weight Loss',
          checked: null,
          when: null
        },
        {
          name: 'Weight Gain',
          checked: null,
          when: null
        },
        {
          name: 'Insomnia',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Gastrointestinal',
      noBlock: null,
      row: [
        {
          name: 'Abdominal pain',
          checked: null,
          when: null
        },
        {
          name: 'Constipation',
          checked: null,
          when: null
        },
        {
          name: 'Bloody stool',
          checked: null,
          when: null
        },
        {
          name: 'Diarrhea',
          checked: null,
          when: null
        },
        {
          name: 'Heartburn',
          checked: null,
          when: null
        },
        {
          name: 'Nausea/Vomiting',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Skin',
      noBlock: null,
      row: [
        {
          name: 'Rashes',
          checked: null,
          when: null
        },
        {
          name: 'Itching',
          checked: null,
          when: null
        },
        {
          name: 'Mole changes',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Cardiovascular',
      noBlock: null,
      row: [
        {
          name: 'Chest pain',
          checked: null,
          when: null
        },
        {
          name: 'Palpitations',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Eyes, Ears, Nose, & Throat',
      noBlock: null,
      row: [
        {
          name: 'Visual changes',
          checked: null,
          when: null
        },
        {
          name: 'Hearing loss',
          checked: null,
          when: null
        },
        {
          name: 'Sore Throat',
          checked: null,
          when: null
        },
        {
          name: 'Nasal congestion',
          checked: null,
          when: null
        },
        {
          name: 'Ear pain',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Musculoskeletal',
      noBlock: null,
      row: [
        {
          name: 'Joint pain',
          checked: null,
          when: null,
          where: null
        },
        {
          name: 'Muscle pain',
          checked: null,
          when: null,
          where: null
        },
        {
          name: 'Leg swelling',
          checked: null,
          when: null,
          where: null
        }
      ]
    },
    {
      title: 'Genitourinary',
      noBlock: null,
      row: [
        {
          name: 'Painful urination',
          checked: null,
          when: null
        },
        {
          name: 'Bloody urine',
          checked: null,
          when: null
        },
        {
          name: 'Increased urination',
          checked: null,
          when: null
        },
        {
          name: 'Leaking urine',
          checked: null,
          when: null
        },
        {
          name: 'Do you wear pads?',
          checked: null
        }
      ]
    },
    {
      title: 'Neurologic',
      noBlock: null,
      row: [
        {
          name: 'Headaches',
          checked: null,
          when: null
        },
        {
          name: 'Dizziness',
          checked: null,
          when: null
        },
        {
          name: 'Difficulty walking',
          checked: null,
          when: null
        },
        {
          name: 'Numbness or tingling',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Respiratory',
      noBlock: null,
      row: [
        {
          name: 'Shortness of breath',
          checked: null,
          when: null
        },
        {
          name: 'Cough',
          checked: null,
          when: null
        },
        {
          name: 'Wheezing',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Neck',
      noBlock: null,
      row: [
        {
          name: 'Swollen glands',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Psychiatric',
      noBlock: null,
      row: [
        {
          name: 'Anxiety',
          checked: null,
          when: null
        },
        {
          name: 'Irritability',
          checked: null,
          when: null
        },
        {
          name: 'Suicidal ideation',
          checked: null,
          when: null
        },
        {
          name: 'Depression',
          checked: null,
          when: null
        },
        {
          name: 'Concerns about your emotional or physical safety',
          checked: null,
          when: null
        },
        {
          name: 'History of domestic violence/ Sexual violence',
          checked: null,
          when: null
        }
      ]
    },
    {
      title: 'Gynecological',
      noBlock: null,
      row: [
        {
          name: 'Irregular menses',
          checked: null,
          when: null
        },
        {
          name: 'Heavy menstrual',
          checked: null,
          when: null
        },
        {
          name: 'Abnormal vaginal discharge',
          checked: null,
          when: null
        },
        {
          name: 'Pelvic pain',
          checked: null,
          when: null
        }
      ]
    }
  ],
  HPIRevisions: {
    noDetails: null,
    location: null,
    duration: null,
    timing: null,
    severity: null,
    quality: null,
    context: null,
    contextNote: '',
    relievingFactors: null,
    relievingFactorsNote: '',
    modifyingFactors: {
      noModifyingFactors: null,
      takenMedication: '',
      medication: '',
      helpedMedication: '',
      appliedHeat: '',
      helpedHeat: '',
      appliedColdCompress: '',
      helpedColdCompress: '',
      hadSurgery: '',
      helpedSurgery: '',
      triedPhysicalTherapy: '',
      helpedPhysicalTherapy: '',
      beenRespirator: '',
      helpedRespirator: '',
      triedRest: '',
      helpedRest: '',
      otherModifyingFactors: '',
      helpedOtherModifyingFactors: ''
    },
    signsAndSymptoms: '',
    narrative: ''
  },
  PastMedicalHistory: {
    noDetails: false,
    narrative: '',
    chronic: {
      illness: '',
      note: ''
    },
    surgeries: {
      surgery: '',
      note: ''
    },
    allergies: {
      allergy: '',
      note: ''
    },
    hospitalization: {
      illnessOrInjury: '',
      note: ''
    }
    // allergicReaction: {
    //   brufen: false,
    //   brufenNote: '',
    //   penicilin: false,
    //   penicilinNote: '',
    //   enythromycin: false,
    //   enythromycinNote: '',
    //   sulfa: false,
    //   sulfaNote: '',
    //   localAnesthetic: false,
    //   localAnestheticNote: '',
    //   fluoride: false,
    //   fluorideNote: '',
    //   metals: false,
    //   metalsNote: '',
    //   latex: false,
    //   latexNote: '',
    //   other: false,
    //   otherNote: ''
    // },
    // heartProblems: '',
    // historyOfInfectiveEndocarditis: '',
    // artificialHeartValve: '',
    // pacemakerOrImplant: '',
    // artificialProsthesis: '',
    // rheumaticOrScarletFever: '',
    // highLowBloodPressure: '',
    // takingBloodThinners: '',
    // anemiaOrBloodDisorder: '',
    // prolongedBleedingDueSlightCut: '',
    // emphysemaShortBreathSarcoidosis: '',
    // tuberculosisMeaslesChickenPox: '',
    // asthma: '',
    // breathingOrSleepProblems: '',
    // kidneyDisease: '',
    // liverDisease: '',
    // jaundice: '',
    // thydoidOrCalciumDeficiency: '',
    // hormoneDifiency: '',
    // cholesterolOrTakingStatinDrugs: '',
    // diabets: '',
    // stomachOrDuodentalUlcer: '',
    // digestiveDisorders: '',
    // osteoporosis: '',
    // arthritisRheumatoidArthritisLupus: '',
    // glaucoma: '',
    // contactLenses: '',
    // headOrNectInjuries: '',
    // epilepsyConvulsions: '',
    // neurologicDisorders: '',
    // viralInfectionsAndColdSores: '',
    // lumpsOrSwellingInMouth: '',
    // hivesSkinsRashHayFever: '',
    // stiStd: '',
    // hepatitis: {
    //   hepatitis: '',
    //   type: '',
    //   note: ''
    // },
    // hivAids: '',
    // tumorAbnormalGrowth: '',
    // radiationTherapy: '',
    // chemotherapyImmunosuppressive: '',
    // emotionalProblems: '',
    // psychiatricTreatment: '',
    // antidepressantMedication: '',
    // alcoholStreetDrugUse: '',
    // presentlyTreatingIllness: '',
    // awareOfHealthIn24hours: '',
    // takingMedicationForWeight: '',
    // takingDietarySupplements: '',
    // oftenExhaustedOrFatigued: '',
    // experiencingFrequentHeadaches: '',
    // smokerOrSmokedOrTobacco: '',
    // consideredTouchyPerson: '',
    // oftenUnhappyOrDepressed: '',
    // takingBirthControlPills: '',
    // pregnant: '',
    // prostateDisorders: '',
    // describeCurrentMedicalTreatment: '',
    // listOfMedicationsSupplementsVitaminsOfTwoYears: ''
  },
  FamilyHistory: {
    noDetails: null,
    narrative: '',
    row: [
      {
        medicalCondition: null,
        motherSide: false,
        fatherSide: false,
        sibling: false,
        note: '',
        id: self.crypto.randomUUID()
      }
    ]
  },
  SurgicalHistory: {
    noDetails: null,
    narrative: '',
    row: [
      {
        surgery: null,
        surgeon: null,
        date: new Date().toISOString().slice(0, 10),
        calendarPopover: false,
        note: '',
        id: self.crypto.randomUUID()
      }
    ]
  },
  SocialHistory: {
    noDetails: null,
    narrative: '',
    cigarettes: {
      never: false,
      yes: false,
      packsDay: null,
      quit: false,
      dateQuit: null,
      yearsSmoked: null
    },
    vape: {
      no: false,
      yes: false
    },
    alcohol: {
      never: false,
      yes: false,
      drinksWeek: null
    },
    drugs: {
      never: false,
      rarely: false,
      timesMonth: null,
      marijuana: false,
      cocaine: false,
      opioid: false,
      other: false,
      otherText: null
    },
    education: {
      highSchool: false,
      tradeSchool: false,
      college: false,
      postGraduateDegree: false
    },
    employed: {
      radio: false,
      retired: false,
      yes: false,
      typeOfWork: null
    },
    exercise: {
      no: false,
      yes: false,
      type: null,
      howOften: null,
      howLongActivity: null
    },
    martialStatus: {
      married: false,
      partnership: false,
      divorced: false,
      separated: false,
      single: false,
      widow: false
    },
    sexualActivity: {
      no: false,
      yes: false,
      numberOfSexualPartners: null,
      men: false,
      women: false,
      noContracteption: false,
      contracteption: false,
      methodContraception: null
    },
    children: {
      no: false,
      yes: false,
      numberOfChildren: null,
      ages: []
    }
  },
  Allergies: {
    noDetails: null,
    narrative: '',
    allergies: [
      {
        allergy: null,
        medication: { value: 'None', label: 'None' },
        typicalReaction: null,
        note: '',
        id: self.crypto.randomUUID()
      }
    ]
  },
  Vaccines: {
    noDetails: null,
    narrative: '',
    row: [
      {
        vaccine: null,
        doses: null,
        lastDose: new Date().toISOString().slice(0, 10),
        calendarPopover: false,
        lastDoseDisabled: false,
        notSure: false,
        note: '',
        id: self.crypto.randomUUID()
      }
    ],
    generalNote: ''
  },
  CurrentMedications: {
    noDetails: null,
    narrative: '',
    medications: [
      {
        medication: null,
        strength: '',
        unit: '',
        days_supply: null,
        quantity: null,
        refills: null,
        sig: '',
        is_past: false,
        condition: null,
        start_date: null,
        end_date: null,
        prescriber: '',
        note: '',
        id: self.crypto.randomUUID()
      },

      {
        medication: null,
        strength: '',
        unit: '',
        days_supply: null,
        quantity: null,
        refills: null,
        sig: '',
        is_past: true,
        condition: null,
        start_date: null,
        end_date: null,
        prescriber: '',
        note: '',
        id: self.crypto.randomUUID()
      }
    ]
  },
  OtherProblems: [
    {
      note: '',
      problems: null,
      id: self.crypto.randomUUID()
    }
  ],
  //   noDetails: false,
  //   hospitalisation: {
  //     illnessOrInjury: '',
  //     note: ''
  //   },
  //   allergicReaction: {
  //     brufen: false,
  //     brufenNote: '',
  //     penicilin: false,
  //     penicilinNote: '',
  //     enythromycin: false,
  //     enythromycinNote: '',
  //     sulfa: false,
  //     sulfaNote: '',
  //     localAnesthetic: false,
  //     localAnestheticNote: '',
  //     fluoride: false,
  //     fluorideNote: '',
  //     metals: false,
  //     metalsNote: '',
  //     latex: false,
  //     latexNote: '',
  //     other: false,
  //     otherNote: ''
  //   },
  //   heartProblems: '',
  //   historyOfInfectiveEndocarditis: '',
  //   artificialHeartValve: '',
  //   pacemakerOrImplant: '',
  //   artificialProsthesis: '',
  //   rheumaticOrScarletFever: '',
  //   highLowBloodPressure: '',
  //   takingBloodThinners: '',
  //   anemiaOrBloodDisorder: '',
  //   prolongedBleedingDueSlightCut: '',
  //   emphysemaShortBreathSarcoidosis: '',
  //   tuberculosisMeaslesChickenPox: '',
  //   asthma: '',
  //   breathingOrSleepProblems: '',
  //   kidneyDisease: '',
  //   liverDisease: '',
  //   jaundice: '',
  //   thydoidOrCalciumDeficiency: '',
  //   hormoneDifiency: '',
  //   cholesterolOrTakingStatinDrugs: '',
  //   diabets: '',
  //   stomachOrDuodentalUlcer: '',
  //   digestiveDisorders: '',
  //   osteoporosis: '',
  //   arthritisRheumatoidArthritisLupus: '',
  //   glaucoma: '',
  //   contactLenses: '',
  //   headOrNectInjuries: '',
  //   epilepsyConvulsions: '',
  //   neurologicDisorders: '',
  //   viralInfectionsAndColdSores: '',
  //   lumpsOrSwellingInMouth: '',
  //   hivesSkinsRashHayFever: '',
  //   stiStd: '',
  //   hepatitis: {
  //     hepatitis: '',
  //     type: '',
  //     note: ''
  //   },
  //   hivAids: '',
  //   tumorAbnormalGrowth: '',
  //   radiationTherapy: '',
  //   chemotherapyImmunosuppressive: '',
  //   emotionalProblems: '',
  //   psychiatricTreatment: '',
  //   antidepressantMedication: '',
  //   alcoholStreetDrugUse: '',
  //   presentlyTreatingIllness: '',
  //   awareOfHealthIn24hours: '',
  //   takingMedicationForWeight: '',
  //   takingDietarySupplements: '',
  //   oftenExhaustedOrFatigued: '',
  //   experiencingFrequentHeadaches: '',
  //   smokerOrSmokedOrTobacco: '',
  //   consideredTouchyPerson: '',
  //   oftenUnhappyOrDepressed: '',
  //   takingBirthControlPills: '',
  //   pregnant: '',
  //   prostateDisorders: '',
  //   describeCurrentMedicalTreatment: '',
  //   listOfMedicationsSupplementsVitaminsOfTwoYears: ''
  // },
  ActiveSymptoms: {
    depression: false,
    depressionNote: '',
    anxiety: false,
    anxietyNote: '',
    obsessions: false,
    obsessionsNote: '',
    continuedSubstanceAbuseAlcohol: false,
    continuedSubstanceAbuseAlcoholNote: '',
    continuedSubstanceAbuseDrugs: false,
    continuedSubstanceAbuseDrugsNote: '',
    relationshipIssuesMarried: false,
    relationshipIssuesMarriedNote: '',
    relationshipIssuesOther: false,
    relationshipIssuesOtherNote: '',
    overwhelmedFeelings: false,
    overwhelmedFeelingsNote: '',
    difficultyConcentrating: false,
    difficultyConcentratingNote: '',
    difficultyPlanning: false,
    difficultyPlanningNote: '',
    difficultyCompletingTasks: false,
    difficultyCompletingTasksNote: '',
    observationsAttitudeAppearanceOther: false,
    observationsAttitudeAppearanceOtherNote: '',
    other: false,
    otherNote: ''
  },
  FunctionalImpairment: {
    depression: false,
    selfCare: false,
    relationships: false,
    schoolOrWork: false,
    generalTasksDemands: false,
    communityParticipation: false,
    none: false,
    other: false,
    otherNote: ''
  },
  Interventions: {
    cognitiveChallenging: false,
    cognitiveReframing: false,
    communicationSkills: false,
    dbtSkills: false,
    explorationExplanationOfCopingPatterns: false,
    explorationExplanationOfEmotions: false,
    explorationExplanationOfRelationshipPatterns: false,
    explorationOfFamilyOfOrigin: false,
    interactiveFeedback: false,
    interpersonalResolutions: false,
    mindfulnessGrounding: false,
    preventativeServices: false,
    psychoEducation: false,
    supportiveReflection: false,
    symptomManagement: false,
    rapportBuilding: false,
    treatmentPlanningGoalReview: false,
    other: false,
    otherNote: ''
  },
  WeightLossAdministration: {
    initialWeight: '',
    goalWeight: '',
    row: [
      {
        medication: null,
        doses: null,
        dosesUnit: 'mg',
        location: '',
        typeOfInjection: '',
        date: new Date().toISOString().slice(0, 10),
        weight: '',
        notes: '',
        id: self.crypto.randomUUID()
      }
    ]
  },
  Goals: [
    {
      goal: null,
      objectives: [
        {
          objective: null,
          treatments: [
            {
              treatment: null
            }
          ]
        }
      ]
    }
  ],
  GoalsNarrative: { narrative: '' },
  Progress: {
    progress: null,
    progressOther: null,
    frequency: null,
    frequencyOther: null,
    continueCurrentTherapeuticFocus: false,
    changeTreatmentGoalsOrObjectives: false
  },
  ProgressList: [
    {
      value: 'Deferred',
      label: 'Deferred'
    },
    {
      value: 'Progressing',
      label: 'Progressing'
    },
    {
      value: 'Regressed',
      label: 'Regressed'
    },
    {
      value: 'Maintained',
      label: 'Maintained'
    },
    {
      value: 'Complete',
      label: 'Complete'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ],
  Frequency: [
    {
      value: 'Meet Weekly',
      label: 'Meet Weekly'
    },
    {
      value: 'Meet Bi-Weekly',
      label: 'Meet Bi-Weekly'
    },
    {
      value: 'Meet Monthly',
      label: 'Meet Monthly'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ],
  Session: {
    type: null,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  },
  RiskFactors: {
    minimalRisk: false,
    lowRisk: false,
    moderateRisk: false,
    highRisk: false,
    none: false,
    other: false,
    otherNote: ''
  }
};
