import { queryClient } from 'App';
import { iaRa } from 'lib/helpers/utility';

export default async (data) => {
  const { patient_id, insuranceProfile } = data;

  queryClient.setQueryData(['insurance-profiles', patient_id], (oldData) => {
    if (!oldData) return;
    return {
      ...oldData,

      insuranceProfile: iaRa(oldData.insuranceProfile).map((it) =>
        it.id === insuranceProfile.id ? { ...it, ...insuranceProfile } : it
      )
    };
  });
};
