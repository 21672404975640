import { treatmentPlanLabels } from '../../lib/constants';

export const routes = {
  empty: {
    name: 'empty',
    btnText: null,
    title: treatmentPlanLabels.list,
    nextPath: 'create',
    nextBtnText: treatmentPlanLabels.create
  },
  create: {
    name: 'create',
    btnText: treatmentPlanLabels.create,
    title: treatmentPlanLabels.create,
    nextPath: 'preview',
    nextBtnText: treatmentPlanLabels.edit
  },
  list: {
    name: 'list',
    btnText: null,
    title: treatmentPlanLabels.list,
    nextPath: 'preview',
    nextBtnText: treatmentPlanLabels.edit
  },
  preview: {
    name: 'preview',
    btnText: treatmentPlanLabels.edit,
    title: 'Preview',
    nextPath: 'edit',
    nextBtnText: treatmentPlanLabels.update
  },
  edit: {
    name: 'edit',
    btnText: treatmentPlanLabels.update,
    title: treatmentPlanLabels.edit,
    nextPath: 'preview',
    nextBtnText: treatmentPlanLabels.edit
  },
  update: {
    name: 'update',
    btnText: null,
    title: treatmentPlanLabels.selectUpdate,
    nextPath: null,
    nextBtnText: null
  }
};
