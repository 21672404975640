import React from 'react';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

export default function Payer({ serviceId, payer, allowAmount, updatePaySchedule }) {
  return (
    <div className="grid grid-cols-2 items-center gap-4">
      <p className="text-sm font-500">{payer.name}</p>
      <CurrencyInput
        value={allowAmount}
        onValueChange={(v) => {
          updatePaySchedule({
            service_id: serviceId,
            payer_id: payer.id,
            allowable_amount_cents: !isNaN(v) ? +v : null
          });
        }}
        id="serviceCurrencyPayerInput"
      />
    </div>
  );
}
