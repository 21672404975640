import { requestApi } from './Api';

export const getUserPackages = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/package/get_by_user',
    params
  });
};

export const getPackages = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/package/get',
    params
  });
};
