import React from 'react';
import EmptyTableState from '../../EmptyTableComponent';

const EmptyPatientForms = () => {
  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="no-data-found" />
      <EmptyTableState.Text
        title="No Forms Found!"
        description="You will see the list of forms once you have at least one form created."
      />
    </EmptyTableState>
  );
};

export default EmptyPatientForms;
