import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteWriteOff, unAllocateBalance } from 'api/Transaction';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

export const AllocateAction = ({ data, rowIndex }) => {
  const { id, type } = data || {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [confirmation, setConfirmation] = useState({ type: null, loading: false });
  const isAllocate = confirmation.type === 'unallocate';
  const isWriteOff = type === 'writeoff';

  const unAllocateMutation = useMutation({
    mutationFn: () => unAllocateBalance(navigate, { transactionId: id }),
    onSuccess: async () => {
      showAlert({
        title: 'Success',
        message: 'Amount unallocated successfully!',
        color: 'success'
      });

      setConfirmation({ type: null, loading: false });
      queryClient.refetchQueries({ queryKey: ['patientInvoices'] });
      queryClient.refetchQueries({ queryKey: ['allocated'] });
      queryClient.refetchQueries({ queryKey: ['unallocated'] });
    },
    onError: () => {
      setConfirmation((prev) => ({ ...prev, loading: false }));
    }
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteWriteOff(navigate, { transactionId: id, type: 'allocated' }),
    onSuccess: async () => {
      showAlert({
        title: 'Success',
        message: 'Write off transaction deleted successfully!',
        color: 'success'
      });

      setConfirmation({ type: null, loading: false });
      queryClient.refetchQueries({ queryKey: ['patientInvoices'] });
      queryClient.refetchQueries({ queryKey: ['allocated'] });
      queryClient.refetchQueries({ queryKey: ['unallocated'] });
    },
    onError: () => {
      setConfirmation((prev) => ({ ...prev, loading: false }));
    }
  });

  const handleUnAllocate = () => {
    setConfirmation((prev) => ({ ...prev, loading: true }));
    unAllocateMutation.mutate();
  };

  const handleDelete = () => {
    setConfirmation((prev) => ({ ...prev, loading: true }));
    deleteMutation.mutate();
  };

  const onUnAllocateClick = () => {
    setConfirmation({ type: 'unallocate', loading: false });
  };

  const onTrashClick = () => {
    setConfirmation({ type: 'trash', loading: false });
  };

  return (
    <div className="flex h-full items-center justify-start gap-8">
      <Button
        data-qa={`unallocate-${rowIndex}`}
        size="small"
        type="secondary"
        text="Unallocate"
        onClick={onUnAllocateClick}
      />
      {isWriteOff && <Icon icon="trash" onClick={onTrashClick} />}
      {confirmation.type && (
        <Confirm
          variant="danger"
          loading={confirmation.loading}
          primaryBtnTxt={isAllocate ? 'Unallocate' : 'Delete'}
          title={isAllocate ? 'Unallocate transaction' : 'Delete write off transaction'}
          handleContinue={isAllocate ? handleUnAllocate : handleDelete}
          handleOpen={confirmation.type}
          handleClose={() => setConfirmation({ type: null, loading: false })}
          message={
            isAllocate
              ? 'Do you want to unallocate this transaction?'
              : 'Do you want to delete this write off transaction?'
          }
        />
      )}
    </div>
  );
};
