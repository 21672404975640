import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import { useEffect, useState } from 'react';
import { routes } from '../lib/helper';

const useNavigation = (initialRoute = 'list') => {
  const [currentRoute, setCurrentRoute] = useState(routes[initialRoute]);
  const breadcrumb = useBreadcrumb([routes[initialRoute]]);
  const { step, steps } = breadcrumb;

  useEffect(() => {
    if (step > -1) {
      navigate(steps[step].name);
    }
  }, [step]);

  const navigate = (routeName) => {
    if (currentRoute.name === 'create') {
      //pop create breadcrumb
      breadcrumb.setSteps((prevSteps) => {
        prevSteps.splice(step);
        return prevSteps;
      });
    }
    const route = routes[routeName || currentRoute.nextPath];
    breadcrumb.push(route);
    setCurrentRoute(route);
  };

  return {
    currentRoute,
    breadcrumb,
    navigate
  };
};

export default useNavigation;
