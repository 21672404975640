export const onRowClicked = async ({ e, gridApi }) => {
  const clickedMedications = e?.data;
  if (gridApi) {
    const clickedNode = gridApi?.getRowNode(clickedMedications?.id);
    if (clickedNode) {
      clickedNode.setSelected(!clickedNode.isSelected());
    }
  }
};
export const getRowId = (row) => {
  return row?.data?.id;
};
