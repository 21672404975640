import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTreatmentPlan } from 'api/TreatmentPlan';
import { showAlert } from 'components/shared/Alert/Alert';

const useUpdateTP = ({ navigate, selectedTreatmentPlan }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data) =>
      updateTreatmentPlan(navigate, {
        treatmentId: selectedTreatmentPlan.id,
        objToBeUpdated: data
      }),
    {
      onSuccess: ({ code }) => {
        if (code === 0) {
          showAlert({
            title: 'Treatment plan updated successfully!'
          });
          queryClient.invalidateQueries('treatmentPlan');
        }
      }
    }
  );

  return mutation;
};

export default useUpdateTP;
