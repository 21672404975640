import React from 'react';
import { formatDateAndTimeZ } from '../../../../../../lib/helpers/utility';
import cs from 'classnames';
import moment from 'moment-timezone';
import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';

export const getTPTableCols = ({ treatment_plan_id, timezone }) => [
  {
    field: 'created_at',
    headerName: 'Creation date',
    minWidth: 220,
    cellRenderer: ({ value, data }) => {
      return (
        <div className="flex items-center gap-2 pl-4">
          {treatment_plan_id === data?.id ? (
            <Tippy className="tippy-dark" content="Applied to today's note">
              <span className="absolute left-2">
                <Icon icon="checkmark-circle" />
              </span>
            </Tippy>
          ) : null}
          {formatDateAndTimeZ(value, timezone)}
        </div>
      );
    }
  },
  {
    field: 'treatment_team',
    minWidth: 130,
    headerName: 'Provider',
    valueFormatter: ({ value }) => {
      return value
        ?.map((item) => (item.provider ? item.provider?.label : null))
        .filter((label) => label !== null)
        .join(', ');
    }
  },
  {
    field: 'icd10',
    minWidth: 130,
    maxWidth: 150,
    headerName: 'ICD codes',
    valueFormatter: ({ value }) => {
      return value?.join(', ') || 'N/A';
    }
  },
  {
    field: 'treatment_frequency_date.startDate',
    minWidth: 130,
    headerName: 'Start date',
    valueFormatter: ({ value }) => {
      return value || 'N/A';
    }
  },
  {
    field: 'treatment_frequency_date.endDate',
    minWidth: 130,
    headerName: 'End date',
    cellRenderer: ({ value, data }) => {
      const endDateMoment = moment(data.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
      const currentDate = moment();
      const isExpired = endDateMoment.isBefore(currentDate, 'day');

      return <p className={cs(isExpired && '!text-danger-500')}> {value}</p> || 'N/A';
    }
  }
];
