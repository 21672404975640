import { requestApi } from './Api';

export const getBalance = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/patient/transaction/balance',
    params
  });

export const readBalance = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/patient/balance/read',
    params
  });

export const unAllocateBalance = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/patient/balance/unallocate',
    params
  });

export const deleteWriteOff = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/transactions/writeoff/remove',
    params
  });

export const getInvoices = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/appointment/invoices/get',
    params
  });

export const getInvoicesCSV = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/appointment/invoices/get_csv',
    params
  });

export const getTransactions = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/transactions/list',
    params
  });

export const getTransactionsCSV = (navigate, params) =>
  requestApi({ navigate, url: '/api/transactions/get_csv', params });
