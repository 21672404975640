import { requestApi } from 'api/Api';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ia, mString } from '../../../../lib/helpers/utility';
import LightboxWrapper from '../../../Payments/lightbox/LightboxWrapper';
import Button from '../../../shared/Buttons/Button';
import { patientAppointment } from '../../patientState';
import CheckinPaymentKiosk from './Kiosk';
import PaySuccess from './paySuccess';
import { useRecoilState } from 'recoil';
import state from '../state';
import { showAlert } from 'components/shared/Alert/Alert';
import Modal from 'components/shared/Modal/Modal';
import SavedCardSale from 'components/Payments/lightbox/SavedCardSale';
import PayFailure from './payFailure';
import PatientCheckinPaymentOnline from 'components/Patient/checkin/payment/Online';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const PayPrompt = ({
  amount,
  invoiceToken,
  saveCard,
  billingAddress,
  setReceipt,
  onApproval,
  onCancel,
  onDeclined
}) => {
  const [appointment, setAppointment] = useState(patientAppointment);
  const [balance, setBalance] = useState(0);
  const [surcharge, setSurcharge] = useState(0);
  const [kioskAuthorization, setKioskAuthorization] = useState('');
  const user = useRecoilState(state.userState);

  const [show, setShow] = useState({
    loading: true,
    startNewTransaction: false,
    saveCard: false,
    transactionStartText: 'Pay',
    processingKind: '',
    kiosk: false,
    promptPayment: false,
    error: false,
    success: false,
    paySuccess: false,
    failure: false,
    cardsLoading: false
  });
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const navigate = useNavigate();
  const [timeoutLink, setTimeoutLink] = useState(null);

  const { updateSteps, handleBack, setDisableContinue } = useOutletContext();

  const { device } = useUIContext();

  useEffect(() => {
    setDisableContinue(show?.failure);
  }, [show]);

  useEffect(() => {
    promptPayment();
    loadSavedCards();
  }, []);

  const loadSavedCards = async () => {
    try {
      let params = { patientId: user.id };

      const onSuccess = (partRes) => {
        if (ia(partRes?.loadedCards)) {
          setCards(partRes?.loadedCards);
        }
      };

      setShow((ps) => ({ ...ps, cardsLoading: true }));
      await requestApi({ url: '/api/transactions/token/list', params, navigate, onSuccess });
      setShow((ps) => ({ ...ps, cardsLoading: false }));
    } catch (error) {
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
  };

  const promptPayment = async () => {
    try {
      setShow((ps) => ({
        ...ps,
        loading: true
      }));
      const resData = await requestApi({
        url: '/api/patient/checkin/payment/pay',
        params: {},
        navigate
      });

      setBalance(resData?.balance);
      setSurcharge(resData?.surcharge);
      setAppointment(resData?.appointment);
      setKioskAuthorization(resData?.kioskAuthorization);

      setShow((ps) => ({
        ...ps,
        kiosk: !!resData?.kiosk,
        loading: false,
        promptPayment: true,
        processingKind: resData?.processingKind,
        error: false
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentConfirmationReceived = () => {
    if (timeoutLink) {
      clearTimeout(timeoutLink);
    }
  };

  const onLightboxSuccess = async () => {
    setShow((ps) => ({ ...ps, success: true }));
    // await interimApi('/api/patient/checkin/complete', { id: appointment.id }, navigate);
    // await interimApi(
    //   '/api/hsp/billing_cost',
    //   {
    //     appointmentId: appointment?.id,
    //     type: 'billing_cost'
    //   },
    //   navigate
    // );
    // updateSteps({ formName: 'Payment', isCompleted: balance <= 0 });
  };

  const onLightboxSuccessContinue = async () => {
    setShow((ps) => ({ ...ps, success: false }));
    updateSteps({ formName: 'Payment', isCompleted: balance <= 0 });
  };

  const SuccessScreen = (props) => {
    return <PaySuccess {...props} onCancel={onLightboxSuccessContinue} continueText="Exit" />;
  };

  const payWithAlternative = () => setShow((ps) => ({ ...ps, processingKind: 'elavonLightbox' }));
  // const onError = () => setShow((ps) => ({ ...ps, error: true }));

  if (show.loading) {
    return (
      <div className="!p-4">
        <div className="flex flex-row items-center justify-center self-center">Loading...</div>
      </div>
    );
  }

  if (show.paySuccess) {
    return (
      <Modal
        isOpen={true}
        disableHeader={true}
        footer={null}
        isFooter={false}
        handleClose={onCancel}
        className="h-full w-full">
        <PaySuccess
          onCancel={onLightboxSuccessContinue}
          transactionId={show?.transactionId}
          continueText="Exit"
        />
      </Modal>
    );
  }

  if (show.failure) {
    return (
      <Modal
        isOpen={true}
        disableHeader={true}
        footer={null}
        isFooter={false}
        handleClose={onCancel}
        className="h-full w-full">
        <PayFailure
          payWithAlternative={payWithAlternative}
          promptPayment={() => {
            promptPayment();
            setShow((ps) => ({
              ...ps,
              message: '',
              messageTitle: '',
              messageKind: ''
            }));
          }}
          onCancel={onCancel}
          errorMessage={show.message}
        />
      </Modal>
    );
  }

  if (show.promptPayment) {
    switch (show.processingKind) {
      case 'elavonKioskTerminal':
        return (
          <CheckinPaymentKiosk
            payWithAlternative={payWithAlternative}
            amount={balance}
            kioskAuthorization={kioskAuthorization}
            onSuccess={onLightboxSuccess}
            onCancel={handleBack}
            handlePaymentConfirmationReceived={handlePaymentConfirmationReceived}
            promptPayment={promptPayment}
            error={show?.error}
            success={show?.success}
            onContinue={onLightboxSuccessContinue}
            cards={cards}
            setCards={setCards}
            setSelectedCard={setSelectedCard}
            showOverrides={show}
            setShowOverrides={setShow}
            // TODO: Add surcharge?
          />
        );
      case 'elavonLightbox':
        return (
          <div className="h-full w-full">
            <PatientCheckinPaymentOnline
              amount={balance}
              appointmentIdx={appointment?.id}
              saveCard={show.saveCard}
              cards={cards}
              cardsLoading={show.cardsLoading}
              setSelectedCard={setSelectedCard}
              setCards={setCards}
              setShow={setShow}
              SuccessScreen={SuccessScreen}
              onApproval={onLightboxSuccess}
              invoices={[appointment?.invoice]}
              billingAddress={{ address: '', zip: '' }}
              onFail={() => navigate('/checkin/payment')}
              onCancel={() => navigate('/checkin/payment')}
              onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
              updateAmount={(amountPaid) => setBalance((prevBalance) => prevBalance - amountPaid)}
              surcharge={surcharge}
            />
          </div>
        );
      case 'elavonSavedCard':
        return (
          <Modal
            isOpen={true}
            disableHeader={true}
            footer={null}
            isFooter={false}
            handleClose={onCancel}
            className="h-full w-full">
            <SavedCardSale
              card={selectedCard}
              amount={balance}
              invoiceToken={invoiceToken}
              invoices={[appointment?.invoice]}
              onSuccess={(tid) => setShow({ ...show, paySuccess: true, transactionId: tid })}
              onFail={() => setShow({ ...show, failure: true })}
            />
          </Modal>
        );
    }
  }

  return (
    <div className="!p-4">
      <div className="flex flex-row">
        <Button
          onClick={() => navigate(-1)}
          text="Back"
          icon="chevron-left"
          type="white"
          color="primary"
          className="mr-[1rem] h-fit rounded-md border-2 border-solid border-neutral-500"
        />

        <div className="!mb-3">
          <h3>Patient Portion</h3>
          <h3>{`Amount Due: ${mString(balance)}`}</h3>
        </div>
      </div>

      <LightboxWrapper
        amount={balance}
        appointmentId={appointment?.id}
        saveCard={show.saveCard}
        onApproval={onLightboxSuccess}
        invoices={[appointment?.invoice]}
        billingAddress={{ address: '', zip: '' }}
        onFail={() => navigate('/checkin/payment')}
        onCancel={() => navigate('/checkin/payment')}
        onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
        updateAmount={(amountPaid) => setBalance((prevBalance) => prevBalance - amountPaid)}
      />
    </div>
  );
};

export default withErrorBoundary(PayPrompt);
