import React from 'react';
import EmptyTableState from '../../EmptyTableComponent';

const EmptyMacros = ({ breadcrumb }) => {
  const onCreate = () => {
    breadcrumb.push({ title: 'Create New Text Macro', name: 'create' });
  };

  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="no-data-found" />
      <EmptyTableState.Text
        title="No Text Macros Found!"
        description="Start creating one now by clicking the button below."
      />
      <EmptyTableState.Button onClick={onCreate} text="New Text Macro" />
    </EmptyTableState>
  );
};

export default EmptyMacros;
