// import { validateSchema } from 'webpack';
import * as Yup from 'yup';

export default () =>
  Yup.object()
    .shape({
      name: Yup.string().min(3).required('Package name is required.'),
      // amount_cents: Yup.number().required('Price is required').min(0, 'Price cannot be negative.'),
      // procedures: Yup.array().of(
      //   Yup.object().shape({
      //     value: Yup.string(),
      //     total_amount_cents: Yup.number().min(
      //       0,
      //       'Service total amount must be greater than or equal to 0'
      //     ),
      //     quantity: Yup.number().when('value', {
      //       is: (value) => value && value.trim().length > 0,
      //       then: () =>
      //         Yup.number()
      //           .required('Service quantity is required')
      //           .min(1, 'Service quantity must be greater than or equal to 0'),
      //       otherwise: () =>
      //         Yup.number().min(0, 'Service quantity must be greater than or equal to 0')
      //     })
      //   })
      // ),
      // products: Yup.array().of(
      //   Yup.object().shape({
      //     value: Yup.string(),
      //     total_amount_cents: Yup.number().min(
      //       0,
      //       'Product total amount must be greater than or equal to 0'
      //     ),
      //     quantity: Yup.number().when('value', {
      //       is: (value) => value && value.trim().length > 0,
      //       then: () =>
      //         Yup.number()
      //           .required('Product quantity is required')
      //           .min(1, 'Product quantity must be greater than 0'),
      //       otherwise: () => Yup.number().min(0, 'Product quantity must be greater than 0')
      //     })
      //   })
      // )
    })
    .test('at-least-one-item', 'At least one procedure or product is required', function (value) {
      const { procedures = [], products = [] } = value;
      return procedures.length > 0 || products.length > 0;
    });
