import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';
import { pm } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import StaffMember from './StaffMember';
import SupervisorSettings from './SupervisorSettings';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSupervisorSettings, setShowSupervisorSettings] = useState(false);

  const userPermissions = useRecoilValue(permissions);

  const { id } = useParams();

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  const onSupervisorSettingsToggle = () => {
    setShowSupervisorSettings((prev) => !prev);
  };

  let options = [
    ...(pm(userPermissions, 'member.update')
      ? [{ label: 'Edit', onClick: onEditModalToggle, icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'member.update')
      ? [
          {
            label: 'Make supervisor',
            onClick: onSupervisorSettingsToggle,
            icon: 'new-user-octagon'
          }
        ]
      : [])
  ];

  return (
    <>
      <div className="flex h-full items-center justify-center">
        <Popover
          isFixed
          options={options}
          position="left"
          icon="new-context-menu-horizontal"
          className="flex flex-1 justify-center self-center"
        />
      </div>
      {showEditModal && (
        <StaffMember
          practiceId={id}
          member={data}
          handleOpen={Boolean(showEditModal)}
          onClose={onEditModalToggle}
        />
      )}
      {!!showSupervisorSettings && (
        <SupervisorSettings
          member={data}
          showModal={!!showSupervisorSettings}
          hideModal={onSupervisorSettingsToggle}
        />
      )}
    </>
  );
};

export default Actions;
