import React from 'react';
import Status from 'components/shared/Status/Status';
import { ia, optionify } from 'lib/helpers/utility';
import Actions from '../components/Actions';

export const processData = (data) => {
  if (!ia(data)) return data;

  const payers =
    (ia(data[0]?.payer) &&
      data[0]?.payer.map((rowPayer) => {
        const payer = rowPayer.payer;
        return payer ? { value: payer.id, label: payer.name } : null;
      })) ||
    [];

  const procedures =
    (ia(data[0]?.procedure) &&
      data[0].procedure.map((rowProcedure) => {
        const procedure = rowProcedure.procedure;
        return procedure ? { value: procedure.id, label: procedure.name } : null;
      })) ||
    [];

  const users = data.map((row) => {
    const user = row?.user;
    return user ? { value: row.user_id, label: `${row?.user?.f_name} ${row?.user?.l_name}` } : null;
  });

  let newFormatData = { supervisor: data[0]?.supervisor, payers, procedures, users };

  return newFormatData;
};

const keys = ['users', 'procedures', 'payers'];

export const extractIds = (data, key) => {
  if (Array.isArray(data)) {
    return data.map((row) => row.value) || [];
  }

  return keys.includes(key) ? [] : data;
};

export const getChanges = (formik, values) => {
  const changes = { supervisorId: values.supervisorId };

  const objectToSubmit = values;
  const initialValues = formik.initialValues;

  for (const key in objectToSubmit) {
    if (objectToSubmit[key] !== initialValues[key]) {
      changes[key] = extractIds(objectToSubmit[key], key);
    }
  }

  return changes;
};

export const extractNewObjectIds = (data) => {
  const extractedValues = {};
  for (const key in data) {
    extractedValues[key] = extractIds(data[key]);
  }

  return extractedValues;
};

export const getCurrentOption = (options, optionId) => {
  if (!optionId) return null;
  if (!ia(options, 0)) return null;
  return options?.find((role) => role.value === optionId);
};

export const defaultFilters = ({ roles }) => ({
  name: {
    type: 'search',
    values: null,
    title: 'Product Name',
    placeholder: 'Search name'
  },
  email: {
    type: 'search',
    values: null,
    title: 'Product Email',
    placeholder: 'Search email'
  },
  roles: {
    type: 'search',
    values: [],
    multiple: true,
    options: optionify(roles, 'name', 'id'),
    title: 'Role',
    placeholder: 'Select Role'
  }
});

export const defaultColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ data }) =>
      `${data.f_name} ${data.m_name ? `${data.m_name} ` : ''}${data.l_name}`
  },
  {
    field: 'email',
    headerName: 'Email',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'role',
    headerName: 'Role',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return (
        <div className="flex h-full items-center justify-center">
          <Status status={value} className="flex min-w-[80px] items-center justify-center" />
        </div>
      );
    }
  },
  {
    field: 'color',
    headerName: 'Color',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return (
        <div className="flex h-full items-center justify-center">
          <div className="h-8 w-8 rounded-sm border border-solid border-neutral-300 !p-1">
            <div style={{ background: value }} className="h-full w-full" />
          </div>
        </div>
      );
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
