import React from 'react';

import { formatPhoneNumber, imagePath } from '../../../../lib/helpers/utility';

const ReceiptHeader = ({ practice, transaction }) => {
  return (
    <div className="relative inline-flex w-full flex-col items-start">
      <div className="relative mb-16 flex h-[94px] w-full items-center !gap-4 rounded-[8px] bg-primary-600 !px-5">
        <div className="!mt-14 w-fit overflow-hidden rounded-[24px] !border border-solid border-neutral-200 shadow-[0px_2px_19px_0px_rgba(48,95,151,0.07),0px_2px_4px_0px_rgba(48,95,151,0.02)]">
          {practice?.header_photo && (
            <img
              alt="Image"
              className="h-[112px] w-[112px] object-cover"
              src={imagePath(JSON.parse(practice?.header_photo).jpg)}
            />
          )}
        </div>

        <div className="flex flex-1 items-start justify-between !gap-4 !pl-2 !pr-1">
          <div className="inline-flex flex-col items-start gap-[4px]">
            <div className="inline-flex items-start">
              <div className="w-fit text-[12px] font-semibold leading-[16px] text-white">
                {practice?.name}
              </div>
            </div>

            <div className="inline-flex items-start ">
              <div className=" text-xxs font-500 leading-[12px] text-white">
                {formatPhoneNumber(practice?.phone)}
              </div>
            </div>

            <div className="inline-flex items-start ">
              <div className="text-xxs font-500 leading-[12px] text-white">{practice?.email}</div>
            </div>
          </div>

          <div className="flex w-[97px] items-start px-0 pb-0 pt-[2px]">
            <p className="flex-1 text-xxs font-500 leading-[12px] !text-white">
              {practice?.address && typeof practice?.address !== 'number' && practice?.address}
            </p>
          </div>

          <div className="relative rounded-[24px] bg-white px-[9px] py-[7px]">
            <p className="relative w-fit text-xs text-primary-600">
              <span className="font-700">RECEIPT</span>
              {transaction?.id && <span className="font-500">: {transaction?.id}</span>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptHeader;
