import { requestApi } from 'api/Api';
import { createInstantPacket } from 'api/InstantPacket';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useFormikContext } from 'formik';
import { ia } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatKind, getPatientFormsStatus } from '../../../../lib';
import { CustomSendModal } from '../../components/CustomSendModal/CustomSendModal';
import SendPatientFormActions from './SendPatientFormActions';

const PatientForms = ({
  data,
  kiosks,
  sendFormsToKiosk,
  selectForm,
  setStep,
  setFormModalVisible,
  formModalVisible
}) => {
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');
  const [formId, setFormId] = useState(null);
  const { values } = useFormikContext();
  const navigate = useNavigate();
  const patientFormsStatus = getPatientFormsStatus(data.patient_forms);

  const { data: patient } = usePatient({
    params: { id: values?.patient?.id },
    dependencies: [values?.patient?.id]
  });

  const patientNumbers = [
    { label: patient?.patient?.phone, value: patient?.patient?.phone },
    { label: patient?.patient?.alt_phone, value: patient?.patient?.alt_phone },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const patientEmails = [
    { label: patient?.patient?.email, value: patient?.patient?.email },
    { label: patient?.patient?.alt_email, value: patient?.patient?.alt_email },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const handleCustomSendModalClose = () => setCustomModalVisible(false);

  const sendLink = async (type, formIds, custom = null) => {
    const params = { formIds, patientId: values.user_id };

    const { token } = await createInstantPacket(navigate, params);
    if (token) {
      const params = { link: token, patientId: values.user_id, custom };
      await requestApi({
        navigate,
        url: `/api/form/instant_packet/${type}`,
        params,
        onError: () => {
          showAlert({ title: `Failed to send ${type}`, color: 'danger' });
        },
        onSuccess: () => {
          showAlert({ title: `Successfully sent ${type}` });
        }
      });
    }
  };

  const isAptDisabled = values.appointment_disabled;

  return (
    <>
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-[6px]">
          <Icon
            icon={
              patientFormsStatus === 'success'
                ? 'new-check'
                : patientFormsStatus === 'warning'
                  ? 'new-partially-completed'
                  : 'new-close-v2'
            }
            color={patientFormsStatus}
            stroke={patientFormsStatus !== 'warning'}
            size={20}
            className="cursor-pointer"
          />
          <h4 className="text-sm font-500 text-neutral-800">Patient Forms</h4>
        </div>

        <SendPatientFormActions
          {...{
            data,
            formModalVisible,
            sendFormsToKiosk,
            sendLink,
            setFormModalVisible,
            kiosks
          }}
        />
      </div>
      {ia(data?.patient_forms) && (
        <div className="ml-6 mt-[2px]">
          {data?.patient_forms?.map((patient_form, idx) => (
            <div key={idx} className="mb-[2px] flex items-center justify-between">
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-[6px] ">
                  <Icon
                    icon={patient_form.completed ? 'new-check' : 'new-close-v2'}
                    color={patient_form.completed ? 'success' : 'danger'}
                    stroke={true}
                    size={20}
                    className="cursor-pointer"
                  />
                  <p className="text-sm italic text-neutral-800">{formatKind(patient_form.name)}</p>
                </div>
                <Popover
                  isFixed
                  panelClassName="z-[200]"
                  icon="three-dots"
                  iconClassName="rotate-90"
                  buttonClassName="!min-h-[20px] !min-w-[20px] !rounded-[3px]"
                  options={[
                    {
                      label: 'View form',
                      onClick: () => {
                        if (patient_form.form_id) {
                          selectForm(patient_form);
                        } else {
                          setStep(patient_form);
                          setFormModalVisible({ ...formModalVisible, [patient_form.kind]: true });
                        }
                      },
                      icon: 'new-document-preview',
                      color: 'primary',
                      size: 20
                    },
                    ...((patient_form.form_id || patient_form.kind) !== 'payment'
                      ? [
                          {
                            label: 'Send Form to',
                            onClick: () => {},
                            icon: 'new-clinical-narrative',
                            color: 'primary',
                            size: 20,
                            disabled: isAptDisabled,
                            children: [
                              ia(kiosks) && kiosks.length > 1
                                ? {
                                    label: 'Kiosk',
                                    icon: 'new-kiosk',
                                    color: 'primary',
                                    children: kiosks.map((kiosk) => ({
                                      label: kiosk.name,
                                      onClick: () =>
                                        sendFormsToKiosk(
                                          [patient_form.form_id || patient_form.kind],
                                          kiosk.id
                                        ),
                                      icon: 'kiosk-nav',
                                      color: 'primary'
                                    }))
                                  }
                                : ia(kiosks) && {
                                    label: 'Kiosk',
                                    icon: 'new-kiosk',
                                    color: 'primary',
                                    onClick: () =>
                                      sendFormsToKiosk(
                                        [patient_form.form_id || patient_form.kind],
                                        kiosks[0].id
                                      )
                                  },
                              {
                                label: 'Email',
                                icon: 'new-email-blue',
                                color: 'primary',
                                children: patientEmails.map((email) => ({
                                  label: email.label,
                                  onClick: () => {
                                    if (email.value === 'Custom') {
                                      setCustomModalVisible(true);
                                      setCustomContactType('email');
                                      setFormId(patient_form.form_id || patient_form.kind);
                                    } else {
                                      sendLink(
                                        'text',
                                        [patient_form.form_id || patient_form.kind],
                                        email.value
                                      );
                                    }
                                  }
                                }))
                              },
                              {
                                label: 'Text Message',
                                icon: 'text-message',
                                color: 'primary',
                                children: patientNumbers.map((number) => ({
                                  label: number.label,
                                  onClick: () => {
                                    if (number.value === 'Custom') {
                                      setCustomModalVisible(true);
                                      setCustomContactType('text');
                                      setFormId(patient_form.form_id || patient_form.kind);
                                    } else {
                                      sendLink(
                                        'text',
                                        [patient_form.form_id || patient_form.kind],
                                        number.value
                                      );
                                    }
                                  }
                                }))
                              }
                            ].filter(Boolean)
                          }
                        ]
                      : [])
                  ]}
                  optionIconSize={18}
                  rerender={() => {}}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {customModalVisible ? (
        <CustomSendModal
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
          sendLink={sendLink}
          formId={formId}
        />
      ) : null}
    </>
  );
};

export default PatientForms;
