const { formatDate, ia, isEmpty, stripHtmlTags } = require('lib/helpers/utility');

exports.createSurgicalHistoryNarrative = (surgicalHistory) => {
  if (surgicalHistory?.noDetails || !ia(surgicalHistory?.row)) {
    return 'No Known Surgical History';
  }

  const narrativeLines = surgicalHistory?.row.map((surgery) => {
    let line = '';

    if (surgery?.surgery?.value) {
      line += `The patient underwent ${surgery.surgery.value}`;
    } else {
      line += 'An unspecified surgical procedure was performed';
    }

    if (surgery?.date) {
      line += ` on ${formatDate(surgery.date, undefined, true)}`;
    } else {
      line += '. The date of the procedure is not specified';
    }

    if (surgery?.surgeon?.label) {
      line += `. The procedure was performed by Dr. ${surgery.surgeon.label}`;
    } else {
      line += ". The surgeon's name is not specified.";
    }

    if (surgery?.note) {
      line += '\n';
      line += `${surgery.note}`;
    }

    return line;
  });

  return narrativeLines?.join('\n\n');
};

exports.createSurgicalHistoryNarrativeV2 = (surgicalHistory) => {
  if (!ia(surgicalHistory)) {
    return '<p>No Known Surgical History</p>';
  }

  const narrativeLines = surgicalHistory.map((surgery) => {
    let line = '<p>';

    if (surgery?.surgery?.value) {
      line += `The patient underwent ${surgery.surgery.value}`;
    } else {
      line += 'An unspecified surgical procedure was performed';
    }

    if (surgery?.date) {
      line += ` on ${formatDate(surgery.date, undefined, true)}`;
    } else {
      line += '. The date of the procedure is not specified';
    }

    if (surgery?.surgeon?.label) {
      line += `. The procedure was performed by Dr. ${surgery.surgeon.label}`;
    } else {
      line += ". The surgeon's name is not specified.";
    }

    line += '</p>'; // Close the main paragraph

    if (!isEmpty(stripHtmlTags(surgery?.note))) {
      line += `<p>Note: ${surgery.note}</p>`;
    }

    return line;
  });

  return narrativeLines.join('<br>');
};

// exports.createSurgicalHistoryNarrativeV2 = (surgicalHistory) => {
//   if (!ia(surgicalHistory)) {
//     return 'No surgical procedures recorded.';
//   }

//   const narrativeLines = surgicalHistory.map((surgery) => {
//     let line = '';

//     if (surgery?.surgery?.value) {
//       line += `The patient underwent ${surgery.surgery.value}`;
//     } else {
//       line += 'An unspecified surgical procedure was performed';
//     }

//     if (surgery?.date) {
//       line += ` on ${formatDate(surgery.date, undefined, true)}`;
//     } else {
//       line += '. The date of the procedure is not specified';
//     }

//     if (surgery?.surgeon?.label) {
//       line += `. The procedure was performed by Dr. ${surgery.surgeon.label}`;
//     } else {
//       line += ". The surgeon's name is not specified.";
//     }

//     if (!isEmpty(stripHtmlTags(surgery?.note))) {
//       line += `\n`;
//       line += `${cleanAndSetHTML(surgery.note)}`;
//     }

//     return line;
//   });

//   return narrativeLines?.join('\n\n');
// };
