import React, { useEffect } from 'react';

import { ia } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';
import InputOptions from '../InputOptions/InputOptions';
import ActionButton from '../shared/ActionButton';
import Header from '../shared/Header';
import Hint from '../shared/Hint';

import RightComponents from './components/RightComponents';
import { getCommonInputProps } from './lib/utils';

/**
 * @param {import('./lib/types').InputProps} props
 */

const Input = (props) => {
  const {
    focusOnMount,
    forwardedRef,
    options,
    units,
    currentUnit,
    unitPanelClassName,
    onUnitChange,
    hint,
    hintIcon,
    error,
    isHeader,
    headerClassName,
    label,
    labelClassName,
    transcribing,
    additionalComponents,
    headerIcon,
    tooltip,
    isOptional,
    disabled,
    required,
    value,
    onChange,
    setDisabled,
    name,
    id,
    actionButtonText,
    actionButtonIcon,
    actionButtonClick
  } = props;

  // Get common props
  const { className, style, inputProps, wrapperProps, iconProps, shouldShowNumberControls } =
    getCommonInputProps(props);

  useEffect(() => {
    if (focusOnMount && forwardedRef?.current) {
      forwardedRef.current.focus();
    }
  }, []);

  const rightComponentsProps = {
    ...props,
    rightIcon: iconProps.rightIcon,
    rightIconClick: iconProps.rightIconClick
  };

  return (
    <div className={className} style={style}>
      <Header
        isHeader={isHeader}
        headerClassName={headerClassName}
        label={label}
        labelClassName={labelClassName}
        transcribing={transcribing}
        additionalComponents={additionalComponents}
        icon={headerIcon}
        options={options}
        tooltip={tooltip}
        isOptional={isOptional}
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        setDisabled={setDisabled}
        name={name}
        id={id}
      />
      <label {...wrapperProps} htmlFor={id}>
        <div className="flex grow items-center justify-between gap-x-2">
          {(iconProps.leftIcon || shouldShowNumberControls) && (
            <Icon htmlFor={id} icon={iconProps.leftIcon} onClick={iconProps.leftIconClick} />
          )}
          <input {...inputProps} />
          {ia(units) && (
            <InputOptions
              options={units}
              currentOption={currentUnit}
              onClickOption={onUnitChange}
              panelClassName={unitPanelClassName}
            />
          )}
        </div>
        <RightComponents {...rightComponentsProps} />
        <ActionButton text={actionButtonText} icon={actionButtonIcon} onClick={actionButtonClick} />
      </label>
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && <p className="pt-2 text-left text-sm text-danger-500">{error}</p>}
    </div>
  );
};

export default Input;
