import state from 'components/state';
import { getRecoil, setRecoil } from 'recoil-nexus';
import initializeUserSocket from './initializeUserSocket';
import { socket } from 'api/Socket';

export default (sessionToken, sessionClientId) => {
  try {
    const socketState = getRecoil(state.socketState);
    if (socketState?.connected || !sessionToken) {
      return;
    }
    const s = socket;
    s.connect();
    setRecoil(state.socketState, s);
    initializeUserSocket(s, sessionToken, sessionClientId);
  } catch (error) {
    console.error(error);
  }
};
