import { currentPractice } from 'components/practice/practiceState';
import state from 'components/state';
import React from 'react';
import { useRecoilValue } from 'recoil';
import Icon from '../../../../shared/Icon/Icon';
import { imagePath } from 'lib/helpers/utility';

const CoverPages = ({
  subject,
  recipientFax,
  recipientPhone,
  recipientName,
  numberOfPages,
  senderFax,
  senderName,
  date,
  note,
  values
}) => {
  const practice = useRecoilValue(currentPractice);
  const user = useRecoilValue(state.userState);
  return (
    <div
      id="cover-page"
      className="relative flex h-[1123px] w-[794px] justify-center overflow-hidden bg-white">
      <div className="relative overflow-hidden">
        <div className="flex items-center justify-between bg-white !py-4 !pl-6 !pr-4">
          {practice?.header_photo && (
            <div className="flex h-[90px] w-[140px] justify-start">
              <img
                className="!w-auto object-contain"
                src={imagePath(JSON.parse(practice?.header_photo).jpg)}
              />
            </div>
          )}
          <div className="ml-auto grid">
            <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
              {practice.name}
            </p>
            <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
              {user?.f_name + ' ' + user?.l_name}
            </p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.address}</p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.phone}</p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.email}</p>
          </div>
        </div>
        <Icon icon="header-shape-cover-page" className="w-full" size={800} />
        <div className="-mt-[200px] px-16  ">
          <h1 className="text-center text-4xl font-700 text-neutral-900">Fax</h1>
          <div className="mt-[50px] flex items-center justify-between gap-[100px]">
            {/* left section */}
            <div className="flex flex-1 gap-2 ">
              <div className="flex flex-col  gap-2">
                <label htmlFor="" className="font-700 text-neutral-900">
                  To:
                </label>
                <label htmlFor="" className="font-700 text-neutral-900">
                  Fax:
                </label>
                <label htmlFor="" className="font-700 text-neutral-900">
                  Phone:
                </label>
                {/* <label htmlFor="" className="text-neutral-900 font-700">
                  No.Page:
                </label> */}
                <label htmlFor="" className="font-700 text-neutral-900">
                  Subject:
                </label>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="text-neutral-900">
                  {recipientName || 'N/A'}
                </label>
                <label htmlFor="" className="text-neutral-900">
                  {recipientFax || 'N/A'}
                </label>
                <label htmlFor="" className="text-neutral-900">
                  {recipientPhone || 'N/A'}
                </label>
                {/* <label htmlFor="" className="text-neutral-900">
                  {numberOfPages}
                </label> */}
                <label htmlFor="" className="text-neutral-900">
                  {subject || 'N/A'}
                </label>
              </div>
            </div>
            {/* right section */}
            <div className="flex flex-1 gap-2 ">
              <div className="flex flex-col  gap-2">
                <label htmlFor="" className="font-700 text-neutral-900">
                  From:
                </label>
                <label htmlFor="" className="font-700 text-neutral-900">
                  Fax:
                </label>
                <label htmlFor="" className="font-700 text-neutral-900">
                  Phone:
                </label>
                <label htmlFor="" className="font-700 text-neutral-900">
                  Date:
                </label>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="text-neutral-900">
                  {senderName || 'N/A'}
                </label>
                <label htmlFor="" className="text-neutral-900">
                  {senderFax || 'N/A'}
                </label>
                <label htmlFor="" className="text-neutral-900">
                  {user.phone || 'N/A'}
                </label>
                <label htmlFor="" className="text-neutral-900">
                  {date}
                </label>
              </div>
            </div>
          </div>
          <p className="!mt-2 max-w-[666px] font-700 text-neutral-900">
            Note: <p className="inline font-400 text-neutral-900">{note || 'N/A'}</p>
          </p>
          <div className="mt-12 flex max-w-[666px] flex-col gap-6">
            <p className=" text-neutral-900">
              <p className="inline font-600 text-neutral-900">Confidentiality Notice:</p> This
              electronic fax transmission contains confidential health information that is legally
              privileged. This information is intended only for the use of the individual or entity
              named above. If you are not the intended recipient, you are hereby notified that any
              disclosure, copying, distribution, or action taken in reliance on the contents of this
              fax is strictly prohibited. If you have received this fax in error, please notify the
              sender immediately and delete the original message and any attachments. This fax is
              transmitted in full compliance with the Health Insurance Portability and
              Accountability Act (HIPAA) regulations, ensuring the secure and private handling of
              patient health information.
            </p>
          </div>
        </div>
        <Icon icon="first-header-shape-cover-page" className="absolute bottom-0 right-0" />
        <Icon icon="second-header-shape-cover-page" className="absolute bottom-0 right-0" />
      </div>
    </div>
  );
};

export default CoverPages;
