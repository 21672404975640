import cs from 'classnames';
import ProviderWeightLoss from 'components/shared/Forms/Custom/Provider/WeightLoss/ProviderWeightLoss';
import React from 'react';

const WeightLossAdministrationSection = ({
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = [],
  isResizable
}) => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div
        className={cs(
          'h-[calc(100%-256px)] text-sm text-neutral-600 duration-200',
          !isResizable && '!h-[calc(100%-210px)]'
        )}>
        <ProviderWeightLoss
          isResizable={isResizable}
          formId={formId}
          defaultValue={defaultValue}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          setCurrentFormData={setCurrentFormData}
          options={options}
        />
      </div>
    </div>
  );
};

export default WeightLossAdministrationSection;
