import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { ia, mString } from '../../../lib/helpers/utility';
import { showAlert } from '../Alert/Alert';
import Icon from '../Icon/Icon';
import InputOptions from '../InputOptions/InputOptions';

const nfs = (s) => (s ? (parseFloat(s) * 100).toFixed(0) : 0);

export default function ({
  value,
  onValueChange,
  className,
  style,
  id,
  prefix = '$',
  disabled,
  label,
  error,
  parentClassName,
  rightIcon,
  wrapperClassName,
  onFocus = () => {},
  onBlur = () => {},
  maxValue,
  minValue,
  required,
  allowDecimals = true,
  decimalSeparator = '.',
  suffix,
  units,
  onUnitChange,
  currentUnit,
  hint,
  optionPosition,
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState('0.00');

  useEffect(() => {
    if (displayValue === '0.00' || !isFocused) {
      setDisplayValue(value ? (value / 100).toFixed(2) : '0.00');
    }
  }, [value]);

  const onValueChangeHandler = (v) => {
    const newValue = nfs(v);

    if (
      newValue >= 0 &&
      (minValue === undefined || newValue >= minValue) &&
      (maxValue === undefined || newValue <= maxValue)
    ) {
      setDisplayValue(v ?? '0.00');
      onValueChange(newValue);
    } else {
      let errorMessage = 'Please enter a valid positive amount';

      if (minValue !== undefined && maxValue !== undefined) {
        errorMessage += ` between ${mString(minValue)} and ${mString(maxValue)}.`;
      } else if (minValue !== undefined) {
        errorMessage += ` greater than or equal to ${mString(minValue)}.`;
      } else if (maxValue !== undefined) {
        errorMessage += ` less than or equal to ${
          suffix === '%' ? maxValue / 100 + '%' : mString(maxValue)
        }.`;
      }

      showAlert({ color: 'danger', message: errorMessage, duration: 3000 });
    }
  };

  return (
    <div className={parentClassName}>
      {label && (
        <div className="flex items-center gap-x-1">
          <label
            className={cs('mb-2 text-sm font-500', disabled && '!text-neutral-400')}
            htmlFor={id}>
            {label}
          </label>

          {required && <span className="text-danger-500">*</span>}
        </div>
      )}

      <label
        className={cs(
          'relative !m-0 flex h-10 items-center justify-between gap-x-2 rounded-md border border-solid border-neutral-200 bg-white !px-3 py-[10px]  focus-within:outline focus-within:!outline-2',
          error
            ? '!border-danger-300 focus-within:!outline-danger-100'
            : 'focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
          disabled && '!bg-neutral-50',
          wrapperClassName
        )}
        htmlFor={id}>
        <CurrencyInput
          data-qa={`currency-input-${id}`}
          id={id}
          style={style}
          prefix={prefix}
          suffix={suffix}
          decimalScale={2}
          disabled={disabled}
          allowDecimals={allowDecimals}
          decimalSeparator={decimalSeparator}
          value={displayValue}
          className={cs(
            'w-full !border-none bg-transparent !p-0 text-sm leading-5 text-neutral-900 !outline-none placeholder:text-neutral-500',
            error ? 'caret-danger-500' : 'caret-primary-500',
            disabled && 'cursor-not-allowed  !text-neutral-500',
            className
          )}
          onValueChange={onValueChangeHandler}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            const newValue = +nfs(displayValue);
            onBlur(newValue);
            setIsFocused(false);
          }}
          {...rest}
        />

        {rightIcon && (
          <Icon icon={rightIcon} className="absolute right-3 rounded bg-neutral-100 !p-2" />
        )}

        {ia(units) && (
          <InputOptions
            options={units}
            disabled={disabled}
            className="!h-5 text-xs"
            position={optionPosition}
            currentOption={currentUnit}
            onClickOption={onUnitChange}
          />
        )}
      </label>

      {hint && !error && <p className={cs('!pt-1 text-xs text-neutral-600')}>{hint}</p>}
      {error && <p className="!pt-2 text-sm text-danger-500">{error}</p>}
    </div>
  );
}
