import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { ia } from '../../../../lib/helpers/utility';
import Payments from '../../../Payments/Payments';
import Tabs from '../../../shared/Tabs/NewTabs';
import state from '../../../state';
import PatientBalanceModal from '../Balance/PatientBalanceModal';
import { useQueryClient } from '@tanstack/react-query';
import { PatientBalanceContextProvider } from 'lib/context/PatientBalanceContext/PatientBalanceContextProvider';
import { usePatientBalance } from 'lib/hooks/queries/billing/usePatientBalance';
import Allowed from 'components/shared/Permissions/Allowed';

const LIMIT = 25;

const tabsData = [
  {
    label: `Transactions`,
    path: '',
    end: true
  },
  {
    label: `Superbills`,
    path: 'superbill',
    permission: 'superbill.read'
  },
  {
    label: `Claims`,
    path: 'claims'
  },
  { label: 'Ledger', path: 'ledger', permission: 'payments.read' },
  {
    label: `Invoices`,
    path: 'invoices',
    permission: 'invoicing.read'
  }
];

export default function Billing({ loadedPatientId, appointmentId }) {
  const queryClient = useQueryClient();

  const { patient } = useOutletContext();

  const [transactions, setTransactions] = useState([]);
  const [patientBalance, setPatientBalance] = useState(0);

  const [show, setShow] = useState({
    transactions: true,
    canLoadMoreTransactions: true
  });
  const navigate = useNavigate();
  const [showNewPayment, setShowNewPayment] = useState(false);
  const { practice_id } = useRecoilValue(state.permissions);
  const { id } = useParams();

  const patientId = patient?.id || loadedPatientId;

  const {
    data,
    isFetching: isPatientBalanceFetching,
    loading: isPatientBalanceLoading
  } = usePatientBalance({
    params: {
      patientId
    },
    dependencies: [patientId]
  });

  useEffect(() => {
    loadTransactions();
  }, [patientId]);

  useEffect(() => {
    getPatientBalance();
  }, [data?.patientBalance]);

  const hideNewPayment = () => {
    setShowNewPayment(false);
  };

  const loadTransactions = async (p) => {
    try {
      const params = {
        patientId,
        offset: transactions.length,
        limit: LIMIT
      };
      if (!isNaN(p?.offset)) {
        params.offset = p?.offset;
      }
      if (p?.limit) {
        params.limit = p?.limit;
      }
      const resData = await requestApi({ url: `/api/transactions/list`, navigate, params });
      const { code, redirect, error, transactions: loadedTransactions } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          if (ia(loadedTransactions)) {
            if (!p?.limit && loadedTransactions.length < LIMIT) {
              show.canLoadMoreTransactions;
            }
            setTransactions((ps) =>
              _.uniqBy([...ps, ...loadedTransactions], (it) => it.id).sort((a, b) => b.id - a.id)
            );
          } else {
            // setTransactions([]);
            setShow((ps) => ({ ...ps, transactions: false }));
          }
          break;

        default:
          toast.error(error || `An unexpected code has been encountered. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  const getPatientBalance = async () => {
    if (Array.isArray(data?.patientBalance) && data?.patientBalance.length > 0) {
      setPatientBalance(data?.patientBalance[0].amount);
    }
  };

  const addToBalance = (v) => {
    setPatientBalance(patientBalance + v);
    loadTransactions({ offset: 0, limit: 2 });
  };

  const onPaymentSuccess = () => {
    queryClient.refetchQueries({ queryKey: ['patientInvoices'] });
    queryClient.invalidateQueries(['transactions']);
  };

  return (
    <Allowed
      requiredPermissions="billing.read"
      customMessage={`You don't have necessary permissions to view this page.`}
      showMessage>
      <div className="flex flex-wrap items-center justify-between gap-4 border-0 !border-b !border-solid !border-neutral-100 bg-white !pr-4">
        <Tabs tabsData={tabsData} theme="secondary" className="w-full items-center border-0" />
        <div className="flex items-center justify-end !gap-4 sm:!mb-4 sm:w-full md:!mb-4 md:w-full">
          <Payments
            practiceId={practice_id}
            amount={patientBalance > 0 ? patientBalance : 0}
            patientId={id}
            appointmentId={appointmentId}
            updateBalance={addToBalance}
            showNewPayment={showNewPayment}
            hideNewPayment={hideNewPayment}
            onSuccess={onPaymentSuccess}
          />
          <PatientBalanceContextProvider>
            <PatientBalanceModal
              patientId={patientId}
              patientBalance={patientBalance}
              balanceLoading={isPatientBalanceFetching || isPatientBalanceLoading}
              setPatientBalance={setPatientBalance}
            />
          </PatientBalanceContextProvider>
        </div>
      </div>

      <div className="flex h-full flex-col overflow-auto bg-white !pt-3">
        <Outlet context={{ id, patient, loadTransactions, transactions, show, setTransactions }} />
      </div>
    </Allowed>
  );
}
