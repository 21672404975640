import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import numOrDefault from 'lib/helpers/numOrDefault';
import { formatLargeNumber, hasObjectTrueValues } from 'lib/helpers/utility';
import { isNumber } from 'lodash';
import React from 'react';
const CommunicationsUsage = (props) => {
  const { dashboardData } = props;
  const item = { name: 'Communications Usage' };
  const prices = JSON.parse(process.env.REACT_APP_PRICES);
  const chartData = [
    {
      name: 'Succeeded Emails',
      value: parseInt(dashboardData?.count?.messages?.email_success || 0),
      fill: '#5090DC'
    },
    {
      name: 'Failed Emails',
      value: parseInt(dashboardData?.count?.messages?.email_fail || 0),
      fill: '#FF0000'
    },
    {
      name: 'Faxes',
      value: parseInt(dashboardData?.count?.messages?.fax_count || 0),
      fill: '#FDDA0D'
    },
    {
      name: 'Succeeded Texts',
      value: parseInt(dashboardData?.count?.messages?.sms_success || 0),
      fill: '#1DBF73'
    },
    {
      name: 'Failed Texts',
      value: parseInt(dashboardData?.count?.messages?.sms_fail || 0),
      fill: '#FF0000'
    },
    {
      name: 'Web Messages',
      value: parseInt(dashboardData?.count?.messages?.web || 0),
      fill: '#FFA500'
    }
  ];
  const segmented = dashboardData?.count?.sms_segments;
  const faxPages = dashboardData?.count?.messages;
  const options = {
    data: chartData,
    width: 200,
    height: 200,
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'name',
        angleKey: 'value',
        innerRadiusRatio: 0.7,
        calloutLabel: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: false
    }
  };

  return (
    <div>
      <WidgetHeader icon="new-messagesv2" item={item} hasPlus={false} {...props} />
      {!hasObjectTrueValues(dashboardData?.count?.messages) ? (
        <NotFound
          icon="new-illustration-8"
          iconClassName="!pb-3"
          title="No Communication Report found!"
        />
      ) : (
        <div className="flex items-center gap-4 !p-6">
          <AGChart options={options} />
          <div className="!grid w-full auto-rows-max grid-cols-[repeat(auto-fill,minmax(110px,1fr))] !gap-3">
            {options?.data?.map((item, index) => {
              let displayValue = item.value;
              let display = '';
              if (item.name === 'Succeeded Texts') {
                display = ` / $${numOrDefault(segmented?.success * prices.sms).toFixed(2) || '0'}`;
              } else if (item.name === 'Faxes') {
                display = ` /$${numOrDefault(faxPages?.fax_pages * prices.fax_pages).toFixed(2) || '0.00'}`;
              }

              return (
                <div className="flex  flex-col flex-wrap gap-2" key={index}>
                  <div className="flex items-center justify-center gap-2">
                    <div
                      className="flex h-3 w-1 flex-wrap items-center justify-center rounded-lg"
                      style={{ backgroundColor: item.fill }}></div>
                    <p className="text-[12px] text-neutral-500">{item.name}</p>
                  </div>
                  <div
                    className="!ml-1 flex items-center justify-center text-lg font-semibold text-primary-900"
                    style={{ whiteSpace: 'nowrap' }}>
                    {isNumber(item.value) && (
                      <p className="text-[18px]  text-primary-900">
                        {formatLargeNumber(displayValue)}
                        {display && (
                          <span className="text-[14px] font-medium text-primary-900">
                            {display}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunicationsUsage;
