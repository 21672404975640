import React, { useEffect, useState, useRef } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { ia, roundToNearestHalf, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import './ProgressBar.scss';
import { useFormikContext } from 'formik';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { completeAppointment } from 'api/Appointment';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { constant, getProgressBarOptions } from './lib';

const ProgressBar = ({ focusItem }) => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const { values, initialValues, setFieldValue } = useFormikContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const appointmentStatus =
    appointmentStatuses.find((item) => values.status === item.value)?.label || values.status;
  const formattedCurrStatus = spaceToKebabCase(appointmentStatus);
  const appointmentCompleted = values.completed;
  const [itemWidth, setItemWidth] = useState(0);
  const buttonRef = useRef(null);

  useEffect(() => {
    const updateItemWidth = () => {
      if (buttonRef.current) {
        setItemWidth(buttonRef.current.offsetWidth + 1);
      }
    };
    updateItemWidth();
    window.addEventListener('resize', updateItemWidth);
    return () => {
      window.removeEventListener('resize', updateItemWidth);
    };
  }, [values.timeline_bar]);

  useEffect(() => {
    if (values.status === 'completed' && initialValues.status !== 'completed') {
      setConfirmationModalVisible(true);
    }
  }, [values.status]);

  const handleCompleteAppointment = async () => {
    const { code, updatedAppointment } = await completeAppointment(navigate, {
      id: values.id,
      completed: !appointmentCompleted
    });
    if (code === 0) {
      setConfirmationModalVisible(false);

      queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.id === values.id ? { ...values, ...updatedAppointment } : apt
        )
      }));

      queryClient.setQueryData(['appointmentv2', values?.id], (oldData) => {
        return {
          ...oldData,
          appointment: { ...values, ...updatedAppointment }
        };
      });
      queryClient.invalidateQueries(['apt-timeline-details']);
      await queryClient.invalidateQueries(['apt-timeline-details']);
    }
  };

  const handleClose = () => {
    setConfirmationModalVisible(false);
    setFieldValue('status', initialValues.status);
  };

  if (!ia(values.timeline_bar)) return null;

  const appointmentCancelled = values.status == 'cancelled';
  const isAptDisabled = values.appointment_disabled;

  const options = getProgressBarOptions({
    setFieldValue,
    appointmentCompleted,
    setConfirmationModalVisible,
    isAptDisabled
  });

  return (
    <div className="relative mx-3 flex grow items-center gap-3 overflow-hidden sm:order-3 sm:!mx-0 md:order-3">
      {!appointmentCancelled && (
        <span className="relative flex h-[28px] items-center overflow-hidden rounded-2xl bg-white pr-[10px]">
          <span
            className="z-20 max-w-[124px] truncate bg-white px-[10px] pr-2 text-sm font-500 capitalize leading-[22px] text-primary-600"
            title={appointmentStatus}>
            {appointmentStatus}
          </span>
          <div className="relative flex h-[16px] w-[16px] shrink-0 items-center justify-center rounded-full bg-primary-600">
            <div className="z-20 h-[10px] w-[10px] rounded-full bg-white" />
          </div>
        </span>
      )}
      <div className="relative flex grow items-center">
        {values.timeline_bar.map((status, index) => (
          <React.Fragment key={status.name}>
            <Tippy content={snakeToTitleCase(status?.label)} placement="top" className="tippy-dark">
              <button
                ref={buttonRef}
                className={cs(
                  'relative z-10 mr-[1px] flex h-[10px] min-w-[46px] grow cursor-pointer items-center justify-center border-2 border-solid border-transparent duration-150 first-of-type:rounded-l-md last-of-type:mr-0 last-of-type:rounded-r-md hover:rounded-[1px]',
                  `bg-${status.color}-400`,
                  `hover:border-${status.color}-700`
                )}
                onClick={() => focusItem(status.name)}>
                {status.status_options.find(
                  (option) => spaceToKebabCase(option) === formattedCurrStatus
                ) && (
                  <div className="pointer-events-none relative flex h-[16px] w-[16px] items-center justify-center rounded-full bg-white shadow-[0px_2px_8px_rgba(0,0,0,0.2)]">
                    <div className="h-[10px] w-[10px] rounded-full bg-primary-600" />
                    <div
                      className="absolute right-full top-1/2 z-30 -translate-y-1/2 border-0 border-t-2 border-dashed border-primary-600"
                      style={{ width: (index + 1) * itemWidth }}
                    />
                  </div>
                )}
              </button>
            </Tippy>
            {(index + 1) % 2 === 0 && index !== values.timeline_bar.length - 1 && (
              <div className="-ml-[1px] mb-[2px] h-[14px] w-[3px] rounded-[2px] bg-white shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]" />
            )}
          </React.Fragment>
        ))}
      </div>

      <Popover
        isFixed
        isDropdown
        position="left"
        text={
          appointmentCancelled
            ? 'Canceled'
            : `${roundToNearestHalf(values?.statusPercentage)}% Completed`
        }
        icon={false}
        options={options}
        optionsGroupBy="property_of"
        buttonTheme="white"
        buttonClassName="py-[2px] !px-[10px] !min-h-0 !rounded-full w-max"
        panelClassName="max-h-[300px] xl:max-h-[500px] z-[100]"
      />
      <Confirm
        handleOpen={!!confirmationModalVisible}
        handleClose={handleClose}
        handleContinue={handleCompleteAppointment}
        title={appointmentCompleted ? 'Incomplete Appointment?' : 'Complete Appointment?'}
        icon="calendar-tick"
        message={appointmentCompleted ? constant.incompleteMessage : constant.completeMessage}
        variant="primary"
      />
    </div>
  );
};

export default withErrorBoundary(ProgressBar);
