import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import PracticeLogo from '../../../Patient/checkin/PracticeLogo';
import state from '../../../Patient/checkin/state';
import { patientAppointment } from '../../../Patient/patientState';
import { AlertContent } from '../../Alert/Alert';
import Button from '../../Buttons/Button';
import Checkbox from '../../Checkbox/Checkbox';
import Icon from '../../Icon/Icon';
import SharedInput from '../../Input/Input';
import DatePopover from '../../Popovers/Date/DatePopover';
import Switch from '../../Switch/Switch';
import Spinner from '../../spinner/spinner';
import './HipaaForm.scss';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import { ia } from 'lib/helpers/utility';
import { useStates } from 'lib/hooks/queries/useStates';

const Input = (props) => {
  if (props.completed) {
    return <div>{props.value}</div>;
  } else {
    return <SharedInput {...props} />;
  }
};

/* // comment to enable local pdf export 
import * as filestack from 'filestack-js';
import { jsPDF } from 'jspdf';
import { ia, iaRa } from '../../../../lib/helpers/utility';
import domtoimage from 'dom-to-image-more';

const createElement = (htmlStr) => {
  var frag = document.createDocumentFragment(),
    temp = document.createElement('div');
  temp.innerHTML = htmlStr;
  while (temp.firstChild) {
    frag.appendChild(temp.firstChild);
  }
  return frag;
};
// */

export default function HipaaForm({ practice, patient, callback, disabled }) {
  const {
    practiceLogo = null,
    practice: checkinPractice,
    updateSteps,
    setDisableContinue = () => null,
    user,
    hipaaSign,
    setHipaaSign,
    ...restOutlet
  } = useOutletContext() || {};

  const [show, setShow] = useState({
    loading: false,
    error: '',
    completed: false,
    showSignature: false
  });
  const [signed, setSigned] = useState(hipaaSign || false);
  const [signature, setSignature] = useState(hipaaSign || null);
  const sigPad = useRef();
  const divToPrint = useRef();
  const fullFormRef = useRef();
  const loadedAppointmentId = useRecoilValue(patientAppointment);
  const [signatureDate, setSignatureDate] = useState(moment().format('MM/DD/YYYY'));
  const [selections, setSelections] = useState({
    sensInfoDisclosure: true
  });

  const { data: statesData } = useStates();
  console.log({ statesData });

  useEffect(() => {
    if (user && statesData) {
      setSelections((ps) => ({
        ...ps,
        dob: moment(user?.dob).format('MM/DD/YYYY') || null,
        ptName: user?.p_name || user?.f_name + ' ' + user?.l_name,
        addr: user?.address_object?.address_ln_1 || null,
        city: user?.address_object?.city || null,
        state:
          statesData?.states?.find((state) => state?.id == user?.address_object?.state)?.name ||
          null,
        zip: user?.address_object?.zip || null
      }));
    }
  }, [user, statesData]);

  const { device } = useUIContext();

  const navigate = useNavigate();

  useEffect(() => {
    setDisableContinue(!!!signature);

    return () => {
      setDisableContinue(false);
    };
  }, [signature]);

  useEffect(() => {
    if (hipaaSign && sigPad.current) {
      sigPad.current.fromDataURL(hipaaSign); // Draw the saved signature
    }
  }, []);

  useEffect(() => {
    if (restOutlet?.stepObject && restOutlet?.stepObject?.hipaaFormsComplete) {
      setShow((ps) => ({ ...ps, completed: restOutlet.stepObject.hipaaFormsComplete }));
      setSignatureDate(moment(restOutlet.stepObject?.hipaaDate).format('MM/DD/YYYY'));
    }
  }, [restOutlet.stepObject]);

  const trimSignature = () => {
    setShow((ps) => ({ ...ps, signatureRequired: false }));
    if (sigPad?.current?.getTrimmedCanvas) {
      let value = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
      value && restOutlet?.setDisableContinue && restOutlet.setDisableContinue(false);
      setSigned(true);
      setSignature(value);
      setHipaaSign(sigPad.current.getCanvas().toDataURL('image/png'));
    }
  };

  const clearSignature = () => {
    restOutlet?.setDisableContinue && restOutlet.setDisableContinue(true);
    setSignature(null);
    setHipaaSign(null);
    sigPad.current.clear();
  };

  const printDocument = async () => {
    if (!signed) {
      if (callback) callback();
      return true;
    }
    if (!fullFormRef?.current || !sigPad?.current) {
      return;
    }
    let sigImgData = '';
    try {
      setShow((ps) => ({ ...ps, loading: true, completed: true }));
      trimSignature();
      if (sigPad?.current?.getTrimmedCanvas) {
        sigImgData = sigPad.current.getTrimmedCanvas().toDataURL('image/png', 1.0);
      }

      const hiddenElements = document.getElementsByName('hidden');
      hiddenElements.forEach((v) => v.removeAttribute('hidden'));
      const domElements = document.getElementsByName('hipaaTrackingClass');
      let domElementsArray = [];
      domElements.forEach((v, i) => {
        if (i < domElements.length) {
          domElementsArray.push(v);
        }
      });

      await savePDF({
        htmlData: encodeURIComponent(fullFormRef?.current?.innerHTML),
        signatureData: encodeURIComponent(sigImgData)
      });

      return true;
      /* // comment to enable local pdf export 
      const pdf = new jsPDF('p', 'pt', 'letter');
      pdf.setFontSize(9);
      pdf.page = 1;
      const footer = () => {
        pdf.text(
          `Myriad Health - HIPAA Authorization - ${
            practice?.name || checkinPractice?.name
          } - Page ${pdf.page} of ${domElementsArray.length}`,
          5,
          10
        ); //print number bottom right
        pdf.page++;
      };

      document.getElementById('hipaaForm').style['width'] = '750px';

      const recursiveParse = async (pdff, i) => {
        const v = domElementsArray[i];
        if (!v || i === domElements.length) {
          return;
        }
        v.style['background'] = 'white';
        const domImage = await domtoimage.toCanvas(v, {});
        // const domImage = await domtoimage.toSvg(v, {});

        if (i > 0) {
          pdff.addPage();
        }
        pdff.addImage(
          domImage,
          'canvas',
          20,
          20,
          domImage?.canvas?.width,
          domImage?.canvas?.height
        );
        footer();
        //// await pdff.addSvgAsImage(domImage, 20, 20, v.offsetWidth, v.offsetHeight);

        if (i < domElements.length) {
          await recursiveParse(pdff, i + 1);
        } else {
          // const sigImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
          const sigImage = await sigPad.current.getTrimmedCanvas();

          pdff.addImage(
            sigImage,
            'canvas',
            20,
            domImage?.canvas?.height + 20,
            sigImage?.canvas?.width,
            sigImage?.canvas?.height
          );
          //// pdff.addImage(sigImage, 20, v.offsetHeight);
        }
      };

      await recursiveParse(pdf, 0);

      pdf.save('example.pdf');

      hiddenElements.forEach((v) => v.setAttribute('hidden', 'true'));
      setShow((ps) => ({ ...ps, completed: false }));
      document.getElementById('hipaaForm').style['width'] = '100%';

      return;
      const completePdf = pdf.output('blob');
      client.upload(completePdf).then((result) => {
        if (result?.handle) {
          savePDF(result?.handle);
        }
      });
      setShow((ps) => ({ ...ps, loading: false }));
      return true;

      return;
      // */
    } catch (error) {
      console.log(error);
    }
  };

  {
    // const printDocument = () => {
    //   if (!fullFormRef?.current || !sigPad?.current) {
    //     return;
    //   }
    //   setShow((ps) => ({ ...ps, loading: true }));
    //   trimSignature();
    //   const fullFormCurrent = fullFormRef?.current;
    //   let imgData = '';
    //   if (sigPad?.current?.getTrimmedCanvas) {
    //     imgData = sigPad.current.getTrimmedCanvas().toDataURL('image/png', 1.0);
    //   }
    //   const raw_hipaaText = fullFormCurrent?.innerHTML || '';
    //   const hipaaText = raw_hipaaText
    //     .replace(/&amp;/g, '&')
    //     .replace(/<[^>\/]*>/g, '')
    //     .replace(/<[^>]*>/g, '\n')
    //     .replace(/\n\n[\n]*/g, '\n\n');
    //   const pdf = new jsPDF({ orientation: 'p', unit: 'mm', lineHeight: LINE_HEIGHT });
    //   let pages = hipaaText.length / PAGE_CHARACTERS;
    //   pdf.setFontSize(12);
    //   let wordArray = hipaaText.split(' ');
    //   let text_page = [];
    //   let new_text = '';
    //   for (let i = 0; i < wordArray.length; i++) {
    //     if (`${new_text} ${wordArray[i]}`.length > PAGE_CHARACTERS) {
    //       text_page.push(new_text);
    //       new_text = wordArray[i];
    //     } else {
    //       new_text += ` ${wordArray[i]}`;
    //     }
    //   }
    //   if (new_text.length > 0) {
    //     text_page.push(new_text);
    //   }
    //   // pdf.addImage(LOGO_SVG, 'SVG', 10, 10);
    //   pdf.text(
    //     pdf.splitTextToSize(
    //       `Myriad Health HIPAA Agreement\n\n${text_page[0]}`,
    //       LINE_WIDTH,
    //       LINE_HEIGHT
    //     ),
    //     10,
    //     10
    //   );
    //   for (let i = 1; i < pages; i++) {
    //     pdf.addPage();
    //     pdf.text(pdf.splitTextToSize(text_page[i], LINE_WIDTH, LINE_HEIGHT), 10, 10);
    //   }
    //   pdf.addImage(imgData, 'JPEG', SIG_POS_X, SIG_POS_Y);
    //   // pdf.save(`${fileName}.pdf`);
    //   const completePdf = pdf.output('blob');
    //   client.upload(completePdf).then((result) => {
    //     if (result?.handle) {
    //       savePDF(result?.handle);
    //     }
    //   });
    //   setShow((ps) => ({ ...ps, loading: false }));
    // };
  }

  const savePDF = async (rawData) => {
    if (show.loading) return false;
    if (signature == null) {
      sigPad?.current?.scrollIntoView();
      return setShow((ps) => ({ ...ps, signatureRequired: true }));
    }
    setShow((ps) => ({ ...ps, loading: true }));
    let data = await requestApi({
      url: '/api/patient/checkin/upsert_hipaa_form',
      params: {
        rawData,
        patientId: patient ? patient.id : user?.id,
        practiceId: practice.id,
        appointmentId: loadedAppointmentId.id
      },
      navigate
    });
    if (data?.patient) {
      setShow((ps) => ({ ...ps, loading: false }));
      // updateSteps && updateSteps({ formName: 'HIPAA Consent', isCompleted: true });
      // updateSteps &&
      //   updateSteps({
      //     version: 2,
      //     newStatus: 'completed',
      //     meta: 'a0'
      //   });
      if (callback) callback();
      return true;
    } else {
      console.error('There was an error uploading this file. Please try again.');
      if (data?.error) {
        setShow((ps) => ({ ...ps, error: data.error, loading: false }));
      }
    }
    setShow((ps) => ({ ...ps, loading: false }));
    return false;
  };

  const handleChange = (e, key) => {
    const name = e?.target?.name || e?.target?.id;
    setSelections((ps) => ({ ...ps, [key || name]: key ? !selections[key] : e?.target?.value }));
  };

  if (show.completed) {
    return (
      <div>
        <label className="m-0 text-2xl font-600 leading-10 text-primary-500">HIPAA Consent</label>
        <div>You have already signed this consent!</div>
        {user?.authenticated && <div>Signed on: {signatureDate}</div>}
        <div
          hidden
          ref={restOutlet?.checkinButton}
          onClick={() => {
            updateSteps &&
              updateSteps({
                version: 2,
                newStatus: 'completed',
                meta: 'a1'
              });
            return true;
          }}
        />
        {show.loading && (
          <div>
            <div>Please wait, submitting...</div>
            <Spinner />
          </div>
        )}
        {show.error && <AlertContent color="warning" message={show.error} />}
      </div>
    );
  }

  return (
    <div className="HipaaForm rounded-md border-none p-6  sm:!px-4 md:rounded-none ">
      {/* <p className="mt-0">Please read and fill out these forms to continue.</p> */}
      <div ref={fullFormRef} id="hipaaForm">
        <div name="hipaaTrackingClass" id="hi">
          {/* <div
            hidden
            name="hidden"
            id="exportUnhide"
            className="flex flex-row justify-between gap-3">
            {practiceLogo && (
              <div className="w-fit">
                <PracticeLogo practiceLogo={practiceLogo} />
              </div>
            )}
            <div>
              <h6 className="text-base">
                <div>{practice?.name || checkinPractice?.name}</div>
                <div>{practice?.phone || checkinPractice?.phone}</div>
                <div>{practice?.practiceAddress || checkinPractice?.practiceAddress}</div>
              </h6>
            </div>
            <Icon icon="logo-main-full" size={200} />
          </div>
          <h5>Authorization Form</h5> */}
          <div className="grid grid-cols-2  gap-4 sm:grid-cols-1 xs:grid-cols-1">
            <Input
              completed={show.completed}
              id="ptName"
              name="ptName"
              value={selections?.ptName}
              onChange={handleChange}
              label="Patient Full Name"
              disabled={disabled}
              validateName
            />
            <DatePopover
              id="dob"
              name="dob"
              label="Date of Birth"
              value={selections?.dob}
              onChange={(value) => handleChange({ target: { value, name: 'dob' } })}
              inputClassName="!h-10"
              placementX={
                device === 'tablet' || device === 'tablet-sm'
                  ? 'right-0'
                  : device === 'mobile'
                    ? '-right-5 !p-0'
                    : 'left-0'
              }
              disabled={disabled}
            />
            <Input
              completed={show.completed}
              id="ssn"
              name="ssn"
              value={selections?.ssn}
              onChange={handleChange}
              label="Social Security Number"
              disabled={disabled}
            />
            <Input
              completed={show.completed}
              id="addr"
              name="addr"
              value={selections?.addr}
              onChange={handleChange}
              label="Address"
              disabled={disabled}
            />
            <div className="col-span-2 grid w-full grid-cols-3 gap-4 sm:grid-cols-1">
              <Input
                completed={show.completed}
                id="city"
                name="city"
                value={selections?.city}
                onChange={handleChange}
                label="City"
                disabled={disabled}
              />
              <Input
                completed={show.completed}
                id="state"
                name="state"
                value={selections?.state}
                onChange={handleChange}
                label="State"
                disabled={disabled}
              />
              <Input
                completed={show.completed}
                id="zip"
                name="zip"
                value={selections?.zip}
                onChange={handleChange}
                label="Zip"
                disabled={disabled}
              />
            </div>
          </div>
          <p className="!mt-5 text-sm text-neutral-700">
            I hereby authorize use or disclosure of protected health information about me as
            described below.
          </p>
          <ol type="1">
            <li>
              <p>
                The following specific person, class of persons, or facility is/are authorized to{' '}
                <strong>receive</strong> protected health information about me:
              </p>
              <h6>
                <div>{practice?.name || checkinPractice?.name}</div>
                <div>{practice?.phone || checkinPractice?.phone}</div>
                <div>{practice?.practiceAddress || checkinPractice?.practiceAddress}</div>
              </h6>
            </li>
            <li>
              <p>
                The following specific person, class of persons, or facility may{' '}
                <strong>use or disclosure</strong> protected health information about me:
              </p>
              <h6>
                <div>{practice?.name || checkinPractice?.name}</div>
                <div>{practice?.phone || checkinPractice?.phone}</div>
                <div>{practice?.practiceAddress || checkinPractice?.practiceAddress}</div>
              </h6>
            </li>
            <li>
              <div>
                <p>
                  The specific information that may be disclosed is (please give dates of service if
                  possible):
                </p>
                <h6 className="pt-1 leading-6 text-primary-900">
                  Entire Medical Record, including patient histories, office notes (except
                  psychotherapy notes), test results, radiology studies, films, referrals, consults,
                  billing records, insurance records, and records sent to you by other health care
                  providers.
                </h6>
              </div>
            </li>
            <li>
              <label className="!my-2 flex flex-row items-center gap-2">
                <Icon icon="new-info" />
                Disclose Information about Alcohol/Substance Abuse, HIV/AIDS, or Mental Health
              </label>
              <div className="mt-3 flex gap-5 !pt-1 sm:flex-col">
                <RadioButton
                  className="leading-5"
                  name="sensInfoDisclosure"
                  id="sensInfoDisclosure_yes"
                  value={selections?.sensInfoDisclosure}
                  isSelected={selections?.sensInfoDisclosure}
                  onChange={() =>
                    setSelections((ps) => ({
                      ...ps,
                      sensInfoDisclosure: true
                    }))
                  }
                  label=" Yes, disclose this information"
                  disabled={disabled}
                />
                <RadioButton
                  className="leading-5"
                  name="sensInfoDisclosure"
                  id="sensInfoDisclosure_no"
                  value={!selections?.sensInfoDisclosure}
                  isSelected={!selections?.sensInfoDisclosure}
                  onChange={() =>
                    setSelections((ps) => ({
                      ...ps,
                      sensInfoDisclosure: false
                    }))
                  }
                  label="No, do not disclose this information"
                  disabled={disabled}
                />
              </div>
              {!selections?.sensInfoDisclosure && (
                <div className="mt-3 h-[2px] w-full rounded-full border-2 bg-red-500" />
              )}
            </li>
            <li>
              <p>
                I understand that the information used or disclosed may be subject to re-disclosure
                by the person or class of persons or facility receiving it, and would then no longer
                be protected by federal privacy regulations, including the HIPAA Privacy Standards.
              </p>
            </li>
            <li>
              <p>
                I may revoke this authorization by notifying the appropriate disclosing party in
                writing of my desire to revoke it. However, I understand that any action already
                taken in reliance on this authorization cannot be reversed, and my revocation will
                not affect those actions.
              </p>
            </li>
            <li>
              <p>
                I understand that treatment by any party may not be conditioned upon my signing of
                this authorization.
              </p>
            </li>
            <li>
              <p>The purpose/use of the information is (check all that apply):</p>
              <div className="flex flex-col gap-2 !pt-2">
                <Checkbox
                  disabled={disabled}
                  label="At My Request."
                  isChecked={selections?.atMyRequest}
                  onChange={(e) => handleChange(e, 'atMyRequest')}
                />
                <Checkbox
                  className="w-full"
                  innerLabelClassName="!items-start"
                  inputClassName="min-w-[24px]"
                  disabled={disabled}
                  label="I authorize the using or disclosing party to communicate with me for
                        marketing purposes when they receive payment from a third party to do so. To
                        authorize the using or disclosing party to sell health information. I
                        understand that the seller will receive compensation for my health
                        information and will cease any further sales if I revoke this authorization."
                  isChecked={selections?.authorize}
                  onChange={(e) => handleChange(e, 'authorize')}
                />
                <div className="flex items-center gap-2">
                  <Checkbox
                    disabled={disabled}
                    label="Other:"
                    isChecked={selections?.other}
                    onChange={(e) => handleChange(e, 'other')}
                  />
                  <Input
                    completed={show.completed}
                    disabled={disabled}
                    value={selections?.otherValue}
                    name="otherValue"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>
            <li>
              <p>
                This authorization expires on{' '}
                <span className="font-700 text-primary-900">
                  {moment().add(1, 'year').format('MM/DD/YYYY')}
                </span>{' '}
                <strong>OR</strong> upon occurrence of the following event that relates to me or to
                the purpose of the intended use or disclosure of information about me:{' '}
              </p>
              <Input completed={show.completed} disabled={disabled} />
            </li>
          </ol>
        </div>
        <br />
        <div name="hipaaTrackingClass">
          <h4>Notice of Privacy Practices</h4>
          <p>
            This notice describes how medical information about you may be used and disclosed and
            how you can get access to this information.
          </p>
          <h5>Your Rights</h5>
          <h6>When it comes to your health information, you have certain rights.</h6>
          <p>This section explains your rights and some of our responsibilities to help you.</p>
          <h5>You have the right to:</h5>
          <h6>Get an electronic or paper copy of your medical record</h6>
          <ul>
            <li>
              You can ask to see or get an electronic or paper copy of your medical record and other
              health information we have about you. Ask us how to do this.
            </li>
            <li>
              We will provide a copy or a summary of your health information, usually within 30 days
              of your request. We may charge a reasonable, cost-based fee.
            </li>
          </ul>
          <h5>Ask us to correct your medical record</h5>
          <ul>
            <li>
              You can ask us to correct health information about you that you think is incorrect or
              incomplete. Ask us how to do this.
            </li>
            <li>
              We may say “no” to your request, but we&quot;ll tell you why in writing within 60
              days.
            </li>
          </ul>
          <h5>Request confidential communications</h5>
          <ul>
            <li>
              You can ask us to contact you in a specific way about your medical information (for
              example, home or office phone) or to send your medical information to a different
              address.
            </li>
            <li>We will say, “yes” to all reasonable requests.</li>
          </ul>
          <h5>Ask us to limit what we use or share</h5>
          <ul>
            <li>
              You can ask us not to use or share certain health information for treatment, payment,
              or our operations. We are not required to agree to your request, and we may say “no”
              if it would affect your care.
            </li>
            <li>
              If you pay for a service or healthcare item out-of-pocket in full, you can ask us not
              to share that information for the purpose of payment or our operations with your
              health insurer. We will say “yes” unless a law requires us to share that information.
            </li>
          </ul>
          <h5>Get a list of those with whom we&quot;ve shared information</h5>
          <ul>
            <li>
              You can ask for a list (accounting) of the times we&quot;ve shared (disclosed) your
              health information, for up to six years prior to the date you ask, who we shared it
              with, and why.
            </li>
            <li>
              We will include all the disclosures except for those about treatment, payment, and
              healthcare operations, and certain other disclosures (such as any you asked us to
              make). We&quot;ll provide one accounting a year for free but will charge a reasonable,
              cost-based fee if you ask for another one within 12 months.
            </li>
          </ul>
          <h5>Get a copy of this privacy notice</h5>
          <p>
            You can ask for a paper copy of this notice at any time, even if you have agreed to
            receive the notice electronically. We will provide you with a paper copy promptly.
          </p>
          <h5>Choose someone to act for you</h5>
          <p>
            If you have given someone medical power of attorney or if someone is your legal
            guardian, that person can exercise your rights and make choices about your health
            information. We will make sure the person has this authority and can act for you before
            we take any action.
          </p>
        </div>
        <div name="hipaaTrackingClass">
          <h5>File a complaint if you feel your rights are violated</h5>
          <p>
            You can file a complaint with us if you feel we have violated your rights by contacting
            our Privacy Officer.
          </p>
          <ul>
            <li>
              To file a complaint with our organization, please submit your request in writing to
              the Privacy Officer{' '}
              {`${practice?.name} ${practice?.address?.address_ln_1} ${practice?.address?.city} ${practice?.address?.stateName} ${practice?.address?.zip}`}
            </li>
            <li>
              You can file a complaint with the U.S. Department of Health and Human Services&quot;
              Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W.,
              Washington, D.C. 20201, by calling 877-696-6775, or visiting
              www.hhs.gov/ocr/privacy/hipaa/complaints/
            </li>
            <li>We will not retaliate against you for filing a complaint.</li>
          </ul>
          <h5>Your Choices</h5>
          <p>
            <strong>
              For certain health information, you can tell us your choices about what we share.
            </strong>
            If you have a clear preference for how we share your information in the situations
            described below, contact us. Tell us what you want us to do, and we will follow your
            instructions.
          </p>
          <p>In these cases, you have both the right and choice to tell us to:</p>
          <ul>
            <li>
              Share information with your family, close friends, or others involved in your care
            </li>
            <li>Share information in a disaster relief situation</li>
            <li>Include your information in a hospital directory</li>
          </ul>
          <p className="italic">
            If you are not able to tell us your preference - for example, if you are unconscious, we
            may share your information if we believe it is in your best interest to do so. We may
            also share your information when needed to lessen a serious and imminent threat to
            health or safety.
          </p>
          <p>
            In these following cases, we <strong>never</strong> share your information unless you
            give us written permission:
          </p>
          <ul>
            <li>Marketing purposes</li>
            <li>Sale of your protected health information</li>
            <li>Most sharing of psychotherapy notes</li>
          </ul>
          <p>In the case of fundraising:</p>
          <ul>
            <li>
              We may contact you for fundraising efforts, but you can tell us not to contact you
              again. We will honor your request to not contact you again.
            </li>
          </ul>
        </div>
        <div name="hipaaTrackingClass">
          <h5>Our Uses and Disclosures</h5>
          <h5>We typically use or share your health information in the following ways:</h5>
          <ul>
            <li>
              <h6>Treatment</h6>
              <p>
                We can use your health information and share it with other professionals who are
                treating you.
              </p>
              <p className="italic">
                Example: A doctor treating you for an injury asks another doctor about your overall
                health condition.
              </p>
            </li>
            <li>
              <h6>Run our organization</h6>
              <p>
                We can use and share your health information to run our practice, improve your care,
                and contact you when necessary.
              </p>
              <p className="italic">
                Example: A doctor treating you for an injury asks another doctor about your overall
                health condition.
              </p>
            </li>
            <li>
              <h6>Bill for your services</h6>
              <p>
                We can use and share your health information to bill and get payment from health
                plans or other entities.
              </p>
              <p className="italic">
                Example: We give information about you to your health insurance plan so it will pay
                for your services.
              </p>
            </li>
          </ul>
          <h5>How else can we use or share your health information?</h5>
          <p>
            We are allowed or required to share your information in other ways - usually in ways
            that contribute to the public good, such as public health and research. We have to meet
            many conditions in the law before we can share your information for these purposes.
          </p>
          <h5>Help with public health and safety issues</h5>
          <p>We can share health information about you for certain situations such as:</p>
          <ul>
            <li>Preventing disease</li>
            <li>Helping with product recalls</li>
            <li>Reporting adverse reactions to medications</li>
            <li>Reporting suspected abuse, neglect, or domestic violence</li>
            <li>Preventing or reducing a serious threat to anyone&quot;s health or safety</li>
          </ul>
          <h5>Do research</h5>
          <p>We can use or share your information for health research.</p>
          <h5>Comply with the law</h5>
          <p>
            We will share information about you if state or federal laws require it, including with
            the Department of Health and Human Services if it wants to see that we&quot;re complying
            with federal privacy law.
          </p>
          <h5>Respond to organ and tissue donation requests</h5>
          <p>We can share health information about you with organ procurement organizations.</p>
          <h5>Work with a medical examiner or funeral director</h5>
          <p>
            We can share health information with a coroner, medical examiner, or funeral director
            when an individual dies.
          </p>
        </div>
        <div name="hipaaTrackingClass">
          <h5>
            Address workers&quot; compensation, law enforcement, and other government requests
          </h5>
          <p>We can use or share health information about you:</p>
          <ul>
            <li>For workers&quot; compensation claims</li>
            <li>For law enforcement purposes or with a law enforcement official</li>
            <li>With health oversight agencies for activities authorized by law</li>
            <li>
              For special government functions such as military, national security, and presidential
              protective services
            </li>
          </ul>
          <h5>Respond to lawsuits and legal actions</h5>
          <p>
            We can share health information about you in response to a court or administrative
            order, or in response to a subpoena.
          </p>
          <h5>Our Responsibilities</h5>
          <ul>
            <li>
              We are required by law to maintain the privacy and security of your protected health
              information.
            </li>
            <li>
              We will let you know promptly if a breach occurs that may have compromised the privacy
              or security of your information.
            </li>
            <li>
              We must follow the duties and privacy practices described in this notice and give you
              a copy of it.
            </li>
            <li>
              We will not use or share your information other than as described here unless you tell
              us we can in writing. If you tell us we can, you may change your mind at any time. Let
              us know in writing if you change your mind.
            </li>
          </ul>
          {/* </div>
        <div name="hipaaTrackingClass"> */}
          <p>
            We can change the terms of this notice, and the changes will apply to all information we
            have about you. The new notice will be available upon request, on our{' '}
            <strong>website, and we will mail a copy to you.</strong>
          </p>
          <h5>Effective Date of Notice: {moment().format('MM/DD/YYYY')}</h5>
          <h5>Other Instructions for Notice</h5>
          <ul>
            <li>
              The Privacy Rule requires you to describe any state or other laws that require greater
              limits on disclosures. For example, “We will never share any substance abuse treatment
              records without your written permission.” Insert this type of information here. If no
              laws with greater limits apply to your entity, no information needs to be added.
            </li>
            <li>
              If your entity provides patients with access to their health information via the Blue
              Button protocol, you may want to insert a reference to it here.
            </li>
          </ul>
          <img
            id="hiddenSignature"
            name="hidden"
            hidden
            ref={divToPrint}
            src={signature}
            className="h-[200px] w-[500px] rounded-[10px] border-2 border-dashed border-neutral-100 bg-[#f8fdff] object-contain"
            data-qa="signature-image"
          />
        </div>
      </div>
      <div className="mt-[1rem]">
        {disabled && (
          <AlertContent
            color="warning"
            title="Viewing a Non-Editable version"
            message="This is what a signature field looks like, your edit will not be saved."
          />
        )}
        <div className="!mt-4 flex">
          <h6 className="label m-0">Date: {signatureDate}</h6>
        </div>

        <div hidden>
          <h3 className="HipaaForm__block__title">Signature:</h3>
          <div className="mt-20">
            <img src={signature} ref={divToPrint} />
          </div>
        </div>
        <div className="mt-4 flex w-full flex-col gap-3">
          <ReactSignatureCanvas
            onEnd={trimSignature}
            penColor="#004f6b"
            canvasProps={{
              // width:
              //   device === 'desktop' || device === 'laptop'
              //     ? 600
              //     : device === 'tablet'
              //       ? 710
              //       : device === 'mobile'
              //         ? 330
              //         : 422,
              // height: 350,
              className: 'SignatureCanvas !bg-primary-50 !border-primary-500 w-full aspect-[2]'
            }}
            ref={sigPad}
            data-qa="signature-Input completed={show.completed}"
          />
          <Button outlined text="Clear" onClick={clearSignature} data-qa="signature-clear" />
        </div>

        <div
          hidden
          ref={restOutlet?.checkinButton}
          onClick={show.completed ? () => true : printDocument}
        />
      </div>
      {show.loading && (
        <div className="flex w-full flex-col items-center justify-center">
          <div>Please wait, submitting...</div>
          <Spinner />
        </div>
      )}
      {show.error && <AlertContent color="warning" message={show.error} />}
    </div>
  );
}
