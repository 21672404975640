import { currentPractice } from 'components/practice/practiceState';
import { formatDateAndTimeZ, ia, iaRa, isEmpty } from 'lib/helpers/utility';
import { useDocumentSign } from 'lib/hooks/queries/documentSign/useDocumentSign';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { processData } from '../../../shared/helpers/documentSignatureHelpers';
import { clinicalNote as clinicalNoteState } from 'components/state';

const ProviderSignature = ({ customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }

  const { data: signaturesDocument } = useDocumentSign({
    params: {
      documentIds: [clinicalNote?.id],
      withRelations: { user: true }
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id,
      select: (response) => {
        if (clinicalNote?.locked) return processData(response);
        return iaRa(response?.data);
      }
    }
  });

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <div className="text-base font-medium leading-normal text-neutral-800">Signatures</div>
      <div className="flex flex-wrap !gap-3">
        {ia(signaturesDocument) ? (
          clinicalNote?.locked ? (
            <LockedSignatures signaturesDocument={signaturesDocument} />
          ) : (
            <SignatureProgress signaturesDocument={signaturesDocument} />
          )
        ) : (
          <p className="text-neutral-800">No signatures found on the clinical note</p>
        )}
      </div>
    </div>
  );
};

const LockedSignatures = ({ signaturesDocument }) => {
  const practice = useRecoilValue(currentPractice);

  return signaturesDocument?.map((row, index) => {
    return (
      <div key={index} className="!mt-3">
        <div className="!mb-2 text-base font-medium leading-normal text-neutral-800">
          {row?.role}
        </div>
        <div className=" inline-flex w-[260px] flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 py-6">
          <div className="text-center text-[32px] leading-snug text-black">
            <img className="size-32 object-contain" src={row?.signature} />
          </div>
          <div className="flex flex-col items-center justify-start gap-1.5">
            <div className="text-center text-sm leading-snug text-neutral-800">{row?.signedBy}</div>
            <div className="text-center text-sm leading-snug text-neutral-800">{row?.initials}</div>
            <div className="text-center text-sm leading-snug text-neutral-800">
              {row?.date ? formatDateAndTimeZ(row?.date, practice?.timezone) : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

const SignatureProgress = ({ signaturesDocument }) => {
  const signed = signaturesDocument
    ?.filter((row) => row?.status === 'signed')
    ?.map((row) => row?.user?.professionalFullName)
    ?.join(', ');

  const notSigned = signaturesDocument
    .filter((row) => row?.status !== 'signed')
    ?.map((row) => row?.user?.professionalFullName)
    ?.join(', ');

  if (isEmpty(signed) && isEmpty(notSigned)) return null;

  return (
    <div>
      {!isEmpty(signed) && (
        <p className="text-sm font-500">
          Providers that signed: <i>{signed}</i>
        </p>
      )}
      {!isEmpty(notSigned) && (
        <p className="text-sm font-500">
          Providers that need to sign: <i>{notSigned}</i>
        </p>
      )}
    </div>
  );
};

export default ProviderSignature;
