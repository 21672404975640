import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useProducts } from 'lib/hooks/queries/products/useProducts';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { formatDate, formatDateAndTime, mString, mapValues } from 'lib/helpers/utility';
import { currentPractice } from '../practiceState';
import { capitalize } from 'lodash';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { usePayments } from 'lib/hooks/queries/billing/usePayments';
import { getPayers } from 'api/Practice';
import { getPatients } from 'api/Patients';
import { getPractitioners } from 'api/Practitioner';
import { getPaymentsCSV } from 'api/Billing';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import Header from 'components/shared/Header/Header';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import ExportData from 'components/shared/AGTable/ExportData';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import PaymentsActions from './Payments/components/PaymentsActions';
import Allowed from 'components/shared/Permissions/Allowed';

async function searchPatients(searchTerm) {
  const patients = await getPatients(() => {}, {
    limit: 25,
    searchTerm,

    offset: 0
  });

  return patients.patients.map((p) => ({
    customLabel: (
      <div>
        {p?.fullName && <span>{p.fullName}</span>}
        {p.dob && <span className="pl-1 text-xs text-neutral-500">{formatDate(p.dob)}</span>}
      </div>
    ),
    label: p?.fullName,
    value: p?.id
  }));
}

const defaultFilters = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Payment',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  type: {
    type: 'checkbox',
    values: null,
    options: [
      { value: 'era', label: 'ERA' },
      { value: 'eob', label: 'EOB' },
      { value: 'Patient Payment', label: 'Patient Payment' }
    ],
    title: 'Payment Type',
    preview: (values) => values.map((v) => v)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  payers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Payers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  services: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Services',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  products: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Products',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  transaction_type: {
    type: 'checkbox',
    values: null,
    options: [
      { label: 'Credit Card', value: 'credit_card' },
      { label: 'Cash', value: 'cash' },
      { label: 'Check', value: 'check' },
      { label: 'Outside Financing', value: 'care_credit' },
      { label: 'Insurance Payments', value: 'era_eob' }
    ],
    title: 'Transaction Type',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  },
  status: {
    type: 'checkbox',
    values: null,
    options: [
      'completed',
      'voided',
      'refunded',
      'partially_refunded',
      'cancelled',
      'pending',
      'failed',
      'finalized'
    ],
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  }
};
export default function PatientPayments() {
  const practice = useRecoilValue(currentPractice);
  const cols = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'type',
      headerName: 'Payment Type',
      valueFormatter: ({ value }) => capitalize(value)
    },
    { field: 'patient', headerName: 'Patient' },
    { field: 'payer', headerName: 'Payer' },

    {
      field: 'created_at',
      headerName: 'Date of Payment',
      valueFormatter: ({ value }) => formatDateAndTime(value, practice.timezone)
    },

    { field: 'practitioner', headerName: 'Provider' },
    {
      field: 'check_type',
      headerName: 'Type/Check/Trace',
      valueFormatter: ({ value }) =>
        capitalize(value === 'care_credit' ? 'outside financing' : value)
    },
    { field: 'cashier', headerName: 'Created by' },
    { field: 'status', headerName: 'Status', valueFormatter: ({ value }) => capitalize(value) },
    {
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: ({ value }) => mString(value)
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: PaymentsActions,
      maxWidth: 70,
      cellClass: 'no-border',
      pinned: 'right'
    }
  ];
  return (
    <Allowed requiredPermissions="payments.read" showMessage={true} showIllustration={true}>
      <TableContextProvider
        name="beyond_billing_payments"
        cols={cols}
        pagination
        defaultFilters={defaultFilters}>
        <Table />
      </TableContextProvider>
    </Allowed>
  );
}

function Table() {
  const category = 'beyond_billing_payments';

  const navigate = useNavigate();
  const { limit, page, setPage, sort, colDefs, filters, setFilters, setTableLoading } =
    useTableContext();

  useEffect(() => {
    getAllProviders();
    getAllPayers();
  }, []);

  const { data, isLoading, isFetching } = usePayments({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });
  const statusPanelData = data?.statusPanelData;
  const payments = data?.payments;
  const count = data?.count;
  const isLoadingOrFetching = isLoading || isFetching;

  const { data: productsList } = useProducts({ params: { forUsers: true } });
  const productsOptions = useMemo(() => {
    if (productsList?.products?.length > 0) {
      return productsList.products.map((p) => ({
        label: p?.name,
        value: p?.id
      }));
    }
    return [];
  }, [productsList]);
  filters.products.options = productsOptions;

  const { data: servicesList } = useServices({});
  const serviceOptions = useMemo(() => {
    if (servicesList?.services?.length > 0) {
      return servicesList.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [servicesList]);
  filters.services.options = serviceOptions;
  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  useEffect(() => {
    if (isLoadingOrFetching) {
      setTableLoading(isLoadingOrFetching);
    } else {
      setTableLoading(false);
    }
  }, [setTableLoading, isLoadingOrFetching]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: payments,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: payments,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [payments, count, page, limit, onPageChange, statusPanelData]);

  async function getAllPayers() {
    const payers = await getPayers(navigate, {});

    setFilters((prev) => {
      return {
        ...prev,
        payers: {
          ...prev.payers,
          options: payers.payers.map((p) => ({
            label: p?.name,
            value: p?.id
          }))
        }
      };
    });
  }
  async function getAllProviders() {
    const providers = await getPractitioners({}, navigate);

    const reshapedProviders = providers?.practitioners?.map((p) => ({
      label: p?.f_name + ' ' + p?.l_name,
      value: p?.id
    }));

    setFilters((prev) => {
      return {
        ...prev,
        providers: {
          ...prev.providers,
          options: reshapedProviders
        }
      };
    });
  }

  const exportCSV = async () => {
    const data = await getPaymentsCSV(navigate, {
      filters: mapValues(filters),
      colDefs,
      sort,
      category
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'payments.csv';
    a.click();
  };

  return (
    <>
      <Header title="Payments">
        <div className="flex gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            contentStyle={{ maxHeight: '80%' }}
          />
          <ExportData
            title="Generate payments report"
            onGenerateClick={exportCSV}
            defaultFilters={defaultFilters}
            filters={filters}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col  overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={payments}
            statusBar={statusBar}
            enableRangeSelection={true}
            loadingOverlayComponent={() => <Skeleton count={24} />}
          />
        </div>
      </div>
    </>
  );
}
