import React from 'react';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Button from 'components/shared/Buttons/Button';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { defaultFilters } from '../lib';

const category = 'resources';

const HeaderTable = ({ setNewResource }) => {
  const { filters, setFilters } = useTableContext();

  return (
    <Header title="Resources">
      <div className="flex items-center gap-2">
        <DisplayButton />
        <Filter
          category={category}
          defaultFilters={defaultFilters}
          filters={filters}
          setFilters={setFilters}
          menuPortalTarget={document.body}
          btnClassName="!h-[30px]"
        />
        <Button
          text="Create Resource"
          icon="new-tag-new"
          size="small"
          iconColor="white"
          onClick={() => setNewResource(true)}
        />
      </div>
    </Header>
  );
};

export default HeaderTable;
