import { calculateAge, imagePath, formatDate } from 'lib/helpers/utility';
import React from 'react';

const PrintViewHeader = ({ practice, patient, createdAt }) => {
  return (
    <div>
      <div className="flex items-center justify-between bg-white !py-4 !pl-6 !pr-4">
        {practice?.header_photo && (
          <div className="flex h-[90px] w-[140px] justify-start">
            <img
              className="!w-auto object-contain"
              src={imagePath(JSON.parse(practice?.header_photo).jpg)}
            />
          </div>
        )}
        <div className="ml-auto grid">
          <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
            {practice?.name}
          </p>
          <p className="text-right text-xs text-[#003A4B] print:text-base">{practice?.address}</p>
          <p className="text-right text-xs text-[#003A4B] print:text-base">{practice?.phone}</p>
          <p className="text-right text-xs text-[#003A4B] print:text-base">{practice?.email}</p>
        </div>
      </div>
      <div className="flex items-center justify-between bg-[#003A4B] !py-3 !pl-6 !pr-4">
        <div className="grid">
          <p className="text-sm font-600 text-white print:text-base print:!text-white">
            {patient?.fullName}
          </p>
          <ul className="flex items-center gap-x-1">
            <li className="text-xs text-white print:text-base">{formatDate(patient?.dob)}</li>
            <span className="text-xs text-white print:text-base">•</span>
            <li className="text-xs text-white print:text-base">
              {calculateAge({ dateOfBirth: patient?.dob })} years
            </li>
            {patient?.gender && (
              <>
                <span className="text-xs text-white print:text-base">•</span>
                <li className="text-xs capitalize text-white print:text-base">{patient?.gender}</li>
              </>
            )}
          </ul>
        </div>
        <div className="grid">
          <p className="text-right text-sm font-600 text-white print:text-xl print:!text-white">
            {createdAt ? `Date of Service: ${formatDate(createdAt, practice?.timezone)}` : ''}
          </p>
          <p className="text-right text-xs text-white print:text-base print:!text-white">
            Chart ID: {patient?.id}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintViewHeader;
