const { snakeToTitleCase } = require('lib/helpers/utility');

export default (status, viewType, appointmentStatuses) => {
  if (viewType === 'status') {
    const statusLabel = appointmentStatuses.find((item) => item.value === status)?.label;
    return statusLabel || snakeToTitleCase(status);
  }

  if (viewType === 'stage' && status === 'checkin') {
    return 'Check-In';
  }

  if (status === 'cancelled') return 'Canceled';

  return snakeToTitleCase(status);
};
