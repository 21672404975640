import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import PackageForm from './PackageForm';
import { requestApi } from '../../../../../api/Api';
import {
  checkIfProcedureOrProduct,
  getFinalPackageObjShape,
  reShapeProcedures,
  reShapeProducts
} from '../lib/helpers';
import { initialValues } from '../lib/initials';
import validationSchema from '../lib/validationSchema';
import { showAlert } from '../../../../shared/Alert/Alert';
import { useProducts } from 'lib/hooks/queries/products/useProducts';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { keyChanges } from 'lib/helpers/utility';

// eslint-disable-next-line react/display-name
const NewPackage = forwardRef(({ onClose, pack }, ref) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { data: products } = useProducts({
    params: { forUsers: true },
    options: {
      select: reShapeProducts
    }
  });

  const { data: procedures } = useServices({
    params: { offset: 0, limit: null },
    options: {
      select: reShapeProcedures
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const formik = useFormik({
    initialValues: initialValues(pack),
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const packageToBeCreated = getFinalPackageObjShape(values);
      if (checkIfProcedureOrProduct(values.procedures, values.products)) {
        showAlert({
          message: 'At least one service or product is required',
          color: 'danger',
          title: 'Form error!'
        });
        return;
      }

      setSubmitting(true);
      pack
        ? await updatePackage(packageToBeCreated, resetForm)
        : await createPackage(packageToBeCreated, resetForm);
      setSubmitting(false);
    }
  });

  const createPackage = async (newPackage, resetForm) => {
    newPackage = {
      ...newPackage,
      procedures: JSON.stringify(newPackage.procedures),
      products: JSON.stringify(newPackage.products)
    };

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['packages'], refetchType: 'all' });

      resetForm();
      onClose();
      showAlert({
        title: 'Package creation',
        message: 'Package created successfully',
        color: 'success'
      });
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/create',
      params: { pack: newPackage },
      onSuccess,
      navigate,
      onError
    });
  };

  const updatePackage = async (productToBeUpdated, resetForm) => {
    const newPackage = {
      ...productToBeUpdated,
      procedures: JSON.stringify(productToBeUpdated.procedures),
      products: JSON.stringify(productToBeUpdated.products)
    };

    const packToUpdate = keyChanges(pack, newPackage);

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['packages'], refetchType: 'all' });

      showAlert({
        title: 'Package update',
        message: 'Package updated successfully',
        color: 'success'
      });
      resetForm();
      onClose();
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to update package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/update',
      params: { pack: { ...packToUpdate, id: pack.id } },
      onSuccess,
      navigate,
      onError
    });
  };

  return (
    <PackageForm
      formik={formik}
      formRef={formRef}
      updating={!!pack}
      procedures={procedures}
      products={products}
    />
  );
});

export default NewPackage;
