import { createStickyNote, deleteStickyNote } from 'api/StickyNote';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import StickyNoteButton from 'components/shared/StickyNotesButton/StickyNotesButton';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia, iaRa } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { useStickyNotes } from 'lib/hooks/queries/sticky_note/useStcikyNotes';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import practiceState from '../practiceState';
import History from './History';
import { useQueryClient } from '@tanstack/react-query';

export default function StickyNotes({
  patientId,
  className,
  customStyle,
  openNotes = false,
  setOpenNotes = () => {},
  fromAppointmentPreview = false
}) {
  const { data } = usePatient({ params: { id: patientId }, dependencies: [patientId] });
  const patient = data?.patient;
  const practice = useRecoilValue(practiceState.currentPractice);
  const [note, setNote] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { device } = useUIContext();
  const ref = useRef();
  const navigate = useNavigate();
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const queryClient = useQueryClient();

  useOutsideClick(ref, () => {
    if (!confirmDelete) {
      setShowNotes(false);
    }
  });

  const { isFetching, data: stickyData } = useStickyNotes({
    params: { patientId: patient?.id },
    dependencies: [patient?.id],
    options: {
      enabled: !!patient?.id,
      refetchOnMount: true,
      onSuccess: (data) => {
        if (data?.code !== 0) {
          showAlert({
            title: 'Sticky Note',
            message: data?.error ?? 'There was an error fetching sticky notes',
            color: 'danger'
          });
          return;
        }
      }
    }
  });

  const notes = iaRa(stickyData?.notes);

  useEffect(() => {
    if (
      practice.display_settings?.sticky_notes?.alert &&
      ia(notes) &&
      device === 'desktop' &&
      !fromAppointmentPreview
    ) {
      setShowNotes(true);
    }
  }, [notes, practice, device]);

  const handleModifyStickyNoteQuery = (notes) => {
    queryClient.setQueryData(['sticky-notes', patient?.id], (oldData) => {
      if (oldData) {
        return {
          ...oldData,
          notes
        };
      }
    });
  };

  const handleDeleteStickyNote = async (id) => {
    const { code } = await deleteStickyNote(navigate, { note_id: id });
    if (code === 0) {
      setDeleteLoading(false);
      const filteredNotes = notes.filter((note) => note.id !== id);
      handleModifyStickyNoteQuery(filteredNotes);
      setConfirmDelete(false);
    }
  };

  const handleSaveStickyNote = async () => {
    setSaveLoading(true);

    const { newStickyNote } = await createStickyNote(navigate, {
      patientId: patient?.id,
      note
    });

    const allNotes = [...iaRa(stickyData?.notes), newStickyNote];
    handleModifyStickyNoteQuery(allNotes);
    setNote('');
    setSaveLoading(false);
  };

  const getNotes = () => {
    setShowNotes(!showNotes);
  };

  return (
    <div ref={ref} className={className} id="stickyNotes">
      {patient?.id && (
        <StickyNoteButton
          onClick={getNotes}
          customStyle={customStyle}
          fromAppointmentPreview={fromAppointmentPreview}
        />
      )}
      {(showNotes || openNotes) && (
        <History
          notes={notes}
          note={note}
          setNote={setNote}
          setConfirmDelete={setConfirmDelete}
          setNoteToDelete={setNoteToDelete}
          onDelete={handleDeleteStickyNote}
          saveLoading={saveLoading}
          saveNote={() => handleSaveStickyNote()}
          setShowNotes={setShowNotes}
          loading={isFetching}
          setOpenNotes={setOpenNotes}
        />
      )}
      {confirmDelete && (
        <Confirm
          ref={ref}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Sticky Note"
          loading={deleteLoading}
          icon="trash"
          iconColor="danger"
          message="Are you sure you want to delete sticky note?"
          handleContinue={() => handleDeleteStickyNote(noteToDelete?.id)}
          handleOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
}
