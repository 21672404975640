export default (dirtyValues) => {
  const changes = {
    appointment_details: { value: false, label: 'Appointment Details' },
    procedures: { value: false, label: 'Procedures' },
    products: { value: false, label: 'Products' },
    timeline_status: { value: false, label: 'Timeline' },
    patient_arrival: { value: false, label: 'Patient Arrival' }
  };

  Object.keys(dirtyValues).forEach((key) => {
    switch (key) {
      case 'products':
        changes.products.value = true;
        break;

      case 'procedures':
        changes.procedures.value = true;
        break;

      case 'timeline_status':
      case 'checkin':
        changes.timeline_status.value = true;
        break;

      case 'appointment_end_date':
      case 'arrivalUpdate':
        changes.patient_arrival.value = true;
        break;

      default:
        changes.appointment_details.value = true;
        break;
    }
  });

  return changes;
};
