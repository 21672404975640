import cs from 'classnames';
import MainVitals from 'components/practice/charts/ClinicalNote/NewVitals/MainVitals';
import React from 'react';

const VitalsSection = () => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="flex h-full flex-col overflow-auto text-sm text-neutral-600 duration-200">
        <MainVitals />
      </div>
    </div>
  );
};

export default VitalsSection;
