import React from 'react';
import { iaRa } from '../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../practiceState';
import moment from 'moment-timezone';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import TPTable from './TPTable';
import { getTPTableCols } from '../lib/getTPTableCols';
import { clinicalNote as clinicalNoteState } from 'components/state';

const TPList = ({ data, onClick, loadEPNToCN = false }) => {
  const practice = useRecoilValue(currentPractice);
  const clinicalNote = useRecoilValue(clinicalNoteState);

  // SORT DATA - DESC
  iaRa(data)?.sort(
    (a, b) =>
      moment(a?.treatment_frequency_date?.startDate) -
      moment(b?.treatment_frequency_date?.startDate)
  );

  return (
    <>
      <TableContextProvider
        name="treatment_plans"
        cols={getTPTableCols({
          treatment_plan_id: clinicalNote?.treatment_plan_id,
          timezone: practice.timezone
        })}>
        <TPTable data={data} loadEPNToCN={loadEPNToCN} onClick={onClick} />
      </TableContextProvider>
    </>
  );
};

export default TPList;
