import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMultipleMedications, updateMultipleMedications } from 'api/Medication';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { hasSingleStatus } from '../lib/helpers';
import { findStatusLabelAndColor, handleStatusFormatData } from './helper';

const MultiMedsActions = ({ data, gridApi = null }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { id: patientId, appointmentId } = useParams();
  const { setOverviewData } = useClinicalNoteContext() || {};
  const handleStatusLabel = findStatusLabelAndColor({ data, type: 'label' });
  const navigate = useNavigate();

  const options = [
    {
      icon: 'trash',
      label: 'Delete',
      onClick: () => setConfirmationModal({ open: true, type: 'multi_delete' })
    }
  ];

  if (hasSingleStatus(data)) {
    options.push({
      icon: 'status',
      color: findStatusLabelAndColor({ data, type: 'color' }),
      stroke: true,
      label: `Mark ${handleStatusLabel}`,
      onClick: () => setConfirmationModal({ open: true, type: 'multi_status' })
    });
  }

  const onHandleDelete = async () => {
    await mutateMultiDeleteMedication.mutateAsync();
    setConfirmationModal({ open: false });
  };

  const onHandleStatus = async () => {
    const dataForMultiStatusUpdate = handleStatusFormatData(data);
    await mutateMultiMedsStatus.mutateAsync({
      patientId,
      appointmentId,
      medicationData: dataForMultiStatusUpdate?.medData,
      weightLossResponses: dataForMultiStatusUpdate?.whlData
    });
    if (gridApi) {
      gridApi.deselectAll();
    }

    setConfirmationModal({ open: false });
  };

  const queryClient = useQueryClient();
  const mutateMultiDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMultipleMedications(navigate, {
        medications: data
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }
      showAlert({
        title: `Medication deleted successfully`
      });
      const idsToDelete = iaRa(data)
        .filter((med) => med?.weight_loss_medication_id)
        .map((med) => med.weight_loss_medication_id);
      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: prev?.weightLossAdministration?.filter(
          (whl) => !idsToDelete.includes(whl?.id)
        )
      }));
      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  const mutateMultiMedsStatus = useMutation((data) => updateMultipleMedications(navigate, data), {
    onSettled: async ({ code, error, newResponses }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }

      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: prev?.weightLossAdministration?.map((weightLoss) => {
          const updatedWeightLoss = newResponses.find(
            (response) => response?.id === weightLoss?.id
          );
          return updatedWeightLoss || weightLoss;
        })
      }));
      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  return (
    <>
      <Popover
        options={options}
        optionIconColor="primary"
        className="rounded-lg border-solid border-neutral-100"
        position="left"
        isFixed
        icon="new-context-menu-dots"
      />

      {confirmationModal?.open && confirmationModal?.type === 'multi_delete' && (
        <Confirm
          icon="new-info"
          iconColor="danger"
          iconSize={42}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Medication"
          message="Are you sure you want to delete selected medications?"
          handleContinue={onHandleDelete}
          handleOpen={!!confirmationModal?.open}
          handleClose={() => setConfirmationModal({ open: false })}
          disabled={mutateMultiDeleteMedication?.isLoading}
          loading={mutateMultiDeleteMedication?.isLoading}
        />
      )}

      {confirmationModal?.open && confirmationModal?.type === 'multi_status' && (
        <Confirm
          icon="new-info"
          iconSize={42}
          variant="warning"
          primaryBtnTxt={`Mark ${handleStatusLabel}`}
          title={`Mark ${handleStatusLabel} Medication`}
          message={`Are you sure you want to mark ${data?.length > 1 ? 'medications' : 'medication'}  as ${handleStatusLabel}?`}
          handleContinue={onHandleStatus}
          handleOpen={!!confirmationModal?.open}
          handleClose={() => setConfirmationModal({ open: false })}
          disabled={mutateMultiMedsStatus?.isLoading}
          loading={mutateMultiMedsStatus?.isLoading}
        />
      )}
    </>
  );
};

export default MultiMedsActions;
