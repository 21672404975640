import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { checkUnsavedChanges } from './lib/checkUnsavedChanges';
import { getDirtyValues } from 'lib/helpers/utility';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Modal from 'components/shared/Modal/Modal';
import UnsavedChanges from './components/UnsavedChanges';
import Details from './components/Sections/Details/Details';
import Pricing from './components/Sections/Pricing/Pricing';
import Timeline from './components/Sections/Timeline/Timeline';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const AppointmentContent = ({ isOpen, handleClose, appointment, setFormValues, isLoading }) => {
  const { values, initialValues, setFieldValue } = useFormikContext();

  const [isUnsaved, setIsUnsaved] = useState(false);

  const handleUnsaved = (values, initialValues) => {
    const isUnsaved = checkUnsavedChanges(values, initialValues);
    setIsUnsaved(isUnsaved);
    if (!isUnsaved) handleClose();
  };

  const handleFormChange = () => {
    const dirtyValues = getDirtyValues(values, initialValues);
    const upComingDirtyValues = getDirtyValues(appointment, initialValues);
    setFormValues(appointment);
    setTimeout(() => {
      Object.keys(dirtyValues).forEach((key) => {
        if (!upComingDirtyValues[key]) {
          setFieldValue(key, dirtyValues[key]);
        }
      });
    }, 200);
  };

  useEffect(() => {
    if (!isLoading) handleFormChange();
  }, [appointment, isLoading]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => handleUnsaved(values, initialValues)}
      isLarge
      slideFromRight
      disableHeader
      className="flex flex-col bg-white"
      headerClassName="sm:items-start"
      bodyClassName="p-0 overflow-hidden flex flex-col h-full"
      footerClassName="mt-auto h-max z-10"
      footer={!isLoading && <Footer handleClose={handleClose} appointment={values} />}>
      {isLoading ? (
        <Skeleton count={10} height="75px" />
      ) : (
        <>
          <Header appointment={values} handleClose={handleClose} />
          <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
            <Details appointment={values} />
            <Pricing />
            <Timeline />
          </div>
          <UnsavedChanges {...{ isUnsaved, setIsUnsaved, handleClose }} />
        </>
      )}
    </Modal>
  );
};

export default AppointmentContent;
