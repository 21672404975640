import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import ProfilePicture from 'components/Profile/ProfilePicture';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import Logout from 'components/shared/Logout/Logout';
import CheckinPracticeLogo from '../PracticeLogo';
import state from '../state';
import cs from 'classnames';
import { ia } from 'lib/helpers/utility';
import { requestApi } from 'api/Api';
import RequestAssistance from 'components/Kiosk/components/RequestAssistance';
import moment from 'moment';

const DEFAULT_STEPS = [
  { name: 'Diagnosis', path: null, status: 'complete', index: 'Diagnosis' },
  { name: 'Behavior', path: null, status: 'complete', index: 'Behavior' },
  { name: 'Goals', path: null, status: 'current', index: 'Goals' },
  { name: 'Objective', path: null, status: 'incomplete', index: 'Objective' },
  { name: 'Interventions', path: null, status: 'incomplete', index: 'Interventions' },
  { name: 'Overview', path: null, status: 'incomplete', index: 'Overview' }
];

const CheckinSidebar = ({
  steps = DEFAULT_STEPS,
  practiceLogo,
  device,
  kiosk,
  setShow,
  disableContinue
}) => {
  const navigate = useNavigate();
  const user = useRecoilValue(state.userState);
  const { pathname } = useLocation();

  const [showPast, setShowPast] = useState(false);

  return (
    <div
      className={cs(
        'col-start-1 row-span-8 row-start-1 flex h-full flex-col justify-start overflow-hidden text-ellipsis border border-solid border-neutral-100 bg-white',
        device === 'mobile' && 'fixed z-10 ',
        device === 'tablet-sm' ? 'w-[250px]' : 'w-[280px]'
      )}>
      <div
        className={cs(
          'sticky top-0  flex w-full items-center justify-center border-0 !border-b border-solid border-neutral-100 bg-white shadow-[0px_2px_16px_0px_#0000000F]',
          device === 'mobile' ? 'h-[48px]' : device === 'tablet-sm' ? 'h-[52px]' : 'h-[56px]'
        )}>
        <CheckinPracticeLogo practiceLogo={practiceLogo} />
        {device === 'mobile' && (
          <div className="absolute left-[6px] top-[16px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center !rounded-md !bg-neutral-100 hover:bg-neutral-100">
            <Icon
              data-qa="sidebar-close-btn"
              icon="back-large"
              size={12}
              color="neutral"
              shade={600}
              onClick={() => setShow(false)}
            />
          </div>
        )}
      </div>
      <div className="m-1 overflow-y-auto">
        {ia(steps) &&
          steps.map((v, i) => (
            <div key={`patient-checkin-sidebar-index-${i}`} className="flex w-full flex-col">
              <div
                onClick={() => {
                  if (disableContinue) return;
                  navigate(v.path);
                  setShow(false);
                  // setShow(FamilyHistoryOverview);
                }}
                className={`${
                  pathname.includes(v.path) ? 'bg-primary-700 ' : 'bg-white '
                } my-[3px] mr-1 flex flex-row rounded-[8px] px-[8px] py-[12px] text-left transition-all `}
                data-qa={`${v.step_id}`}>
                {/* <div
                className={`text-white ${
                  pathname.includes(v.path) ? 'bg-primary-500' : 'bg-primary-300'
                } flex h-[1.75rem] w-[1.75rem] items-center justify-center rounded-full`}>
                {v.completed ? (
                  <Icon icon="checkmark" color="white" size={22} className="!mt-1" />
                ) : (
                  <div className="!m-0 !aspect-square !h-4 !w-4 rounded-full border-none bg-white !p-0"></div>
                )}
              </div> */}
                <div className="flex h-[1rem] w-[1rem] items-center justify-center rounded-full border border-solid border-primary-100 bg-white p-[13px] ">
                  {v.completed ? (
                    <Icon
                      icon="checkmark"
                      color="primary"
                      shade={600}
                      size={20}
                      className="!mt-[2px]"
                    />
                  ) : (
                    <p className="text-[14px] font-medium !text-primary-600">{i + 1}</p>
                  )}
                </div>
                <div
                  className={`${
                    pathname.includes(v.path) ? 'text-white' : 'text-neutral-800'
                  } mb-[1px] ml-[0.6rem] flex w-[80%] flex-row items-center justify-start truncate text-left text-[15px]`}>
                  {v?.name}
                </div>
                {v?.past && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!v.past?.some((past) => pathname.includes(past.path))) {
                        setShowPast(!showPast);
                      }
                    }}
                    className={cs(
                      'cursor-pointer items-center justify-center rounded-full p-2 ',
                      pathname.includes(v.path) ? 'hover:bg-primary-800' : 'hover:bg-primary-50'
                    )}>
                    <Icon
                      icon="new-chevron-down"
                      className={cs(
                        'cursor-pointer',
                        showPast && 'rotate-180',
                        v.past?.some((past) => pathname.includes(past.path)) && '!rotate-180 '
                      )}
                      color={
                        pathname.includes(v.path)
                          ? 'white'
                          : v.past?.some((past) => pathname.includes(past.path))
                            ? 'neutral'
                            : 'primary'
                      }
                      size={16}
                    />
                  </div>
                )}
              </div>
              {v?.past && (showPast || v.past?.some((past) => pathname.includes(past.path))) && (
                <div className="mx-1 mb-2 ml-[20px] flex flex-col gap-[8px] text-sm">
                  {v?.past.map((past, index) => (
                    <div
                      onClick={() => {
                        if (disableContinue) return;
                        navigate(past.path);
                        setShow(false);
                        // setShow(FamilyHistoryOverview);
                      }}
                      className={cs(
                        pathname.includes('past') && index === 0 && 'mt-2',
                        pathname.includes(past.path) && 'bg-primary-50 ',
                        'flex w-full cursor-pointer items-center justify-between gap-3 rounded-lg p-[8px] pl-6 pr-6 hover:bg-primary-50'
                      )}
                      key={index}>
                      <div className="flex-col">
                        <p
                          className={
                            pathname.includes(past.path)
                              ? 'font-medium text-primary-900'
                              : 'text-primary-700'
                          }>
                          {past?.name}
                        </p>
                        <i className="text-xs text-neutral-500">
                          Last update: ({moment(past?.updated).format('MM/DD/YYYY')})
                        </i>
                      </div>
                      <Icon
                        shade={700}
                        className=" cursor-pointer"
                        color="primary"
                        icon="new-edit-pen"
                        size={14}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>

      <div className="!mt-auto mb-3 flex w-full justify-center ">
        {!kiosk && <RequestAssistance />}
      </div>
      {user && (
        <div
          className="flex h-[80px] w-full items-center
 justify-between border-0 !border-t !border-solid !border-neutral-100 bg-white !p-4 shadow-[0px_-2px_16px_0px_#0000000F]">
          <div className="flex flex-row items-center text-primary-900">
            <ProfilePicture
              firstName={user.f_name}
              lastName={user.l_name}
              id={user.id}
              image={user?.profile_photo}
              size={36}
            />
            <div className="!ml-3 text-sm font-500">
              {user?.f_name && `${user.f_name} ${user.l_name}`}
            </div>
          </div>
          {!kiosk ? (
            <Logout
              showText
              textClassName="text-primary-900 text-2xl"
              iconClassName="color-primary-900"
            />
          ) : (
            <div
              className={cs(
                'flex cursor-pointer items-center justify-center !rounded-md hover:bg-primary-50'
              )}
              onClick={async () => {
                await requestApi({
                  url: '/api/user/logout',
                  params: {},
                  navigate
                });

                navigate('/kiosk/idle');
              }}
              data-qa="logout-btn">
              <span className={cs('mr-1 text-xs text-red-900')}>Exit</span>

              <Icon icon="new-logout" color="danger" className={cs('!ml-1 cursor-pointer')} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(CheckinSidebar);
