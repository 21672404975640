import React from 'react';
import { useRecoilState } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';
import Button from 'components/shared/Buttons/Button';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { showAlert } from 'components/shared/Alert/Alert';
import moment from 'moment-timezone';
import Tippy from '@tippyjs/react';

const TPModalFooter = ({ noProvidersSelected, view, handleBack, btnText, handleBtnClick }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const { selectedTreatmentPlan, setLoadedTP, setLoading, saveNoteRef } = useClinicalNoteContext();

  const loadTPInCN = () => {
    setLoading(true);

    const tp = {
      ...selectedTreatmentPlan,
      icd_10_codes: selectedTreatmentPlan.icd10
    };

    delete tp.id;
    delete tp.icd10;

    setClinicalNote({
      ...clinicalNote,
      ...tp,
      present_illness: tp?.patient_needs,
      other_complaints: tp?.problem_manifestation_definition,
      user_id: tp.patient_id,
      treatment_plan_id: selectedTreatmentPlan.id
    });

    showAlert({ title: 'Loaded data successfully!' });
    setLoadedTP(tp);

    setTimeout(() => {
      saveNoteRef.current?.onSaveNote();
      setLoading(false);
    }, 300);
  };

  let isExpired = false;

  if (selectedTreatmentPlan && view === 'preview') {
    const endDateMoment = moment(
      selectedTreatmentPlan.treatment_frequency_date?.endDate,
      'MM/DD/YYYY'
    );
    const currentDate = moment();
    isExpired = endDateMoment.isBefore(currentDate, 'day');
  }

  return (
    <div className="flex w-full items-center justify-between">
      <Button
        outlined
        color="neutral"
        text="Back"
        onClick={() => handleBack(view)}
        data-qa="close-modal-btn"
      />
      <div className="flex items-center justify-between gap-2">
        {view === 'preview' && clinicalNote?.treatment_plan_id !== selectedTreatmentPlan?.id ? (
          <Tippy
            disabled={!(clinicalNote?.locked || isExpired)}
            className="tippy-dark"
            content={
              clinicalNote?.locked
                ? 'This note is locked. Must unlock to make modifications.'
                : 'Cannot load expired treatment plan'
            }>
            <span>
              <Button
                data-qa={`modal-btn-${btnText}`}
                outlined
                text="Load in Clinical Note"
                onClick={loadTPInCN}
                disabled={clinicalNote?.locked || isExpired}
              />
            </span>
          </Tippy>
        ) : null}
        <Button
          data-qa={`modal-btn-${btnText}`}
          text={btnText}
          onClick={handleBtnClick}
          disabled={
            (view === 'list' && !selectedTreatmentPlan?.id) ||
            ((view === 'edit' || view === 'create') &&
              (noProvidersSelected ||
                !selectedTreatmentPlan?.treatment_frequency_date?.startDate ||
                !selectedTreatmentPlan?.treatment_frequency_date?.endDate))
          }
        />
      </div>
    </div>
  );
};

export default TPModalFooter;
