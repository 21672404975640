import { Tab } from '@headlessui/react';
import cs from 'classnames';
import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { useNewAppointmentContext } from '../../../../../lib/context/Appointment/NewAppointmentContext';
import { currentPractice } from '../../../practiceState';
import AddProceduresProducts from './AddProceduresProducts';
import { permissions } from 'components/state';
import { imagePath, pm, reShapeProcedures } from 'lib/helpers/utility';

const ProceduresProducts = ({
  packages = [],
  formik: formikProp,
  originalProceduresProducts = {}
}) => {
  const { formik: formikContext } = useNewAppointmentContext() || {};
  const practice = useRecoilValue(currentPractice);
  const userPermissions = useRecoilValue(permissions);

  const { packages: packagesPermission = false, products: productsPermission = false } =
    practice.display_settings;

  const formik = formikProp || formikContext;
  const { values, touched, errors } = formik || {};

  const tabsData = [
    ...(pm(userPermissions, 'services.read')
      ? [
          {
            label: 'Services',
            length: values?.procedures?.length,
            content: (
              <AddProceduresProducts
                type="procedures"
                label="Services"
                endpoint="api/practice/services/get"
                queryKey="services"
                packageList={packagesPermission ? packages : []}
                formik={formik}
                originalProceduresProducts={originalProceduresProducts}
                formatData={(data) => reShapeProcedures(data)}
              />
            ),
            hasPermission: true
          }
        ]
      : []),
    ...(pm(userPermissions, 'products.read')
      ? [
          {
            label: 'Products',
            length: values?.products?.length,
            content: (
              <AddProceduresProducts
                type="products"
                label="Products"
                endpoint="api/product/get"
                queryKey="products"
                formik={formik}
                originalProceduresProducts={originalProceduresProducts}
                formatData={(data) =>
                  data.map((p) => ({
                    ...p,
                    total_amount_cents: p?.amount_cents,
                    sales_count: 1,
                    ...(p?.image && {
                      image: imagePath(JSON.parse(p?.image || `{}`)?.jpg)
                    })
                  }))
                }
              />
            ),
            hasPermission: productsPermission
          }
        ]
      : [])
  ];

  return (
    <div
      className="rounded-lg border border-solid border-neutral-100 bg-white !p-4 shadow-[0px_0px_8px_0px__rgba(0,79,107,0.08)]"
      data-dd-privacy="allow"
      data-public>
      <Tab.Group selectedIndex={touched?.procedures && errors?.procedures && 0}>
        <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
          {tabsData
            .filter((it) => it.hasPermission)
            .map((tab, i) => {
              return (
                <Tab as={Fragment} key={i}>
                  {({ selected }) => (
                    <button
                      data-qa={`tab-${tab.label}`}
                      className={cs(
                        'flex items-center border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                        selected
                          ? ' !border-primary-500 font-600 text-primary-500'
                          : 'font-500 text-neutral-800'
                      )}>
                      {tab.label}

                      {tab.length > 0 && (
                        <label
                          className={cs(
                            '!ml-2 mb-0 grid !h-6 !w-6 cursor-pointer place-content-center rounded-full text-xs',
                            selected
                              ? 'bg-primary-100 text-primary-500'
                              : 'bg-neutral-100 text-neutral-700'
                          )}>
                          {tab.length}
                        </label>
                      )}
                    </button>
                  )}
                </Tab>
              );
            })}
        </Tab.List>

        <Tab.Panels>
          {tabsData
            .filter((it) => it.hasPermission)
            .map((tab, i) => (
              <Tab.Panel key={i}>{tab.content}</Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ProceduresProducts;
