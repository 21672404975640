import React from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import Button from 'components/shared/Buttons/Button';
import { checkUnsavedChanges } from '../lib/checkUnsavedChanges';
import FooterAction from './FooterAction';
import { showAlert } from 'components/shared/Alert/Alert';
const activeClasses = 'animate-shine bg-gradient-to-r from-[#13b8ff] to-[#098cc1] !py-[5px]';

const Footer = ({ handleClose, appointment }) => {
  const { values, errors, initialValues, handleSubmit } = useFormikContext();
  const isReschedule = !moment(values.starts_at).isSame(moment(initialValues.starts_at));
  const isUnsaved = checkUnsavedChanges(values, initialValues);
  const isAptDisabled = values.appointment_disabled;

  const handleOnClick = () => {
    if (errors?.procedures) showAlert({ message: errors?.procedures, color: 'danger' });

    setTimeout(() => {
      handleSubmit();
    }, 100);
  };
  return (
    <div className="flex w-full items-center justify-between gap-4">
      <Button text="Cancel" onClick={handleClose} outlined color="neutral" size="small" />
      <div className="flex items-center ">
        <FooterAction appointment={appointment} handleClose={handleClose} />
        {!isAptDisabled && (
          <Button
            size="small"
            disabled={!isUnsaved}
            onClick={handleOnClick}
            text={`Update ${isReschedule ? ' & Reschedule' : ''} Appointment`}
            className={isUnsaved && activeClasses}
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
