import { useMutation } from '@tanstack/react-query';
import { deleteVital } from 'api/Vitals';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { clinicalNote as ClinicalNoteState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { sanitize } from 'lib/helpers/sanitize';
import {
  cmToInch,
  separateFeetAndInches,
  separatePoundsAndOunces,
  stripHtmlTags
} from 'lib/helpers/utility';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import VitalPreviewItem from './VitalPreviewItem';
import { updateVitalsStatesAfterDelete } from './lib/helper';

const VitalsPreview = ({ vital, setShowVitalsUpsertModal = () => {}, setVital = () => {} }) => {
  const clinicalNote = useRecoilValue(ClinicalNoteState);
  const { setCurrentHpOverviewData, setOverviewData } = useClinicalNoteContext() || {};
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { feet, inches } = separateFeetAndInches(cmToInch(vital?.height));
  const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);

  const mutatateDeleteVital = useMutation({
    mutationFn: () =>
      deleteVital(navigate, {
        vitalId: vital?.id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Delete Vital',
          message: error,
          color: 'warning'
        });
        return;
      }

      updateVitalsStatesAfterDelete({
        vitalId: vital?.id,
        setCurrentHpOverviewData,
        setOverviewData
      });
    }
  });

  const onHandleDelete = async () => {
    if (vital?.id) {
      await mutatateDeleteVital.mutateAsync();
    }
    setConfirmDelete(false);
  };

  return (
    <div className="w-full bg-primary-10 p-3 pt-4">
      <div className="grid grid-cols-2  gap-y-12 rounded-t-[8px] bg-white p-4">
        <div className="flex items-center gap-2">
          <div className="flex h-[35px] w-[35px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
            <Icon icon="new-calendar" className="h-[18px] w-[18px]" />
          </div>
          <div>
            <p className="text-sm font-500 text-neutral-800">Vitals taken on: </p>
            <p className="text-sm font-400 text-neutral-700">
              {moment(vital?.timestamp).format('MM/DD/YYYY hh:mm:ss A')}
            </p>
          </div>
        </div>
        <div className="ml-auto flex gap-4">
          <Icon
            data-qa="delete-vital-btn"
            icon="trash"
            size={18}
            color="primary"
            disabled={clinicalNote?.locked}
            onClick={() => {
              setConfirmDelete(true);
            }}
          />
          <Icon
            data-qa="edit-vital-btn"
            icon="new-edit"
            color="primary"
            size={18}
            onClick={() => {
              setVital(vital);
              setShowVitalsUpsertModal(true);
            }}
          />
        </div>
      </div>
      <div className="mx-4 border border-solid border-[#ECEFF0]"></div>
      <VitalPreviewItem vital={vital} feet={feet} inches={inches} pounds={pounds} ounces={ounces} />
      <div className="flex gap-2 rounded-b-[8px] bg-white  px-4 py-2 !pb-3">
        <div className="flex h-[35px] w-[35px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
          <Icon icon="new-calendar-note" color="primary" className="h-[18px] w-[18px]" />
        </div>
        <div className="flex flex-col justify-between gap-x-1">
          <p className="whitespace-nowrap text-sm font-500 leading-4 text-neutral-800">Note: </p>
          <p
            className="break-all text-sm font-400 text-neutral-700"
            dangerouslySetInnerHTML={sanitize(
              isEmpty(stripHtmlTags(vital?.notes)) ? '-' : vital?.notes
            )}></p>
        </div>
      </div>
      {confirmDelete && (
        <Confirm
          icon="new-info"
          iconColor="danger"
          iconSize={42}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Vital"
          message="Are you sure you want to delete this vital?"
          handleContinue={onHandleDelete}
          handleOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          di
          disabled={clinicalNote?.locked || mutatateDeleteVital?.isLoading}
          loading={mutatateDeleteVital?.isLoading}
        />
      )}
    </div>
  );
};
export default VitalsPreview;
