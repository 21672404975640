import { Honeybadger } from '@honeybadger-io/react';
import Tippy from '@tippyjs/react';
import { interimApi } from 'api/InterimApi';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import state from '../../state';

import { requestApi } from 'api/Api';
import Modal from 'components/shared/Modal/Modal';
import { formatDateAndTimeZ, ia } from 'lib/helpers/utility';
import { patientAppointment } from '../patientState';

const Dashboard = () => {
  const [userState, setUserState] = useRecoilState(state.userState);
  const [latest_messages, setLatestMessage] = useState({
    subject: '',
    sender_name: '',
    date: new Date(),
    unread_messages: ''
  });
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [appointmentState, setAppointmentState] = useRecoilState(patientAppointment);
  const [closestAppointment, setClosestAppointment] = useState(null);
  const latestResults = {
    result_name: '',
    practice_name: '',
    date_published: new Date()
  };
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  useEffect(() => {
    getData();
    getAppointments();
  }, []);

  const getData = async () => {
    try {
      let res = await requestApi({url: '/api/user/dashboard', navigate});
      const { redirect, latest_messages: loadedLatestMessage, user } = res.data;
      if (res.data.code === 0) {
        setLatestMessage({
          ...loadedLatestMessage
        });
        setUserState({
          ...userState,
          ...user,
          user_id: user?.id
        });
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getAppointments = async () => {
    try {
      const params = {
        approved: false,
        upcoming: true
      };
      const res = await interimApi('/api/patient/appointment/read', params, navigate);
      const { redirect, appointments: loadedAppointments } = res.data;
      if (ia(res.data.appointments) && queryParams.get('modal') === 'true') {
        setUpcomingAppointments(loadedAppointments);
        setClosestAppointment(loadedAppointments[0]);
        setIsOpen(true);
      } else if (ia(res.data.appointments)) {
        setUpcomingAppointments(loadedAppointments);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const checkIn = async (appointmentId) => {
    try {
      setAppointmentState({ id: appointmentId });
      localStorage.setItem('appointmentId', appointmentId);

      const data = await requestApi({
        url: `/api/patient/checkin/start`,
        params: { appointmentId },
        navigate
      });

      if (data?.redirectTo) {
        navigate(data.redirectTo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    { closestAppointment &&
      <Modal
        handleOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        title="Upcoming Appointment"
        footer={
          <div className="flex w-full justify-between">
            <Button
              outlined
              text="Remind me later"
              color="neutral"
              onClick={() => setIsOpen(false)}
            />
            <Button
              text="Complete"
              onClick={() => checkIn(closestAppointment?.id)}
              data-qa="complete-btn"
            />
          </div>
        }>
        <div className="flex flex-col gap-1 p-2 py-4">
          <span>You have paperwork for an upcoming appointment.</span>
          <span className="pb-4"> Please complete now. </span>
          <span>Practice: {closestAppointment?.practice.name}</span>
          <span>
            {`Date & Time: ${formatDateAndTimeZ(
              closestAppointment?.starts_at,
              closestAppointment?.practice?.timezone
            )}`}
          </span>
          <span>
            Location:{' '}
            {closestAppointment?.location === 'virtual'
              ? closestAppointment?.virtual_link || 'Virtual'
              : closestAppointment?.practice.practiceAddress?.fullAddress}
          </span>
        </div>
      </Modal>
    }
      <div className="grid grid-cols-3 gap-4 !p-4">
        <Box className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <span className="text-xl font-500 text-neutral-900">Upcoming Appointments</span>
            {upcomingAppointments.length > 0 && (
              <Tippy
                className="tippy-dark"
                content={`You have ${upcomingAppointments.length} appointments!`}>
                <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-white">{`${upcomingAppointments.length}`}</span>
              </Tippy>
            )}
          </div>
          {upcomingAppointments.length > 0 ? (
            <div className="flex flex-col gap-1">
              <span>
                {formatDateAndTimeZ(
                  closestAppointment?.starts_at,
                  closestAppointment?.practice.timezone
                )}
              </span>
              <span className="mb-0">{closestAppointment?.practice.name}</span>
              <span className="mb-0">
                {closestAppointment?.practice.practiceAddress.fullAddress}
              </span>
            </div>
          ) : (
            <p className="text-sm text-neutral-700">You don't have any upcoming appointments!</p>
          )}
          <Button
            className="mt-auto"
            text="See all appointments"
            onClick={() => navigate('/myportal/appointments')}
          />
        </Box>
        <Box className="flex flex-col gap-4">
          <span className="text-xl font-500 text-neutral-900">Messages</span>
          {latest_messages.number_unread > 0 ? (
            <div className="flex flex-col gap-2">
              <span>{`You have ${latest_messages.number_unread} new messages`}</span>
            </div>
          ) : (
            <p className="text-sm text-neutral-700">You don't have any new messages!</p>
          )}
          <Button
            className="mt-auto"
            text="See all messages"
            onClick={() => navigate('/myportal/inbox')}
          />
        </Box>
        <Box className="flex flex-col gap-4">
          <span className="text-xl font-500 text-neutral-900">Latest Results</span>
          {latestResults.number_unread > 0 ? (
            <div className="flex flex-col gap-2">
              <span>{latestResults.number_unread}</span>
              <span>You have new results</span>
            </div>
          ) : (
            <p className="text-sm text-neutral-700">
              There are no new results published at this time!
            </p>
          )}
          <Button
            className="mt-auto"
            text="See all results"
            onClick={() =>
              navigate(`/myportal/${latestResults.number_unread > 0 ? 'inbox' : 'medical-records'}`)
            }
          />
        </Box>
      </div>
    </>
  );
}

export default Dashboard;