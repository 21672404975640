import React from 'react';
import cs from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import state, { userState as user_state, permissions } from 'components/state';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useUserPackages } from 'lib/hooks/queries/packages/useUserPackages';
import AEOB from './components/AEOB';
import Icon from 'components/shared/Icon/Icon';
import Payments from 'components/Payments/Payments';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import Section from 'components/practice/NewAppointment/components/Section';
import { mString } from 'lib/helpers/utility';
import ProceduresProducts from './components/ProceduresProduct/ProceduresProducts';
import EligibilityWrapper from 'components/practice/Eligibility/EligibilityWrapper';
import CalculateAndPayments from './components/CalculatePayments/CalculateAndPayments';
import Balance from 'components/practice/NewAppointment/components/Sections/Pricing/components/Balance/Balance';
import Allowed from 'components/shared/Permissions/Allowed';

const Pricing = () => {
  const formik = useFormikContext();
  const userState = useRecoilValue(user_state);
  const hasPaymentPermission = useRecoilValue(permissions)?.payments?.read;
  const { device } = useUIContext();
  const [, setCostShareState] = useRecoilState(state?.costShareState);
  const isMobile = device === 'mobile' || device === 'tablet-sm' || device === 'tablet';
  const isPined = isMobile || userState?.display_settings?.appointment?.pricing?.isPined;
  const isOpen = userState?.display_settings?.appointment?.pricing?.isOpen ?? false;

  const includeIds =
    formik?.values?.invoice?.procedures
      ?.filter?.((p) => p?.packageId || p?.invoiceId)
      .map?.(({ id, packageId, invoiceId }) => {
        return { id, packageId, invoiceId };
      }) || [];

  const { data: packagesData } = useUserPackages({
    dependencies: [formik?.values?.id],
    params: { patientId: formik?.values?.patient?.id, includeIds }
  });

  const { data: { patient } = {} } = usePatient({
    params: { id: formik.values?.user_id },
    dependencies: [formik.values?.user_id]
  });

  const isAptDisabled = formik?.values?.appointment_disabled;
  const hasPrimaryType = formik?.values?.procedures?.some((p) => p?.type === 'primary');
  const activeClasses = 'animate-shine bg-gradient-to-r from-[#13b8ff] to-[#098cc1] !py-[5px]';

  const handleEligibilityRun = ({ benefits, type }) => {
    if (isAptDisabled || !hasPrimaryType) return;

    formik.setFieldValue('calculate', { value: type });

    const { deductible = 0, co_payment = 0, co_insurance = 0 } = benefits?.cost_share || {};
    setCostShareState({
      deductible: { checked: true, value: deductible },
      coPayment: { checked: true, value: co_payment },
      coInsurance: { checked: true, value: co_insurance }
    });
  };

  const HeaderChildren = (
    <div className="ml-auto flex flex-wrap justify-end gap-x-4 gap-y-1">
      <div className="ml-auto flex items-center gap-3">
        <Allowed requiredPermissions="payments.read">
          <div className="flex items-center gap-[6px]">
            <Icon icon="wallet" size={20} />
            <span className="text-sm font-400 leading-[22px] text-neutral-800">
              Account balance:
              <strong className="font-600">{mString(formik?.values?.patient_balance)}</strong>
            </span>
          </div>
        </Allowed>

        {patient && (
          <EligibilityWrapper
            patient={patient}
            onSuccess={(benefits) => handleEligibilityRun(benefits)}
          />
        )}

        <AEOB invoice={formik?.values?.invoice} patient={patient} disabled={isAptDisabled} />

        <Payments
          smallButton
          apptInvoice={true}
          appointment={formik?.values}
          appointmentId={formik?.values?.id}
          patientId={formik?.values?.user_id}
          practiceId={formik?.values?.practice_id}
          amount={formik?.values?.invoice?.balance || 0}
          buttonClassName={formik?.values?.isPaymentActive ? activeClasses : true}
        />
      </div>
    </div>
  );

  return (
    <Section
      target="pricing"
      title="Pricing Protocol"
      headerChildren={HeaderChildren}
      className={cs('!pb-0', isOpen && !isPined && '!px-0')}
      isMobile={isMobile}>
      <div
        className={cs(
          'relative grid h-full',
          isPined || !hasPaymentPermission ? 'grid-cols-1 pt-2' : 'grid-cols-2'
        )}>
        {isAptDisabled && <div className="absolute inset-0 z-[100] bg-white opacity-40"></div>}
        <ProceduresProducts formik={formik} packages={packagesData?.invoicePackages} />
        {hasPaymentPermission && (
          <div className="grid grid-cols-2 xs:grid-cols-1">
            <CalculateAndPayments isPined={isPined} />
            <Balance insuranceProfiles={patient?.insuranceProfile} />
          </div>
        )}
      </div>
    </Section>
  );
};

export default Pricing;
