import { requestApi } from './Api';

export const enhanceNarrative = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/enhance_narrative',
    params
  });

export const getPatientForms = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/patient/get_forms',
    params
  });

export const getPrompts = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/chat/list',
    params
  });

export const getPrompt = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/chat/get',
    params
  });
