import React from 'react';
//components
import Diagnosis from '../../TreatmentPlan/components/preview/Diagnosis';
import PatientNeeds from '../../TreatmentPlan/components/preview/PatientNeeds';
import ProblemManifestationDefinition from '../../TreatmentPlan/components/preview/ProblemManifestationDefinition';
import Goals from '../../TreatmentPlan/components/preview/Goals';
import TreatmentTeam from '../../TreatmentPlan/components/preview/TreatmentTeam';
import AdditionalComments from '../../TreatmentPlan/components/preview/AdditionalComments';
import TreatmentFrequency from '../../TreatmentPlan/components/preview/TreatmentFrequency';
import TreatmentPlanSignatures from '../../TreatmentPlan/components/preview/TreatmentPlanSignatures';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Button from '../../../../../shared/Buttons/Button';

const TPPreview = ({ data }) => {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();

  return (
    <div className="h-full overflow-auto !p-3">
      {data?.length > 1 && (
        <Button
          onClick={() => setSelectedTreatmentPlan(null)}
          text="Back to list"
          icon="back"
          size="small"
          shade={800}
          textClassName="text-primary-800"
          transparent
          className="-ml-2"
        />
      )}
      <div className="grid !gap-6 !pt-4">
        <Diagnosis />
        <PatientNeeds />
        <ProblemManifestationDefinition />
        <Goals />
        <TreatmentTeam />
        <AdditionalComments />
        <TreatmentFrequency />
        <TreatmentPlanSignatures />
      </div>
    </div>
  );
};

export default TPPreview;
