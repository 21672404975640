import { onMyscribeAiAck, onMyscribeAiError, onMyscribeAiResponse } from './myscribe';
import {
  onAppointmentUpdate,
  onAppointmentCreate,
  onAppointmentAllergiesUpdate,
  onCustomChartIdUpdate
} from './appointment';
import { onPatientUpdate } from './patient';
import { onStickyNoteCreate, onStickyNoteDelete } from './sticky_notes';
import { onCustomFormUpdate } from './custom_form_response';
import {
  onInsuranceProfileCreate,
  onInsuranceProfileDelete,
  onInsuranceProfileUpdate
} from './insurance_profiles';

export default (data) => {
  switch (data?.kind) {
    case 'myscribe-ai-ack':
      onMyscribeAiAck(data);
      break;

    case 'myscribe-ai-error':
      onMyscribeAiError(data);
      break;

    case 'myscribe-ai-response':
      onMyscribeAiResponse(data);
      break;

    case 'appointment-update':
      onAppointmentUpdate(data);
      break;

    case 'appointment-create':
      onAppointmentCreate(data);
      break;

    case 'appointment-allergies-update':
      onAppointmentAllergiesUpdate(data);
      break;
    case 'appointment-chart_id-update':
      onCustomChartIdUpdate(data);
      break;

    case 'patient-update':
      onPatientUpdate(data);
      break;

    case 'sticky-note-create':
      onStickyNoteCreate(data);
      break;

    case 'sticky-note-delete':
      onStickyNoteDelete(data);
      break;

    case 'custom-form-update':
      onCustomFormUpdate(data);
      break;

    case 'insurance-profile-create':
      onInsuranceProfileCreate(data);
      break;

    case 'insurance-profile-update':
      onInsuranceProfileUpdate(data);
      break;

    case 'insurance-profile-delete':
      onInsuranceProfileDelete(data);
      break;

    default:
      break;
  }
};
