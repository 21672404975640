import moment from 'moment';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { useNavigate } from 'react-router-dom';

import { readPayers } from 'api/Payer';
import { formatDate } from 'lib/helpers/utility';
import { paymentMethods } from 'constants.js';

import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { showAlert } from 'components/shared/Alert/Alert';
import FileUploader from 'components/shared/FileUploader/FileUploader';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const EOBForm = ({ eob, formik, showInfoInRecordings }) => {
  const [selectedPayerId, setSelectedPayerId] = useState({
    value: eob?.payer_id,
    label: eob?.payer_name
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    value: eob?.payment_method,
    label: eob?.payment_method
  });

  const isVPayment = selectedPaymentMethod.label === 'vPayment';
  const isDisabled = isVPayment && !!eob;

  const handlers = formik?.values?.handle && [formik.values.handle];

  const navigate = useNavigate();

  const saveImage = async (data) => {
    formik.setFieldValue('handle', data.handle);
  };

  const handleDelete = async () => {
    formik.setFieldValue('handle', '');
  };

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        const result = await readPayers(navigate, { searchTerm });

        if (result?.payers) {
          return result.payers.map((payer) => ({ value: payer.id, label: payer.name }));
        }
      } catch (error) {
        showAlert({
          title: 'Error',
          message: 'There was an error getting all payers.',
          color: 'danger'
        });
      }
    }
  };

  const selectPayer = (e) => {
    formik.setFieldValue('payerid', e?.value);
    setSelectedPayerId(e);
  };

  const selectPaymentMethod = (e) => {
    formik.setFieldValue('payment_method', e?.value);
    setSelectedPaymentMethod({
      value: e?.value,
      label: e?.label
    });
  };

  return (
    <form className="grid gap-3" data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
      <Select
        isAsync
        isClearable={!!selectedPayerId?.value}
        onChange={selectPayer}
        value={selectedPayerId}
        loadOptions={searchPayers}
        placeholder="Choose Payer"
        label="Insurance Payer Name/ID"
        error={formik?.errors?.payerid}
        noOptionsMessage={() => 'Start typing the ID or name.'}
      />
      <Input
        label="Payer trace number"
        value={formik.values.check_number}
        name="check_number"
        onChange={formik.handleChange}
        error={formik?.errors?.check_number}
      />
      {!!eob ? (
        <Tippy content="You cannot change the payment method." className="tippy-dark">
          <Input
            label="Payment Method"
            value={formik.values.payment_method}
            name="payment_method"
            disabled={!!eob}
          />
        </Tippy>
      ) : (
        <Select
          isClearable={!!selectedPaymentMethod?.value}
          label="Payment Method"
          placeholder="Choose Payment Method"
          options={paymentMethods}
          onChange={selectPaymentMethod}
          value={selectedPaymentMethod}
          error={formik?.errors?.payment_method}
        />
      )}
      <CurrencyInput
        id="total_paid"
        label={isVPayment ? 'Total' : 'Total paid'}
        disabled={isDisabled}
        value={formik.values.total_paid}
        error={formik?.errors?.total_paid}
        className="!h-10 !rounded-md border border-neutral-100"
        onValueChange={(v) => formik.setFieldValue('total_paid', v)}
      />
      <>
        <Popover>
          {({ close }) => (
            <>
              <label className="mb-[6px] text-sm text-neutral-900">Check Date</label>
              <Popover.Button className="relative flex w-full items-center justify-center rounded-lg border border-solid border-neutral-200 !p-0 focus:!border-primary-500 focus:!shadow-[0px_0px_0px_2px_#CBF0FD]">
                <input
                  className="w-full cursor-pointer rounded-lg border-none !px-3 !py-2 text-sm text-neutral-800 placeholder-neutral-800"
                  placeholder={formatDate(formik?.values?.paid_date)}
                />
                <Icon icon="new-calendar-gray" className="absolute right-3 cursor-pointer" />
              </Popover.Button>

              <Popover.Panel className="flex shadow-md">
                <Calendar
                  onChange={(date) => {
                    formik.setFieldValue('paid_date', formatDate(moment.utc(date).add(12, 'h')));
                    close();
                  }}
                  className="text-base"
                  date={new Date(formik?.values?.paid_date) || new Date()}
                  showDateDisplay
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
        <FileUploader
          handlers={handlers}
          onUpload={saveImage}
          onDelete={handleDelete}
          className="max-w-[516px]"
        />
      </>
    </form>
  );
};

export default withErrorBoundary(EOBForm);
