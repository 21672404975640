import { useState } from 'react';
import { SHA1 } from 'crypto-js';

import useLoading from './useLoading';
import { showAlert } from 'components/shared/Alert/Alert';
import { getFilestackClient as getFileStackClient } from 'lib/clients/filestack/filestackClient';

export const useFileStack = (options = {}) => {
  const [files, setFiles] = useState([]);
  const [notUploaded, setNotUploaded] = useState([]);
  const { loading, startLoading, stopLoading } = useLoading();

  const updateFileState = (fileId, updates) => {
    setFiles((prev) =>
      prev.map((file) => (file.fileId === fileId ? { ...file, ...updates } : file))
    );
  };

  const handleUploadProgress = (fileId, event) => {
    const status = event.totalPercent === 100 ? 'uploaded' : 'uploading';
    updateFileState(fileId, {
      progress: event.totalPercent,
      uploadedSize: event.totalBytes,
      status
    });
  };

  const handleUploadSuccess = (uploadedFile, onUpload) => {
    setFiles((prev) =>
      prev.map((file) => (file.fileId === uploadedFile.fileId ? { ...uploadedFile } : file))
    );
    setNotUploaded((prev) => prev.filter((file) => file.fileId !== uploadedFile.fileId));

    if (onUpload) onUpload(uploadedFile);
  };

  const addFile = async (newFile, onUpload) => {
    const fileStackClient = await getFileStackClient();

    if (!fileStackClient) return;

    if (newFile) {
      const fileId = SHA1(new Date().toString()).toString();

      setNotUploaded((prev) => [...prev, { fileId, originalFile: newFile }]);
      const fileData = {
        fileId,
        name: newFile.name,
        size: newFile.size,
        uploadedSize: 0,
        type: newFile.type,
        status: 'uploading',
        progress: 0
      };
      setFiles((prev) => [...prev, fileData]);

      await fileStackClient
        .upload(newFile, {
          ...options,
          onProgress: (event) => handleUploadProgress(fileId, event)
        })
        .then((response) => {
          const uploadedFile = {
            fileId,
            handle: response.handle,
            name: newFile.name,
            size: newFile.size,
            uploadedSize: newFile.size,
            type: newFile.type,
            url: response.url,
            status: 'uploaded',
            progress: 100
          };
          handleUploadSuccess(uploadedFile, onUpload);
        })
        .catch(() => updateFileState(fileId, { status: 'failed' }));
    }
  };

  const openFilePicker = async (onUpload) => {
    const fileId = SHA1(new Date().toString()).toString();
    const fileStackClient = await getFileStackClient();

    const defaultOptions = {
      accept: ['image/*', '.pdf', '.png', '.jpeg'],
      maxFiles: 1,
      uploadInBackground: false,
      exposeOriginalFile: true,
      onUploadDone: (data) => {
        if (data && data.filesUploaded.length > 0) {
          const uploaded = data.filesUploaded[0];
          const fileData = {
            fileId,
            handle: uploaded.handle,
            name: uploaded.filename,
            size: uploaded.size,
            uploadedSize: uploaded.size,
            type: uploaded.mimetype,
            url: uploaded.url,
            status: 'uploaded',
            progress: 100
          };
          setFiles((prev) => [...prev, fileData]);
          handleUploadSuccess(fileData, onUpload);
        }
      },
      onFileUploadFailed: () => {
        showAlert({ color: 'danger', message: 'Failed to upload. Please try again.' });
      }
    };

    fileStackClient.picker({ ...defaultOptions, ...options }).open();
  };

  const deleteFile = async (fileHandle, onDelete) => {
    startLoading();
    try {
      await onDelete();
      setFiles((prev) => prev.filter((file) => file.handle !== fileHandle));
      return true;
    } catch (err) {
      showAlert({ color: 'danger', message: err.message || 'Failed to delete' });
      return false;
    } finally {
      stopLoading();
    }
  };

  const readFile = async (fileHandle) => {
    const existingFile = files.find((file) => file.handle === fileHandle);
    if (existingFile) return existingFile;

    startLoading();
    try {
      const fileStackClient = await getFileStackClient();

      const result = await fileStackClient.download(fileHandle);

      const file = new File([result.data], result.headers['x-file-name'], {
        type: result.headers['content-type']
      });
      const fileId = SHA1(new Date().toString()).toString();

      const fileData = {
        fileId,
        handle: fileHandle,
        name: file.name,
        size: file.size,
        uploadedSize: file.size,
        type: file.type,
        url: result.config.url,
        progress: 100,
        status: 'uploaded'
      };
      setFiles((prev) => [...prev, fileData]);
      return fileData;
    } catch (err) {
      showAlert({ color: 'danger', message: err.message || 'Failed to read file.' });
      return null;
    } finally {
      stopLoading();
    }
  };

  const retryUpload = async (fileId, onUpload) => {
    const file = notUploaded.find((file) => file.fileId === fileId);
    if (!file) return;

    setFiles((prev) => prev.filter((file) => file.fileId !== fileId));
    addFile(file.originalFile, onUpload);
  };

  return {
    files,
    addFile,
    deleteFile,
    readFile,
    retryUpload,
    openFilePicker,
    loading
  };
};
