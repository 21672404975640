import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import moment from 'moment';
import { getAppointments } from 'api/Appointment';
import { formatAppointments, formatEvents } from 'lib/helpers/appointment';
import {
  appointmentStatuses as aptStatuses,
  currentPractice
} from 'components/practice/practiceState';

export const useAppointmentEvents = (params, options) => {
  const currPractice = useRecoilValue(currentPractice);
  const permissions = useRecoilValue(state.permissions);
  const navigate = useNavigate();
  const appointmentStatuses = useRecoilValue(aptStatuses);

  const {
    practice,
    defaultDate,
    rangeDate,
    reloadAppointment,
    currentView,
    filters,
    setEvent,
    withalRest
  } = params;

  return useQuery(
    [
      'appointment-events',
      practice,
      moment(defaultDate).format('MM/DD/YY'),
      rangeDate,
      reloadAppointment,
      currentView,
      filters
    ],
    () => getAppointments({ ...params, navigate }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      select: (data) => {
        return [
          ...formatAppointments({
            appointments: data?.appointment,
            currentView,
            permissions,
            appointmentStatuses
          }),
          ...formatEvents({
            calendarEvents: data?.events,
            setEvent,
            timezone: currPractice.timezone,
            currentView
          })
        ];
      },
      ...options
    }
  );
};
