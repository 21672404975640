import { requestApi } from './Api';

export const getVitals = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/read',
    params
  });

export const upsertVital = async (navigate, params, onError, onSuccess) =>
  await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/create',
    params,
    onError,
    onSuccess
  });

export const deleteVital = async (navigate, params, onSuccess, onError) =>
  await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/delete',
    params,
    onError,
    onSuccess
  });
