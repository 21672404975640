import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { useFormik } from 'formik';
import { iaRa } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import NewBillingProvider from './NewBillingProvider';
import Row from './Row';

export default function AdminBilling() {
  const navigate = useNavigate();

  const { practice } = useOutletContext();
  const [billingInfo, setBillingInfo] = useState([]);
  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const openModal = (type, initialValues) => {
    formik.resetForm();
    formik.setValues(initialValues || formik.initialValues);
    setShowModal(true);
    setModalType(type);
  };

  const PracticeBillingValidationSchema = Yup.object().shape({
    practice_id: Yup.number().required('Practice is required'),
    bill_name: Yup.string().required('Bill name is required.'),
    bill_npi: Yup.string(),
    bill_taxid: Yup.string(),
    bill_taxid_type: Yup.object().required('Bill Tax ID Type is required'),
    bill_taxonomy: Yup.string(),
    bill_id: Yup.string(),
    bill_addr_1: Yup.string().required('Address line one is required.'),
    bill_addr_2: Yup.string().required('Address line two is required, type N/A if not applicable.'),
    bill_city: Yup.string().required('City is required'),
    bill_state: Yup.string().required('State is required'),
    bill_zip: Yup.string()
    .trim()
    .length(9, 'Zip code must be exactly 9 digits')
    .required('Zip code is required (9 digits)'),
    bill_phone: Yup.string(),
    practitioner_ids: Yup.array().min(1, 'Please select at least one practitioner')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: null,
      practice_id: practice?.id,
      bill_name: practice?.name,
      bill_npi: '',
      bill_taxid: '',
      bill_taxid_type: '',
      bill_taxonomy: '',
      bill_id: '',
      bill_addr_1: practice?.practiceAddress?.address_ln_1,
      bill_addr_2: practice?.practiceAddress?.address_ln_2,
      bill_city: practice?.practiceAddress?.city,
      bill_state: practice?.practiceAddress?.state,
      bill_zip: practice?.practiceAddress?.zip?.replace(/-/g, ''),
      bill_phone: practice.phone,
      practitioner_ids: [],
    },
    validationSchema: PracticeBillingValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
    
      const hasNpiAndTaxId = values.bill_npi && values.bill_taxid;
      const hasTaxonomyAndId = values.bill_taxonomy && values.bill_id;
    
      if (!hasNpiAndTaxId && !hasTaxonomyAndId) {
        showAlert({
          title: 'Please provide either NPI & Tax ID or Taxonomy & ID.',
          color: 'danger',
        });
        setSubmitting(false);
        return;
      }
    
      await submitBillingProvider(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (!practice?.id) return;

    getBillingInfo();
    getStates();
  }, [practice?.id]);

  const getStates = async () => {
    try {
      const statesArray = await getStatesAPI(navigate, { optionify: true });
      setStates(statesArray);
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: getStates, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const getBillingInfo = async () => {
    try {
      const onSuccess = ({ billInfo }) => {
        setBillingInfo(billInfo);
      };
      const onError = (error) => {
        console.log('error', error);
        showAlert({ title: error, color: 'danger' });
      };
      await requestApi({
        url: `/api/admin/practice/billing/read`,
        params: {
          practiceId: practice.id
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: getBillingInfo - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const submitBillingProvider = async (values) => {
    const newBillingProvider = { ...values };
    try {
      const res = await requestApi({
        url: '/api/admin/practice/billing/upsert',
        params: {
          newBillingProvider: {
            ...newBillingProvider,
            bill_taxid_type: newBillingProvider.bill_taxid_type.value
          }
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Success!', color: 'primary' });
          getBillingInfo();
          setShowModal(false)
          setModalType(null)
        } else {
          Honeybadger.notify(
            `file: /billing/AdminBilling, method: submitBillingProvider, error: An unexpected error has occurred.`
          );
          showAlert({ title: 'There was an error.', color: 'danger' });
        }
      } else {
        Honeybadger.notify(
          `file: /billing/AdminBilling, method: submitBillingProvider, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: submitBillingProvider - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleDeletion = async (id) => {
    try {
      const res = await requestApi({
        url: '/api/admin/practice/billing/delete',
        params: {
          id
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Successfully deleted!', color: 'primary' });
          getBillingInfo();
          setShowModal(false)
          setModalType(null)
        } else {
          showAlert({ title: 'There was an error deleting', color: 'danger' });
          Honeybadger.notify(
            `file: /billing/AdminBilling, method: handleDeletion, error: An unexpected error has occurred.`
          );
        }
      } else {
        Honeybadger.notify(
          `file: /billing/AdminBilling, method: handleDeletion, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: handleDeletion - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const selectBiller = (biller) => {
    const updatedValues = {
      id: biller.id,
      practice_id: biller.practice_id,
      bill_name: biller.bill_name,
      bill_npi: biller.bill_npi,
      bill_taxid: biller.bill_taxid,
      bill_taxid_type: biller.bill_taxid_type,
      bill_taxonomy: biller.bill_taxonomy,
      bill_id: biller.bill_id,
      bill_addr_1: biller.bill_addr_1,
      bill_addr_2: biller.bill_addr_2,
      bill_city: biller.bill_city,
      bill_state: biller.bill_state,
      bill_zip: biller.bill_zip,
      bill_phone: biller.bill_phone,
      practitioner_ids: biller.practitioner_ids
    };
    openModal('update', updatedValues);
  };

  return (
    <div className="flex h-fit min-h-[30vh] flex-col p-[1rem]">
      <Button
        icon="plus"
        onClick={() =>
          openModal('create', formik.initialValues)
        }
        text="New"
        size="small"
        className="w-[100px]"
      />
      <div className="!mt-6 overflow-auto !pr-4">
        {iaRa(billingInfo)?.map((row) => {
          return (
            <Row
              key={row.id}
              row={row}
              members={practice?.members}
              onClick={() => selectBiller(row)}
              states={states}
            />
          );
        })}
      </div>
      {
        showModal && (
          <NewBillingProvider
                  formik={formik}
                  open={showModal}
                  type={modalType}
                  handleClose={() => {setShowModal(false); setModalType(null)}}
                  isLoading={formik.isSubmitting}
                  handleSubmit={formik.submitForm}
                  states={states}
                  loadedMembers={iaRa(practice?.members).map((member) => {
                    return { label: `${member.f_name} ${member.l_name}`, value: member.id };
                  })}
                  handleDeletion={handleDeletion}
                />
        )
      }
    
    </div>
  );
}
