import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';
import { pm } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import ProductModal from './ProductModal';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userPermissions = useRecoilValue(permissions);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let options = [
    ...(pm(userPermissions, 'products.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'products.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];

  const deleteProduct = async (product) => {
    const onSuccess = () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'products';
        },
        refetchType: 'all'
      });

      showAlert({
        title: 'Product deletion',
        message: 'Product deleted successfully',
        color: 'success'
      });

      setShowDeleteModal(false);
    };

    const onError = (error, code) => {
      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete products!",
          color: 'danger'
        });
    };

    await requestApi({
      url: 'api/product/update',
      params: {
        product: { ...product, status: 'deleted' }
      },
      onSuccess,
      onError,
      navigate
    });
  };

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <ProductModal product={data} isOpen={showEditModal} handleClose={onEditModalToggle} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete product"
          title="Delete Product"
          icon="new-calendar-red"
          message="Are you sure you want to delete this product?"
          handleOpen={showDeleteModal}
          handleContinue={() => deleteProduct(data)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
