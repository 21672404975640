import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPatientInsuranceProfiles } from 'api/InsuranceProfiles';

export const useInsuranceProfiles = ({ params, options }) => {
  const navigate = useNavigate();
  const { userId } = params;

  return useQuery(
    ['insurance-profiles', userId],
    ({ signal }) => getPatientInsuranceProfiles(navigate, params, signal),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
