import { queryClient } from 'App';

export default async (data) => {
  const { chartId, patientId } = data;
  if (patientId) {
    const allSingleAppointmentQueries = queryClient.getQueryCache().findAll(['appointmentv2']);
    for (const query of allSingleAppointmentQueries) {
      queryClient.setQueryData(query.queryKey, (oldData) => {
        if (oldData && oldData.appointment?.patient.id === patientId) {
          return {
            ...oldData,
            appointment: {
              ...oldData?.appointment,
              patient: {
                ...oldData?.appointment?.patient,
                practicePatientLink: {
                  ...oldData?.appointment?.patient?.practicePatientLink,
                  custom_id: chartId
                }
              }
            }
          };
        }
        return oldData;
      });
    }
  }
};
