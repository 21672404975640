import { calculateBMI, iaRa } from 'lib/helpers/utility';
import { isEqual } from 'lodash';
export const convertoPounds = (pounds, ounces) => {
  return pounds + ounces / 16;
};
export const convertToInches = (feet, inches) => {
  const totalInches = feet * 12 + inches;
  const centimeters = totalInches * 2.54;
  return centimeters;
};

export const handleBMIChange = ({ e, formik }) => {
  const key = e.target.name;
  const value = e.target.value?.replace('null', '');
  if (key === 'weight' && formik?.values?.height > 0) {
    const bmi = calculateBMI({ weight: value, height: formik?.values?.height });
    formik.setFieldValue('bmi', bmi);
  }
  formik.setFieldValue(key, value);
};

export const handlePoundsAndOz = ({ event, ounces, pounds, formik }) => {
  let field = event.target.name;
  let value = parseFloat(event.target.value) || 0;

  if (field === 'pounds') {
    value = convertoPounds(value, ounces);
  } else if (field === 'oz') {
    value = convertoPounds(pounds, value);
  }

  handleBMIChange({ e: { target: { name: 'weight', value: value.toString() } }, formik });
  return;
};
export const handleHeightValue = ({ e, formik }) => {
  const { value: centimeters } = e.target;

  if (formik?.values?.weight > 0) {
    const bmi = calculateBMI({ weight: formik?.values?.weight, height: centimeters });
    if (bmi === 'Infinity' || !bmi) {
      formik.setFieldValue('bmi', '');
      formik.setFieldValue('height', centimeters);
      return;
    }
    formik.setFieldValue('bmi', bmi);
  }
  formik.setFieldValue('height', centimeters);
};

export const handleFeetAndInch = ({ event, inches, feet, formik }) => {
  let field = event.target.name;
  let value = parseFloat(event.target.value) || 0;

  if (field === 'feet') {
    value = convertToInches(value, inches);
  } else if (field === 'inches') {
    value = convertToInches(feet, value);
  }

  handleHeightValue({ e: { target: { value: value.toString() } }, formik });
  return;
};
export const emptyVital = ({ newVital }) => {
  delete newVital?.timestamp;
  delete newVital?.id;
  const emptyVitalState = {
    blood_pressure_diastolic: '',
    blood_pressure_systolic: '',
    bmi: '',
    head_circumference: '',
    head_circumference_unit: 'in',
    heart_rate: '',
    height: '',
    notes: '',
    oxygen_level: '',
    respirations: '',
    temperature: '',
    weight: ''
  };
  return isEqual(newVital, emptyVitalState);
};
export const onNotFoundCreateClick = ({
  setShowVitalsUpsertModal = () => {},
  setVital = () => {}
}) => {
  setShowVitalsUpsertModal(true);
  setVital({});
};
export const handleNumericChange = ({ event, formik, fieldName }) => {
  const value = event?.target?.value;
  if (!isNaN(value)) formik.setFieldValue(fieldName, value);
};
export const formatNumberIfDecimal = (number) => {
  const stringValue = number?.toString();

  if (stringValue?.includes('.')) {
    return parseFloat(number).toFixed(1);
  }

  return number;
};
export const updateVitalsStatesAfterUpsert = ({
  newVitals,
  newPatientVitals,
  values,
  setCurrentHpOverviewData = () => {},
  setOverviewData = () => {},
  currentUser = { f_name: '', l_name: '' }
}) => {
  const createFullName = () => `${currentUser?.f_name} ${currentUser?.l_name}`;

  const updateOrAddVital = (group, newVital, isUpdate = false) => ({
    ...group,
    vitals: isUpdate
      ? iaRa(group?.vitals).map((vital) =>
          vital?.id === values?.id
            ? { createdBy: { fullName: createFullName() }, ...newVital }
            : vital
        )
      : [
          {
            createdBy: { fullName: createFullName() },
            ...newVital
          },
          ...group.vitals
        ]
  });

  const updateVitalsData = (prev, updaterFn) => ({
    ...prev,
    vitals: updaterFn(prev?.vitals)
  });

  if (newPatientVitals) {
    setCurrentHpOverviewData((prev) =>
      updateVitalsData(prev, (vitals) => ({
        ...vitals,
        vitals: [newVitals, ...iaRa(vitals?.vitals)]
      }))
    );

    setOverviewData((prev) => {
      const currentVitals = iaRa(prev?.vitals);
      const existingGroup = currentVitals.find(
        (group) => group?.appointment_id === newVitals?.appointment_id
      );

      return {
        ...prev,
        vitals: existingGroup
          ? currentVitals.map((group) =>
              group?.appointment_id === newVitals?.appointment_id
                ? updateOrAddVital(group, newVitals)
                : group
            )
          : [
              {
                appointment_id: newVitals?.appointment_id,
                vitals: [
                  {
                    createdBy: { fullName: createFullName() },
                    ...newVitals
                  }
                ]
              },
              ...currentVitals
            ]
      };
    });
  } else {
    setCurrentHpOverviewData((prev) =>
      updateVitalsData(prev, (vitals) => ({
        ...vitals,
        vitals: vitals?.vitals?.map((vital) => (vital?.id === values?.id ? newVitals : vital))
      }))
    );

    setOverviewData((prev) => ({
      ...prev,
      vitals: prev?.vitals?.map((group) =>
        group?.vitals.some((vital) => vital?.id === values?.id)
          ? updateOrAddVital(group, newVitals, true)
          : group
      )
    }));
  }
};
export const updateVitalsStatesAfterDelete = ({
  setCurrentHpOverviewData = () => {},
  setOverviewData = () => {},
  vitalId
}) => {
  setCurrentHpOverviewData((prev) => ({
    ...prev,
    vitals: {
      ...prev?.vitals,
      vitals: iaRa(prev?.vitals?.vitals).filter((v) => v?.id !== vitalId)
    }
  }));

  setOverviewData((prev) => ({
    ...prev,
    vitals: prev?.vitals?.map((group) => ({
      ...group,
      vitals: iaRa(group?.vitals).filter((v) => v?.id !== vitalId)
    }))
  }));
};
