import ProfilePicture from 'components/Profile/ProfilePicture';
import React from 'react';

const UserItem = ({ user }) => {
  return (
    <div className="flex items-center gap-2">
      <ProfilePicture
        firstName={user.f_name}
        lastName={user.l_name}
        image={user.profile_photo}
        className="h-12 w-12 rounded-full border-[2px] border-solid border-white"
        id={user.id}
        size={32}
      />
      <div className="flex flex-col">
        <span className="text-sm font-500 text-neutral-700">{user.fullName}</span>
        <span className="text-xs text-neutral-700">Has this appointment opened</span>
      </div>
    </div>
  );
};

export default UserItem;
