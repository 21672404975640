import React from 'react';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { handleModifierSearch } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';

export const customSelectStyle = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 16,
    backgroundColor: '#ffffff',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};

export default function UpdateAddressForm({ formik, states }) {
  const { setFieldValue, values, errors, handleChange } = formik;

  const findStateName = (value) => {
    const stateName = states?.find((state) => {
      if (state?.value == value) {
        return state;
      }
    });

    return stateName && stateName?.label;
  };

  return (
    <div className="grid gap-y-3">
      <Input
        label="Name"
        placeholder="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        disabled
        id="name"
        error={errors.name}
      />

      <Input
        label="Address 1"
        placeholder="Address"
        name="address_ln_1"
        value={values.address_ln_1}
        onChange={handleChange}
        id="address_ln_1"
        error={errors.address_ln_1}
      />
      <Input
        label="Address 2"
        type="text"
        placeholder="Address 2"
        name="address_ln_2"
        value={values.address_ln_2}
        onChange={handleChange}
        id="address_ln_2"
      />
      <Input
        label="City"
        type="text"
        placeholder="City"
        name="city"
        value={values.city}
        onChange={handleChange}
        id="city"
      />
      <Input
        label="Zip"
        placeholder="Zip"
        name="zip"
        value={values.zip}
        onChange={handleChange}
        id="zip"
        error={errors.zip}
      />
      <Select
        label="State"
        className="select-full-width w-full"
        placeholder="Type and select a state"
        value={{ label: findStateName(formik?.values?.state) }}
        options={states}
        onChange={(v) => {
          setFieldValue('state', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        inputId="countryAddress"
        error={errors.state || null}
        menuPortalTarget={document.body}
      />
      <Select
        label="Virtual Modifiers"
        placeholder="Type and select Modifiers"
        isAsync
        isMulti
        isClearable
        value={values.virtual_modifiers?.map((modifier) => ({
          label: modifier,
          value: modifier
        }))}
        loadOptions={handleModifierSearch}
        menuPortalTarget={document.body}
        error={errors.virtual_modifiers || null}
        onChange={(selectedOptions) => {
          setFieldValue(
            'virtual_modifiers',
            selectedOptions ? selectedOptions.map((option) => option.value) : []
          );
        }}
      />
    </div>
  );
}
