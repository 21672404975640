import Tippy from '@tippyjs/react';
import React from 'react';
import UserItem from './UserItem';

const AvatarTooltip = ({ children, users, ...props }) => {
  let content = null;
  if (users?.length === 1) {
    const user = users[0];
    content = <UserItem user={user} key={user.id} />;
  } else {
    content = (
      <>
        {users.map((user) => (
          <UserItem user={user} key={user.id} />
        ))}
      </>
    );
  }
  return (
    <Tippy content={content} placement="top" arrow={true} {...props}>
      {children}
    </Tippy>
  );
};

export default AvatarTooltip;
