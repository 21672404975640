const { capitalize, isEqual } = require('lodash');
const { HPIRevisions } = require('../CustomFormInitialStates');
const { isEmpty } = require('lib/helpers/utility');

exports.createHPINarrative = (data) => {
  if (isEqual(data, HPIRevisions)) {
    return;
  }

  const {
    noDetails,
    location,
    duration,
    timing,
    quality,
    context,
    contextNote,
    relievingFactor,
    relievingFactorsNote,
    modifyingFactors,
    signsAndSymptoms
  } = data;

  if (noDetails) {
    return '<p>No Details Reported</p>';
  }

  let narrative = '';
  let tempNarrative = '';

  if (quality && quality.value) {
    tempNarrative += `<strong>${quality.value.toLowerCase()} pain</strong> `;
  }
  if (location && location.value) {
    tempNarrative += `in the <strong>${location.value.toLowerCase()}</strong> `;
  }
  if (timing && timing.value) {
    tempNarrative += `<strong>${timing.value.toLowerCase()}</strong> `;
  }
  if (duration && duration.value) {
    tempNarrative += `for <strong>${duration.value.toLowerCase()}</strong> `;
  }

  if (!isEmpty(tempNarrative)) {
    narrative += '<p>The patient has been experiencing ';
    narrative += tempNarrative;
    narrative += '.</p>';
  }

  if (context && context.value) {
    narrative += `<p>This occurs during <em>${context.value.toLowerCase()}</em>.</p>`;
  }
  if (contextNote) {
    narrative += `<p>Additional context provided: <em>${capitalize(contextNote)}</em>.</p>`;
  }

  if (signsAndSymptoms) {
    narrative += `<p>Signs and symptoms reported include: <strong>${signsAndSymptoms.trim()}</strong>.</p>`;
  }

  if (relievingFactorsNote) {
    narrative += `<p>The patient noted the following for symptom relief: <strong>${capitalize(
      relievingFactorsNote
    )}</strong>.</p>`;
  }
  if (relievingFactor && relievingFactor.value) {
    narrative += `<p>Relieving factors include: <strong>${relievingFactor.value.toLowerCase()}</strong>.</p>`;
  }

  let modifying = [];
  if (modifyingFactors && !modifyingFactors?.noModifyingFactors) {
    const mf = modifyingFactors;
    if (mf.takenMedication === 'yes') {
      modifying.push(`taken the medication <strong>"${mf.medication}"</strong>`);
    } else if (mf.takenMedication === 'no') {
      modifying.push(`not taken any medication `);
    }

    if (mf.helpedMedication === 'yes') {
      modifying.push('<em>medication helped alleviate symptoms</em>');
    } else if (mf.helpedMedication === 'no') {
      modifying.push('<em>medication did not help alleviate symptoms</em>');
    }

    if (mf.appliedHeat === 'yes') {
      modifying.push('applied heat');
    } else if (mf.appliedHeat === 'no') {
      modifying.push('did not apply heat');
    }

    if (mf.helpedHeat === 'yes') {
      modifying.push('<em>heat helped alleviate symptoms</em>');
    } else if (mf.helpedHeat === 'no') {
      modifying.push('<em>heat did not help alleviate symptoms</em>');
    }

    if (mf.appliedColdCompress === 'yes') {
      modifying.push('used a cold compress');
    } else if (mf.appliedColdCompress === 'no') {
      modifying.push('did not use a cold compress');
    }

    if (mf.helpedColdCompress === 'yes') {
      modifying.push('<em>cold compress helped alleviate symptoms</em>');
    } else if (mf.helpedColdCompress === 'no') {
      modifying.push('<em>cold compress did not help alleviate symptoms</em>');
    }

    if (mf.hadSurgery === 'yes') {
      modifying.push('had surgery for this issue');
    } else if (mf.hadSurgery === 'no') {
      modifying.push('has not had surgery for this issue');
    }

    if (mf.helpedSurgery === 'yes') {
      modifying.push('<em>surgery helped improve symptoms</em>');
    } else if (mf.helpedSurgery === 'no') {
      modifying.push('<em>surgery did not help improve symptoms</em>');
    }

    if (mf.triedPhysicalTherapy === 'yes') {
      modifying.push('tried physical therapy');
    } else if (mf.triedPhysicalTherapy === 'no') {
      modifying.push('has not tried physical therapy');
    }

    if (mf.helpedPhysicalTherapy === 'yes') {
      modifying.push('<em>physical therapy was beneficial</em>');
    } else if (mf.helpedPhysicalTherapy === 'no') {
      modifying.push('<em>physical therapy was not beneficial</em>');
    }

    if (mf.beenRespirator === 'yes') {
      modifying.push('been on a respirator');
    } else if (mf.beenRespirator === 'no') {
      modifying.push('has not been on a respirator');
    }

    if (mf.helpedRespirator === 'yes') {
      modifying.push('<em>respirator use helped alleviate symptoms</em>');
    } else if (mf.helpedRespirator === 'no') {
      modifying.push('<em>respirator use did not help alleviate symptoms</em>');
    }

    if (mf.triedRest === 'yes') {
      modifying.push('tried rest');
    } else if (mf.triedRest === 'no') {
      modifying.push('has not tried rest');
    }

    if (mf.helpedRest === 'yes') {
      modifying.push('<em>rest was helpful</em>');
    } else if (mf.helpedRest === 'no') {
      modifying.push('<em>rest was not helpful</em>');
    }

    if (mf.otherModifyingFactors) {
      modifying.push(
        `tried other approaches such as <strong>"${mf.otherModifyingFactors}"</strong>`
      );
    }

    if (mf.helpedOtherModifyingFactors === 'helped') {
      modifying.push('<em>these approaches were helpful</em>');
    } else if (mf.helpedOtherModifyingFactors === 'hurt') {
      modifying.push('<em>these approaches worsened the symptoms</em>');
    }

    if (modifying.length > 0) {
      narrative += `<p>The patient has also ${formatList(modifying)} to manage their condition.</p>`;
    }
  } else if (modifyingFactors?.noModifyingFactors) {
    narrative += `<p>The patient has not tried any modifying factors to manage their condition.</p>`;
  }

  return narrative;
};

const formatList = (items) => {
  if (items?.length === 1) return items[0];
  return items?.slice(0, -1).join(', ') + ', and ' + items?.[items.length - 1];
};
