import React, { useEffect, useState } from 'react';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Switch from 'components/shared/Switch/Switch';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { timeUnitArray, emptyProceduresObject, emptyProductObject } from '../lib/initials';
import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useNavigate } from 'react-router-dom';
import { imagePath } from 'lib/helpers/utility';

export default function PackageForm({ formik, procedures, products, updating }) {
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const { setFieldValue, values, touched, errors, handleChange, handleBlur } = formik;
  const [isEditing, setIsEditing] = useState(updating);

  useEffect(() => {
    if (!isEditing) {
      const totalAmountOfProcedures = values.procedures.reduce(function (previousVal, currentVal) {
        return previousVal + Number(currentVal.total_amount_cents);
      }, 0);

      const totalAmountOfProducts = values.products.reduce(function (previousVal, currentVal) {
        return previousVal + Number(currentVal.total_amount_cents);
      }, 0);
      const total_price = Number(totalAmountOfProducts) + Number(totalAmountOfProcedures);
      setFieldValue('amount_cents', total_price);
      setTotalPrice(total_price);
    }
  }, [values.procedures, values.products]);

  useEffect(() => {
    setIsEditing(false);
  }, []);

  const handleChangeQuantity = (val, id, field) => {
    const quantity = Number(val);
    setFieldValue(
      field,
      values[field].map((s) => {
        if (s.id === id) {
          return { ...s, quantity, total_amount_cents: s.amount_cents * quantity };
        }
        return s;
      })
    );
  };

  const handleChangePrice = (val, id, field) => {
    const price = Number(val);

    setFieldValue(
      field,
      values[field].map((s) => {
        if (s.id === id) {
          return { ...s, total_amount_cents: price };
        }
        return s;
      })
    );
  };

  const newProcedure = () => {
    const newArray = [...values.procedures, emptyProceduresObject];
    setFieldValue('procedures', newArray);
  };

  const newProduct = () => {
    const newArray = [...values.products, emptyProductObject];
    setFieldValue('products', newArray);
  };

  const deleteProduct = (idx) => {
    if (values.products.length === 1) {
      setFieldValue('products', [emptyProductObject]);
      return;
    }
    const newArray = [...values.products];
    newArray.splice(idx, 1);

    setFieldValue('products', newArray);
  };

  const deleteProcedure = (idx) => {
    if (values.procedures.length === 1) {
      setFieldValue('procedures', [emptyProceduresObject]);
      return;
    }
    const newArray = [...values.procedures];
    newArray.splice(idx, 1);

    setFieldValue('procedures', newArray);
  };

  const handleSelect = (value, idx, field) => {
    let newValues = [...values[field]];
    newValues = newValues.map((item, i) => (i === idx ? value : item));
    setFieldValue(field, newValues);
  };

  const handleClickTimeUnit = (timeUnit) => {
    setFieldValue('validity_time_unit', timeUnit);
  };

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      maxSize: 3 * 1024 * 1024
    };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };

    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    filestackClient.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      if (handle) {
        formik.setFieldValue('image', JSON.stringify(handle));
      }
    } catch (error) {}
  };
  // const handleCurrentUnitChange = () => {

  // }

  return (
    <div className="relative flex h-full flex-col justify-between gap-4">
      <div className="grid gap-4 overflow-y-auto overflow-x-hidden !pr-3">
        <div className="flex !gap-5">
          <div className="w-[65%] !gap-5">
            <Input
              label="Name"
              placeholder="Enter package name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              id="packageName"
              data-qa="package-name"
              name="name"
              className="mb-3"
            />
            <Textarea
              label="Note"
              placeholder="Enter note"
              value={values.note}
              onChange={handleChange}
              error={errors.note}
              id="note"
              data-qa="package-note"
              name="note"
            />
          </div>
          <div className="flex w-[35%] flex-col">
            <label className="m-0 !pr-4 pb-[6px] text-sm font-500 text-neutral-800">Image</label>
            <div
              onClick={openFilestackPicker}
              className="flex h-auto flex-1 cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100">
              <div className="flex flex-col items-center justify-center">
                {values.image ? (
                  <img
                    className="object-contain"
                    style={{ maxWidth: '80%' }}
                    src={imagePath(JSON.parse(values.image).jpg)}
                    alt="Product Image"
                  />
                ) : (
                  <>
                    <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                    <p className="m-1 text-center text-xs font-normal text-neutral-500">
                      Click here or drag and{' '}
                      <span className="!m-0 block !p-0">drop image to upload</span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-[63%,35%] !gap-5">
          <div className="relative">
            <Input
              label="Validity"
              placeholder=""
              value={values.validity_time_length}
              onChange={(e) => {
                const val = Number(e.target.value) || 0;
                setFieldValue('validity_time_length', val);
                setFieldValue('no_expiration', !val);
              }}
              onBlur={handleBlur}
              error={touched.validity_time_length && errors.validity_time_length}
              id="validityTimeUnit"
              data-qa="package-validity"
              name="validity_time_length"
              className="mb-3"
              type="number"
              units={timeUnitArray}
              currentUnit={values.validity_time_unit}
              onUnitChange={handleClickTimeUnit}
            />
          </div>
          <div>
            <label
              className="m-0 !mb-2 !pr-4 pb-[6px] text-sm font-500 text-neutral-800"
              data-qa="no-expiration-date">
              No expiration date
            </label>
            <Switch
              checked={values.no_expiration}
              onChange={(val) => {
                setFieldValue('no_expiration', val);
                setFieldValue('validity_time_length', val ? 0 : 1);
              }}
            />
          </div>
        </div>
        <div className="relative rounded-md bg-white !p-4 shadow-sm">
          {values.procedures.map((procedure, idx) => (
            <div className="!mb-2 flex !gap-2" key={procedure?.id}>
              <div className="w-[40%]">
                <Select
                  onChange={(value) => handleSelect(value, idx, 'procedures')}
                  value={procedure}
                  options={procedures}
                  placeholder="Type and select procedures"
                  label={idx === 0 && 'Service'}
                  className="select-full-width w-full"
                  key={procedure.id}
                  isClearable={false}
                  id="set-procedure"
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="flex w-[60%] !gap-2">
                <CurrencyInput
                  id="unit-price"
                  key={procedure.amount_cents}
                  label={idx === 0 && 'Unit Price'}
                  value={procedure.amount_cents}
                  className="!h-10 !rounded-md border border-neutral-100 !px-1"
                  disabled
                />
                <Input
                  label={idx === 0 && 'Qty'}
                  value={procedure.quantity}
                  onChange={(e) => handleChangeQuantity(e.target.value, procedure.id, 'procedures')}
                  onBlur={handleBlur}
                  error={errors?.procedures?.[idx]?.quantity}
                  id="quantity"
                  name="quantity"
                  type="number"
                  className="!w-[110px] flex-none"
                />
                <CurrencyInput
                  id="total-value"
                  key={`${procedure.quantity}${procedure.label}$procedure?.id`}
                  label={idx === 0 && 'Total'}
                  value={procedure.total_amount_cents}
                  onValueChange={(val) => handleChangePrice(val, procedure.id, 'procedures')}
                  className="!h-10 !rounded-md border border-neutral-100 !px-1"
                  error={errors?.procedures?.[idx]?.total_amount_cents}
                />

                <Icon
                  icon="trash"
                  className={idx === 0 && `!mt-6 `}
                  color="neutral"
                  onClick={() => deleteProcedure(idx)}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <div className="flex cursor-pointer items-center" onClick={newProcedure}>
              <p
                className="!m-2 w-full !text-right text-sm text-primary-500"
                data-qa="add-procedure">
                Add Service
              </p>
              <Icon icon="new-add-circle" className="cursor-pointer" />
            </div>
          </div>
        </div>
        <div
          className="rounded-md bg-white !p-4 shadow-sm"
          key={'product' + values.products.length}>
          {values.products.map((product, idx) => (
            <div className="!mb-2 flex !gap-2" key={product.id}>
              <div className="w-[40%]">
                <Select
                  onChange={(value) => handleSelect(value, idx, 'products')}
                  value={product}
                  options={products}
                  placeholder="Type and select products"
                  label={idx === 0 && 'Product'}
                  className="select-full-width w-full"
                  key={product.id}
                  isClearable={false}
                  id="set-product"
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="flex w-[60%] !gap-2">
                <CurrencyInput
                  key={product.amount_cents}
                  label={idx === 0 && 'Unit Price'}
                  value={product.amount_cents}
                  className="!h-10 !rounded-md border border-neutral-100 !px-1"
                  disabled
                />
                <Input
                  label={idx === 0 && 'Qty'}
                  value={product.quantity}
                  onChange={(e) => handleChangeQuantity(e.target.value, product.id, 'products')}
                  onBlur={handleBlur}
                  error={errors?.products?.[idx]?.quantity}
                  id="quantity"
                  name="values.products[idx].quantity"
                  type="number"
                  className="!w-[110px] flex-none"
                />

                <CurrencyInput
                  key={`${product.quantity}${product.label}${product.id}`}
                  label={idx === 0 && 'Total'}
                  value={product.total_amount_cents}
                  onValueChange={(val) => handleChangePrice(val, product.id, 'products')}
                  className="!h-10 !rounded-md border border-neutral-100 !px-1"
                  error={errors?.products?.[idx]?.total_amount_cents}
                />
                <Icon
                  icon="trash"
                  className={idx === 0 && `!mt-6`}
                  color="neutral"
                  onClick={() => deleteProduct(idx)}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <div className="flex cursor-pointer items-center" onClick={newProduct}>
              <p className="!m-2 w-full !text-right text-sm text-primary-500">Add Product</p>
              <Icon icon="new-add-circle" className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
      <div className="!pr-3">
        <div className="flex h-[72px] justify-end rounded-lg bg-gradient-to-r from-primary-50 to-primary-100">
          <div className="flex w-[300px] items-center gap-6 !p-4">
            <label className="text-sm font-500 text-primary-900">Total package price</label>
            <CurrencyInput
              key={totalPrice}
              value={values.amount_cents}
              onValueChange={(val) => setFieldValue('amount_cents', Number(val))}
              className="!h-10 !rounded-md border border-neutral-100"
              parentClassName="w-[110px]"
              error={touched.amount_cents && errors.amount_cents}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
