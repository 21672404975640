import React, { useState } from 'react';
import { upperCase } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useEraContext } from '../../../../../lib/context/EraContext/EraContext';
import { calcAdjustment, calcTotalAdjustment } from './ERA&EOBHelpers';
import UnMatchedClaimsRows from './components/UnMatchedClaimsRows';
import { ia, mString } from '../../../../../lib/helpers/utility';
import MatchedClaimsRows from './components/MatchedClaimsRows';
import { matchClaim, postEOB } from '../../../../../api/ERA';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { showAlert } from '../../../../shared/Alert/Alert';
import AssignUnmatchedClaim from './AssignUnmatchedClaim';
import { currentPractice } from '../../../practiceState';
import Button from '../../../../shared/Buttons/Button';
import Modal from '../../../../shared/Modal/Modal';
import AllocateEOB from './AllocateEOB';
import PreviewFile from 'components/shared/PreviewFile/PreviewFile';

const View = () => {
  const currPractice = useRecoilValue(currentPractice);
  const {
    era,
    eraLoading,
    codes,
    selectedClaim,
    setSelectedClaim,
    type,
    selectedUnmatchedClaim,
    allocateEob,
    setAllocateEob,
    amount,
    procedures,
    status_code
  } = useEraContext();

  const navigate = useNavigate();

  const [viewEOB, setViewEOB] = useState(false);

  const queryClient = useQueryClient();

  const mutateMatchClaim = useMutation({
    mutationFn: () =>
      matchClaim(navigate, {
        eraId: era.id,
        claimId: selectedUnmatchedClaim,
        unmatchedClaimIndex: selectedClaim?.index
      }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          queryClient.invalidateQueries(['era']);
          setSelectedClaim(null);
          showAlert({
            title: `Claim posted successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to post claim!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to post claim!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutatePostEOB = useMutation({
    mutationFn: () => {
      if (!status_code) {
        showAlert({
          message: 'Please select a status code.',
          icon: 'new-close-circle',
          color: 'danger'
        });
        return;
      }

      return postEOB(navigate, {
        amount,
        procedures,
        eobId: era.id,
        claimId: selectedUnmatchedClaim,
        status_code
      });
    },
    onSuccess: (data, error) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          queryClient.invalidateQueries(['era']);
          setAllocateEob(false);
          showAlert({ title: `Claim posted successfully!` });
          break;
        case 2:
          showAlert({
            title: `Failed to post claim!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;
        case 6:
          showAlert({
            title: `Failed to post claim!`,
            message: error ?? 'Payment amount exceeds available unallocated amount on this EOB.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to post claim!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const handleClose = () => {
    navigate(`/portal/beyond-billing/era-eob/${type}`);
  };

  if (eraLoading) return <Skeleton />;

  return (
    <Modal
      isOpen={true}
      slideFromRight
      handleClose={() => handleClose()}
      title={`${upperCase(era?.type)} #${era?.id} ${
        selectedClaim ? '> Post Claim' : allocateEob ? '> Allocate Amount' : ''
      }`}
      prevButton={
        (selectedClaim || allocateEob) && (
          <Button
            icon="back-large"
            color="primary-900"
            onClick={() => {
              setAllocateEob(false);
              setSelectedClaim(null);
            }}
            transparent
            data-qa="back-btn"
          />
        )
      }
      isLarge
      headButton={
        era?.type === 'eob' && (
          <div className="flex w-full justify-end">
            <Button text="Preview EOB" transparent onClick={() => setViewEOB(true)} />
          </div>
        )
      }
      footer={
        <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:gap-2">
          <Button
            text="Cancel"
            onClick={() => handleClose()}
            color="neutral"
            transparent
            outlined
            data-qa="cancel-btn"
          />
          {selectedClaim && (
            <Button
              text="Post"
              onClick={() => mutateMatchClaim.mutate()}
              disabled={!selectedClaim}
            />
          )}
          {allocateEob && (
            <Button
              text="Post"
              onClick={() => mutatePostEOB.mutate()}
              disabled={!selectedUnmatchedClaim}
            />
          )}
        </div>
      }>
      <div className="px-4">
        {selectedClaim ? (
          <AssignUnmatchedClaim />
        ) : allocateEob ? (
          <AllocateEOB />
        ) : (
          <>
            <div className="mb-6 flex justify-between">
              <div className="flex flex-col">
                <h3 className="text-lg font-bold text-primary-900">{era?.payer?.name}</h3>
                <h3 className="text-sm text-primary-800">
                  {era?.payer?.payerAddress?.fullAddress}
                </h3>
              </div>
              <div className="flex flex-col text-center">
                <h3 className="text-lg font-bold text-primary-900">
                  Total Paid:{' '}
                  {era?.paid_amount >= 0 ? mString(era?.paid_amount) : mString(era?.total_paid)}
                </h3>
                <h3 className="text-sm text-primary-800">
                  Total Adjustment: {calcTotalAdjustment(era?.claims, era?.unmatched_claims)}
                </h3>
              </div>
              <div className="flex flex-col text-end">
                <h3 className="text-lg font-bold text-primary-900">{currPractice?.name}</h3>
                <h3 className="text-sm text-primary-800">{currPractice?.address}</h3>
              </div>
            </div>
            {era?.type === 'eob' && (
              <div className="flex justify-between">
                <h3 className="text-lg font-bold text-primary-900">
                  Unallocated Amount: {mString(era?.unallocated_amount_cents)}
                </h3>
                <Button text="Allocate" onClick={() => setAllocateEob(true)} />
              </div>
            )}
            {ia(era?.unmatched_claims) && (
              <>
                <h3 className="!mb-2 text-primary-900">Not Posted</h3>
                <UnMatchedClaimsRows
                  era={era}
                  timezone={currPractice.timezone}
                  codes={codes}
                  calcAdjustment={calcAdjustment}
                  setSelectedClaim={setSelectedClaim}
                />
              </>
            )}
            {ia(era?.claims) && (
              <>
                <h3 className="!mb-2 !mt-4 text-primary-900">Posted</h3>
                <MatchedClaimsRows
                  era={era}
                  timezone={currPractice.timezone}
                  codes={codes}
                  calcAdjustment={calcAdjustment}
                  setSelectedClaim={setSelectedClaim}
                />
              </>
            )}
          </>
        )}

        {viewEOB && (
          <PreviewFile
            isOpen={viewEOB}
            title="Uploaded EOB"
            handle={era?.handle}
            onClose={() => setViewEOB(false)}
            slideFromRight
            isLarge
          />
        )}
      </div>
    </Modal>
  );
};

export default View;
