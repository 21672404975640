import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteWriteOff } from 'api/Transaction';

import { usePatientBalanceContextApi } from 'lib/context/PatientBalanceContext/PatientBalanceContext';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

export const UnAllocateAction = ({ data, rowIndex }) => {
  const [confirmation, setConfirmation] = useState({ type: null, loading: false });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { setActiveMode, setActiveTab, setAllocatingRow } = usePatientBalanceContextApi();

  const isWriteOff = data?.type === 'writeoff';
  const isDelete = confirmation.type === 'trash';

  const onAllocateClick = () => {
    setActiveTab(0);
    setAllocatingRow({ ...data });
    setActiveMode({ writeoff: false, allocate: true });
  };

  const deleteMutation = useMutation({
    mutationFn: () => deleteWriteOff(navigate, { transactionId: data.id, type: 'unallocated' }),
    onSuccess: async () => {
      showAlert({
        title: 'Success',
        message: 'Write off transaction deleted successfully!',
        color: 'success'
      });

      setConfirmation({ type: null, loading: false });
      queryClient.refetchQueries({ queryKey: ['patientInvoices'] });
      queryClient.refetchQueries({ queryKey: ['allocated'] });
      queryClient.refetchQueries({ queryKey: ['unallocated'] });
    },
    onError: () => {
      setConfirmation((prev) => ({ ...prev, loading: false }));
    }
  });

  const onTrashClick = () => {
    setConfirmation({ type: 'trash', loading: false });
  };

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  return (
    <div className="flex h-full items-center justify-start gap-8">
      <Button
        data-qa={`allocate-${rowIndex}`}
        size="small"
        type="secondary"
        text="Allocate"
        onClick={onAllocateClick}
      />
      {isWriteOff && <Icon icon="trash" onClick={onTrashClick} />}

      {isDelete && (
        <Confirm
          variant="danger"
          loading={confirmation.loading}
          primaryBtnTxt="Delete"
          title="Delete write off transaction"
          handleContinue={handleDelete}
          handleOpen={isDelete}
          handleClose={() => setConfirmation({ type: null, loading: false })}
          message="Do you want to delete this write off transaction?"
        />
      )}
    </div>
  );
};
