import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { socket } from 'api/Socket';
import state from 'components/state';
import { iaRa } from 'lib/helpers/utility';
import GroupedAvatar from 'components/shared/GroupedAvatar/GroupedAvatar';

const ActiveUsers = () => {
  const { values: appointment } = useFormikContext();
  const currentUser = useRecoilValue(state.userState);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    socket.emit('appointment-open', { appointment_id: appointment.id });

    socket.on(`appointment-${appointment?.id}-opened`, (data) => {
      setActiveUsers(iaRa(data.users).filter((user) => user.id !== currentUser?.user_id));
    });

    socket.on(`appointment-${appointment?.id}-closed`, (data) => {
      setActiveUsers(iaRa(data.users).filter((user) => user.id !== currentUser?.user_id));
    });

    return () => {
      socket.emit('appointment-close', {
        appointment_id: appointment.id,
        user_id: currentUser?.user_id
      });
    };
  }, []);

  return <GroupedAvatar users={activeUsers} size="SMALL" />;
};

export default ActiveUsers;
