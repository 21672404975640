import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  currentPractice,
  appointmentStatuses as aptStatuses
} from 'components/practice/practiceState';
import ClinicalNotesTable from './ClinicalNotesTable';
import { defaultColumns, getPracticeDefaultFilters } from '../lib/helper';

const ClinicalNotesTableWrapper = ({ patient_id }) => {
  const { id } = useParams();
  const patientId = patient_id || id;
  const practice = useRecoilValue(currentPractice);
  const appointmentStatuses = useRecoilValue(aptStatuses);

  return (
    <TableContextProvider
      defaultFilters={getPracticeDefaultFilters(practice)}
      cols={defaultColumns(practice?.timezone, appointmentStatuses)}
      name="patient_clinical_notes"
      pagination>
      <ClinicalNotesTable patientId={patientId} />
    </TableContextProvider>
  );
};

export default ClinicalNotesTableWrapper;
