import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';
import { pm } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import PackageModal from './PackageModal';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userPermissions = useRecoilValue(permissions);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let options = [
    ...(pm(userPermissions, 'packages.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'packages.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];

  const handleDelete = async (pack) => {
    const onSuccess = () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'packages';
        },
        refetchType: 'all'
      });

      showAlert({ title: 'Package deleted successfully', color: 'success' });
      setShowDeleteModal(false);
    };

    const newPack = { ...pack };
    delete newPack.procedures;
    delete newPack.products;

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/update',
      params: { pack: { ...newPack, status: 'deleted' } },
      onSuccess,
      navigate,
      onError
    });
  };

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <PackageModal
          currentPackage={data}
          isOpen={showEditModal}
          handleClose={onEditModalToggle}
        />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete package"
          title="Delete Package"
          icon="new-calendar-red"
          message="Are you sure you want to delete this package?"
          handleOpen={showDeleteModal}
          handleContinue={() => handleDelete(data)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
