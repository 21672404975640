import React, { useMemo, useState } from 'react';

import 'react-confirm-alert/src/react-confirm-alert.css';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { defaultColumns, defaultFilters } from './lib/helpers';
import ProductModal from './components/ProductModal';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import TableHeader from './components/TableHeader';
import { useProducts } from 'lib/hooks/queries/products/useProducts';
import { mapValues } from 'lib/helpers/utility';
import { useProductsCategories } from 'lib/hooks/queries/products/useProductCategories';

const Products = () => {
  const columnDefs = defaultColumns();

  const { data } = useProductsCategories();

  const defaultFilterOptions = useMemo(() => {
    const categories = data?.categories || [];
    const newDefaultFilters = defaultFilters(categories);
    return newDefaultFilters;
  }, [data]);

  return (
    <TableContextProvider
      defaultFilters={defaultFilterOptions}
      cols={columnDefs}
      name="products"
      pagination>
      <ProductsTable />
    </TableContextProvider>
  );
};
const ProductsTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);

  const { limit, page, sort, setPage, filters } = useTableContext();

  const { data, isLoading, isFetching } = useProducts({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      withCount: true
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const products = data?.products;
  const count = data?.count || 0;

  const onCellClicked = async (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    setSelectedProduct(event?.data);
    setShowEditModal(true);
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100
    };
  }, []);

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
    setSelectedProduct(false);
  };

  return (
    <>
      <TableHeader setNewProduct={onEditModalToggle} />
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading ? (
            <Skeleton count={10} />
          ) : (
            <AGTable
              data={products}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {showEditModal && (
        <ProductModal
          product={selectedProduct}
          isOpen={showEditModal}
          handleClose={onEditModalToggle}
          filters={filters}
        />
      )}
    </>
  );
};

export default Products;
