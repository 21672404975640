import React, { useState } from 'react';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';
import toast from 'react-hot-toast';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Loading from 'components/shared/Loading/Loading';
import { getFilestackClient } from 'lib/clients/filestack/filestackClient';

const Card = ({
  handle,
  setViewDocument,
  patientId,
  type,
  label,
  url,
  loading,
  setLoading,
  setId,
  setInsurance
}) => {
  const navigate = useNavigate();
  const [zoomed, setZoomed] = useState({ visible: false, image: null });

  const filestackInline = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      displayMode: 'inline',
      container: `FilestackInline-${type}`,
      uploadInBackground: false,
      fromSources: ['local_file_system', 'webcam'],
      exposeOriginalFile: true,
      imageMin: [200, 200],
      rootId: `Inline-${type}`
    };

    pickerOptions.onUploadDone = handleSave;
    filestackClient.picker(pickerOptions).open();
  };

  const filestackOverlay = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      fromSources: ['local_file_system', 'webcam'],
      exposeOriginalFile: true,
      imageMin: [200, 200],
      rootId: `Overlay-${type}`
    };

    pickerOptions.onUploadDone = handleSave;
    filestackClient.picker(pickerOptions).open();
  };

  const handleSave = async (files) => {
    let handleUploaded = {};

    setLoading((prev) => ({ ...prev, [type]: true }));

    switch (type) {
      case 'personal_id_front':
        handleUploaded = { front: files?.filesUploaded[0]?.handle, back: handle?.back };
        break;

      case 'personal_id_back':
        handleUploaded = { front: handle?.front, back: files?.filesUploaded[0]?.handle };
        break;

      case 'insurance_front':
        handleUploaded = { front: files?.filesUploaded[0]?.handle, back: handle?.back };
        break;

      case 'insurance_back':
        handleUploaded = { front: handle?.front, back: files?.filesUploaded[0]?.handle };
        break;

      default:
        toast.error(`There's been an error while deleting image.`);
        break;
    }

    let data = await requestApi({
      url: `/api/filestack/${type}`,
      params: {
        handle: handleUploaded,
        userId: patientId
      },
      navigate
    });

    if (data.front > 0 || data.back > 0) {
      setViewDocument((prev) => ({ ...prev, handle: handleUploaded }));

      if (type === 'personal_id_front' || type === 'personal_id_back') setId(handleUploaded);

      if (type === 'insurance_front' || type === 'insurance_back') setInsurance(handleUploaded);
    } else {
      toast.error('Image update failed. Please try again.');
    }
    setLoading((prev) => ({ ...prev, [type]: false }));
  };

  const deleteHandle = async (type) => {
    let handleDeleted = {};

    setLoading((prev) => ({ ...prev, [type]: true }));

    switch (type) {
      case 'personal_id_front':
        handleDeleted = { front: null, back: handle?.back };
        break;

      case 'personal_id_back':
        handleDeleted = { front: handle?.front, back: null };
        break;

      case 'insurance_front':
        handleDeleted = { front: null, back: handle?.back };
        break;

      case 'insurance_back':
        handleDeleted = { front: handle?.front, back: null };
        break;

      default:
        toast.error(`There's been an error while deleting image.`);
        break;
    }

    const data = await requestApi({
      url: `/api/filestack/${type}`,
      params: {
        handle: handleDeleted,
        userId: patientId
      },
      navigate
    });

    if (data.front > 0 || data.back > 0) {
      setViewDocument((prev) => ({ ...prev, handle: handleDeleted }));

      if (type === 'personal_id_front' || type === 'personal_id_back') setId(handleDeleted);

      if (type === 'insurance_front' || type === 'insurance_back') setInsurance(handleDeleted);
    } else {
      toast.error('Image update failed. Please try again.');
    }

    setLoading((prev) => ({ ...prev, [type]: false }));
  };

  return (
    <div className="CardWrapper">
      <div className="flex items-center justify-between gap-x-2">
        <label className="pb-[6px] text-sm font-500 text-neutral-800" htmlFor="front-card-btn">
          {label}
        </label>
        <div className="flex items-center gap-x-2">
          <Icon icon="new-edit" onClick={filestackOverlay} />
          <Icon icon="trash" color="primary" onClick={() => deleteHandle(type)} />
        </div>
      </div>
      <div className={cs('Inner', url && 'flex items-center justify-between')}>
        {loading ? (
          <Loading />
        ) : url ? (
          <img
            className="cursor-zoom-in"
            src={url}
            onClick={() => setZoomed({ visible: true, image: url })}
          />
        ) : (
          <div id={`FilestackInline-${type}`} className="FilestackInline ">
            {filestackInline}
          </div>
        )}
      </div>
      <Modal
        isOpen={zoomed.visible}
        handleClose={() => setZoomed({ visible: false, image: null })}
        className="h-[80%] w-[80%]"
        isFooter={false}>
        <img className="h-full w-full object-contain" src={zoomed.image} />
      </Modal>
    </div>
  );
};

export default Card;
