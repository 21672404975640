export default (size) => {
  if (
    ![
      'X_SMALL',
      'SMALL',
      'MEDIUM',
      'LARGE',
      'EXTRA_LARGE',
      '2Extra_LARGE',
      '3Extra_LARGE',
      '4Extra_LARGE'
    ].includes(size)
  ) {
    return 'SMALL';
  }
  return size;
};
