import React, { useState } from 'react';

import Modal from '../../../../../../../shared/Modal/Modal';
import Input from '../../../../../../../shared/Input/Input';
import Icon from '../../../../../../../shared/Icon/Icon';
import Select from '../../../../../../../shared/Select/Select';
import { optionify } from '../../../../../../../../lib/helpers/utility';
import cs from 'classnames';
import { capitalize } from 'lodash';
import { useProductsSearch } from '../../../../../../../../lib/hooks/queries/products/useProductsSearch';
import { useProductsCategories } from '../../../../../../../../lib/hooks/queries/products/useProductCategories';
import { useServicesSearch } from '../../../../../../../../lib/hooks/queries/services/useServices';
import { handleICD10Search } from '../../SOAPNote/components/ICDCodes';

export default function ProductsModal({ setProduct, ...props }) {
  const types = ['product', 'service', 'icd10'];
  const [type, setType] = useState(types[0]);

  return (
    <Modal slideFromRight className="!max-w-[480px]" title={`Add ${type}`} {...props}>
      <ol className="flex gap-4 py-2">
        <li
          className={cs(
            'flex cursor-pointer flex-col items-center justify-center text-sm text-neutral-600'
          )}>
          Add:
        </li>

        {types.map((t, index) => (
          <li
            key={index}
            data-qa={`tab-${t}`}
            onClick={() => setType(t)}
            className={cs(' cursor-pointer flex-col', type == t && 'text-primary-900')}>
            <p className="px-1 py-[6px] text-base">{capitalize(t)}</p>
            {type == t && <div className="h-[2px] w-full bg-primary-700"></div>}
          </li>
        ))}
      </ol>

      {type == 'product' && (
        <Products
          props={props}
          setProduct={setProduct}
          products={props.products}
          filters={props.filters}
          setFilters={props.setFilters}
        />
      )}
      {type == 'service' && <Services props={props} setProduct={setProduct} />}
      {type == 'icd10' && <Diagnosis props={props} setProduct={setProduct} />}
    </Modal>
  );
}

function Item({ item, onSelect, index }) {
  return (
    <div
      data-qa={`item-${index}`}
      onClick={() => onSelect(item)}
      className="flex cursor-pointer items-center gap-2 rounded-md bg-white p-3 !pr-5">
      <Icon icon="new-verify" />
      {item.name}
    </div>
  );
}

const Products = ({ setProduct, products, setFilters, filters, props }) => {
  const onSelect = (item) => {
    setProduct({ ...item, item_type: 'product' });
    props.handleClose();
  };

  const { data, isLoading } = useProductsCategories();
  const categories = data?.categories;

  return (
    <div>
      <div className="mb-3 flex items-center gap-2">
        <Input
          data-qa="search-products"
          id="searchProducts"
          type="string"
          placeholder="Search product..."
          value={filters.searchTerm}
          icon="new-search"
          className=" w-full !border-secondary-400"
          onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          rightText={
            filters.searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setFilters({ ...filters, searchTerm: '', page: 1 });
                }}
              />
            )
          }
        />
        <div className="flex items-center justify-center">
          {!isLoading && (
            <Select
              inputId="select-category"
              placeholder="Category..."
              options={optionify(categories)}
              onChange={(e) =>
                e?.value
                  ? setFilters({ ...filters, categoryId: e.value })
                  : setFilters({ ...filters, categoryId: null })
              }
              value={
                filters.categoryId && {
                  label: categories?.find((category) => category?.id === filters.categoryId)?.name
                }
              }
              className="!w-48"
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2 pb-3">
        {products?.map((product, index) => (
          <Item onSelect={onSelect} item={product} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

const Services = ({ setProduct, props }) => {
  const { filters, setFilters, services } = useServicesSearch({});

  const onSelect = (item) => {
    setProduct({ ...item, item_type: 'service' });

    props.handleClose();
  };

  return (
    <div>
      <div className="mb-3 flex items-center gap-2">
        <Input
          data-qa="search-services"
          id="searchServices"
          type="string"
          placeholder="Search services..."
          value={filters.searchTerm}
          icon="new-search"
          className="w-full !border-secondary-400"
          onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          rightText={
            filters.searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setFilters({ ...filters, searchTerm: '', page: 1 });
                }}
              />
            )
          }
        />
      </div>

      <div className="flex flex-col gap-2 !pb-3">
        {services?.map((product, index) => (
          <Item onSelect={onSelect} item={product} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

const Diagnosis = ({ setProduct, props }) => {
  const [search, setSearch] = useState('');
  const [codes, setCodes] = useState([]);

  const onSelect = (item) => {
    setProduct({ ...item, item_type: 'icd10' });
    props.handleClose();
  };

  return (
    <div>
      <div className="mb-3 flex items-center gap-2">
        <Input
          data-qa="search-diagnosis"
          id="searchDiagnosis"
          type="string"
          placeholder="Search icd10 diagnosis..."
          value={search}
          icon="new-search"
          className="w-full !border-secondary-400"
          onChange={async (e) => {
            setSearch(e.target.value);
            setCodes(await handleICD10Search(e.target.value));
          }}
          rightText={
            search && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearch();
                }}
              />
            )
          }
        />
      </div>

      <div className="flex flex-col gap-2 pb-3">
        {codes?.map((code, index) => (
          <Item
            onSelect={onSelect}
            item={{ id: code.value, name: code.label }}
            key={index}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
