import state from 'components/state';
import _ from 'lodash';
import { getRecoil, setRecoil } from 'recoil-nexus';

const updateClinicalNote = (data) => {
  try {
    const clinicalNote = getRecoil(state.clinicalNote);
    // const updateNarrative = (key, value) => {
    //   setRecoil(state.clinicalNote, {
    //     ...clinicalNote,
    //     [key]:
    //       typeof clinicalNote[key] === 'object' ? { ...clinicalNote[key], narrative: value } : value
    //   });
    // };
    // console.log('Utility.updateClinicalNote.data', data, clinicalNote);

    const { vertical, form_name, form_type, response } = data;

    if (['soap', 'hp', 'clinical_narrative'].includes(vertical)) {
      if (
        ['present_illness', 'other_complaints', 'ros', 'exam'].includes(form_type) ||
        (['soap'].includes(form_name) && !['medicalHistory'].includes(form_type))
      ) {
        const newObject = _.set(clinicalNote, form_type, response);
        // console.log('custom_form,present_illness', newObject);
        // console.log(newObject);
        setRecoil(state.clinicalNote, (ps) => ({ ...ps, [form_type]: response }));
      } else if (
        typeof _.get(clinicalNote, `${form_name}.${form_type}`) === 'string' ||
        !_.get(clinicalNote, `${form_name}.${form_type}`)
      ) {
        // console.log('name.type');
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_name}.${form_type}`, response)
        );
      } else if (
        typeof _.get(clinicalNote, `${form_type}`) === 'string' ||
        !_.get(clinicalNote, `${form_type}`)
      ) {
        // console.log('type');
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_type}`, response)
        );
      } else if (
        typeof _.get(clinicalNote, `${form_name}`) === 'string' ||
        !_.get(clinicalNote, `${form_name}`)
      ) {
        // console.log('name');
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_name}`, response)
        );
      }
    }

    const newAiClinicalNoteState = getRecoil(state.intelligenceAiState);
    if (newAiClinicalNoteState?.timeoutTimeout)
      clearTimeout(newAiClinicalNoteState?.timeoutTimeout);
    // console.log(`${vertical}.${data?.form_name}.${data?.form_type}:${data?.response}`);
    setRecoil(state.intelligenceAiState, (ps) => ({
      ...ps,
      loading: !!!data?.done,
      response: data?.response,
      path: `${vertical}.${data?.form_name}.${data?.form_type}`
    }));

    return;

    if (data?.form_name === 'goals') {
      setRecoil(state.clinicalNote, {
        ...clinicalNote,
        goals_narrative: data?.response
      });
      // } else if (data?.vertical && data?.customFormId && advancedFormNarrativeRef.current) {
      //   advancedFormNarrativeRef.current((prev) => ({
      //     ...prev,
      //     narrative: data?.response
      //   }));
    } else if (data?.form_name) {
      switch (data.form_name) {
        case 'hp':
        case 'soap':
          updateNarrative(data?.form_type, data?.response);
          setRecoil(state.intelligenceAiState, (ps) => ({ ...ps, loading: false }));
          break;
        case 'intake':
          if (['timeFrames', 'riskAndCoMorbidities'].includes(data?.form_name)) {
            setRecoil(state.clinicalNote, {
              ...clinicalNote,
              mha: {
                ...clinicalNote?.mha,
                [data?.form_name]: data?.response
              }
            });
          } else {
            updateNarrative(data?.form_name, data?.response);
          }
          break;
        case 'progress':
          if (['sessionQuotesAndContents'].includes(data?.form_name)) {
            setRecoil(state.clinicalNote, {
              ...clinicalNote,
              epn: {
                ...clinicalNote?.epn,
                [data?.form_name]: data?.response
              }
            });
          } else if (['notes'].includes(data?.form_name)) {
            updateNarrative(data?.form_name, data?.response);
          } else {
            setRecoil(state.clinicalNote, {
              ...clinicalNote,
              epn: {
                ...clinicalNote?.epn,
                [data?.form_name]: {
                  ...clinicalNote?.epn?.[data?.form_name],
                  narrative: data?.response
                }
              }
            });
          }
          break;
      }
    }
    const aiClinicalNoteState = getRecoil(state.intelligenceAiState);
    if (aiClinicalNoteState?.timeoutTimeout) clearTimeout(aiClinicalNoteState?.timeoutTimeout);
    setRecoil(state.intelligenceAiState, (ps) => ({ ...ps, loading: !!!data?.done }));
  } catch (e) {
    console.log(e);
  }
};

export default updateClinicalNote;
