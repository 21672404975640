export const DEFAULT_FORMATS = 'jpg, png, pdf';
export const DEFAULT_TITLE = 'Choose a file or drag & drop it here.';

export const DOCUMENT_TYPES = {
  'application/pdf': 'file-pdf',
  'image/jpeg': 'file-jpg',
  'image/png': 'file-png'
};

export const STATUSES = {
  uploading: 'Uploading...',
  uploaded: 'Completed',
  failed: 'Failed'
};

export const ICON_TYPES = {
  uploading: 'loader',
  uploaded: 'checkmark-circle',
  failed: 'new-info'
};

export const TRY_AGAIN_LABEL = 'Try again';
