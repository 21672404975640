import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../../../../api/Products';
import { useEffect, useState } from 'react';

export const useProductsSearch = ({ options } = {}) => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10000,
    column: 'name',
    sort: 'asc',
    categoryId: null
  });

  const query = useQuery(
    ['products', filters?.search, filters?.page, filters.sort, filters.column, filters.categoryId],
    () => getProducts(navigate, { ...filters, filters }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters?.searchTerm]);

  return { ...query, data: query?.data, products: query?.data?.products, filters, setFilters };
};
