import ProfilePicture from 'components/Profile/ProfilePicture';
import React from 'react';
import AvatarTooltip from './AvatarTooltip';

import { checkSize, avatarSizes, fontSizes } from './lib';

/**
 * GroupedAvatar component to display avatars and labels
 * @param {Object} props - Component props
 * @param {'X_SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | '2Extra_LARGE' | '3Extra_LARGE' | '4Extra_LARGE'} props.size - Size of the avatars
 * @param {Array} props.users - List of users to display
 * @param {boolean} props.showLabel - Whether to show the label
 */
const GroupedAvatar = ({ size = 'LARGE', users = [] }) => {
  size = checkSize(size);

  const avatarSize = avatarSizes[size];
  const dynamicMargin = `-${avatarSize / 4}px`;
  const fontSize = fontSizes[size];

  if (users.length === 0) {
    return null;
  }

  return (
    <div className="relative m-0 overflow-hidden  rounded-full border-[2px] border-solid  border-neutral-200 bg-white ">
      <div className="flex w-full items-center  rounded-full">
        {users.slice(0, 4).map((user, index) => (
          <AvatarTooltip key={user.id} users={[user]}>
            <div>
              <div
                key={user.id}
                style={{
                  marginLeft: index !== 0 ? dynamicMargin : undefined,
                  zIndex: 10 + index
                }}
                className="relative">
                <ProfilePicture
                  firstName={user.f_name}
                  lastName={user.l_name}
                  image={user.profile_photo}
                  className="h-12 w-12 rounded-full border-[2px] border-solid border-white"
                  id={user.id}
                  size={avatarSize}
                />
              </div>
            </div>
          </AvatarTooltip>
        ))}

        {users.length > 4 && (
          <AvatarTooltip users={users.slice(4, users.length)}>
            <div className={`w-[${[size]}px] h-[${[size]}px] flex items-center justify-center`}>
              <span className={`font-600 text-neutral-700 ${fontSize}`}> +{users.length - 4}</span>
            </div>
          </AvatarTooltip>
        )}
      </div>
    </div>
  );
};

export default GroupedAvatar;
