import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getProfessionGroups } from 'api/ProfessionGroups';

export const useProfessionGroups = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['professionGroups', ...dependencies],
    () => getProfessionGroups(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options
    }
  );
};
