import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import React, { useEffect, useMemo, useState } from 'react';
import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useResources } from 'lib/hooks/queries/resoruces/useResources';
import NewResourceModal from './NewResourceModal';
import Skeleton from 'react-loading-skeleton';
import TableCounter from 'components/shared/Table/TableCounter';
import HeaderTable from './HeaderTable';

const ResourcesTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(false);

  const { limit, page, sort, setPage, filters, setFilters } = useTableContext();

  const { data, isLoading, isFetching } = useResources({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      withCount: true
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const resources = data?.resources;
  const count = data?.resourceCount;

  const onCellClicked = async (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    setSelectedResource(event?.data);
    setShowEditModal(true);
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100
    };
  }, []);

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
    setSelectedResource(false);
  };

  const { data: providers = {} } = useProviders({ params: {} });

  useEffect(() => {
    if (ia(providers?.practitioners)) {
      const formattedProviders = providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
      setFilters((prev) => ({
        ...prev,
        providers: { ...prev.providers, options: formattedProviders }
      }));
    }
  }, [providers?.practitioners]);

  return (
    <>
      <HeaderTable setNewResource={onEditModalToggle} />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="flex h-full flex-col overflow-hidden">
          <div className="ag-theme-quartz !mb-0 h-full">
            <AGTable
              data={resources}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          </div>
          <div className="flex items-center justify-between px-3">
            <TableCounter page={page} limit={limit} count={count && count} />
            <Pagination
              onPageChange={({ selected }) => setPage(selected + 1)}
              totalItems={count ? count : null}
              page={page}
              perPage={limit}
            />
          </div>
        </div>
      )}
      {showEditModal && (
        <NewResourceModal
          resource={selectedResource}
          isOpen={showEditModal}
          handleClose={onEditModalToggle}
          filters={filters}
        />
      )}
    </>
  );
};

export default ResourcesTable;
