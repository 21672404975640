import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import { imagePath } from 'lib/helpers/utility';

const CheckinPracticeLogo = ({ practiceLogo, small = false }) => {
  if (practiceLogo) {
    if (small) {
      return (
        <img
          src={imagePath(practiceLogo)}
          className="h-full max-h-[5rem] w-fit border-none bg-white object-contain"
        />
      );
    } else {
      return (
        <img
          src={imagePath(practiceLogo)}
          className="h-full max-h-[5rem] w-full border-none bg-white object-contain"
        />
      );
    }
  }

  if (small) {
    return (
      <div className="bg-white !p-4">
        <Icon icon="logo" size={90} />
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center bg-white p-4">
        <Icon icon="logo-symbol" size={26} />
      </div>
    );
  }
};

export default withErrorBoundary(CheckinPracticeLogo);
