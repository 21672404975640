import ResizableContainer from 'components/practice/charts/ClinicalNote/shared/VerticallyResizableDiv/ResizableContainer';
import { currentPractice } from 'components/practice/practiceState';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import { clinicalNote as clinicalNoteState, permissions } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, iaRa, io } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AddToTodaysNote from '../Provider/components/AddToTodaysNote';
import NarrativeForm from '../Provider/components/NarrativeForm';
import NoteHeader from '../Provider/components/NoteHeader';
import MedicationSelectionModal from './components/MedicationSelectionModal';
import MedicationsTable from './components/MedicationsTable';
import MultiMedsActions from './components/MultiMedsActions';
import UpsertProviderMedication from './components/UpsertMedication';
import { FINAL_NOTE_OPTIONS } from './lib/constants';
import { getRowId, onRowClicked } from './lib/medicationTableSelectHelpers';
import providerMedicationsTableCols from './lib/providerMedicationsTableCols';

const ProviderMedications = ({ options, isResizable = true }) => {
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const userPermissions = useRecoilValue(permissions);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const practice = useRecoilValue(currentPractice);

  return (
    <TableContextProvider
      key={`${clinicalNote?.locked}-medications`}
      cols={() =>
        providerMedicationsTableCols({
          showMedicationModal,
          setShowMedicationModal,
          userPermissions,
          noteLocked: clinicalNote?.locked,
          practice,
          showCheckboxes: true
        })
      }
      name="Medications"
      defaultFilters={[]}>
      <Table
        options={options}
        isResizable={isResizable}
        clinicalNote={clinicalNote}
        showMedicationModal={showMedicationModal}
        setShowMedicationModal={setShowMedicationModal}
      />
    </TableContextProvider>
  );
};

const Table = ({
  options,
  isResizable = true,
  clinicalNote,
  showMedicationModal,
  setShowMedicationModal = () => {}
}) => {
  const { setSelectedCustomFormTypes, medications, setMedications } = useClinicalNoteContext();
  const { setColDefs, selectedRows } = useTableContext();
  const [showMedicationSelectionModal, setShowMedicationSelectionModal] = useState(false);
  const [resize, setResize] = useState(250);
  const [gridApi, setGridApi] = useState(null);
  const practice = useRecoilValue(currentPractice);
  const userPermissions = useRecoilValue(permissions);

  const formattedData = iaRa(selectedRows)?.map((row) => {
    const { data } = row;
    return data;
  });

  const { id: patientId } = useParams();

  const { isLoading: isMedicationLoading } = useMedication({
    params: {
      patientId
    },
    dependencies: [patientId],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications),
      onSuccess: (medicationData) => {
        setMedications(medicationData);
      }
    }
  });

  useEffect(() => {
    if (!gridApi) return;

    setColDefs(
      providerMedicationsTableCols({
        showMedicationModal,
        setShowMedicationModal,
        userPermissions,
        noteLocked: clinicalNote?.locked,
        practice,
        showCheckboxes: true,
        gridApi
      })
    );
  }, [gridApi]);

  const onCellClicked = async (e) => {
    if (['0', 'actions'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }
    await onRowClicked({ e, gridApi });
  };

  return (
    <>
      <div className="h-full">
        <AddToTodaysNote
          type="medications"
          finalNoteOptions={FINAL_NOTE_OPTIONS}
          onChange={({ type, options }) =>
            setSelectedCustomFormTypes((prevState) => ({
              ...prevState,
              [type]: options
            }))
          }
        />

        <NoteHeader title="Medications">
          <DisplayButton />
          {ia(formattedData) && <MultiMedsActions data={formattedData} gridApi={gridApi} />}
          <Button
            text="Add Medication"
            icon="new-add-square"
            disabled={clinicalNote?.locked}
            iconColor="white"
            size="small"
            onClick={() => setShowMedicationSelectionModal(true)}
          />
        </NoteHeader>

        {showMedicationSelectionModal && (
          <MedicationSelectionModal
            isOpen={showMedicationSelectionModal}
            handleClose={() => setShowMedicationSelectionModal(false)}
            onPastMedicationSelect={() => {
              setShowMedicationModal({ open: true });
              setShowMedicationSelectionModal(false);
            }}
          />
        )}

        {io(showMedicationModal) && showMedicationModal?.open && (
          <UpsertProviderMedication
            showModal={showMedicationModal}
            hideModal={() => setShowMedicationModal({ open: false })}
          />
        )}

        <ResizableContainer
          isResizable={isResizable}
          onResize={(height) => setResize(height)}
          topContent={
            <MedicationsTable
              onCellClicked={onCellClicked}
              data={medications}
              getGridApi={(api) => setGridApi(api)}
              getRowId={getRowId}
            />
          }
          bottomContent={
            <NarrativeForm
              key={`medications-narrative-${patientId}`}
              type="medications"
              loading={isMedicationLoading}
              gridApi={gridApi}
              resize={resize}
              items={medications}
              options={options}
            />
          }
          resizableDivClassNames={{
            contentClassName: '!mt-0 !pt-0'
          }}
          resizableContainerClassNames={!isResizable && { bottomContentClassName: '!bottom-12' }}
        />
      </div>
    </>
  );
};

export default ProviderMedications;
