export const getInitialData = (data = null) => {
  return {
    id: data?.id || '',
    temperature: data?.temperature || '',
    heart_rate: data?.heart_rate || '',
    respirations: data?.respirations || '',
    blood_pressure_systolic: data?.blood_pressure_systolic || '',
    blood_pressure_diastolic: data?.blood_pressure_diastolic || '',
    weight: data?.weight || '',
    height: data?.height || '',
    bmi: data?.bmi || '',
    oxygen_level: data?.oxygen_level || '',
    timestamp: data?.timestamp,
    notes: data?.notes || '',
    head_circumference: data?.head_circumference || '',
    head_circumference_unit: data?.head_circumference_unit || 'in'
  };
};
