import React, { useEffect, useState } from 'react';
import Select from '../../../../../../shared/Select/Select';
import Input from '../../../../../../shared/Input/Input';
import { frequencyListDefault } from '../../../../../../../constants';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import moment from 'moment-timezone';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const initialState = {
  frequency: null,
  startDate: null,
  endDate: null
};

const TreatmentFrequency = () => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [treatmentFrequency, setTreatmentFrequency] = useState(initialState);

  useEffect(() => {
    if (selectedTreatmentPlan?.treatment_frequency_date) {
      setTreatmentFrequency(selectedTreatmentPlan?.treatment_frequency_date);
    }
  }, [selectedTreatmentPlan]);

  const onChange = (event, key) => {
    setTreatmentFrequency({ ...treatmentFrequency, [key]: event });
    setSelectedTreatmentPlan({
      ...selectedTreatmentPlan,
      treatment_frequency_date: {
        ...selectedTreatmentPlan.treatment_frequency_date,
        [key]: event
      }
    });
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4">
      <Select
        label="Treatment frequency"
        placeholder="Example: Every week"
        value={treatmentFrequency?.frequency}
        options={frequencyListDefault}
        onChange={(event) => onChange(event, 'frequency')}
        inputId="treatment-plan-frequency"
      />
      <Popover>
        {({ close }) => (
          <div className="relative">
            <Popover.Button className="w-full !p-0">
              <Input
                label="Start date"
                placeholder="Please select date"
                rightIcon="new-calendar-gray"
                value={treatmentFrequency?.startDate}
                data-qa="treatment-start-date-input"
                required
                error={treatmentFrequency?.startDate ? false : 'Required field'}
              />
            </Popover.Button>
            <Popover.Panel className="absolute bottom-[110%] right-0 z-10 flex w-max bg-white p-3 shadow-md">
              <Calendar
                date={
                  selectedTreatmentPlan?.treatment_frequency_date?.startDate
                    ? new Date(selectedTreatmentPlan?.treatment_frequency_date?.startDate)
                    : new Date()
                }
                onChange={(event) => {
                  onChange(moment.utc(event).add(12, 'h').format('MM/DD/YYYY'), 'startDate');
                  close();
                }}
                data-qa="treatment-start-date-calendar"
              />
            </Popover.Panel>
          </div>
        )}
      </Popover>
      <Popover>
        {({ close }) => (
          <div className="relative">
            <Popover.Button className="w-full !p-0">
              <Input
                label="End date"
                placeholder="Please select date"
                rightIcon="new-calendar-gray"
                value={treatmentFrequency?.endDate}
                data-qa="treatment-end-date-input"
                required
                error={treatmentFrequency?.endDate ? false : 'Required field'}
              />
            </Popover.Button>
            <Popover.Panel className="absolute bottom-[110%] right-0 z-10 flex w-max bg-white p-3 shadow-md">
              <Calendar
                minDate={
                  treatmentFrequency?.startDate ? new Date(treatmentFrequency?.startDate) : null
                }
                date={
                  selectedTreatmentPlan?.treatment_frequency_date?.endDate
                    ? new Date(selectedTreatmentPlan?.treatment_frequency_date?.endDate)
                    : new Date()
                }
                onChange={(event) => {
                  onChange(moment.utc(event).add(12, 'h').format('MM/DD/YYYY'), 'endDate');
                  close();
                }}
                data-qa="treatment-end-date-calendar"
              />
            </Popover.Panel>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default TreatmentFrequency;
