import Allowed from 'components/shared/Permissions/Allowed';
import { camelCaseToReadableFormat, ia, io } from 'lib/helpers/utility';
import React from 'react';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import { emptyChildrenFormat, formatDisplayValue } from '../lib/helpers';
import SocialHistoryModal from '../Modals/SocialHistoryModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const SocialHistoryOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview?.social_history && !cnSettings?.overview?.socialHistory) return null;

  return (
    <Allowed requiredPermissions="patients_medical_history.read">
      <SimpleAccordion
        title="Social history"
        onToggle={() => toggleOne('socialHistory')}
        open={isOpen.socialHistory}
        showExpandIcon={ia(overviewData?.socialHistory)}
        edit={
          <SocialHistoryModal
            formId={currentHpOverviewData?.socialHistory?.id}
            defaultValue={currentHpOverviewData?.socialHistory?.response}
            setCurrentHpOverviewData={setCurrentHpOverviewData}
            setCustomResponses={setOverviewData}
          />
        }
        addPermissions="patients_medical_history.update">
        {ia(overviewData?.socialHistory) &&
          overviewData?.socialHistory?.map((socialHistory, index) => {
            if (!io(socialHistory?.response)) return null;

            if (socialHistory?.response?.noDetails) {
              return (
                <NoKnown
                  key={index}
                  form={socialHistory}
                  description="No known social history"
                  shouldShowDivider={index !== 0}
                />
              );
            }

            return (
              <React.Fragment key={index}>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                {Object.entries(socialHistory?.response)?.map(([key, value], idx) => {
                  if (key === 'narrative') return null;
                  if (key === 'children' && emptyChildrenFormat(value)) return null;
                  const hasTruthySubValue = Object.values(value || {}).some(
                    (childValue) => childValue
                  );

                  if (hasTruthySubValue) {
                    return (
                      <div key={idx} className="flex justify-between gap-x-4">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          {`${camelCaseToReadableFormat(key)}: `}
                        </span>
                        <div className="flex flex-col items-end gap-y-1">
                          {Object.entries(value)?.map(([subKey, subValue], idx) => {
                            if (subValue) {
                              if (subKey === 'ages' && !ia(subValue)) return null;
                              const displayValue = formatDisplayValue(subKey, subValue, key);
                              return (
                                <span
                                  key={idx}
                                  className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                                  {displayValue}
                                </span>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
                <CreationInfo
                  createdAt={socialHistory?.createdAt}
                  createdBy={socialHistory?.createdBy?.fullName}
                />
              </React.Fragment>
            );
          })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default SocialHistoryOverview;
