import React, { useState } from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import useCopy from 'lib/hooks/useCopy';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { calculateAge, formatDate, formatPhoneNumber } from 'lib/helpers/utility';
import Tags from './components/Tags';
import ChartID from './components/ChartID';
import Allergies from './components/Allergies';
import Icon from 'components/shared/Icon/Icon';
import { showAlert } from 'components/shared/Alert/Alert';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Transition from 'components/shared/Transition/Transition';
import StickyNotes from 'components/practice/StickyNotes/StickyNotes';
import ActiveUsers from './components/ActiveUsers';

const Header = ({ appointment, handleClose }) => {
  const { device } = useUIContext();
  const { copyToClipboard } = useCopy();
  const { userState, saveDisplaySettings } = useDisplaySettings();
  const [isOpen, setIsOpen] = useState(userState.display_settings?.appointment?.header ?? false);
  const phoneNumber = formatPhoneNumber(appointment.patient.phone);

  const copyPhoneNumber = () => {
    copyToClipboard(phoneNumber);
    showAlert({
      title: 'Phone number copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  const copyEmail = () => {
    copyToClipboard(appointment.patient.email);
    showAlert({
      title: 'Email copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
    saveDisplaySettings({
      ...userState.display_settings,
      appointment: {
        ...userState.display_settings?.appointment,
        header: !isOpen
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div
        className={cs(
          'flex w-full items-center justify-between gap-4 px-4 py-[6px] sm:flex-wrap',
          isOpen && '!pb-[2px]'
        )}>
        <div className="flex items-center gap-2">
          <div
            className="flex size-[28px] cursor-pointer select-none items-center justify-center rounded-[4px] p-0"
            onClick={handleOpen}>
            <div className="flex size-[17px] items-center justify-center rounded-[4px] bg-[#D4D9DB]">
              <Icon
                className={cs('cursor-pointer duration-150', !isOpen && 'mt-[1px] rotate-180')}
                icon="chevron-up"
                size={9}
                color="neutral"
              />
            </div>
          </div>
          <Link
            data-qa="patient-overview"
            to={`/portal/charts/${appointment?.patient?.id}`}
            target="_blank"
            className="flex cursor-pointer select-none items-center gap-2">
            <ProfilePicture
              size={device === 'desktop' || device === 'laptop' || device === 'tablet' ? 24 : 48}
              fontSize="text-sm sm:text-2xl"
              firstName={appointment.patient.f_name}
              lastName={appointment.patient.l_name}
              image={appointment.patient.header_photo}
              id={appointment.patient.id}
            />
          </Link>
          <div className="flex flex-wrap items-center gap-x-2">
            <Link
              className="flex items-center gap-[2px]"
              target="_blank"
              to={`/portal/charts/${appointment?.patient?.id}`}>
              <span className="whitespace-nowrap text-base font-500 leading-6 text-primary-900">
                {appointment.patient.fullName}
              </span>
              <div className="flex size-[24px] items-center justify-center">
                <Icon icon="new-link" size={11} className="cursor-pointer" />
              </div>
              <span className="text-sm text-primary-600">{`#${appointment.patient.id}`}</span>
            </Link>
            <div className="flex flex-wrap items-center gap-2">
              {appointment.patient.dob && (
                <div className="border-0 border-l border-solid border-neutral-300 pl-2 sm:hidden md:hidden">
                  <span className="text-sm text-primary-900">{`${formatDate(appointment.patient.dob)} (${calculateAge({ dateOfBirth: appointment.patient.dob })} years old)`}</span>
                </div>
              )}
              {appointment.patient.phone && (
                <div
                  className="cursor-pointer border-0 border-l border-solid border-neutral-300 pl-2 sm:border-none sm:pl-0 md:border-none md:pl-0"
                  onClick={copyPhoneNumber}>
                  <span className="text-sm text-neutral-700">{phoneNumber}</span>
                </div>
              )}
              {appointment.patient.email && (
                <div
                  className="cursor-pointer border-0 border-l border-solid border-neutral-300 pl-2 xxs:border-none xxs:pl-0"
                  onClick={copyEmail}>
                  <span className="text-sm text-neutral-600">{appointment.patient.email}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <ActiveUsers />
          <span className="whitespace-nowrap text-sm font-500 text-neutral-600">{`Appointment #${appointment.id}`}</span>
          <Icon
            data-qa="new-close-square"
            icon="new-close-square"
            onClick={handleClose}
            className="flex h-6 w-6 items-center justify-center"
          />
        </div>
      </div>
      <Transition isOpen={isOpen}>
        <div
          className={cs(
            'flex items-center justify-between gap-2 bg-primary-10 px-4 py-[6px] duration-200',
            !isOpen && 'h-0 overflow-hidden p-0 opacity-0'
          )}>
          <div className="flex gap-2">
            <ChartID />
            <Allergies />
          </div>
          <div className="flex gap-[2px]">
            <Tags />
            <StickyNotes
              patientId={appointment?.patient?.id}
              customStyle={{
                button: 'cursor-pointer items-center bg-warning-50 flex gap-x-[6px] rounded-full',
                icon: { size: 18 }
              }}
              fromAppointmentPreview
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Header;
