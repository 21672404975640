import React, { useRef, useState } from 'react';
import { requestApi } from '../../../../../../api/Api';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../../../shared/Alert/Alert';
import ReactToPrint from 'react-to-print';
import Tippy from '@tippyjs/react';
import Button from '../../../../../shared/Buttons/Button';
import Confirm from '../../../../../shared/Modal/Confirm/Confirm';
import Diagnosis from '../components/preview/Diagnosis';
import PatientNeeds from '../components/preview/PatientNeeds';
import ProblemManifestationDefinition from '../components/preview/ProblemManifestationDefinition';
import Goals from '../components/preview/Goals';
import TreatmentTeam from '../components/preview/TreatmentTeam';
import AdditionalComments from '../components/preview/AdditionalComments';
import TreatmentFrequency from '../components/preview/TreatmentFrequency';
import TreatmentPlanSignatures from '../components/preview/TreatmentPlanSignatures';

const TPNarrative = ({ id, patientSide = false }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();

  const sendEmailToPatient = async () => {
    setLoading(true);
    const onSuccess = () => {
      showAlert({ title: 'Treatment Plan', message: 'Email sent successfully!' });
      setLoading(false);
      setShowConfirmation(false);
    };
    requestApi({
      url: '/api/clinical_note/treatment_plan/email_patient',
      navigate,
      params: { treatmentId: id },
      onSuccess
    });
  };

  return (
    <div>
      <div className="flex items-center justify-end !gap-3">
        <Tippy content="Print as PDF" placement="bottom" className="tippy-dark">
          <div>
            <ReactToPrint
              documentTitle="treatment-plan"
              bodyClass="!p-6 bg-white"
              content={() => ref.current}
              trigger={() => (
                <Button type="secondary" icon="new-printer-outline" color="neutral" iconIsStroke />
              )}
            />
          </div>
        </Tippy>
        {!patientSide && (
          <Tippy content="Send email to patient" placement="bottom" className="tippy-dark">
            <div>
              <Button
                type="secondary"
                icon="new-mail-outline"
                color="neutral"
                iconIsStroke
                onClick={() => setShowConfirmation(true)}
              />
            </div>
          </Tippy>
        )}
      </div>
      <div ref={ref} className="grid gap-6">
        <Diagnosis />
        <PatientNeeds />
        <ProblemManifestationDefinition />
        <Goals />
        <TreatmentTeam />
        <AdditionalComments />
        <TreatmentFrequency />
        <TreatmentPlanSignatures />
      </div>
      {showConfirmation && (
        <Confirm
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          handleContinue={sendEmailToPatient}
          title="Send email to patient"
          message="Are you sure you want to send email to patient?"
          primaryBtnTxt="Send email to patient"
          secondaryBtnTxt="Close"
          icon="new-mail"
          loading={loading}
        />
      )}
    </div>
  );
};

export default TPNarrative;
