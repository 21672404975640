import Icon from 'components/shared/Icon/Icon';
import React, { memo, useCallback } from 'react';
import EmptyView from './EmptyView';
import { useEnhanceNoteContextData } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { filterCheckedAndSelectedComponents } from '../../lib/helpers';
import VirtualizedList from '../../../shared/components/VirtualizedList';
import ChatRow from '../../../shared/components/ChatRow';
import { transformData } from '../../../shared/lib/helpers';
import { EnhanceNoteViewFooter } from './EnhanceNoteViewFooter';
import cs from 'classnames';

let type;
const EnhanceNoteView = () => {
  const {
    filledForms = {},
    componentRefs,
    loading,
    filledFormsLoading,
    scrollTo
  } = useEnhanceNoteContextData();
  const checkedAndSelectedFilledForms = filterCheckedAndSelectedComponents(filledForms);

  const formsList = transformData(Object.values(checkedAndSelectedFilledForms));

  const renderRow = useCallback(
    (item, index, handleInputChange, inputValues) => {
      let head = null;

      if (item?.type !== type || index === 0) {
        type = item?.type;
        head = (
          <div className="py-1 pb-3 text-xl text-primary-500">{item?.typeLabel?.toUpperCase()}</div>
        );
      }

      return (
        <div
          className={cs(
            'mx-auto h-full w-2/3 py-2 md:w-3/4',
            index === formsList?.length - 1 && 'pb-[140px]'
          )}>
          {head}
          <ChatRow
            key={`${item.key}-${item.type}`}
            item={item}
            index={index}
            value={inputValues[index] || ''}
            onInputChange={handleInputChange}
            componentRefs={componentRefs}
            components={checkedAndSelectedFilledForms[item?.type]?.components}
          />
        </div>
      );
    },
    [formsList?.length, componentRefs, checkedAndSelectedFilledForms]
  );

  return (
    <div className="overflow-y-none flex h-full flex-col">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 z-[-1]" />
      {loading || filledFormsLoading ? (
        <div className="flex-grow overflow-y-scroll px-4 pb-[150px]">
          <div className="mx-[230px] flex flex-col px-10 sm:mx-0 md:mx-[50px] lg:mx-[100px] xl:mx-[150px]">
            <Skeleton count={5} height="250px" />
          </div>
        </div>
      ) : formsList?.length > 0 ? (
        <div className="flex-grow overflow-y-scroll">
          <div className="mx-auto flex h-full w-full flex-col">
            <VirtualizedList data={formsList} renderItem={renderRow} scrollToIndex={scrollTo} />
          </div>
        </div>
      ) : (
        <EmptyView />
      )}
      <div className="px-auto absolute bottom-0 z-50 flex w-1/2 self-center bg-transparent">
        <EnhanceNoteViewFooter />
      </div>
    </div>
  );
};

export default memo(EnhanceNoteView);
