import Button from 'components/shared/Buttons/Button';
import MultiMedsActions from 'components/shared/Forms/Custom/ProviderMedications/components/MultiMedsActions';
import { ia } from 'lib/helpers/utility';
import React from 'react';

const MedicationWidgetFooter = ({ formattedData, setShow, gridApi }) => {
  return (
    <div className="ml-auto flex gap-2">
      {ia(formattedData) && <MultiMedsActions gridApi={gridApi} data={formattedData} />}

      <Button
        outlined
        text="Close"
        className="!h-[34px]"
        color="neutral"
        onClick={() => setShow(false)}
      />
    </div>
  );
};
export default MedicationWidgetFooter;
