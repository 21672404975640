import { queryClient } from 'App';

export default async (data) => {
  const { appointment, invalidate } = data;

  if (invalidate) {
    queryClient.refetchQueries(['appointment-events']);
    return;
  }

  queryClient.setQueryData(['appointmentv2', appointment?.id], (oldData) => {
    if (oldData) {
      return {
        ...oldData,
        appointment: { ...(oldData?.appointment || {}), ...appointment }
      };
    }
  });

  queryClient.setQueriesData(
    { queryKey: ['appointment-events'] },
    (oldData) =>
      oldData && {
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.id === appointment.id ? { ...(apt || {}), ...appointment } : apt
        )
      }
  );
};
