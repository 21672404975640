import { Popover } from '@headlessui/react';
import { isBoolean } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../../../../api/Api';
import { formatDate, mString, sumBy } from '../../../../../../../lib/helpers/utility';
import Configurations from '../../../../../../Payments/Configurations';
import Icon from '../../../../../../shared/Icon/Icon';
import Confirm from '../../../../../../shared/Modal/Confirm/Confirm';
import Modal from '../../../../../../shared/Modal/Modal';
import Pagination from '../../../../../../shared/Pagination/Pagination';
import Status from '../../../../../../shared/Status/Status';
import PreviewInvoicePackageModal from './PreviewInvoicePackageModal';

const InvoicePackageTable = ({
  invoicePackages,
  setInvoicePackages,
  patientId,
  filters,
  invoicePackagesCount,
  setFilters,
  getPackages
}) => {
  const [invoice, setInvoice] = useState(null);
  const [cancelInvoiceId, setCancelInvoiceId] = useState(null);
  const [singlePackageDetails, setSinglePackageDetails] = useState(null);
  const navigate = useNavigate();

  const cancelInvoice = async () => {
    const onSuccess = (data) => {
      const { invoice, updatedPackage } = data.invoice;

      setCancelInvoiceId(null);

      setInvoicePackages((prev) =>
        prev.map((item) =>
          item.id === invoice.id
            ? {
                ...item,
                total_amount: invoice.total_amount,
                packages: item.packages.map((pack) =>
                  pack.id === updatedPackage.id ? { ...pack, status: 'cancelled' } : pack
                )
              }
            : item
        )
      );
    };
    requestApi({
      url: '/api/package/delete_invoice_package',
      params: cancelInvoiceId,
      onSuccess,
      navigate
    });
  };

  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };

  const twoLastIndexes = useMemo(
    () =>
      invoicePackages
        .map((invoicePack) => invoicePack.packages.map((pack) => invoicePack.id + '' + pack.id))
        .slice(invoicePackages.length - 2, invoicePackages.length)
        .flat(),
    [invoicePackages]
  );

  useEffect(() => {
    if (isBoolean(invoice) && !invoice) {
      getPackages();
    }
  }, [invoice]);

  return (
    <>
      <table className="primary-table">
        <thead>
          <tr>
            <th className="w-[40%] cursor-pointer">Name</th>
            <th className="cursor-pointer">Purchase date</th>
            <th className="cursor-pointer">Valid until</th>
            <th className="cursor-pointer">Balance</th>
            <th className="cursor-pointer">Status</th>
            <th className=""></th>
          </tr>
        </thead>
        <tbody>
          {invoicePackages.map((invoicePack) => {
            let amountPaid = invoicePack?.amount_paid + (invoicePack?.discount?.amount_cents || 0);

            amountPaid -= sumBy(invoicePack?.procedures, 'total_cost_cents') + invoicePack?.tax;

            return invoicePack.packages.map((pack) => {
              const isCancelled = pack?.status === 'cancelled';

              const packCharge = (isCancelled ? 0 : pack?.total_amount_cents) || 0;

              const minAmount = Math.min(packCharge, amountPaid);

              amountPaid -= minAmount;

              return (
                <tr key={pack.id}>
                  <td className="!font-500">{pack.name}</td>
                  <td>{formatDate(invoicePack.created_at)}</td>
                  <td>{pack.validUntil}</td>
                  <td>{mString(packCharge - minAmount)}</td>
                  <td>
                    <Status status={pack.status} className="w-max" />
                  </td>

                  <td className="relative w-[50px]">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <div>
                          <Popover.Button
                            className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                              !open && 'hover:!bg-white'
                            } ${open && '!bg-primary-700 transition-all'}`}>
                            <Icon
                              icon="three-dots"
                              className="flex cursor-pointer"
                              color={open ? 'white' : 'black'}
                            />
                          </Popover.Button>

                          <Popover.Panel
                            className={`absolute right-0 top-10 z-[99999] w-max cursor-pointer rounded-lg  bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]
                        ${
                          twoLastIndexes.includes(invoicePack.id + '' + pack.id) &&
                          pack.status === 'active'
                            ? '!-top-[125px]'
                            : twoLastIndexes.includes(invoicePack.id + '' + pack.id) &&
                              '!-top-[90px]'
                        }
                        `}>
                            <div
                              data-qa="edit-product-btn"
                              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                              onClick={() => {
                                setSinglePackageDetails(pack);
                                close();
                              }}>
                              <Icon icon="new-eye" color="primary" />
                              <div className="ml-1 text-sm text-primary-900">Preview Detail</div>
                            </div>
                            <div
                              data-qa="delete-product-btn"
                              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                              onClick={() => {
                                setInvoice(invoicePack);
                                close();
                              }}>
                              <Icon icon="money-send" color="primary" />
                              <div className="ml-1 text-sm text-primary-900">Pay Balance</div>
                            </div>
                            {pack.status === 'active' ? (
                              <div
                                data-qa="delete-product-btn"
                                className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                onClick={() => {
                                  setCancelInvoiceId({
                                    invoiceId: invoicePack.id,
                                    packageId: pack.id
                                  });
                                  close();
                                }}>
                                <Icon icon="cancel" color="primary" />
                                <div className="ml-1 text-sm text-primary-900">Cancel</div>
                              </div>
                            ) : null}
                          </Popover.Panel>
                        </div>
                      )}
                    </Popover>
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>
      <div className="mt-[26px] flex items-center justify-between">
        <label className="text-xs text-primary-900">
          Showing {filters.page * filters.limit - 9}-
          {filters.page * filters.limit < invoicePackagesCount
            ? filters.page * filters.limit
            : invoicePackagesCount}{' '}
          out of {invoicePackagesCount}
        </label>
        <Pagination
          onPageChange={changePage}
          page={filters.page}
          totalItems={invoicePackagesCount}
          perPage={filters.limit}
        />
      </div>

      <Confirm
        handleOpen={!!cancelInvoiceId}
        handleClose={() => setCancelInvoiceId(null)}
        handleContinue={cancelInvoice}
        title="Cancel invoice package"
        message="Are you sure you want to cancel this invoice package?"
        primaryBtnTxt="Cancel package"
        secondaryBtnTxt="Keep"
        icon="new-calendar-red"
        variant="danger"
      />

      {singlePackageDetails ? (
        <PreviewInvoicePackageModal {...{ setSinglePackageDetails, singlePackageDetails }} />
      ) : null}

      {invoice ? (
        <Modal
          isLarge
          slideFromRight
          handleOpen={!!invoice}
          title="Package Invoice"
          handleClose={() => setInvoice(false)}>
          <Configurations
            newInvoice={[
              {
                ...invoice,
                selected: true,
                expanded: true,
                discountAmount: invoice?.discount?.amount_cents || 0,
                balance:
                  invoice.total_amount -
                  invoice.amount_paid -
                  (invoice?.discount?.amount_cents || 0) +
                  invoice?.tax
              }
            ]}
            amount={invoice.total_amount - invoice.amount_paid}
            patientId={patientId}
            onClose={() => setInvoice(false)}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default InvoicePackageTable;
