const { ia, iaRa } = require('lib/helpers/utility');
const { title } = require('process');

exports.createReviewOfSystemsNarrative = (reviewData) => {
  const narratives = {
    patient: [],
    practitioner: []
  };

  const processReviewData = (type) => {
    const data = reviewData?.[type];
    if (!ia(data)) {
      return;
    }

    data.forEach((section) => {
      if (section?.noBlock) {
        return narratives[type].push(
          `<p><strong>${section?.title}:</strong> No ${section?.title?.toLowerCase()} issue reported.</p>`
        );
      }

      const checkedSymptoms = section.row.filter((item) => item.checked);

      if (checkedSymptoms.length > 0) {
        const sectionNarrative = checkedSymptoms
          .map((symptom) => {
            let timePhrase = symptom.when?.label ? `for ${symptom.when.label.toLowerCase()}` : '';
            let locationPhrase = symptom.where ? ` at location ${symptom.where}` : '';

            return `${symptom?.name?.toLowerCase()}${timePhrase || locationPhrase ? ` ${timePhrase}${locationPhrase}` : ''}`;
          })
          .join(', ');

        narratives[type].push(`<p><strong>${section?.title}:</strong> ${sectionNarrative}.</p>`);
      }
    });
  };

  processReviewData('patient');
  processReviewData('practitioner');

  const patientSection = ia(narratives.patient)
    ? `<p><strong>Patient Response:</strong></p>${narratives.patient.join('')}`
    : '';

  const practitionerSection = ia(narratives.practitioner)
    ? `<p><strong>Practitioner Observations:</strong></p>${narratives.practitioner.join('')}`
    : '';

  const sections = [patientSection, practitionerSection].filter(Boolean);
  return ia(sections) ? `<div>${sections.join('<br>')}</div>` : '';
};
