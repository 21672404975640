import { showAlert } from 'components/shared/Alert/Alert';
import state from 'components/state';
import { setRecoil } from 'recoil-nexus';

export default (data) => {
  setRecoil(state.intelligenceAiState, (ps) => {
    showAlert({
      title: 'MyScribe AI Enhancement Error',
      color: 'warning',
      message: data?.error || 'Unknown error has occurred.'
    });
    return { ...ps, loading: false };
  });
};
