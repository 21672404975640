import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';

import { usePatientChartContext } from 'lib/context/PatientChartContext/PatientChartContext';
import { TagContextProvider } from 'lib/context/TagContext/TagContextProvider';

import { interimApi } from '../../../../api/InterimApi';
import { chartLinks } from '../../../../constants';
import { NewAppointmentContextProvider } from '../../../../lib/context/Appointment/NewAppointmentContextProvider';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import { reShapePractitioners } from '../../../../lib/helpers/utility';
import { usePatient } from '../../../../lib/hooks/queries/patients/usePatient';
import { usePractitioners } from '../../../../lib/hooks/queries/practitioners/usePractitioners';
import usePageTitle from '../../../../lib/hooks/usePageTitle';
import useScrollBlock from '../../../../lib/hooks/useScrollBlock';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import Tabs from '../../../shared/Tabs/NewTabs';
import { userState } from '../../../state';
import NewAppointment from '../../appointment/NewAppointment/NewAppointment';
import OverviewBlock from '../OverviewBlock/OverviewBlock';
import { handleUpdatePatientTags } from '../lib/updatePatientTags';

import PinnedTagsContainer from './components/PatientTags/PinnedSection/PinnedTagsContainer';

function PatientChart() {
  const { togglePinnedSection } = usePatientChartContext();
  const [practicePatient, setPracticePatient] = useState();
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const { id, appointmentId } = useParams();
  const { data, isLoading, isFetching } = usePatient({
    params: { id },
    dependencies: [Number(id)]
  });
  const patient = data?.patient;
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const navigate = useNavigate();
  const { device } = useUIContext();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const currentUser = useRecoilValue(userState);
  const tagSettings = currentUser?.display_settings?.patientChartTags;
  const isPageClinicalNotesOld = pathname?.includes(`clinical-notes-old/${appointmentId}`);
  const isClinicalNote = pathname?.includes(`clinical-notes/${appointmentId}`);
  const is3dPage = pathname?.includes(`clinical-notes/${appointmentId}/3d`);

  usePageTitle(patient?.fullName);

  useEffect(() => {
    getPracticePatientData();
  }, [id]);

  const getPracticePatientData = async () => {
    try {
      let res = await interimApi(
        '/api/practice/patient/read',
        {
          patient_id: id
        },
        navigate
      );
      const { practicePatientData } = res.data;
      if (res.data && practicePatientData) {
        setPracticePatient(practicePatientData);
      } else {
        Honeybadger.notify(`getPracticePatientData patientID: ${id}`);
      }
    } catch (error) {
      Honeybadger.notify(`getPracticePatientData patientID: ${id}, error: ${error}`);
    }
  };

  const { data: practitionersList } = usePractitioners();
  const practitioners = practitionersList?.practitioners;

  const showNewAppointmentModal = () => {
    blockScroll();
    setNewAppointmentModal(true);
  };

  const hideNewAppointmentModal = () => {
    allowScroll();
    setNewAppointmentModal(false);
  };

  if (isClinicalNote)
    return (
      <Outlet
        context={{
          patient,
          practicePatient,
          setPracticePatient
        }}
      />
    );

  const debouncedUpdateTags = debounce(async (patientId, tags, mode) => {
    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId,
      tags,
      mode,
      fromChart: true
    });
  }, 1000);

  const handleAddTags = (tag) => {
    const tagsToAdd = tag;
    debouncedUpdateTags(patient?.id, tagsToAdd, 'add');
    setTagsToAdd([]);
  };

  const handleRemoveTag = async (id) => {
    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId: patient?.id,
      tags: [id],
      mode: 'remove',
      fromChart: true
    });
    setTagsToAdd([]);
  };

  return (
    <div className={cs(device === 'desktop' || is3dPage ? 'flex h-full flex-col' : 'block h-full')}>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <OverviewBlock
          showNewAppointmentModal={showNewAppointmentModal}
          patient={patient}
          isLoading={isLoading || isFetching}
        />
      </ErrorBoundary>
      <div
        className={cs(
          device == 'desktop' && 'overflow-hidden',
          'flex flex-col',
          isPageClinicalNotesOld ? '!mt-0 bg-transparent shadow-none' : 'h-full shadow-small'
        )}>
        {isLoading || isFetching || !patient ? (
          <Skeleton />
        ) : (
          <>
            {(tagSettings?.isPinned || togglePinnedSection) && (
              <TagContextProvider>
                <PinnedTagsContainer
                  tags={patient?.tags || []}
                  showHighlight={togglePinnedSection}
                  handleAddTags={handleAddTags}
                  handleRemove={handleRemoveTag}
                  tagsToAdd={tagsToAdd}
                  setTagsToAdd={setTagsToAdd}
                />
              </TagContextProvider>
            )}
            <Tabs tabsData={chartLinks} />
            <Outlet
              context={{
                patient,
                practicePatient,
                setPracticePatient,
                showNewAppointmentModal
              }}
            />
          </>
        )}
      </div>
      {newAppointmentModal && (
        <NewAppointmentContextProvider>
          <NewAppointment
            selectedPatient={patient}
            isOpen={newAppointmentModal}
            onAfterOpen={showNewAppointmentModal}
            hideNewAppointment={hideNewAppointmentModal}
            practitioners={reShapePractitioners(practitioners)}
          />
        </NewAppointmentContextProvider>
      )}
    </div>
  );
}

export default PatientChart;
