import { iaRa } from 'lib/helpers/utility';
import { translateMedicationToWeightLossData } from '../../Provider/WeightLoss/lib/helpers';
import { formatMultipleMedsFormat, formatMultipleWhlFormat } from '../lib/helpers';

export const handleStatusFormatData = (data) => {
  const syncedMedsToWhl = iaRa(data).filter((med) => med?.weight_loss_medication_id);

  const weightLossResponses = iaRa(syncedMedsToWhl).map((med) =>
    translateMedicationToWeightLossData({
      medication: med,
      medication_id: med?.id,
      weight: med?.weight,
      showId: true
    })
  );
  const medData = {
    medications: formatMultipleMedsFormat(data, data[0]?.status)
  };
  const whlData = formatMultipleWhlFormat(weightLossResponses, data[0]?.status);
  return { whlData, medData };
};

export const findStatusLabelAndColor = ({ type = 'label', data }) => {
  if (type == 'label') return data[0]?.status === 'active' ? 'Inactive' : 'Active';
  return data[0]?.status === 'active' ? 'danger' : 'primary';
};
