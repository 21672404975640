import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { iaRa } from 'lib/helpers/utility';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';

export const isItemNull = (item) => item == null || item == '' || item == 'null';

export function ChartRowData({ data, index, dataset, setFieldToFocus, setShow, vitals, setVital }) {
  const [editPopOver, setEditPopOver] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const popoverRef = useRef(null);
  const vitalsData = iaRa(vitals);

  const handleSetLastVitals = (vital) => {
    const {
      heart_rate,
      appointment_id,
      blood_pressure_systolic,
      blood_pressure_diastolic,
      bmi,
      notes,
      head_circumference,
      head_circumference_unit,
      height,
      weight,
      timestamp,
      respirations,
      oxygen_level,
      temperature,
      id,
      ...rest
    } = vital;

    const vitalsArray = [
      {
        id,
        heart_rate,
        blood_pressure_systolic,
        blood_pressure_diastolic,
        bmi,
        notes,
        head_circumference,
        head_circumference_unit,
        height,
        weight,
        timestamp,
        respirations,
        oxygen_level,
        temperature,
        appointment_id
      }
    ];
    setVital(...vitalsArray);
  };
  return (
    <tr
      className={cs(
        '!ml-3 !w-full border !border-x-0 !border-b-0',
        index !== 0 && 'border !border-x-0 !border-b-0  border-neutral-100',
        !dataset.visible && 'opacity-25'
      )}>
      {data?.map(({ item, id: vitalId }, cellIndex) => {
        const modifier = dataset?.modifier ? dataset.modifier(item) : null;

        return (
          <td
            key={cellIndex}
            onMouseEnter={() => {
              setIndexToEdit(cellIndex);
              setEditPopOver(true);
            }}
            onMouseLeave={() => setEditPopOver(false)}
            className={`relative !h-[45px] !rounded-none !py-[12px] text-center hover:bg-primary-25 ${
              !isItemNull(item) ? 'font-medium text-neutral-600' : 'text-neutral-400'
            } text-sm`}>
            {isItemNull(item) ? (
              '-'
            ) : modifier && dataset?.label === 'Weight' ? (
              <div className="flex flex-row flex-wrap justify-center">
                {modifier?.pounds !== '' && <p className="mr-1">{modifier?.pounds} lbs</p>}
                {modifier?.ounces !== '' && <p>{modifier?.ounces} oz</p>}
              </div>
            ) : modifier && dataset?.label === 'Height' ? (
              <div className="flex flex-row flex-wrap justify-center">
                {modifier?.feet !== '' && <p className="mr-1">{modifier?.feet} ft</p>}
                {modifier?.inches !== '' && <p>{modifier?.inches} in</p>}
              </div>
            ) : (
              modifier || item
            )}

            {editPopOver && indexToEdit === cellIndex && (
              <div
                ref={popoverRef}
                className="absolute z-30 grid w-max cursor-pointer rounded-[4px] bg-white shadow-medium"
                style={{ top: '-10px', right: '-10px' }}>
                <Icon
                  icon="new-edit"
                  size={20}
                  onClick={() => {
                    setFieldToFocus(dataset?.label);
                    const selectedVital = vitalsData?.find((vital) => vital?.id === vitalId);
                    if (selectedVital) {
                      handleSetLastVitals(selectedVital);
                    }
                    setShow(true);
                  }}
                />
              </div>
            )}
          </td>
        );
      })}
    </tr>
  );
}
export function ChartLabels({ labels }) {
  return (
    <tr>
      {labels?.map((label, index) => (
        <th className="sticky top-0 h-[64px] min-w-[70px] !py-[10px] text-center" key={index}>
          <p className="text-xs !font-medium tracking-tight text-neutral-600">
            {moment(label).format('MMM DD')}
          </p>
          <p className="text-[10px] !font-medium tracking-wide !text-neutral-600">
            {moment(label).format('YYYY')}
          </p>
        </th>
      ))}
    </tr>
  );
}

export default function ChartRow({ item, index, toggleDataset }) {
  return (
    <div
      onClick={toggleDataset}
      className={cs(
        'flex cursor-pointer items-center justify-between gap-2 !rounded-none !py-[14px] px-3',
        index !== 0 && 'border !border-x-0 !border-b-0 border-neutral-100',
        item.visible && 'to-primary-0 bg-gradient-to-r from-primary-50'
      )}>
      <div className="flex items-center gap-2">
        <Checkbox
          isChecked={item.visible}
          inputClassName="!w-5 !h-5 before:!w-5 before:!h-5 before:!rounded-full after:!w-[5px] after:!h-[10px] after:!top-[4px] after:!left-[7.5px]"
        />
        <p className={`${!item.visible ? '!text-gray-400' : 'font-medium !text-primary-800'}`}>
          {item.label}
        </p>
        <p className={`mt-[2px] text-xs ${item.visible ? 'text-neutral-600' : 'text-gray-300'}`}>
          {item.rate}
        </p>
      </div>
      {item.visible && (
        <div
          style={{ backgroundColor: item.color }}
          className="!h-4 !w-4 rounded-full !border-4 border-solid !border-white !border-opacity-90"
        />
      )}
    </div>
  );
}
