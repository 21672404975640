import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../practiceState';
import ClinicalNotesTableWrapper from './components/ClinicalNotesTableWrapper';

const AllClinicalNotes = () => {
  const practice = useRecoilValue(currentPractice);
  const { appointmentId } = useParams();
  const { patient, practicePatient, setPracticePatient } = useOutletContext();

  moment.tz.setDefault(practice?.timezone);

  let hpNoteOverviewRefs = {};
  const makeHpNoteOverviewRefs = () => {
    const stringArray = [
      'allergies',
      'vitals',
      'medicationHistory',
      'vaccines',
      'weightLossAdministration',
      'socialHistory',
      'familyHistory',
      'medicalHistory',
      'surgicalHistory'
    ];

    stringArray.forEach((string) => {
      hpNoteOverviewRefs[string] = useRef(null);
    });
  };

  makeHpNoteOverviewRefs();

  if (appointmentId)
    return (
      <Outlet
        context={{
          patient,
          practicePatient,
          setPracticePatient,
          hpNoteOverviewRefs
        }}
      />
    );

  return <ClinicalNotesTableWrapper patient_id={patient?.id} />;
};

export default withErrorBoundary(AllClinicalNotes);
