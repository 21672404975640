import Allowed from 'components/shared/Permissions/Allowed';
import React from 'react';
import { ia, io, isEmpty, stripHtmlTags } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const FamilyHistoryOverview = ({
  cnSettings,
  isOpen = {},
  toggleOne = () => {},
  overviewData = {}
}) => {
  if (cnSettings && !cnSettings?.overview?.family_history && !cnSettings?.overview?.familyHistory) {
    return null;
  }

  return (
    <Allowed requiredPermissions="patients_medical_history.read">
      <SimpleAccordion
        title="Family history"
        onToggle={() => toggleOne('familyHistory')}
        showExpandIcon={ia(overviewData?.familyHistory)}
        open={isOpen.familyHistory}>
        {ia(overviewData?.familyHistory) &&
          overviewData?.familyHistory?.map((familyHistory, index) => {
            if (familyHistory?.response?.noDetails) {
              return (
                <NoKnown
                  key={index}
                  form={familyHistory}
                  description="No known family history"
                  shouldShowDivider={index !== 0}
                />
              );
            }

            return familyHistory?.response?.row?.map((item, idx) => (
              <React.Fragment key={idx}>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                <div key={idx} className="grid gap-y-1">
                  {io(item.medicalCondition) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Medical condition:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                        {item.medicalCondition.value}
                      </span>
                    </div>
                  )}
                  {(item?.fatherSide || item?.motherSide || item?.sibling) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Inheritance:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                        {[
                          item.fatherSide && 'Father side',
                          item.motherSide && 'Mother side',
                          item.sibling && 'Sibling'
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    </div>
                  )}
                  {!isEmpty(item.note) && (
                    <div className="flex flex-wrap justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Note:
                      </span>
                      <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                        {stripHtmlTags(item.note)}
                      </span>
                    </div>
                  )}
                  <CreationInfo
                    createdAt={familyHistory?.createdAt}
                    createdBy={familyHistory?.createdBy?.fullName}
                  />
                </div>
              </React.Fragment>
            ));
          })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default FamilyHistoryOverview;
