import { queryClient } from 'App';

export default async (data) => {
  const { note, patient_id } = data;
  queryClient.setQueryData(['sticky-notes', patient_id], (oldData) => {
    if (oldData) {
      const newNoteExists = oldData.notes.find((it) => it.id === note.id);

      return {
        ...oldData,
        notes: newNoteExists ? oldData.notes : [...oldData.notes, note]
      };
    }
  });
};
