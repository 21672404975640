import { useQuery } from '@tanstack/react-query';
import { fetchData } from 'api/FetchData';
import { useNavigate } from 'react-router-dom';

export const useFetchData = ({ queryKey, url, params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  if (!queryKey) {
    throw new Error('The "queryKey" parameter is required for useFetchData.');
  }

  if (!url) {
    throw new Error('The "url" parameter is required for useFetchData.');
  }

  return useQuery([queryKey, ...dependencies], () => fetchData(url, navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
