import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate } from 'react-router-dom';
import { filePath } from 'lib/helpers/utility';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { useQueryClient } from '@tanstack/react-query';

const CreateCNConfirm = ({
  showCreateConfirm,
  setShowCreateConfirm,
  patientId,
  provider,
  setProvider,
  providers
}) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const currPractice = useRecoilValue(currentPractice);
  const queryClient = useQueryClient();

  const onSuccess = async (data) => {
    queryClient.setQueriesData({ queryKey: ['clinicalNotes'] }, (oldData) => {
      const { appointment, practitioner } = data;

      const newItem = { appointment, practitioner };

      oldData?.clinicalNote?.unshift(newItem);

      const count = Number(oldData?.count?.count);

      return {
        ...oldData,
        count: {
          ...oldData?.count,
          count: count + 1
        }
      };
    });

    if (data?.appointment_id)
      navigate(`/portal/charts/${patientId}/clinical-notes/${data.appointment_id}`);
  };

  const onError = (error, code) => {
    if (code === 4) {
      error.map((errorMessage) => showAlert({ message: errorMessage, color: 'danger' }));
      return;
    }

    showAlert({ message: error, color: 'danger' });
  };

  const createNote = async () => {
    setLoading(true);

    try {
      const appointmentToBeCreated = {
        user_id: patientId,
        practitioner_id: provider.value,
        resource_id: null,
        practice_note: null,
        type: null,
        starts_at: moment().tz(currPractice?.timezone).format(),
        ends_at: moment().tz(currPractice?.timezone).add(60, 'minutes').format(),
        location: null,
        form_ids: [],
        procedures: [],
        products: [],
        virtual_link: null,
        enable_notifications: false,
        tag_ids: null
      };

      await requestApi({
        url: '/api/appointment/create',
        params: {
          appointment: appointmentToBeCreated,
          additionalTimes: null,
          appointmentLength: 60,
          checkForExistingAppt: false,
          returnDetails: true
        },
        onSuccess,
        onError,
        navigate,
        filePath: `${__dirname}/${__filename}`
      });
    } catch (err) {
      showAlert({ message: 'Appointment creation failed', color: 'danger' });
      Honeybadger.notify(`${filePath(import.meta.url)}, createNote(), err: ${err}`);
    }

    setShowCreateConfirm(false);
    setLoading(false);
  };

  return (
    <Confirm
      handleOpen={showCreateConfirm}
      handleClose={() => setShowCreateConfirm(false)}
      handleContinue={() => {
        createNote();
      }}
      disabled={!provider || loading}
      title="Confirm Clinical Note Creation"
      primaryBtnTxt="Yes, Create Clinical Note"
      secondaryBtnTxt="Cancel"
      icon="new-notev2"
      loading={loading}>
      <p className="whitespace-pre-line text-sm text-neutral-600">
        Creating a clinical note will automatically generate an appointment in your calendar based
        on the selected time and provider. This ensures compliance and seamless integration within
        your Myriad Health interface.
      </p>
      <Select
        isImage
        label="Provider"
        placeholder="Type and select a provider"
        parentClassName="mt-2 mb-3"
        inputId="provider"
        data-qa="appointment-practitioner-select"
        options={providers}
        value={provider}
        isClearable={false}
        onChange={(e) => {
          setProvider(e);
        }}
      />

      <p className="whitespace-pre-line text-sm font-semibold text-neutral-600">
        Are you sure you want to proceed?
      </p>
    </Confirm>
  );
};

export default CreateCNConfirm;
