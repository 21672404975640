import isEqual from 'lodash/isEqual';

import { mString } from 'lib/helpers/utility';
import Actions from '../components/Actions';

export const ndcCodesOptions = (ndcCodes) => {
  return ndcCodes?.map((item) => ({
    value: item?.package_ndc,
    label: item?.package_ndc
  }));
};

export const defaultFilters = () => ({
  name: {
    type: 'search',
    values: null,
    title: 'Name',
    placeholder: 'Search name'
  },
  description: {
    type: 'search',
    values: null,
    title: 'Description'
  },
  procedure_code: {
    type: 'search',
    values: null,
    title: 'Procedure Code'
  }
});

export const defaultColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    tooltipField: 'name',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'description',
    cellClass: 'no-border',
    headerName: 'Description',
    tooltipField: 'description',
    flex: 1
  },
  {
    field: 'procedure_code',
    headerName: 'Procedure Code',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'time_length',
    headerName: 'Time Length',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'total_cost_cents',
    headerName: 'Self Pay Amt.',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'standard_charge',
    headerName: 'Standard Charge',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'cpt',
    headerName: 'Medicare Allowable Amt.',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => mString(value?.par_amount)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

/**
 * @param {Object} updatedService - The updated service object.
 * @param {Object} service - The original service object.
 * @returns {Object} - An object containing the changes.
 */
export const getServiceChanges = (updatedService, service) => {
  const serviceChanges = {};
  for (const key in updatedService) {
    if (!isEqual(updatedService[key], service[key])) {
      serviceChanges[key] = updatedService[key];
    }
  }
  return serviceChanges;
};

/**
 * @param {Object} values - The current pay schedule values from formik.
 * @param {Object} initialValues - The initial pay schedule values (e.g., service.payerConnection).
 * @returns {Array} - An array of objects that contain the changes in the pay schedule.
 */
export const getPayScheduleChanges = (values, initialValues) => {
  const payScheduleChanges = [];
  for (const key in values) {
    if (values[key].allowable_amount_cents !== initialValues[key].allowable_amount_cents) {
      payScheduleChanges.push(values[key]);
    }
  }
  return payScheduleChanges;
};
