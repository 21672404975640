import { requestApi } from 'api/Api';
import state from 'components/state';
import { getRecoil, setRecoil } from 'recoil-nexus';

const onAuthReq = async (socket) => {
  let socketSessionTokenState = getRecoil(state.socketSessionTokenState);
  if (!socketSessionTokenState) {
    const resData = await requestApi({
      url: '/api/user/check_token',
      navigate: () => {}
    });
    const { socketSession } = resData;
    setRecoil(state.socketSessionTokenState, socketSession);
    socketSessionTokenState = socketSession;
  }
  socket.emit('user-auth', { token: socketSessionTokenState });
};

export default onAuthReq;
