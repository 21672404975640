import AGTable from 'components/shared/AGTable/AGTable';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import cs from 'classnames';
import EmptyTreatmentPlan from '../../MyScribeAI/Templates/MyScribeTemplate/components/treatmentPlan/components/EmptyTreatmentPlan';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const TPTable = ({ data, loadEPNToCN = false, onClick }) => {
  const { gridApi } = useTableContext();
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  useEffect(() => {
    setSelectedTreatmentPlan({});
  }, []);

  useEffect(() => {
    if (gridApi && selectedTreatmentPlan) {
      const node = gridApi?.getRowNode(selectedTreatmentPlan.id);
      if (node) {
        node.setSelected(true);
      }
    }
  }, [selectedTreatmentPlan, gridApi]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      cellClass: ({ data }) => {
        const endDateMoment = moment(data.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
        const currentDate = moment();
        const isExpired = endDateMoment.isBefore(currentDate, 'day');

        return cs(
          isExpired && loadEPNToCN ? 'cursor-not-allowed' : 'cursor-pointer',
          selectedTreatmentPlan?.id === data.id && 'selected'
        );
      },
      tooltipValueGetter: ({ data }) => {
        if (!loadEPNToCN) return;

        const endDateMoment = moment(data.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
        const currentDate = moment();
        const isExpired = endDateMoment.isBefore(currentDate, 'day');

        if (isExpired && loadEPNToCN) {
          return 'Expired';
        }
      }
    }),
    []
  );

  const rowClickHandler = ({ data, node }) => {
    if (!node.selectable) return;
    node.setSelected(true);
    setSelectedTreatmentPlan(data);
    onClick?.({ treatmentPlan: data });
  };

  const checkRowSelectable = (data) => {
    if (loadEPNToCN) {
      const endDateMoment = moment(data.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
      const currentDate = moment();
      const isExpired = endDateMoment.isBefore(currentDate, 'day');

      if (isExpired) return false;
    }
    return true;
  };

  return (
    <div className="!mb-0 h-full">
      <AGTable
        data={data}
        defaultColDef={defaultColDef}
        getRowId={({ data }) => {
          return data.id;
        }}
        isRowSelectable={({ data }) => checkRowSelectable(data)}
        customClassName="ag-grid-interactive "
        onRowClicked={rowClickHandler}
        tooltipShowDelay={1000}
        noRowsOverlayComponent={EmptyTreatmentPlan}
        noRowsOverlayComponentParams={{ onClick }}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px]">
            <Skeleton count={24} />
          </div>
        )}
      />
    </div>
  );
};

export default TPTable;
