import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { capitalize } from 'lodash';
import { interimApi } from 'api/InterimApi';
import { useNavigate } from 'react-router-dom';
import { optionify, imagePath } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Button from 'components/shared/Buttons/Button';

export default function Account({
  onSubmit,
  onChange,
  onAddressChange,
  errors,
  patient,
  genderList,
  raceList,
  stateList,
  address,
  maritalStatusList,
  onHandleEmergencyContactChange,
  openFilestackPicker
}) {
  const [payers, setPayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPayers();
  }, []);

  const findStateName = (value) => {
    const state = stateList?.find((state) => {
      if (state?.id == value.state) {
        return state;
      }
    });

    return state && state?.name;
  };

  const getPayers = async () => {
    try {
      let res = await interimApi('/api/patient/checkin/payers', {}, navigate);
      const { payers: loadedPayers } = res.data;
      if (res.data.payers) {
        setPayers(loadedPayers);
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(' ', err);
    }
  };
  return (
    <div className="p-12">
      <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center">
        <form onSubmit={onSubmit} className="block p-3">
          <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
            <div className="block w-full p-3">
              <div
                className="item-center flex size-52 cursor-pointer justify-center overflow-hidden rounded-lg border border-dashed border-primary-500"
                onClick={() => openFilestackPicker()}>
                {patient.profile_photo ? (
                  <img src={imagePath(JSON.parse(patient.profile_photo).jpg)} />
                ) : (
                  <Icon icon="new-file-upload" />
                )}
              </div>
            </div>
            <div className="flex w-full p-3 sm:flex-col md:flex-col xs:flex-col">
              <div className="!m-1 inline-block">
                <Input
                  label="First Name"
                  placeholder="First Name"
                  name="f_name"
                  value={patient.f_name}
                  disabled
                  error={errors.f_name?.message}
                />
              </div>

              <div className="!m-1 inline-block">
                <Input
                  label="Middle Name"
                  placeholder="Middle Name"
                  name="m_name"
                  value={patient.m_name}
                  disabled
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Last Name"
                  placeholder="Last Name"
                  name="l_name"
                  value={patient.l_name}
                  disabled
                  error={errors.l_name?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Date of birth"
                  type="date"
                  name="dob"
                  value={Moment(patient.dob).format('YYYY-MM-DD')}
                  onChange={onChange}
                  error={errors.dob?.message}
                />
              </div>

              <div className="!m-1 inline-block">
                <Input
                  label="Phone number"
                  placeholder="+1(123)4567890"
                  name="phone"
                  value={patient.phone}
                  onChange={onChange}
                  error={errors.phone?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Email"
                  placeholder="email@example.com"
                  name="email"
                  value={patient.email}
                  onChange={onChange}
                  error={errors.email?.message}
                />
              </div>
            </div>
            <div className="flex w-full p-3 sm:flex-col md:flex-col xs:flex-col">
              <div className="!m-1 inline-block">
                <Select
                  className="min-w-[182px]"
                  inputId="gender"
                  options={genderList}
                  label="Gender"
                  placeholder="Type and select Gender"
                  value={{ label: capitalize(patient.gender) }}
                  onChange={(e) =>
                    onChange({ target: { name: 'gender', value: e?.value || null } })
                  }
                  error={errors.gender?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Select
                  className="min-w-[182px]"
                  inputId="race"
                  options={optionify(raceList, 'value', 'value')}
                  label="Race"
                  placeholder="Type and select Race"
                  value={{
                    label: patient.race
                  }}
                  onChange={(e) => onChange({ target: { name: 'race', value: e?.value || null } })}
                  error={errors.race?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Select
                  className="min-w-[182px]"
                  inputId="marital_status"
                  options={optionify(maritalStatusList, 'value', 'value')}
                  label="Marital Status"
                  value={{
                    label: patient.marital_status
                  }}
                  onChange={(e) =>
                    onChange({ target: { name: 'marital_status', value: e?.value } })
                  }
                  error={errors.marital_status?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Driver's License"
                  placeholder="Driver's License"
                  name="personal_id"
                  value={patient.personal_id}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex w-full p-3 sm:flex-col md:flex-col xs:flex-col">
              <div className="!m-1 inline-block">
                <Input
                  label="Address"
                  placeholder="Address"
                  name="address_ln_1"
                  value={address.address_ln_1}
                  onChange={onAddressChange}
                  error={errors.address.address_ln_1?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Address 2"
                  placeholder="Address 2"
                  name="address_ln_2"
                  value={address.address_ln_2}
                  onChange={onAddressChange}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="City"
                  placeholder="City"
                  name="city"
                  value={address.city}
                  onChange={onAddressChange}
                  error={errors.address.city?.message}
                />
              </div>
              <div className="!m-1 inline-block">
                <Select
                  className="min-w-[182px]"
                  inputId="state-select"
                  label="State"
                  placeholder="State"
                  name="state"
                  value={{ label: findStateName(address) }}
                  onChange={(e) => onAddressChange({ target: { ...e, name: 'state' } })}
                  options={optionify(stateList, 'name', 'id')}
                />
              </div>
              <div className="!m-1 inline-block">
                <Input
                  label="Zip"
                  placeholder="Zip"
                  name="zip"
                  value={address.zip}
                  onChange={onAddressChange}
                  error={errors.address.zip?.message}
                />
              </div>
            </div>
            {/* TODO: figure out how to display multiple insurance profiles per practice */}
            {/* <div className="block p-3 w-full">
              <InsuranceProfiles
                patientId={patient.id}
                insuranceProfiles={patient.insuranceProfile}
                payers={payers}
              />
            </div> */}
            <div className="block w-full p-3 ">
              <h6>Emergency Contact</h6>
              <div className=" flex sm:flex-col md:flex-col xs:flex-col">
                <div className="!m-1 inline-block">
                  <Input
                    label="Name"
                    placeholder="Full name"
                    name="name"
                    value={patient.emergency_contact.name}
                    onChange={onHandleEmergencyContactChange}
                  />
                </div>
                <div className="!m-1 inline-block">
                  <Input
                    label="Phone Number"
                    placeholder="Phone Number"
                    name="phone"
                    value={patient.emergency_contact.phone}
                    onChange={onHandleEmergencyContactChange}
                  />
                </div>
                <div className="!m-1 inline-block">
                  <Input
                    label="Relationship"
                    placeholder="Relation to patient"
                    name="relationship"
                    value={patient.emergency_contact.relationship}
                    onChange={onHandleEmergencyContactChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button text="Update" buttonType="submit" />
        </form>
      </div>
    </div>
  );
}
