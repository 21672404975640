export default {
  X_SMALL: 'text-xs',
  SMALL: 'text-xs',
  MEDIUM: 'text-sm',
  LARGE: 'text-base',
  EXTRA_LARGE: 'text-lg',
  '2Extra_LARGE': 'text-xl',
  '3Extra_LARGE': 'text-2xl',
  '4Extra_LARGE': 'text-3xl'
};
