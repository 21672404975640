export const initialValues = (data) => ({
  supervisorId: data?.supervisorId || null,
  users: data?.users || [],
  payers: data?.payers || [],
  procedures: data?.procedures || []
});

export const initialMember = {
  f_name: '',
  l_name: '',
  email: '',
  id: 0,
  m_name: '',
  phone: '',
  color: '#ffffff',
  role_id: 0,
  active: false
};
