import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { userState } from 'components/state';
import { formatDateAndTimeZ, iaRa, reShapePractitioners } from 'lib/helpers/utility';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isToday } from '../../PreviewNote/lib/renderedHelper';
import CreateCNConfirm from './CreateCNConfirm';
import ExistingCNConfirm from './ExistingCNConfirm';

const getTodayNotes = (notes, timezone) =>
  iaRa(notes)?.filter((note) => isToday(note?.appointment?.starts_at, timezone));

const formatNoteLabel = (note, timezone) => {
  const date = formatDateAndTimeZ(note?.appointment?.starts_at, timezone);
  const provider = note?.practitioner?.professionalFullName;
  const services = note?.services?.join(', ');

  return `${date} - ${provider} ${services ? '- ' + services : ''}`;
};

const CreateClinicalNoteButton = ({ patientId, widget = false }) => {
  const [note, setNote] = useState(null);
  const [provider, setProvider] = useState(null);
  const [showCreateConfirm, setShowCreateConfirm] = useState(false);
  const [showLoadOrCreateConfirm, setShowLoadOrCreateConfirm] = useState(false);

  const currentUser = useRecoilValue(userState);
  const currPractice = useRecoilValue(currentPractice);

  const timezone = currPractice?.timezone;

  const today = moment.tz(timezone).format();

  const { data: clinicalNotes } = useClinicalNote({
    dependencies: ['today', patientId],
    params: {
      patientId,
      startDate: today,
      endDate: today
    },
    options: {
      select: (res) => iaRa(res?.clinicalNote)
    }
  });

  const noteOptions = useMemo(
    () =>
      getTodayNotes(clinicalNotes, timezone).map((note) => ({
        label: formatNoteLabel(note),
        value: note?.appointment?.id,
        data: note
      })),
    [clinicalNotes]
  );

  const { data: practitionersList } = usePractitioners(
    {},
    {
      select: (data) => reShapePractitioners(iaRa(data.practitioners)),
      enabled: currentUser.kind !== 'practitioner'
    }
  );

  useEffect(() => {
    if (!provider) {
      const currentProvider = iaRa(practitionersList).find(
        (p) => p?.value === currentUser?.user_id
      );
      if (currentProvider) setProvider(currentProvider);
    }
  }, [showCreateConfirm, practitionersList]);

  const clickHandler = () => {
    if (!!noteOptions?.length) setShowLoadOrCreateConfirm(true);
    else setShowCreateConfirm(true);
  };

  return (
    <Allowed requiredPermissions="clinical_note.create">
      {!widget ? (
        <Button
          text="Create Clinical Note"
          icon="new-add-square"
          iconSize={20}
          iconColor="white"
          size="small"
          onClick={clickHandler}
        />
      ) : (
        <Icon icon="new-add-square" className="cursor-pointer" onClick={clickHandler} size={22} />
      )}
      <ExistingCNConfirm
        note={note || noteOptions[0]}
        setNote={setNote}
        patientId={patientId}
        todayNotes={noteOptions}
        setShowCreateConfirm={setShowCreateConfirm}
        showLoadOrCreateConfirm={showLoadOrCreateConfirm}
        setShowLoadOrCreateConfirm={setShowLoadOrCreateConfirm}
      />
      <CreateCNConfirm
        showCreateConfirm={showCreateConfirm}
        setShowCreateConfirm={setShowCreateConfirm}
        patientId={patientId}
        provider={provider}
        setProvider={setProvider}
        providers={practitionersList}
      />
    </Allowed>
  );
};

export default CreateClinicalNoteButton;
