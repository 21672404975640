import NotFound from 'components/practice/Dashboard/components/NotFound';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from 'lib/helpers/utility';
import React from 'react';
import VitalsPreview from './VitalsPreview';
import { onNotFoundCreateClick } from './lib/helper';
const VitalsMenu = ({ setVital = () => {}, setShowVitalsUpsertModal = () => {} }) => {
  const { currentHpOverviewData } = useClinicalNoteContext();
  const appointmentVitals = currentHpOverviewData?.vitals;
  if (!ia(appointmentVitals?.vitals)) {
    return (
      <NotFound
        title="No vitals added yet"
        description="You can start adding vitals right now."
        icon="new-no-cpt-codes-illustration"
        className="h-96 !p-0"
        createText="Add Vitals"
        onCreate={() => onNotFoundCreateClick({ setShowVitalsUpsertModal, setVital })}
      />
    );
  }
  return (
    <div className="h-full border-r-8 bg-primary-10">
      {iaRa(appointmentVitals?.vitals).map((vital, index) => (
        <VitalsPreview
          setVital={setVital}
          key={index}
          index={index}
          vital={vital}
          setShowVitalsUpsertModal={setShowVitalsUpsertModal}
        />
      ))}
    </div>
  );
};
export default VitalsMenu;
