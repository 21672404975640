const { requestApi } = require('api/Api');
const Honeybadger = require('@honeybadger-io/js');

exports.getCustomFormResponse = async ({
  patientId,
  type = [
    'allergies',
    'medicalHistory',
    'vaccines',
    'conditions',
    'familyHistory',
    'socialHistory',
    'surgicalHistory',
    'weightLossAdministration'
  ]
}) => {
  try {
    const customResponsesRes = await requestApi({
      url: '/api/custom_form_response/read',
      params: {
        patientId,
        allVersions: true,
        type
      }
    });

    let { response } = customResponsesRes;

    return response;
  } catch (error) {
    console.log({ error });
  }
};

exports.getPatientHistory = async ({
  patientId,
  type,
  setPatientHistoryLoading = () => {},
  setOverviewData = () => {}
}) => {
  try {
    setPatientHistoryLoading(true);

    const customFormResponse = await this.getCustomFormResponse({ patientId, type });

    setOverviewData((prevState) => ({
      ...prevState,
      ...customFormResponse
    }));
  } catch (error) {
    console.error(error);
    Honeybadger.notify(
      `Clinical note get custom responses - patientId: ${patientId}, error: ${error}`
    );
  } finally {
    setPatientHistoryLoading(false);
  }
};
