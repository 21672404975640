import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { requestApi } from 'api/Api';
import { getStates as getStatesAPI } from 'api/State';
import { io } from 'lib/helpers/utility';
import { showAlert } from 'components/shared/Alert/Alert';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { userState } from 'components/state';
import Profile from './Profile';
import './Profile.scss';
import { getFilestackClient } from 'lib/clients/filestack/filestackClient';

const EditUserValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  dob: Yup.string().required('Date of birth required'),
  address_ln_1: Yup.string().required('Address required'),
  city: Yup.string().required('City required'),
  state: Yup.string().required('State required'),
  zip: Yup.string().required('Zip code required')
});

const ProfileContainer = () => {
  const [states, setStates] = useState([]);
  const [{ user_id }, setUserRecoilState] = useRecoilState(userState);
  const sigPad = useRef(null);
  const formRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getStates();
    getUser();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: '',
      profile_photo: '',
      f_name: '',
      m_name: '',
      l_name: '',
      email: '',
      dob: '',
      gender: '',
      prefix: '',
      suffix: '',
      phone: '',
      marital_status: '',
      race: '',
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: '',
      zip: '',
      signature: null,
      share_signature: false,
      virtual_link: ''
    },
    validationSchema: EditUserValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const userToBeUpdated = {
        user: {
          f_name: values.f_name,
          m_name: values.m_name,
          l_name: values.l_name,
          email: values.email,
          dob: values.dob,
          prefix: values.prefix,
          suffix: values.suffix,
          gender: values.gender,
          phone: values.phone,
          marital_status: values.marital_status,
          race: values.race,
          signature: values.signature,
          share_signature: values.share_signature
        },
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        virtual_link: values.virtual_link
      };

      setSubmitting(true);
      await submitChanges(userToBeUpdated);
      setSubmitting(false);
    }
  });

  const getUser = async () => {
    try {
      let { user } = await requestApi({
        url: '/api/user/get',
        params: { userIdentifier: user_id },
        navigate
      });

      if (!user) {
        formik.resetForm({ values: {} });
        return;
      }
      user.virtual_link = user.practice?.virtual_link;
      delete user.practice;

      formik.resetForm({ values: { ...user, ...user.userAddress } });
    } catch (err) {
      showAlert({
        message: 'There was an error getting user data. Please try again.',
        color: 'danger'
      });
    }
  };

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      showAlert({
        message: 'There was an error getting state data. Please try again.',
        color: 'danger'
      });
    }
  };

  const trimSignature = () => {
    if (sigPad.current) {
      formik.setFieldValue(
        'signature',
        sigPad.current.isEmpty() ? null : sigPad.current.getTrimmedCanvas().toDataURL('image/png')
      );
    }
  };

  const clearSignature = (e) => {
    e.preventDefault();
    if (sigPad.current) {
      sigPad.current.clear();
    }
    formik.setFieldValue('signature', null);
    setUserRecoilState((prev) => ({
      ...prev,
      signature: null
    }));
  };

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    filestackClient.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      let res = await requestApi({
        url: '/api/filestack/profile_photo',
        params: { handle, userId: user_id },
        navigate
      });
      if (res.profilePhoto > 0) {
        formik.setFieldValue('profile_photo', JSON.stringify(handle));
        setUserRecoilState((prev) => ({ ...prev, profile_photo: JSON.stringify(handle) }));
        showAlert({ message: 'Profile photo updated successfully!', color: 'success' });
      }
    } catch (error) {
      showAlert({
        message: 'There was an error uploading this image. Please try again.',
        color: 'danger'
      });
    }
  };

  const submitChanges = async ({ user, address, virtual_link }) => {
    if (user) {
      const changes = {};

      const values = user;
      const initialValues = formik.initialValues;
      for (const key in values) {
        if (values[key] !== initialValues[key]) changes[key] = values[key];
      }

      if (io(changes)) {
        try {
          const onSuccess = async () => {
            showAlert({ message: `User updated successfully!`, color: 'success' });

            setUserRecoilState((prev) => ({
              ...prev,
              f_name: user.f_name,
              l_name: user.l_name,
              email: user.email,
              signature: user?.signature
            }));
          };
          await requestApi({
            url: '/api/user/update',
            params: { user_id, changes },
            navigate,
            onSuccess
          });
        } catch (err) {
          showAlert({ message: `An error has occurred. Please try again later.`, color: 'danger' });
        }
      }
    }

    if (address) {
      const { address_ln_1, address_ln_2, city, state, zip } = formik?.initialValues || {};
      const addressChanged = !isEqual({ address_ln_1, address_ln_2, city, state, zip }, address);

      if (addressChanged) {
        try {
          const onSuccess = async () => {
            showAlert({ message: `Address updated successfully!`, color: 'success' });
          };
          await requestApi({
            url: '/api/address/patch',
            params: { loadedAddress: address, userId: user_id },
            navigate,
            onSuccess
          });
        } catch (err) {
          showAlert({ message: `An error has occurred. Please try again later.`, color: 'danger' });
        }
      }
    }
    if (virtual_link !== formik.initialValues.virtual_link) {
      const onSuccess = async () => {
        showAlert({ message: `Virtual link updated successfully!`, color: 'success' });
      };

      try {
        await requestApi({
          url: '/api/practice/member/update',
          params: { userId: user_id, newMemberSettings: { virtual_link } },
          navigate,
          onSuccess
        });
      } catch (err) {
        showAlert({ message: 'Virtual link failed to update', color: 'danger' });
      }
    }
    formik.resetForm({ values: { ...formik.initialValues, ...user, ...address } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current) {
      trimSignature();
      formik.submitForm();
    }
  };

  if (!formik?.values?.id) {
    return <Skeleton count={1} height="100vh" width="100%" />;
  }

  return (
    <Profile
      formRef={formRef}
      openFilestackPicker={openFilestackPicker}
      formik={formik}
      handleSubmit={handleSubmit}
      states={states}
      sigPad={sigPad}
      clearSignature={clearSignature}
      trimSignature={trimSignature}
    />
  );
};

export default ProfileContainer;
