import Allowed from 'components/shared/Permissions/Allowed';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import { formatDate, ia, isEmpty, stripHtmlTags } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import CreationInfo from './CreationInfo';

const MedicationHistoryOverview = ({ cnSettings = {}, isOpen = {}, toggleOne = () => {} }) => {
  const { overviewData } = useClinicalNoteContext();
  if (!cnSettings?.overview.medications) return null;

  return (
    <Allowed requiredPermissions="patients_medical_history.read">
      <SimpleAccordion
        title="Medications"
        showExpandIcon={ia(overviewData?.medicationHistory)}
        onToggle={() => toggleOne('medicationHistory')}
        open={isOpen.medicationHistory}
        addPermissions="patients_medical_history.update">
        {overviewData?.medicationHistory?.map((item, index) => {
          const medicationLabel = item?.medication;
          const prescribingPhysicianLabel = item?.prescriber?.label;

          return (
            <React.Fragment key={index}>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>

              <div className="grid gap-y-1">
                {!isEmpty(medicationLabel) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Medication:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {medicationLabel}
                    </span>
                  </div>
                )}

                {!isEmpty(item.sig) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      SIG:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {stripHtmlTags(item.sig)}
                    </span>
                  </div>
                )}

                {!isEmpty(item.strength) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Dose:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {item.strength}
                      {item.unit ? ` (${item.unit})` : ''}
                    </span>
                  </div>
                )}

                {!isEmpty(item.quantity) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Quantity:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {item.quantity}
                    </span>
                  </div>
                )}

                {!isEmpty(item.days_supply) && (
                  <div className="flex justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Days Supply
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {item.days_supply}
                    </span>
                  </div>
                )}

                {!isEmpty(item.refills) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Refills:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {item.refills}
                    </span>
                  </div>
                )}

                {ia(item.condition) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Condition:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {item.condition.map((condition, index) => (
                        <span key={index} className="m-0 block first-letter:capitalize">
                          {condition.label}
                        </span>
                      ))}
                    </span>
                  </div>
                )}

                {!isEmpty(item.start_date) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Date Started:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {formatDate(item.start_date)}
                    </span>
                  </div>
                )}

                {!isEmpty(item.end_date) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Date Ended:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {formatDate(item.end_date)}
                    </span>
                  </div>
                )}

                {!isEmpty(prescribingPhysicianLabel) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Prescribing physician:
                    </span>
                    <span className="flex-1 text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                      {prescribingPhysicianLabel}
                    </span>
                  </div>
                )}

                {!isEmpty(item.note) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Note:
                    </span>
                    <span className="break-all text-sm font-400 text-primary-900 first-letter:capitalize">
                      {stripHtmlTags(item.note)}
                    </span>
                  </div>
                )}
              </div>

              <CreationInfo createdAt={item?.created_at} createdBy={item?.createdBy?.fullName} />
            </React.Fragment>
          );
        })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default MedicationHistoryOverview;
