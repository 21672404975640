import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import NewService from '../NewService';
import { ia, io } from 'lib/helpers/utility';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import { getPayScheduleChanges, getServiceChanges } from '../lib/helpers';
import { ServiceValidationSchema, getFinalShapeServiceObj, initialValues } from '../serivceHelpers';

const ServiceModal = ({ item: service, isOpen, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const serviceFormRef = useRef(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues(service),
    validationSchema: ServiceValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const serviceToBeCreated = getFinalShapeServiceObj(values);

      setSubmitting(true);
      service ? await handleUpdate(serviceToBeCreated) : await createService(serviceToBeCreated);
      setSubmitting(false);
    }
  });

  const createService = async (newService) => {
    setLoading(true);
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    randomColor = '#' + randomColor;

    const new_service = {
      ...newService,
      color: randomColor
    };

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['services'], refetchType: 'all' });

      showAlert({
        title: 'Service Creation',
        message: 'Service created successfully',
        color: 'success'
      });

      handleClose();
    };

    requestApi({
      url: `/api/practice/services/create`,
      params: { service: new_service },
      onSuccess,
      navigate
    });
    setLoading(false);
  };

  const handleUpdate = async (updatedService) => {
    setLoading(true);

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['services'], refetchType: 'all' });

      showAlert({
        title: 'Service Update',
        message: 'Service updated successfully',
        color: 'success'
      });

      handleClose();
    };

    const serviceChanges = getServiceChanges(updatedService, service);
    const payScheduleChanges = getPayScheduleChanges(
      formik.values.paySchedule,
      service.payerConnection
    );

    if (!io(serviceChanges) && !ia(payScheduleChanges)) {
      showAlert({ title: 'Service Update', message: 'No changes detected', color: 'warning' });
      setLoading(false);
      return;
    }

    requestApi({
      navigate,
      onSuccess,
      url: `/api/practice/services/update`,
      params: {
        service_id: service.id,
        ...(io(serviceChanges) && { fields: serviceChanges }),
        ...(ia(payScheduleChanges) && { paySchedule: payScheduleChanges })
      }
    });

    setLoading(false);
  };

  return (
    <Modal
      customStyling={{ width: '700px' }}
      handleOpen={isOpen}
      bodyClassName="!p-4 !h-[calc(100vh-115px)]"
      handleClose={handleClose}
      title={`${service ? 'Update' : 'New'} service`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={handleClose}
            id="closeServiceModalBtn"
          />
          <Button
            onClick={() => serviceFormRef.current.submitForm()}
            text={service ? 'Update' : 'Create service'}
            id="createUpdateServiceBtn"
            data-qa="create-update-service-btn"
            loading={loading}
          />
        </div>
      }>
      <NewService formik={formik} ref={serviceFormRef} />
    </Modal>
  );
};

export default ServiceModal;
