import React, { useState } from 'react';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Button from '../../../../../shared/Buttons/Button';
import TPModal from '../../TreatmentPlan/TPModal';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const TreatmentPlan = () => {
  const [showTPModal, setShowTPModal] = useState(false);

  const { device } = useUIContext();

  const { cnDisplaySettings, setSelectedTreatmentPlan } = useClinicalNoteContext();

  if (!cnDisplaySettings?.treatment_plan?.enabled) return null;

  const openTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(true);
  };

  const closeTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(false);
  };

  return (
    <>
      <Button
        text={device === 'desktop' || device === 'laptop' ? 'Treatment Plan' : 'Tx Plan'}
        icon="new-task-square"
        className="hover:!bg-primary-800"
        textClassName="text-primary-100"
        shade={100}
        transparent
        size="small"
        onClick={openTPModal}
        data-qa="treatment-plan-btn"
      />
      {showTPModal && <TPModal isOpen={showTPModal} handleClose={closeTPModal} />}
    </>
  );
};

export default TreatmentPlan;
