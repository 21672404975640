import Button from 'components/shared/Buttons/Button';
import React, { useState } from 'react';
import CustomFormConfirm from './CustomFormConfirm';

const DeleteCustomFormButton = ({
  disabled = false,
  id,
  modalOpen = false,
  hideModal,
  onDeleteItem,
  type
}) => {
  const [itemToDelete, setItemToDelete] = useState(null);

  return (
    <>
      <Button
        icon="trash"
        outlined={true}
        iconColor="black"
        color="neutral"
        className="h-[40px] w-[40px]"
        disabled={disabled}
        onClick={() => setItemToDelete(id)}
      />
      <CustomFormConfirm
        modalOpen={modalOpen}
        type={type}
        hideModal={hideModal}
        itemToDelete={itemToDelete}
        onDeleteItem={onDeleteItem}
        setItemToDelete={setItemToDelete}
      />
    </>
  );
};

export default DeleteCustomFormButton;
