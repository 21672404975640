import React from 'react';
import Tippy from '@tippyjs/react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import { io } from 'lib/helpers/utility';
import { filterNotHiddenComponents, transformData } from '../../../shared/lib/helpers';
import { filterCheckedForms } from '../../lib/helpers';

const List = ({ type }) => {
  const { chatForms, setChatForms, setScrollTo } = useMyTemplateContext();
  const visibleComponents = filterNotHiddenComponents(chatForms?.[type]?.components);
  const currentComponents = Object.entries(visibleComponents);

  const chatSelectedForms = filterCheckedForms(chatForms);
  const formsList = transformData(Object.values(chatSelectedForms));

  const propsForNotFoundNote = {
    icon: 'new-notev1',
    iconSize: 32,
    title: 'No data',
    titleClassName: '!mt-3',
    description: 'No data in display settings'
  };

  const onSelect = (e, itemKey) => {
    e.stopPropagation();
    const { checked = false } = e.target;

    setChatForms((prevChatForms = {}) => {
      return {
        ...prevChatForms,
        [type]: {
          ...prevChatForms?.[type],
          components: {
            ...prevChatForms?.[type]?.components,
            [itemKey]: {
              ...prevChatForms?.[type]?.components?.[itemKey],
              checked
            }
          }
        }
      };
    });

    const chat = document.querySelector('#my-template-chat > div > div.flex');
    if (chat) {
      chat.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const scrollToComponent = (item) => {
    if (!item?.checked) return;

    const idx = formsList.findIndex((form) => form.path === item.path);
    setScrollTo(idx);
  };

  return (
    <div className=" py-[6px] !pr-2 pl-[10px]">
      {io(currentComponents) ? (
        currentComponents?.map(([itemKey, item]) => (
          <Tippy
            content={item?.title}
            placement="right"
            className="tippy-dark"
            key={`${item.title}-${type}`}>
            <div
              className="flex cursor-pointer items-center rounded-md p-2 shadow-md"
              onClick={() => scrollToComponent(item)}>
              <Checkbox
                onChange={(e) => onSelect(e, itemKey)}
                color="blue"
                label={item?.title}
                // disabled={cnSaving}
                value={item.title}
                checked={item?.checked}
                data-qa={`left-sidebar-checkbox-${item.title}`}
              />
            </div>
          </Tippy>
        ))
      ) : (
        <NotFound {...propsForNotFoundNote} />
      )}
    </div>
  );
};

export default List;
