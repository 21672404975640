import { requestApi } from './Api';

export const getSecurity = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filestack/security',
    params
  });
};

export const getFileStackUrl = async (navigate, params = {}) => {
  return requestApi({
    navigate,
    url: '/api/filestack/preview',
    params
  });
};
