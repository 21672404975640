import moment from 'moment';

export default (dashboardData, type, timezone) => {
  const formattedData = dashboardData?.appointmentGraphData
    ? Object.entries(dashboardData?.appointmentGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const offsetMinutes = momentTime.tz(timezone).utcOffset();
        const localDate = new Date(momentTime.valueOf() + offsetMinutes * 60000);

        return {
          timestamp: localDate,
          ...(type === 'status' && {
            unconfirmed: parseFloat(data.unconfirmed),
            confirmed: parseFloat(data.confirmed),
            not_checked_in: parseFloat(data.not_checked_in),
            partially_checked_in: parseFloat(data.partially_checked_in),
            checked_in: parseFloat(data.checked_in),
            balance_not_paid: parseFloat(data.balance_not_paid),
            balance_partially_paid: parseFloat(data.unconfirmed),
            balance_paid: parseFloat(data.balance_paid),
            no_cc_on_file: parseFloat(data.no_cc_on_file),
            cc_on_file: parseFloat(data.cc_on_file),
            cc_not_needed: parseFloat(data.cc_not_needed),
            not_arrived: parseFloat(data.not_arrived),
            no_show: parseFloat(data.no_show),
            arrived_waiting: parseFloat(data.arrived_waiting),
            appointment_ended: parseFloat(data.appointment_ended),
            clinical_note_not_started: parseFloat(data.clinical_note_not_started),
            clinical_note_started: parseFloat(data.clinical_note_started),
            clinical_note_locked: parseFloat(data.clinical_note_locked),
            superbill_not_created: parseFloat(data.superbill_not_created),
            superbill_not_scrubbed: parseFloat(data.superbill_not_scrubbed),
            superbill_scrubbed: parseFloat(data.superbill_scrubbed),
            claim_not_created: parseFloat(data.claim_not_created),
            claim_ready: parseFloat(data.claim_ready),
            claim_acknowledged: parseFloat(data.claim_acknowledged),
            claim_rejected: parseFloat(data.claim_rejected),
            claim_pending: parseFloat(data.claim_pending),
            claim_denied: parseFloat(data.claim_denied),
            claim_paid: parseFloat(data.claim_paid),
            completed: parseFloat(data.completed),
            cancelled: parseFloat(data.cancelled),
            rescheduled: parseFloat(data.rescheduled)
          }),

          ...(type === 'stage' && {
            checkin: parseFloat(data.checkin),
            financial: parseFloat(data.financial),
            clinical: parseFloat(data.clinical),
            billing: parseFloat(data.billing),
            final: parseFloat(data.final)
          }),

          ...(type === 'confirmation' && {
            cancelled: parseFloat(data.cancelled),
            // random
            // cancelled: Math.floor(Math.random() * 100),
            confirmed: parseFloat(data.confirmed),
            unconfirmed: parseFloat(data.unconfirmed)
          })
        };
      })
    : [];

  return formattedData;
};
