import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { formatKind } from '../../../../lib';
import { capitalize } from 'lodash';
import Switch from 'components/shared/Switch/Switch';
import { useFormikContext } from 'formik';
import { ia } from 'lib/helpers/utility';
import { useInsuranceProfiles } from 'lib/hooks/queries/insuranceProfiles/useInsuranceProfiles';

const Insurance = ({ handleSelectInsurance }) => {
  const { values, setFieldValue } = useFormikContext();
  const priorAuthNeeded = values.checkin?.prior_auth_needed;
  const isAptDisabled = values.appointment_disabled;

  const params = {
    userId: values?.patient?.id
  };

  const { data } = useInsuranceProfiles({ params });
  const insurances = data?.insuranceProfile || [];

  const handleSetAuthNeeded = (value) => setFieldValue('checkin.prior_auth_needed', value);
  return (
    <>
      <div className="mt-2 flex items-center gap-[6px]">
        {insurances?.length ? (
          <Icon
            icon="new-check"
            color="success"
            stroke={true}
            size={20}
            className="cursor-pointer"
          />
        ) : (
          <span className="mb-1 text-[20px]">-</span>
        )}
        <h4 className="text-sm font-500 text-neutral-800">Insurance</h4>
      </div>
      {ia(insurances) ? (
        <div className="ml-12 mt-[2px]">
          {insurances?.map((insurance, idx) => (
            <div key={idx} className="mb-[2px] flex items-center justify-between">
              <div className=" flex w-full items-center justify-between gap-2">
                <p className="text-sm text-neutral-800">{capitalize(insurance.type)}</p>
                <p
                  className="max-w-full cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-sm text-primary-600"
                  onClick={() => handleSelectInsurance(insurance)}>
                  {formatKind(insurance.payer_name)}
                </p>
              </div>
            </div>
          ))}
          <div className="mb-[2px] flex items-center justify-between gap-[6px]">
            <p className="text-sm text-neutral-800">Prior Auth Needed</p>
            <div className="flex items-center gap-1">
              <p className={`text-sm text-neutral-${priorAuthNeeded ? '400' : '800'}`}>No</p>
              <Switch
                height={12}
                width={20}
                checked={priorAuthNeeded}
                onChange={handleSetAuthNeeded}
                className="-mt-2"
                disabled={isAptDisabled}
              />
              <p className={`text-sm text-neutral-${priorAuthNeeded ? '800' : '400'}`}>Yes</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-6 mt-[2px]">
          <p className="text-sm text-neutral-800">Patient doesn't have an insurance.</p>
        </div>
      )}
    </>
  );
};

export default Insurance;
