const { camelCase } = require('lodash');
const { iaRa } = require('../../../../../../lib/helpers/utility');
const { initialStatesRender } = require('./initials');
const moment = require('moment-timezone');

exports.mapRenderedInitialState = (selected) => {
  return createInitialRenderStates(selected);
};

const createInitialRenderStates = (selected) => {
  return initialStatesRender.map((key) => {
    return {
      key,
      checked: selected?.[key]?.checked,
      position: selected?.[key]?.position,
      checkedFormTypes: getCheckedFormTypes(selected?.[key]?.formType)
    };
  });
};

const getCheckedFormTypes = (selected) => {
  const selectedArray = iaRa(selected);

  const result = {};

  selectedArray.forEach((item) => {
    const key = camelCase(`is ${item?.title ?? ''} checked`);
    result[key] = selectedArray
      .filter((s) => s?.title === item?.title)
      .every((obj) => obj?.checked === true);
  });

  return {
    ...result,
    isChecked: (form) =>
      selectedArray?.filter((s) => s.title === form)?.every((obj) => obj.checked === true)
  };
};

exports.processSelectedForms = ({ selected, rendered, componentsOrder = null }) => {
  const objectsToAdd = [];
  const hpForms = [];
  const soapForms = [];
  const advancedForms = [];

  for (const key in selected) {
    if (selected.hasOwnProperty(key)) {
      // Compare selected with rendered
      const objectExistsInRendered = rendered.some((item) => item.key === key);
      // Add the new object
      if (!objectExistsInRendered) {
        const newFormObject = {
          key,
          advancedType: selected[key].advancedType,
          title: selected[key].title,
          checked: selected[key].checked,
          position: selected[key].position,
          checkedFormTypes: getCheckedFormTypes(selected?.[key]?.formType)
        };
        objectsToAdd.push(newFormObject);
        if (selected[key].advancedType === 'hp') {
          hpForms.push(newFormObject);
        } else if (selected[key].advancedType === 'soap') {
          soapForms.push(newFormObject);
        } else if (selected[key].advancedType === 'customForm') {
          advancedForms.push(newFormObject);
        }
      }
    }
  }

  const updatedRendered = rendered.concat(objectsToAdd);

  const checkedRendered = sortByPosition(updatedRendered, componentsOrder);

  return { checkedRendered, hpForms, soapForms, advancedForms };
};

const sortByPosition = (selection, reference = null) => {
  return selection
    .filter((item) => item.checked)
    .sort((a, b) => {
      const getPosition = (item) => {
        if (!reference) return item.position;

        const refItem = reference[item.key];
        return refItem?.position !== undefined ? refItem.position : item.position;
      };

      const posA = getPosition(a);
      const posB = getPosition(b);

      if (typeof posA === 'number' && typeof posB === 'number') {
        return posA - posB;
      }
      if (typeof posA === 'number') {
        return -1;
      }
      if (typeof posB === 'number') {
        return 1;
      }
      return 0;
    });
};

exports.isToday = (date, timezone) => {
  try {
    const now = moment().tz(timezone).startOf('day'); // Start of today in the given timezone
    const inputDate = moment.tz(date, timezone).startOf('day'); // Start of the input date in the given timezone

    return now.isSame(inputDate, 'day');
  } catch (error) {
    console.log({ error });
  }
};

exports.filterTodayOnly = (timezone, data, field = 'created_at') =>
  iaRa(data).filter((item) => this.isToday(item[field], timezone));

exports.formatData = (data, field = 'row') => {
  return data?.reduce((prev, curr) => {
    return [...prev, ...(curr?.response?.[field] || [])];
  }, []);
};
