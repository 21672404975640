export default {
  X_SMALL: 16,
  SMALL: 24,
  MEDIUM: 32,
  LARGE: 40,
  EXTRA_LARGE: 48,
  '2Extra_LARGE': 56,
  '3Extra_LARGE': 64,
  '4Extra_LARGE': 72
};
