import { mString } from 'lib/helpers/utility';
import Actions from '../components/Actions';

export const reShapeProcedures = (data) => {
  return (
    data?.services?.map((procedure) => ({
      id: procedure.id,
      label: procedure.name,
      procedure_code: procedure.procedure_code,
      timeLength: procedure?.time_length,
      amount_cents: procedure.total_cost_cents,
      total_amount_cents: procedure.total_cost_cents,
      quantity: 1,
      value: procedure.id
    })) || []
  );
};

export const reShapeProducts = (data) => {
  return (
    data?.products?.map((product) => ({
      id: product.id,
      label: product.name,
      amount_cents: product.amount_cents,
      total_amount_cents: product.amount_cents,
      quantity: 1,
      inventory: product.quantity,
      value: product.id
    })) || []
  );
};

export const getFinalPackageObjShape = (values) => ({
  name: values.name,
  note: values.note,
  image: values.image,
  amount_cents: Number(values.amount_cents),
  validity_time_length: values.validity_time_length || 0,
  validity_time_unit: values.validity_time_unit,
  no_expiration: values.no_expiration,
  procedures: values.procedures
    .filter((s) => !!s.label)
    .map((item) => {
      const newItem = { ...item, name: item.label, sales_count: 0 };
      delete newItem.value;
      delete newItem.label;
      return newItem;
    }),
  products: values.products
    .filter((s) => !!s.label)
    .map((item) => {
      const newItem = { ...item, name: item.label };
      delete newItem.value;
      delete newItem.label;
      return newItem;
    })
});

export const checkIfProcedureOrProduct = (procedures, products) => {
  procedures = procedures.filter((procedure) => procedure.label);
  products = products.filter((product) => product.label);
  if (procedures.length + products.length === 0) {
    return true;
  }
  return false;
};

export const formatValidity = (timeLength, timeUnit, valid, pack) => {
  if (valid) return 'No expiration';
  if (timeLength == 1) {
    return timeLength + ' ' + timeUnit;
  }
  return timeLength + ' ' + timeUnit + 's';
};

export const timeUnitArray = [
  { value: 'day', label: 'day(s)' },
  { value: 'week', label: 'week(s)' },
  { value: 'month', label: 'month(s)' },
  { value: 'year', label: 'year(s)' }
];

export const defaultFilters = {
  name: {
    type: 'search',
    values: null,
    title: 'Name',
    placeholder: 'Search name'
  }
};

export const defaultColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'procedures',
    headerName: 'Services',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => value?.length || '-'
  },
  {
    field: 'products',
    headerName: 'Products',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => value?.length || '-'
  },
  {
    field: 'validity',
    headerName: 'Validity',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ data }) =>
      formatValidity(data.validity_time_length, data.validity_time_unit, data.no_expiration, data)
  },
  {
    field: 'amount_cents',
    headerName: 'Price',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
